import gql from 'graphql-tag';

export const companyLogo = gql`
    query getLogoByCompany($companyId: String!) {
        getLogoByCompany(companyId: $companyId) {
            logo
        }
    }`;

export const companiesByUserLogged = gql`
    query getCompaniesByUserLogged{
        getCompaniesByUserLogged{
            idCompany{
                id,
                name,
                cif,
                logo,
                contactEmail,
                contactPerson,
                contactPhone,
                contactAddress,
                contactCity,
                contactCountry
                companyConfiguration {
                    id,
                    onlyRankingCompany,
                    language,
                    features {
                        id,
                        name
                        }
                    }
            },
            idEmployeeRole{
                id,
                name
            }
        }
    }`;
export const companiesByUser = gql`
query getCompaniesByUser($userId: String!){
    getCompaniesByUser(userId: $userId){
        idCompany{
            id,
            name,
        },
        idEmployeeRole{
            id,
            name
        }
    }
}`;

export const companiesByCif = gql`
    query getCompaniesByCifByUserLogged{
        getCompaniesByCifByUserLogged{
            id,
            name,
            cif,
            logo,
            contactEmail,
            contactPerson,
            contactPhone,
            contactAddress,
            contactCity,
            contactCountry
        }
    }`;

export const getCompanylogoDownloadUrl = gql`
query getCompanylogoDownloadUrl($companyId: String!) {
    getCompanylogoDownloadUrl(companyId: $companyId)
}`;

export const getCompanylogoUploadUrl = gql`
query getCompanylogoUploadUrl($logo: String!,$contentType: String!) {
    getCompanylogoUploadUrl(logo: $logo,contentType: $contentType)
}`;