import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Subscription } from 'rxjs';
import { concatAll } from 'rxjs/operators';
import { Company } from 'src/app/core/models/company/company.model';
import { User } from 'src/app/core/models/user/User.model';
import { EventService } from 'src/app/core/services/event.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { UserFilterService } from 'src/app/shared/services/userFilter.service';
import { environment } from 'src/environments/environment';
import { ScheduleControlService } from '../../../core/services/shedulecontrol.service';
import { SubscribeTypes } from '../../../shared/types/subcribes.types';
import { ScheduleControlComponent } from '../schedule-control/schedule-control.component';
import { StorageService } from '../../../core/services/storage.service';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
  selector: 'app-schedule-panel',
  templateUrl: './schedule-panel.component.html',
  styleUrls: ['./schedule-panel.component.scss']
})
export class SchedulePanelComponent implements OnInit, OnDestroy {

  selectCompany: Company[];
  companies: Company[];
  users: User[];
  urlAvatar = environment.storageBucket;
  defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
  areWorking = [];
  companiesSubcription: Subscription;
  scheduleAddSubcription: Subscription;

  constructor(
    private eventService: EventService,
    private userService: UserProfileService,
    private userFilterService: UserFilterService,
    private modalService: NgbModal,
    private scheduleService: ScheduleControlService,
    private storageService: StorageService,
    private translateService: TranslatorService,
  ) { }

  ngOnInit() {
    this.eventService.broadcast('changePageHeading', this.translateService.trans('schedule.panel.tittle'));
    this.companiesSubcription = this.eventService.subscribe(new SubscribeTypes().COMPANIES, selectedCompanies => {
      this.selectCompany = selectedCompanies;
      this.getUsers();
    });
    this.scheduleAddSubcription = this.eventService.subscribe(new SubscribeTypes().SCHEDULE_ADD, workInDone => {
      this.showIfWorking();
    });
    this.selectCompany = this.storageService.getCompanies();
    this.getUsers();
  }
  ngOnDestroy() {
    this.companiesSubcription.unsubscribe();
    this.scheduleAddSubcription.unsubscribe();
  }

  getUsers() {
    const userObservables = [];
    this.selectCompany.forEach(companyId => {
      userObservables.push(this.userService.usersByCompany$(companyId.id));
    });

    let users = [];
    from(userObservables)
      .pipe(concatAll())
      .subscribe((companyUsers: any) => {
        users = [...users, ...companyUsers];
      }, () => { }, () => {
        this.users = this.userFilterService.filterUniqueUsers(users);
        this.users.sort((a, b) => a.name > b.name ? 1 : -1);
        this.showIfWorking();

      });
  }

  errorHandler(event) {
    event.target.src = this.defaultAvatar;
  }

  openModalWithComponent(user: User) {
    const scheduleControlModal = this.modalService.open(ScheduleControlComponent, { ariaLabelledBy: 'static' });
    scheduleControlModal.componentInstance.user = user;
  }

  showIfWorking() {
    this.areWorking = [];
    this.users.forEach(user => {
      this.scheduleService.isWorking$(user.id, new Date()).subscribe(working => {
        this.areWorking[user.id] = [];
        this.areWorking[user.id] = working;
      });
    });
  }

}
