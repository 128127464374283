import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createCompanyUserEmployeeRole, deleteCompanyUserEmployeeRole, updateEmployeeRole, createEmployeeRole } from 'src/app/graphql/operations/employeerole/mutations';
import { EmployeeRoleByCompany, getCompanyUserByEmployeeRole, getEmployeeRolesFiltered, getCompanyEmployeeRoleByUser } from '../../graphql/operations/employeerole/queries';
import { CompanyUserEmployeeRole } from '../models/companyuseremployeerole/companyUserEmployeeRole.model';
import { CompanyUserEmployeeRolesInput } from '../models/companyuseremployeerole/companyUserEmployeeRoles.input';
import { EmployeeRoleByCompanyResponse } from '../models/employeeRole/companyemployeeroletargettypes.response';
import { EmployeeRole } from '../models/employeeRole/employeeRole.model';
import { GetCompanyUserByEmployeeRoleInput } from '../models/employeeRole/getCompanyUserByEmployeeRole.input';
import { GetEmployeeRolesFilteredInput } from '../models/employeeRole/getEmployeeRolesFiltered.input';
import { CompanyUserEmployeeRoleInput } from '../models/companyuseremployeerole/companyUserEmployeeRole.input';
import { EmployeeRoleInput } from '../models/employeeRole/employeeRole.input';
@Injectable({ providedIn: 'root' })
export class EmployeeRoleService {
    constructor(
        private readonly apollo: Apollo) { }

    employeeRoleByCompany$(companyId: string): Observable<EmployeeRoleByCompanyResponse> {
        return this.apollo
            .query({
                query: EmployeeRoleByCompany,
                variables: {
                    companyId
                }
            }).pipe(map(result => {
                return new EmployeeRoleByCompanyResponse(result.data['getEmployeeRoleByCompany']);
            }));
    }
    getEmployeeRolesFiltered$(getEmployeeRolesFilteredInput: GetEmployeeRolesFilteredInput): Observable<EmployeeRole[]> {
        return this.apollo
            .query({
                query: getEmployeeRolesFiltered,
                variables: {
                    getEmployeeRolesFilteredInput
                }
            }).pipe(map(result => {
                return result.data['getEmployeeRolesFiltered'];
            }));
    }

    getCompanyUserByEmployeeRole$(getCompanyUserByEmployeeRoleInput: GetCompanyUserByEmployeeRoleInput): Observable<CompanyUserEmployeeRole[]> {
        return this.apollo
            .query({
                query: getCompanyUserByEmployeeRole,
                variables: {
                    getCompanyUserByEmployeeRoleInput
                }
            }).pipe(map(result => {
                return result.data['getCompanyUserByEmployeeRole'];
            }));
    }

    getCompanyEmployeeRoleByUser$(getCompanyUserByEmployeeRoleInput: GetCompanyUserByEmployeeRoleInput): Observable<CompanyUserEmployeeRole[]> {
        return this.apollo
            .query({
                query: getCompanyEmployeeRoleByUser,
                variables: {
                    getCompanyUserByEmployeeRoleInput
                }
            }).pipe(map(result => {
                return result.data['getCompanyEmployeeRoleByUser'];
            }));
    }

    createCompanyUserEmployeeRole$(companyUserEmployeeRolesInput: CompanyUserEmployeeRolesInput) {
        return this.apollo
            .mutate({
                mutation: createCompanyUserEmployeeRole,
                variables: {
                    companyUserEmployeeRolesInput
                }
            }).pipe(map(result => {
                return result.data['createCompanyUserEmployeeRole'];
            }));
    }

    deleteCompanyUserEmployeeRole$(companyUserEmployeeRoleInput: CompanyUserEmployeeRoleInput) {
        return this.apollo
            .mutate({
                mutation: deleteCompanyUserEmployeeRole,
                variables: {
                    companyUserEmployeeRoleInput
                }
            }).pipe(map(result => {
                return result.data['deleteCompanyUserEmployeeRole'];
            }));
    }

    createEmployeeRole$(employeeRoleInput: EmployeeRoleInput) {
        return this.apollo
            .mutate({
                mutation: createEmployeeRole,
                variables: {
                    employeeRoleInput
                }
            }).pipe(map(result => {
                return result.data['createEmployeeRole'];
            }));
    }

    updateEmployeeRole$(employeeRoleInput: EmployeeRoleInput) {
        return this.apollo
            .mutate({
                mutation: updateEmployeeRole,
                variables: {
                    employeeRoleInput
                }
            }).pipe(map(result => {
                return result.data['updateEmployeeRole'];
            }));
    }


}

