import gql from 'graphql-tag';

export const getTasks = gql`
    query getTasks($getTasksInput: GetTasksInput!) {
        getTasks(getTasksInput: $getTasksInput) {
            tasks {
                id,
                name,
                description,
                dueDate,
                dateStart,
                dateEnd,
                status,
                archived
                user {
                    id,
                    name,
                    lastName,
                    avatar
                },
                userCreator {
                    name,
                    lastName,
                    avatar
                }
            }
        }
    }`;
export const getTasksArchived = gql`
    query getTasksArchived($getTasksInput: GetTasksInput!) {
        getTasksArchived(getTasksInput: $getTasksInput) {
            tasks {
                id,
                name,
                description,
                dueDate,
                dateStart,
                dateEnd,
                status,
                archived
                user {
                    id,
                    name,
                    lastName,
                    avatar
                },
                userCreator {
                    name,
                    lastName,
                    avatar
                }
            }
        }
    }`;

export const getTask = gql`
    query getTask($getTaskInput: GetTaskInput!) {
        getTask(getTaskInput: $getTaskInput) {
            task {
                id,
                name,
                description,
                dueDate,
                dateStart,
                dateEnd,
                status,
                user {
                    id,
                    name,
                    lastName,
                    avatar
                },
                userCreator {
                    id,
                    name,
                    lastName,
                    avatar
                }
            }
        }
    }`;

