import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { UpdateWeighingOfCompanyEmployeeRoleTargetType } from '../models/companyemployeeroletargettypes/updateWeighingOfCompanyEmployeeRoleTargetType.input';
import { updateWeighingMutation } from '../../graphql/operations/companyemployeeroletarget/mutations';

@Injectable({ providedIn: 'root' })
export class CompanyEmployeeRoleTargetService {
    constructor(
        private readonly apollo: Apollo) { }

    updateWeighingOfCompanyEmployeeRoleTarget$(updateWeighingOfCompanyEmployeeRoleTarget: UpdateWeighingOfCompanyEmployeeRoleTargetType) {
        return this.apollo
            .mutate({
                mutation: updateWeighingMutation,
                variables: {
                    updateWeighingOfCompanyEmployeeRoleTarget
                }
            }).pipe(map(result => {
                return Boolean;
            }));
    }

}
