import { Component, OnInit, OnDestroy } from '@angular/core';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { EmployeeRoleByCompanyResponse } from 'src/app/core/models/employeeRole/companyemployeeroletargettypes.response';
import { EmployeeRole } from 'src/app/core/models/employeeRole/employeeRole.model';
import { Target } from 'src/app/core/models/target/target.model';
import { TargetsByEmployeeRoleByDateByCompanyResponse } from 'src/app/core/models/target/TargetsByEmployeeRoleByDateByCompany.response';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EmployeeRoleService } from 'src/app/core/services/employeerole.service';
import { EventService } from 'src/app/core/services/event.service';
import { TargetService } from 'src/app/core/services/target.service';
import { RolType } from 'src/app/shared/types/rol.types';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { Company } from '../../core/models/company/company.model';
import { StorageService } from '../../core/services/storage.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-targets',
    templateUrl: './targets.component.html',
    styleUrls: ['./targets.component.scss']
})
export class TargetsComponent implements OnInit,OnDestroy {

    targets: Target[];
    employeeRoles: any[];
    companySelected: Company;
    companies: Company[];
    arrayOfEmployeeRoles: EmployeeRole[];
    currentDate = new Date();
    selectDate = this.currentDate;
    employeeRoleSelected;
    rolType: RolType = new RolType();
    userLogged;
    showDates = true;
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    selectEmployeeRole: any;
    typeRoleSelected: EmployeeRole;
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    locale: string = 'es';
    locales = listLocales();
    companiesSubcription: Subscription;

    constructor(
        private employeeRoleService: EmployeeRoleService,
        private eventService: EventService,
        private targetService: TargetService,
        private readonly storageService: StorageService,
        private authService: AuthenticationService,
        private localeService: BsLocaleService
    ) {
        this.employeeRoles = new Array<EmployeeRoleByCompanyResponse>();
        defineLocale(this.locale, esLocale);
        this.localeService.use(this.locale);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'YYYY-MM-DD'});
    }

    ngOnInit() {
        this.userLogged = this.authService.getUser().roles;
        this.companiesSubcription=this.eventService.subscribe(this.subscribeTypes.COMPANIES, companies => {
            this.companySelected = companies[0].id;
            if (this.userLogged === this.rolType.ROLE_USER) {
                this.showDates = false;
                this.eventService.subscribe(this.subscribeTypes.USER_ROLES, companyRoles => {
                    this.employeeRoles = companyRoles;
                    this.typeRoleSelected = this.employeeRoles[0];
                    this.agentChargeTarget(this.typeRoleSelected);
                });
            } else {
                this.showDates = true;
                this.getEmployeeRoles(companies);

                if (this.employeeRoleSelected) {
                    this.getTargets(this.companySelected, this.currentDate, this.employeeRoleSelected);

                }
            }

        });
        // if (this.userLogged.roles !== this.rolType.ROLE_USER) {
        //     this.getEmployeeRoles(this.storageService.getCompanies());
        // }
    }

    ngOnDestroy(){
        this.companiesSubcription.unsubscribe();
    }

    private getEmployeeRoles(selectedCompanies: Company[]) {
        this.employeeRoles = [];
        selectedCompanies
            .map(company => company.id)
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach(companyId => {
                this.getEmployeeRole(companyId);
            });
    }

    getEmployeeRole(companySelected) {
        this.employeeRoleService.employeeRoleByCompany$(companySelected).subscribe(
            (employeeRoleByCompanyResponse: EmployeeRoleByCompanyResponse) => {
                employeeRoleByCompanyResponse.employeeRole.forEach(rol => {
                    const filteredRoles = this.employeeRoles.filter(roles => {
                        return roles.idEmployeeRole.id === rol.idEmployeeRole.id && roles.idCompany.id === rol.idCompany.id;
                    });
                    if (filteredRoles.length === 0) {
                        this.employeeRoles.push(rol);
                    }
                });
            });
        this.companySelected = companySelected;
    }

    agentChargeTarget(employeeRole: EmployeeRole) {
        if (this.userLogged === this.rolType.ROLE_USER) {
            this.sendEmployeeRoleSelected(employeeRole);
        }
    }

    getTargets(companySelected, date, employeeRole) {
        this.targetService.targetByEmployeeRoleByDateByCompany$(companySelected, date, employeeRole).subscribe(
            (targetsByEmployeeRoleByDateByCompanyResponse: TargetsByEmployeeRoleByDateByCompanyResponse) => {
                this.targets = targetsByEmployeeRoleByDateByCompanyResponse.target;
            });
    }

    sendEmployeeRoleSelected(employeeRole) {
        this.employeeRoleSelected = employeeRole.idEmployeeRole.id;
        this.getTargets(this.companySelected, this.currentDate, employeeRole.idEmployeeRole.id);
    }

    sendSelectedDate(date) {
        this.getTargets(this.companySelected, date, this.employeeRoleSelected);
    }
}
