import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { activityTargetsByUserInDateSelected } from 'src/app/graphql/operations/activity/queries';
import { UpdateActivityResponse } from '../../core/models/activity/UpdateActivity.response';
import { createActivityMutation, updateActivityMutation, deleteActivityMutation } from '../../graphql/operations/activity/mutations';
import { ActivityTargetsByUserInDateSelectedResponse } from '../models/activity/ActivityTargetsByUserInDateSelected.response';
import { CreateActivityInput } from '../models/activity/createActivity.input';
import { CreateActivityResponse } from '../models/activity/createActivity.response';
import { DeleteActivityInput } from '../models/activity/deleteActivityInput.input';


@Injectable({ providedIn: 'root' })
export class ActivityService {
    constructor(
        private readonly apollo: Apollo) { }

    createActivity$(activity: CreateActivityInput): Observable<CreateActivityResponse> {
        return this.apollo
            .mutate({
                mutation: createActivityMutation,
                variables: {
                    activity
                }
            }).pipe(map(result => {
                return new CreateActivityResponse(result.data['createActivity']);
            }));
    }

    updateActivity$(activityId: string, realAmount: number): Observable<UpdateActivityResponse> {
        return this.apollo
            .mutate({
                mutation: updateActivityMutation,
                variables: {
                    activityId,
                    realAmount
                }
            }).pipe(map(result => {
                return new UpdateActivityResponse(result.data['updateActivity']);
            }));
    }

    activityTargetsByUserInDateSelected$(companyId: string, userId: string, dateRange: Date[]): Observable<ActivityTargetsByUserInDateSelectedResponse> {
        return this.apollo
            .query({
                query: activityTargetsByUserInDateSelected,
                variables: {
                    companyId,
                    userId,
                    dateRange
                }
            }).pipe(map(result => {
                return new ActivityTargetsByUserInDateSelectedResponse(result.data['getActivityTargetsByUserInDateSelected']);
            }));
    }

    deleteActivity$(deleteActivityInput: DeleteActivityInput) {
        return this.apollo
            .mutate({
                mutation: deleteActivityMutation,
                variables: {
                    deleteActivityInput
                }
            }).pipe(map(result => {
                return Boolean;
            }));
    }
}
