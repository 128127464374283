import { Injectable } from '@angular/core';
import { startOfDay } from '@fullcalendar/core';
import { differenceInBusinessDays, differenceInDays, isSameDay } from 'date-fns';
import { TranslatorService } from './translator.service';

@Injectable({ providedIn: 'root' })


export class TaskThermometerResolver {
    constructor(
        private translateService: TranslatorService,
    ){}

    getThermometerStatus(date: Date) {
        date = new Date(date);
        if (differenceInBusinessDays(date, new Date()) > 5) {
            return 'info';
        } else if (differenceInBusinessDays(date, new Date()) > 1) {
            return 'warning';
        } else if (differenceInBusinessDays(date, new Date()) <= 1) {
            return 'danger';
        } else {
            return 'success';
        }
    }
    getThermometerType(date: Date) {
        date = new Date(date);
        if (differenceInBusinessDays(date, new Date()) > 5) {
            return 'quarter';
        } else if (differenceInBusinessDays(date, new Date()) > 1) {
            return 'three-quarters';
        } else if (differenceInBusinessDays(date, new Date()) <= 1) {
            return 'full';
        } else {
            return 'empty';
        }
    }
    getRestDays(date: Date) {
        date = new Date(date);
        if (isSameDay(date, new Date())) {
            return this.translateService.trans('task.rest.today');
        } else if (startOfDay(date) > startOfDay(new Date())) {
            if (differenceInDays(date, new Date()) === 0) {
                return this.translateService.trans('task.rest.day');
            } else {
                return this.translateService.trans('task.rest.general') +' '+ differenceInDays(date, new Date()) + ' '+this.translateService.trans('task.rest.days');
            }

        } else {
            return this.translateService.trans('task.rest.end');
        }
    }
}
