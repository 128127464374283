import gql from 'graphql-tag';

export const ActivityByUserByCompanyByDateRange = gql`
    query getActivityByUserByCompanyByDateRange($companyIds: [String]!,$dateRange: [DateTime]!, $userIds: [String]!) {
        getActivityByUserByCompanyByDateRange(companyIds: $companyIds, dateRange: $dateRange, userIds: $userIds) {
            id,
            name,
            lastName,
            activitys{
                id,
                dateDay,
                realAmount,
                target{
                    id
                }
            }
        }
    }`;

export const TargetsByUserByCompanyByDateRange = gql`
    query getTargetsByUserByCompanyByDateRange($companyIds: [String]!,$dateRange: [DateTime]!, $userIds: [String]!) {
        getTargetsByUserByCompanyByDateRange(companyIds: $companyIds, dateRange: $dateRange, userIds: $userIds) {
            id,
            name,
            lastName,
            color,
            companyUserEmployeeRoles{
                idEmployeeRole{
                    id,
                    name
                    companyEmployeeRoleTargetTypes{
                        idTarget{
                            id,
                            name,
                            targetEspecifications{
                                id,
                                startDate,
                                finishDate,
                                goal
                            },
                        },
                        weighing
                }
            },
        },
        }
    }`;

export const UsersByCompany = gql`
    query getUsersByCompany($companyId: String!) {
        getUsersByCompany(companyId: $companyId) {
           id,
           name,
           lastName,
           color,
           roles,
           avatar,
           email,
           contactPhone,
           status,
           companyUserEmployeeRoles{
               idCompany {
                   id,
                   name
               }
           }
        }
    }`;

export const usersAllByCompany = gql`
query usersAllByCompany($companyId: String!) {
    getAllUsersByCompany(companyId: $companyId) {
       id,
       name,
       lastName,
       color,
       roles,
       avatar,
       email,
       contactPhone,
       status,
       companyUserEmployeeRoles{
           idCompany {
               id,
               name
           }
       }
    }
}`;

export const allVisibleUsersByCompany = gql`
    query getAllVisibleUsersByCompany($companyId: String!) {
        getAllVisibleUsersByCompany(companyId: $companyId) {
           id,
           name,
           lastName,
           color,
           roles,
           avatar,
           email,
           contactPhone,
           status,
           companyUserEmployeeRoles{
               idCompany {
                   id,
                   name
               }
           }
        }
    }`;

export const AgentsActivityAndTarget = gql`
query getUserWithActivityAndTargetRatioByCompany($companyId: String!, $dateDay: String!){
  getUserWithActivityAndTargetRatioByCompany(companyId: $companyId, dateDay: $dateDay){
    id,
    name,
    lastName,
    avatar,
    color,
    roles,
 	companyUserEmployeeRoles{
        idEmployeeRole{
        id,
        name
        companyEmployeeRoleTargetTypes{
            idTarget{
                id,
                name,
                targetEspecifications{
                    id,
                    startDate,
                    finishDate,
                    goal
                },
            },
            weighing
        }
        },
    },
    activitys{
        id,
        realAmount,
        dateDay,
        target{
            id
        }
    }
  }
}`;

export const getUsersActivityAndTargetByCompany = gql`
query getUsersActivityAndTargetByCompany($input: GetUsersActivityAndTargetByCompanyInput){
    getUsersActivityAndTargetByCompany(getUsersActivityAndTargetByCompanyInput: $input){
    id,
    name,
    lastName,
    avatar,
    color,
    roles,
 	companyUserEmployeeRoles{
        idEmployeeRole{
        id,
        name
        companyEmployeeRoleTargetTypes{
            idTarget{
                id,
                name,
                targetEspecifications{
                    id,
                    startDate,
                    finishDate,
                    goal
                },
            },
            weighing
        }
        },
    },
    activitys{
        id,
        realAmount,
        dateDay,
        target{
            id
        }
    }
  }
}`;

export const UserRankingWithActivityAndTargetRatioByCompany = gql`
query getWostUserWithActivityAndTargetRatioByCompany($companyId: String!, $startDate: DateTime!, $finishDate: DateTime!){
    getWostUserWithActivityAndTargetRatioByCompany(companyId: $companyId, startDate: $startDate, finishDate: $finishDate){
        id,
        name,
        lastName,
        avatar,
        color,
        roles,
        companyUserEmployeeRoles{
            idEmployeeRole{
            id,
            name
            companyEmployeeRoleTargetTypes{
                idTarget{
                    id,
                    name,
                    targetEspecifications{
                        id,
                        startDate,
                        finishDate,
                        goal
                    },
                },
                weighing
            }
            },
            idCompany{
                id,
                name
            }
        },
        activitys{
            id,
            realAmount,
            dateDay,
            target{
                id
            }
        }
    }
}`;


export const UserRankingWithAcitivyAndTargetRatioByCompanyByUsers = gql`
query getUserWithActivityAndTargetRatioByCompanyByUsers($companyId: String!, $startDate: DateTime!, $finishDate: DateTime!, $userIds: [String]!){
    getUserWithActivityAndTargetRatioByCompanyByUsers(companyId: $companyId, startDate: $startDate, finishDate: $finishDate, userIds: $userIds){
        id,
        name,
        lastName,
        avatar,
        color,
        roles,
        companyUserEmployeeRoles{
            idEmployeeRole{
            id,
            name
            companyEmployeeRoleTargetTypes{
                idTarget{
                    id,
                    name,
                    targetEspecifications{
                        id,
                        startDate,
                        finishDate,
                        goal
                    },
                },
                weighing
            }
            },
            idCompany{
                id,
                name
            }
        },
        activitys{
            id,
            realAmount,
            dateDay,
            target{
                id
            }
        }
    }
}`;

export const getUserAvatarDownloadUrl = gql`
    query getUserAvatarDownloadUrl($userId: String!) {
        getUserAvatarDownloadUrl(userId: $userId)
    }`;

export const getUserAvatarUploadUrl = gql`
    query getUserAvatarUploadUrl($avatar: String!,$contentType: String!) {
        getUserAvatarUploadUrl(avatar: $avatar,contentType: $contentType)
    }`;
