import { Component, OnInit } from '@angular/core';
import { UserLogged } from 'src/app/core/models/auth.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EventService } from 'src/app/core/services/event.service';
import { environment } from '../../../environments/environment';
import { CompanyGroupService } from '../../core/services/companyGroup.service';


@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

    urlLogo: string;
    cifCompanySelected: string;
    currentUser: UserLogged;
    logoBucket = environment.storageBucket;
    defaultLogo = environment.storageBucket + '/company/logo/default.png';

    constructor(
        private eventService: EventService,
        private authService: AuthenticationService,
        private companyGroupService: CompanyGroupService,
    ) {
    }


    ngOnInit() {
        this.currentUser = this.authService.getUser();
        this.eventService.subscribe('SelectedCompanies', arrayOfcompanyRecived => {
            this.companyGroupService.getCompanyGroupByCompany$(arrayOfcompanyRecived[0].id).subscribe(data => {
                this.urlLogo = this.logoBucket + '/' + arrayOfcompanyRecived[0].logo;
            });
        });
    }
    errorHandler(event) {
        event.target.src = this.defaultLogo;
    }
}
