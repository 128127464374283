import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { forkJoin, Subscription } from 'rxjs';
import { Company } from 'src/app/core/models/company/company.model';
// tslint:disable-next-line: max-line-length
import { UpdateWeighingOfCompanyEmployeeRoleTargetType } from 'src/app/core/models/companyemployeeroletargettypes/updateWeighingOfCompanyEmployeeRoleTargetType.input';
import { CompanyUserEmployeeRole } from 'src/app/core/models/companyuseremployeerole/companyUserEmployeeRole.model';
import { EmployeeRoleByCompanyResponse } from 'src/app/core/models/employeeRole/companyemployeeroletargettypes.response';
import { Target } from 'src/app/core/models/target/target.model';
import { CreateTargetEspecificationsDTO } from 'src/app/core/models/targetEspecifications/createTargetEspecifications.dto';
import { DeleteTargetEspecificationsInput } from 'src/app/core/models/targetEspecifications/deleteTargetEspecifications.dto';
import { TargetEspecificationsInput } from 'src/app/core/models/targetEspecifications/targetEspecifications.input';
import { CompanyEmployeeRoleTargetService } from 'src/app/core/services/companyemployeeroletarget.service';
import { EmployeeRoleService } from 'src/app/core/services/employeerole.service';
import { EventService } from 'src/app/core/services/event.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TargetService } from 'src/app/core/services/target.service';
import { TargetEspecificationsService } from 'src/app/core/services/targetEspecifications.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { AlertService } from '../../../../shared/services/alert.service';
import { TranslatorService } from '../../../../shared/services/translator.service';

@Component({
    selector: 'app-edit-target',
    templateUrl: './edit-target.component.html',
    styleUrls: ['./edit-target.component.scss']
})
export class EditTargetComponent implements OnInit, OnDestroy {

    closeResult: string;
    targets: Target[] = [];
    employeeRoles: CompanyUserEmployeeRole[] = [];
    employeerole: CompanyUserEmployeeRole;
    companySelected: Company;
    companies: Company[];
    employeeRolesSelected = [];
    currentDate = new Date();
    selectDate = this.currentDate;
    arrayTargets: Target[] = [];
    company: Company;
    checked: boolean;
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    companiesSubcription: Subscription;

    constructor(
        private employeeRoleService: EmployeeRoleService,
        private eventService: EventService,
        private targetService: TargetService,
        private modalService: NgbModal,
        private targetEspecificationService: TargetEspecificationsService,
        private storageService: StorageService,
        private alertService: AlertService,
        private localeService: BsLocaleService,
        private companyEmployeeRoleTargetService: CompanyEmployeeRoleTargetService,
        private translateService: TranslatorService,

    ) {
        const language = this.storageService.getCompanies()[0].companyConfiguration.language;

        this.localeService.use(language);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'YYYY-MM-DD' });
    }

    ngOnInit() {
        this.companiesSubcription = this.eventService.subscribe(this.subscribeTypes.COMPANIES, data => {
            this.companies = data;
        });
        this.companies = this.storageService.getCompanies();
    }

    ngOnDestroy() {
        this.companiesSubcription.unsubscribe();
    }

    getEmployeeRole(companySelected) {
        this.employeeRoleService.employeeRoleByCompany$(companySelected).subscribe(
            (employeeRoleByCompanyResponse: EmployeeRoleByCompanyResponse) => {
                this.employeeRoles = [];
                employeeRoleByCompanyResponse.employeeRole.forEach(rol => {
                    const filteredRoles = this.employeeRoles.filter(roles => {
                        return roles.idEmployeeRole.id === rol.idEmployeeRole.id;
                    });
                    if (filteredRoles.length === 0) {
                        this.employeeRoles.push(rol);
                    }
                });
            });
    }

    getTargets() {
        this.targets = [];
        this.employeeRolesSelected.forEach(rol => {
            this.targetService.targetByEmployeeRoleByDateByCompany$(this.companySelected.id, this.currentDate, rol.id).subscribe(
                data => {
                    data.target.forEach(element => {
                        this.targets.push(element);
                    });
                });
        });
    }

    sendCompanySelected(company) {
        this.companySelected = company;
        this.getEmployeeRole(company.id);
    }

    rolesSelected(rol, event) {
        this.employeerole = rol.id;
        if (event === true) {
            this.employeeRolesSelected.push(rol);
        } else {
            this.employeeRolesSelected = this.employeeRolesSelected.filter((obj) => {
                return obj.id !== rol.id;
            });
        }
        this.getTargets();
    }

    // Update target
    updateTargetAndWeighing(target: Target) {
        const targetEspecificationId = target.targetEspecifications[0].id;
        const goal = target.targetEspecifications[0].goal;
        const startDate = target.targetEspecifications[0].startDate;
        const finishDate = target.targetEspecifications[0].finishDate;
        const updateWeighingOfCompanyEmployeeRoleTargetInput = new UpdateWeighingOfCompanyEmployeeRoleTargetType(
            this.companySelected.id,
            target.id,
            target.companyEmployeeRoleTargetTypes[0].idEmployeeRole.id,
            true,
            +target.companyEmployeeRoleTargetTypes[0].weighing
        );
        const targetEspecificationsInput = new TargetEspecificationsInput(
            startDate,
            finishDate,
            +goal
        );
        const validation = this.validationForm(
            updateWeighingOfCompanyEmployeeRoleTargetInput.weighing,
            targetEspecificationsInput.goal
        );

        if (validation) {
        } else {
            targetEspecificationsInput.goal = +goal;
            targetEspecificationsInput.startDate = format(new Date(startDate), 'yyyy-MM-dd');
            targetEspecificationsInput.finishDate = format(new Date(finishDate), 'yyyy-MM-dd');

            forkJoin({
                target: this.targetEspecificationService.updateTargetEspecificationsMutation$(
                    targetEspecificationId, targetEspecificationsInput),
                weighing: this.companyEmployeeRoleTargetService.updateWeighingOfCompanyEmployeeRoleTarget$(
                    updateWeighingOfCompanyEmployeeRoleTargetInput)
            }).subscribe(response => {
                if (response) {
                    this.alertService.show(this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_info'), '');
                } else {
                    this.alertService.show(this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_info'), '');
                }
            });
        }


    }

    // valide the fields input of form
    validationForm(weighing, goal) {
        let request = false;
        if (weighing < 0) {
            this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('target.edit.weigth.err'));
            request = true;
        }
        if (goal < 0) {
            this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('target.edit.goal.err'));
            request = true;
        }
        return request;
    }

    deleteTarget(id, employeeRole) {
        this.targetService.deleteTarget$(this.companySelected.id, id, employeeRole).subscribe(data => {
            if (data) {
                this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_delete'));
                this.getTargets();
            } else {
                this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_delete'));
            }
        });
    }

    open(buttons) {
        this.modalService.open(buttons, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.clearModal();
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.clearModal();
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    clearModal() {
        this.companySelected = undefined;
        this.employeeRoles = [];
        this.employeeRolesSelected = [];
        this.targets = [];
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    addTargetEspecifications(target) {
        let date = target.startDate;
        date = format(new Date(), 'yyyy-MM-dd');
        const addTargetEspecifications = new CreateTargetEspecificationsDTO();
        addTargetEspecifications.startDate = date;
        addTargetEspecifications.finishDate = date;
        addTargetEspecifications.goal = 0;
        target.targetEspecifications.push(addTargetEspecifications);
    }

    // Update the target Especification of targets
    updateTarget(id, goal, startDate, finishDate) {
        goal = +goal;
        startDate = format(new Date(startDate), 'yyyy-MM-dd');
        finishDate = format(new Date(finishDate), 'yyyy-MM-dd');
        const targetEspecificationsInput = new TargetEspecificationsInput(
            startDate,
            finishDate,
            goal
        );
        this.targetEspecificationService.updateTargetEspecificationsMutation$(id, targetEspecificationsInput).subscribe(data => {
            if (data) {
                this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('target.edit.succ'));
            } else {
                this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('target.edit.err'));
            }
        });
    }

    createTargetEspecificationsFrom(target, id) {
        let startDate = target.startDate;
        startDate = format(new Date(startDate), 'yyyy-MM-dd');
        let finishDate = target.finishDate;
        finishDate = format(new Date(finishDate), 'yyyy-MM-dd');
        const goal = target.goal;
        const targetId = id;

        const addTargetEspecifications = new CreateTargetEspecificationsDTO();
        addTargetEspecifications.startDate = startDate;
        addTargetEspecifications.finishDate = finishDate;
        addTargetEspecifications.goal = +goal;
        addTargetEspecifications.targetId = targetId;

        this.targetEspecificationService.createTargetEspecifications$(addTargetEspecifications).subscribe(data => {
            this.getTargets();
        });
    }

    removeTargetEspecifications(index, targetEspecifications, targetEspecification) {
        if (targetEspecification.id) {
            const targetEspecificationId = new DeleteTargetEspecificationsInput(targetEspecification.id);
            this.targetEspecificationService.deleteTargetEspecification$(targetEspecificationId).subscribe(response => {
                if (response) {
                    this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_delete'));
                } else {
                    this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_delete'));
                }
            });
        } else {
        }

        targetEspecifications.splice(index, 1);
    }

}
