export class CreateActivityInput {
    target: string;
    dateDay: Date;
    realAmount: number;
    user: string;

    constructor(target: string, user: string, realAmount: number, dateDay: Date) {
        this.user = user;
        this.target = target;
        this.dateDay = dateDay;
        this.realAmount = realAmount;

    }
}
