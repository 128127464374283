import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Company } from '../models/company/company.model';

export const SELECTED_COMPANIES = 'companies';

const SECRET_KEY = 'CLICKTEC-2020';
const SecureStorage = require('secure-web-storage');

@Injectable({ providedIn: 'root' })
export class StorageService {

    constructor() { }

    public secureStorage = new SecureStorage(localStorage, { 
        hash: function hash(key) {
            key = CryptoJS.SHA256(key, SECRET_KEY);

            return key.toString();
        },
        encrypt: function encrypt(data) {
            data = CryptoJS.AES.encrypt(data, SECRET_KEY);

            data = data.toString();

            return data;
        },
        decrypt: function decrypt(data) {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);

            data = data.toString(CryptoJS.enc.Utf8);

            return data;
        }
    });

    getCompanies(): Company[] {
        return JSON.parse(this.secureStorage.getItem(SELECTED_COMPANIES));
    }

    setCompanies(companies: Company[]): void {
        this.secureStorage.setItem(SELECTED_COMPANIES, JSON.stringify(companies));
    }

}
