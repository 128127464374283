import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveRefillableDocumentMutation, deleteRefillableDocument } from '../../graphql/operations/refillable-document/mutations';
import { getRefillableDocumentsByCompany, getRefillableDocumentDownloadUrl } from '../../graphql/operations/refillable-document/queries';
import { RefillableDocumentListResponse } from '../models/refillable-document/dto/refillable-document-list.response';
import { RefillableDocumentInput } from '../models/refillable-document/dto/refillableDocument.input';

@Injectable({ providedIn: 'root' })
export class RefillableDocumentService {

    constructor(
        private readonly apollo: Apollo
    ) { }

    saveDocument$(saveRefillableDocumentInput: RefillableDocumentInput): Observable<string> {
        return this.apollo
            .mutate({
                mutation: saveRefillableDocumentMutation,
                variables: {
                    saveRefillableDocumentInput
                }
            }).pipe(map((result: any) => {
                return result.data['saveRefillableDocument'];
            }));
    }

    deleteRefillableDocument$(refillableDocumentId: string) {
        return this.apollo
            .query({
                query: deleteRefillableDocument,
                variables: {
                    refillableDocumentId
                }
            }).pipe(map(result => {
                return result.data['deleteRefillableDocument'];
            }));
    }

    refillableDocumentList$(companyId: string): Observable<RefillableDocumentListResponse> {
        return this.apollo
            .query({
                query: getRefillableDocumentsByCompany,
                variables: {
                    companyId
                }
            }).pipe(map(result => {
                return new RefillableDocumentListResponse(result.data['getRefillableDocumentsByCompany']);
            }));
    }

    getRefillableDocumentDownloadUrl$(documentId: string): Observable<string> {
        return this.apollo
            .query({
                query: getRefillableDocumentDownloadUrl,
                variables: {
                    documentId
                }
            }).pipe(map(result => {
                return result.data['getRefillableDocumentDownloadUrl'];
            }));
    }
}