import { Target } from './target.model';

export class TargetsByEmployeeRoleByDateByCompanyResponse {
    target: Array<Target>;

    constructor(targetByEmployeeRoleByDateByCompany: Array<Target>) {
        this.target = targetByEmployeeRoleByDateByCompany;

    }
}
