export class UpdateUserInput {
    id: string;
    name: string;
    lastName: string;
    contactEmail: string;
    contactPhone: string;
    avatar: string;
    status: boolean;
    email: string;
    password: string;
    roles: string;
}
