import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import { User } from 'src/app/core/models/user/User.model';
import { Company } from '../../core/models/company/company.model';
import { ScheduleControlListExport, ScheduleControlListRowExport } from '../../core/models/schedulelist/schedulecontrollist.export';
import { GetSignWorkByCompanyInputDto } from '../../core/models/schedulelist/schedulelist.input.dto';
import { CompanyService } from '../../core/services/company.service';
import { ScheduleControlService } from '../../core/services/shedulecontrol.service';
import { ScheduleTimeCalculator } from '../schedule-time.calculator';
import { DateUtil } from './date-util.service';
import { TranslatorService } from './translator.service';

@Injectable({ providedIn: 'root' })
export class ScheduleListExportService {

    scheduleListExport: ScheduleControlListExport[] = [];
    company: Company;
    timeResume: number;
    totalWorkedTime: string;
    startDate: Date;
    finishDate: Date;

    constructor(
        private scheduleControlService: ScheduleControlService,
        private scheduleTimeCalculator: ScheduleTimeCalculator,
        private companyService: CompanyService,
        private dateUtil: DateUtil,
        private translateService: TranslatorService,
    ) {
    }

    //EXPORTAR A CSV
    exportToCsv(getSignWorkByCompanyInputDto: GetSignWorkByCompanyInputDto, type, users: User[]) {

        this.startDate = getSignWorkByCompanyInputDto.startDate;
        this.finishDate = getSignWorkByCompanyInputDto.finishDate;
        this.scheduleListExport = [];
        if (type === 1) {
            this.scheduleControlService.getListScheduleControlToExportNoSignature$(getSignWorkByCompanyInputDto).subscribe(data => {
                this.scheduleListExport = data;
                this.exportAsExcelFile(this.scheduleListExport, this.translateService.trans('schedule.export.tittle'), type, users);
            });
        }
        if (type === 0) {
            this.scheduleControlService.getListScheduleControlToExport$(getSignWorkByCompanyInputDto).subscribe(data => {
                this.scheduleListExport = data;
                this.exportAsExcelFile(this.scheduleListExport, this.translateService.trans('schedule.export.tittle'), type, users);
            });
        }
    }


    //EXPORTAR A EXCEL
    public exportAsExcelFile(json: any[], excelFileName: string, type, users: User[]): void {

        const workbook = new Workbook();
        const header = [this.translateService.trans('schedule.export.users'), this.translateService.trans('pageview.date'), this.translateService.trans('schedule.export.time'), this.translateService.trans('schedule.export.timedecimal')]

        let data = json;

        let worksheet = workbook.addWorksheet(this.translateService.trans('schedule.export.list'));

        // Add new row
        let titleRow = worksheet.addRow([this.translateService.trans('schedule.export.list')]);

        // Set font, size and style in title row.
        titleRow.font = { family: 4, size: 11, bold: true, name: 'FreeMono' };

        // merge cells
        worksheet.mergeCells('A1:E2');

        // date range of the report
        const startDate = format(this.startDate, 'dd-MM-yyyy');
        const finishDate = format(this.finishDate, 'dd-MM-yyyy');

        worksheet.addRow([ this.translateService.trans('schedule.export.date.start')+': ', startDate]);
        worksheet.addRow([ this.translateService.trans('schedule.export.date.end')+': ' , finishDate]);

        // Blank Row
        worksheet.addRow([]);
        let largerCommentLength = 0;

        if (type === 1) {
            header.push(this.translateService.trans('task.comment'));
            //Add Header Row
            let headerRow = worksheet.addRow(header);
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'd3d3d3' },
                    bgColor: { argb: 'd3d3d3' },
                },
                    cell.font = { size: 10, bold: true, name: 'FreeMono' }
            });
            users.forEach(user => {
                this.timeResume = 0;
                let timeResumeDecimal = 0;
                const newData = data.filter(dataUser => user.id === dataUser.userId);
                if (newData.length === 0) {
                    return;
                }
                // Add newData
                newData.forEach(d => {
                    this.timeResume = this.timeResume + this.scheduleTimeCalculator.getMilisecondsOfTimeString(d.totalTime);

                    d = Object.values(d);
                    const dateLetter = format(new Date(d[1]), 'EEEE', { locale: es });
                    const dateFull = new Date(d[1]);
                    d[1] = dateLetter.charAt(0).toUpperCase() + dateLetter.slice(1) + ' ' + format(new Date(d[1]), 'dd/MM/yyyy');
                    d[2] = d[2].split(':')[0] + ':' + d[2].split(':')[1] + ':' + d[2].split(':')[2];
                    d[3] = this.dateUtil.timeStringToFloat(d[2]).replace(".", ',');
                    const row = worksheet.addRow(d);
                    row.height = 50;
                    timeResumeDecimal += parseFloat(d[3]);
                    if (!this.dateUtil.isBusinessDay(dateFull)) {
                        row.eachCell((cell, number) => {
                            cell.font = { bold: false };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'ececec' },
                                bgColor: { argb: 'ececec' },
                            };
                        });
                    }
                    row.eachCell((cell, number) => {
                        if(number == 4){
                           cell.value =parseFloat(this.dateUtil.timeStringToFloat(d[2]));
                           cell.numFmt = "#,##0.000";
                        }
                        cell.font = { bold: false };
                    });
                });
                this.totalWorkedTime = this.scheduleTimeCalculator.getTotalHours(this.timeResume);

                worksheet.addRow([]);
                const total = worksheet.addRow([this.translateService.trans('schedule.export.totalworked'), user.name + ' ' + user.lastName, this.totalWorkedTime, this.dateUtil.timeStringToFloat(this.totalWorkedTime)]);
                worksheet.addRow([]);
                total.eachCell((cell, number) => {
                    if(number == 4){
                        cell.value =parseFloat(this.dateUtil.timeStringToFloat(this.totalWorkedTime));
                        cell.numFmt = "#,##0.000";
                     }
                    cell.font = { bold: true, name: 'FreeMono', size: 12 };
                    cell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
                });
            });
            worksheet.getColumn(5).width = 50;

        } else if (type === 0) {
            //Add Header Row
            header.push('Firma Digital');

            let headerRow = worksheet.addRow(header);
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'd3d3d3' },
                    bgColor: { argb: 'd3d3d3' },
                },
                    cell.font = { size: 10, bold: true, name: 'FreeMono' }
            });
            // Add Data
            data.forEach(d => {
                const logoBase64 = d.door[0].signature;
                d = Object.values(d);
                d[1] = format(new Date(d[1]), 'dd/MM/yyyy');
                d[2] = d[2].split(':')[0] + ':' + d[2].split(':')[1] + ':' + d[2].split(':')[2];
                d[3] = this.dateUtil.timeStringToFloat(d[2]).replace(".", ',');
                d[4] = '';
                d[5] = null;
                const row = worksheet.addRow(d);
                row.height = 50;
                row.eachCell((cell, number) => {
                    if(number == 4){
                        cell.value =parseFloat(this.dateUtil.timeStringToFloat(d[2]));
                        cell.numFmt = "#,##0.000";
                     }
                    cell.font = { bold: false };
                });

                //add signature
                if (logoBase64 != '' && logoBase64 != null ) {
                    let logo = workbook.addImage({
                        base64: logoBase64,
                        extension: 'png',
                    });
                    worksheet.addImage(logo, {
                        tl: { col: 4, row: row.number - 1 },
                        ext: { width: 150, height: 70 }
                    });
                }
            });
            worksheet.getColumn(5).width = 15;

        }

        worksheet.eachRow(row => {
            row.eachCell(cell => {
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };

            });
        });

        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 30;

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, this.translateService.trans('schedule.export.document')+'.xlsx');
        });
    }

    private formatSignworkComments(signwork: any) {
        let largerCommentLength = 0;

        let comments = signwork.door
            .filter(door => door.comment !== null)
            .map(door => {
                const comment = format(new Date(door.eventDate), 'dd/MM/yyyy HH:mm:ss') + ': ' + door.comment;
                largerCommentLength = comment.length > largerCommentLength ? comment.length : largerCommentLength;

                return comment;
            })
            .reduce((total, comment) => total += comment + '\n', '');
        comments = comments.substring(0, comments.length - 1);

        return { comments, largerCommentLength };
    }

    //EXPORTAR A CSV POR USUARIO Y DIA  
    exportToCsvByScheduleRow(getSignWorkByCompanyInputDto: GetSignWorkByCompanyInputDto) {
        this.timeResume = 0;
        this.companyService.companiesByUser$(getSignWorkByCompanyInputDto.userIds[0]).subscribe(data => {
            this.company = data.companyUserEmployeeRole[0].idCompany;
        });
        this.scheduleListExport = [];
        this.scheduleControlService.getListScheduleControl$(getSignWorkByCompanyInputDto).subscribe(data => {
            data.scheduleControl.sort((a, b) => a.date > b.date ? 1 : -1);
            data.scheduleControl.forEach(schedules => {
                schedules.door.sort((a, b) => a.eventDate > b.eventDate ? 1 : -1);
                const scheduleExport = new ScheduleControlListRowExport();
                scheduleExport.userName = schedules.user.name.concat(' ', schedules.user.lastName);
                scheduleExport.dateDay = schedules.date;
                const totalTime = new Date(0, 0, 0, 0, 0, 0, this.scheduleTimeCalculator.getTotalHoursFromSchedule(schedules));
                scheduleExport.totalTime = moment(totalTime).format("HH:mm:ss");
                this.timeResume = this.timeResume + this.scheduleTimeCalculator.getTotalHoursFromSchedule(schedules);
                scheduleExport.door = schedules.door;
                this.scheduleListExport.push(scheduleExport);
            });
            this.totalWorkedTime = this.scheduleTimeCalculator.getTotalHours(this.timeResume);
            this.exportAsExcelFileWithDoors(this.scheduleListExport, this.translateService.trans('schedule.export.list.user'));
        });
    }

    //EXPORTAR A EXCEL POR USUARIO Y DIA
    public exportAsExcelFileWithDoors(json, excelFileName: string): void {

        const workbook = new Workbook();
        const header = [this.translateService.trans('pageview.date'), this.translateService.trans('schedule.export.time'), this.translateService.trans('schedule.export.sign'), this.translateService.trans('task.comment')]
        let data = json;
        let worksheet = workbook.addWorksheet(this.translateService.trans('schedule.export.list'));

        // Add new row
        let titleRow = worksheet.addRow([this.translateService.trans('schedule.export.diary')]);
        // Set font, size and style in title row.
        titleRow.font = { family: 4, size: 11, bold: true, name: 'FreeMono' };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

        worksheet.mergeCells('A1:C2');

        // Add new row
        const subTitleRow = worksheet.addRow([this.translateService.trans('role.company'), "", ""]);
        const subTitleRow1 = worksheet.addRow([this.translateService.trans('schedule.export.worker'), "", ""]);
        subTitleRow.font = { family: 4, size: 10, bold: true, name: 'FreeMono' };
        subTitleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        subTitleRow1.font = { family: 4, size: 10, bold: true, name: 'FreeMono' };
        subTitleRow1.alignment = { vertical: 'middle', horizontal: 'center' };

        //Add Header Row
        let headerRow = worksheet.addRow(header);
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'd3d3d3' },
                bgColor: { argb: 'd3d3d3' },
            };
        });
        // Add Data
        data.forEach(d => {
            d = Object.values(d);
            const doors = d[3];
            delete d[3];
            subTitleRow1.findCell(2).value = d[0];
            delete d[0];
            d[1] = format(new Date(d[1]), 'dd/MM/yyyy');
            d[3] = '';
            const row = worksheet.addRow(d);

            doors.forEach(door => {
                const doorLogoBase64 = door.signature;
                const eventDate = moment(new Date(door.eventDate)).format("HH:mm:ss");
                const comment = door.comment;
                let type;
                if (door.type === true) {
                    type = this.translateService.trans('schedule.list.in');
                }
                else {
                    type = this.translateService.trans('schedule.list.out');
                }
                //add signature
                const row1 = worksheet.addRow([type, eventDate, "", comment]);
                if (doorLogoBase64 != '' && doorLogoBase64 != null) {
                    let signature = workbook.addImage({
                        base64: doorLogoBase64,
                        extension: 'png',
                    });
                    worksheet.addImage(signature, {
                        tl: { col: 2, row: row1.number - 1 },
                        ext: { width: 100, height: 40 }
                    });
                }
                row1.eachCell((cell, number) => {
                    cell.font = { size: 9, name: 'FreeMono' };
                });
                row1.outlineLevel = 1;
                row1.height = 35;
            });
            row.height = 50;
            row.eachCell((cell, number) => {
                cell.font = { bold: true, name: 'FreeMono' };
                cell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
            });
        });
        subTitleRow.findCell(2).value = this.company.name;

        worksheet.addRow([]);
        const total = worksheet.addRow([this.translateService.trans('schedule.export.totalworked'), this.totalWorkedTime]);
        total.eachCell((cell, number) => {
            cell.font = { bold: true, name: 'FreeMono', size: 12 };
            cell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
        });

        worksheet.eachRow(row => {
            row.eachCell(cell => {
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };

            });
        });

        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 50;

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, `${json[0].userName.replace(/\s+/g, '')}.xlsx`);
        });
    }
}

