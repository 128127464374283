import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createTargetMutation, deleteTargetMutation } from 'src/app/graphql/operations/target/mutations';
import { targetByEmployeeRoleByDateByCompany } from '../../graphql/operations/target/queries';
import { DeleteTargetResponse } from '../models/companyemployeeroletargettypes/deleteTarget.response';
import { CreateTargetInput } from '../models/target/createTarget.input';
import { CreateTargetResponse } from '../models/target/createTarget.response';
import { TargetsByEmployeeRoleByDateByCompanyResponse } from '../models/target/TargetsByEmployeeRoleByDateByCompany.response';


@Injectable({ providedIn: 'root' })
export class TargetService {
    constructor(
        private readonly apollo: Apollo) { }

    createTarget$(createTargetInput: CreateTargetInput, companiesId: string[], employeeRolesId: string[], weighing: number): Observable<CreateTargetResponse> {
        return this.apollo
            .mutate({
                mutation: createTargetMutation,
                variables: {
                    createTargetInput,
                    companiesId,
                    employeeRolesId,
                    weighing
                }
            }).pipe(map(result => {
                return new CreateTargetResponse(result.data['createTarget']);
            }));
    }

    deleteTarget$(companyId: string, targetId: string, employeeRoleId: string): Observable<DeleteTargetResponse> {
        return this.apollo
            .mutate({
                mutation: deleteTargetMutation,
                variables: {
                    companyId,
                    targetId,
                    employeeRoleId
                }
            }).pipe(map(result => {
                return result.data['deleteTarget'];
            }));
    }

    targetByEmployeeRoleByDateByCompany$(companyId: string, dateDay: Date, employeeRole: string): Observable<TargetsByEmployeeRoleByDateByCompanyResponse> {
        return this.apollo
            .query({
                query: targetByEmployeeRoleByDateByCompany,
                variables: {
                    companyId,
                    dateDay,
                    employeeRole
                }
            }).pipe(map(result => {
                return new TargetsByEmployeeRoleByDateByCompanyResponse(result.data['getTargetsByEmployeeRoleByDateByCompany']);
            }));
    }
}
