import gql from 'graphql-tag';

export const targetByEmployeeRoleByDateByCompany = gql`
    query getTargetsByEmployeeRoleByDateByCompany($companyId: String!,$dateDay: DateTime!,$employeeRole: String!) {
        getTargetsByEmployeeRoleByDateByCompany(companyId: $companyId,dateDay: $dateDay,employeeRole: $employeeRole) {
            id,
            name,
            targetEspecifications{
                id,
                startDate,
                finishDate,
                goal,
                status
            },
            companyEmployeeRoleTargetTypes{
                idEmployeeRole{
                  id,
                  name,
                  color
                },
                weighing
            }
        }
    }`;

