import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePad } from 'angular2-signaturepad';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { EventService } from '../../core/services/event.service';
import { AlertService } from '../../shared/services/alert.service';
import { TranslatorService } from '../../shared/services/translator.service';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss']
})
export class SignatureModalComponent implements OnInit {

  @ViewChild('modalBody', { static: true }) modalBody: ElementRef;
  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;

  @Output() signConfirmed: EventEmitter<string[]> = new EventEmitter();
  @Input() schedule: boolean;

  signaturePadOptions: Object = {
    minWidth: 1,
  };
  subscribeTypes: SubscribeTypes = new SubscribeTypes();


  signImage: string;
  showComment = false;
  comment: string;

  constructor(
    readonly activeModal: NgbActiveModal,
    private alertService: AlertService,
    private eventService: EventService,
    private translateService: TranslatorService,

  ) { }

  ngOnInit() {
    this.signaturePadOptions = {
      minWidth: 1,
      canvasWidth: this.modalBody.nativeElement.offsetWidth - 40,
      canvasHeight: this.modalBody.nativeElement.offsetHeight,
    }
  }

  clear() {
    this.signaturePad.clear();
  }

  save() {
    if (this.signaturePad.isEmpty()) {
      this.alertService.show('warning', this.translateService.trans('toast.alert'), 'Debe firmar para poder continuar');
      return;
    }
    this.signImage = this.signaturePad.toDataURL('image/png');
    this.signConfirmed.emit([this.signImage, this.comment]);
    this.activeModal.close();
  }

  closeModal() {
    this.eventService.broadcast(this.subscribeTypes.SIGNATURE_MODAL_CLOSED, true);
    this.activeModal.close();
  }

  addComment() {
    this.showComment = !this.showComment;
  }

}
