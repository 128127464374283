import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { format } from 'date-fns';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { Subscription } from 'rxjs';
import { CompaniesByUserLoggedResponse } from 'src/app/core/models/company/CompaniesByUserLogged.response';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyUserEmployeeRole } from 'src/app/core/models/companyuseremployeerole/companyUserEmployeeRole.model';
import { EmployeeRoleByCompanyResponse } from 'src/app/core/models/employeeRole/companyemployeeroletargettypes.response';
import { CreateTargetInput } from 'src/app/core/models/target/createTarget.input';
import { CreateTargetEspecificationsFormDTO } from 'src/app/core/models/target/createTargetEspecificationsForm.dto';
import { CreateTargetFormDTO } from 'src/app/core/models/target/createTargetForm.dto';
import { TargetInput } from 'src/app/core/models/target/Target.input';
import { TargetEspecificationsInput } from 'src/app/core/models/targetEspecifications/targetEspecifications.input';
import { CompanyService } from 'src/app/core/services/company.service';
import { EmployeeRoleService } from 'src/app/core/services/employeerole.service';
import { EventService } from 'src/app/core/services/event.service';
import { TargetService } from 'src/app/core/services/target.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { isEmpty } from 'rxjs/operators';
import { TranslatorService } from '../../../../shared/services/translator.service';

@Component({
    selector: 'app-new-target',
    templateUrl: './new-target.component.html',
    styleUrls: ['./new-target.component.scss']
})
export class NewTargetComponent implements OnInit, OnDestroy {

    employeerole: CompanyUserEmployeeRole;
    employeeRoles: CompanyUserEmployeeRole[] = [];
    selectCompany: Company[];
    companies: Company[];
    companys: any;
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    closeResult: string;
    createTargetFormDto: CreateTargetFormDTO;
    checked: boolean;
    employeeRolesSelected = [];
    companySelected = [];
    selectEmployeeRoles: string[];
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    locale: string = 'es';
    locales = listLocales();
    companiesSubcription: Subscription;

    constructor(
        private modalService: NgbModal,
        private eventService: EventService,
        private employeeRoleService: EmployeeRoleService,
        private companyService: CompanyService,
        private targetService: TargetService,
        private toasterService: ToasterService,
        private localeService: BsLocaleService,
        private translateService: TranslatorService,
    ) {
        this.createTargetFormDto = new CreateTargetFormDTO('', [], 0);
        this.createTargetFormDto.targetEspecifications.push(new CreateTargetEspecificationsFormDTO(null));
        defineLocale(this.locale, esLocale);
        this.localeService.use(this.locale);
    }

    ngOnInit() {
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'YYYY-MM-DD' });
        this.getCompanys();
    }

    ngOnDestroy() {
    }


    getCompanys() {
        this.companyService.companiesByUserLogged$().subscribe(
            (companiesByUserLoggedResponse: CompaniesByUserLoggedResponse) => {
                this.companys = companiesByUserLoggedResponse.companyUserEmployeeRole;
                this.eventService.broadcast(this.subscribeTypes.USER_ROLES, this.companys);
            });
    }

    sendCompanySelected(companys) {
        if (companys.length === 0) {
            this.employeeRoles = [];
        }
        companys.forEach(company => {
            this.getEmployeeRole(company.id);
            this.employeeRoles = [];
        });
    }

    getEmployeeRole(selectCompany) {
        this.employeeRoleService.employeeRoleByCompany$(selectCompany).subscribe(
            (employeeRoleByCompanyResponse: EmployeeRoleByCompanyResponse) => {
                employeeRoleByCompanyResponse.employeeRole.forEach(rol => {
                    const filteredRoles = this.employeeRoles.filter(roles => {
                        return roles.idEmployeeRole.id === rol.idEmployeeRole.id;
                    });
                    if (filteredRoles.length === 0) {
                        this.employeeRoles.push(rol);
                    }
                });
            });
    }

    rolesSelected(rol, event) {
        this.employeerole = rol.id;
        if (event === true) {
            this.employeeRolesSelected.push(rol);
        } else {
            this.employeeRolesSelected = this.employeeRolesSelected.filter((obj) => {
                return obj.id !== rol.id;
            });
        }
    }

    addRange() {
        this.createTargetFormDto.targetEspecifications.push(new CreateTargetEspecificationsFormDTO(null));
    }

    deleteRange(deleteTargetEspecifications) {
        this.createTargetFormDto.targetEspecifications.splice(deleteTargetEspecifications, 1);
    }

    createTarget() {
        const targetInput = new TargetInput(
            this.createTargetFormDto.name,
            this.createTargetFormDto.targetEspecifications.map(targetEspecification => {
                return new TargetEspecificationsInput(
                    format(targetEspecification.daterange[0], 'yyyy-MM-dd'),
                    format(targetEspecification.daterange[1], 'yyyy-MM-dd'),
                    targetEspecification.goal,
                );
            }),
        );
        const weighing = this.createTargetFormDto.weighing;

        const createTargetInput = new CreateTargetInput(targetInput);
        this.companySelected = this.selectCompany.map(companys => companys.id);
        this.selectEmployeeRoles = this.employeeRolesSelected.map(employeeRoles => employeeRoles.id);

        this.targetService.createTarget$(createTargetInput, this.companySelected, this.selectEmployeeRoles, weighing).subscribe(data => {
            this.toasterService.pop('success', this.translateService.trans('target.new.add.succ'), this.translateService.trans('target.new.add.succ_info'));
        });
        this.destroyForm();
    }

    destroyForm() {
        this.createTargetFormDto = {
            name: '',
            targetEspecifications: [{
                daterange: null,
                goal: 0
            }],
            weighing: 0
        };
        this.selectCompany = [];
        this.employeeRolesSelected = [];
        this.employeeRoles = [];
    }

    open(buttons) {
        this.modalService.open(buttons, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.destroyForm()
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.destroyForm()
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    private ValidateTargetsEspecifications() {
        let request = true;
        if (this.createTargetFormDto.targetEspecifications === undefined) {
            request = false;
        }
        this.createTargetFormDto.targetEspecifications.forEach(items => {
            if (items.daterange === undefined) {
                request = false;
            }
            if (items.goal < 0 || items.goal === null) {
                request = false;
            }
        });
        return request;
    }

    validate() {
        const badDatesFormat = this.ValidateTargetsEspecifications();
        if (this.selectCompany === undefined) {
            this.toasterService.pop('error',this.translateService.trans('toast.edit_role.err'), this.translateService.trans('target.new.add.err.company'));

        } else if (badDatesFormat === false) {
            this.toasterService.pop('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('target.new.add.err.date'));

        } else if (this.createTargetFormDto.name.startsWith(' ')) {
            this.toasterService.pop('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('target.new.add.err.blank'));

        } else if (this.selectCompany.length !== 0
            && this.employeeRolesSelected.length !== 0
            && this.createTargetFormDto.name.length !== 0
            && this.createTargetFormDto.targetEspecifications.length !== 0
            && this.createTargetFormDto.weighing >= 0
            && badDatesFormat) {
            this.createTarget();
        } else if (this.createTargetFormDto.weighing < 0) {
            this.toasterService.pop('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('target.edit.goal.err'));
        } else {
            this.toasterService.pop('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('target.new.add.err.full'));
        }
    }
}
