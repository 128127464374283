import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { endOfDay, startOfDay } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createUserMutation, updateUserMutation } from '../../graphql/operations/user/mutations';
import { ActivityByUserByCompanyByDateRange, getUsersActivityAndTargetByCompany, TargetsByUserByCompanyByDateRange, UserRankingWithActivityAndTargetRatioByCompany, UsersByCompany, allVisibleUsersByCompany, getUserAvatarDownloadUrl, getUserAvatarUploadUrl, UserRankingWithAcitivyAndTargetRatioByCompanyByUsers, usersAllByCompany } from '../../graphql/operations/user/queries';
import { ActivityByUserByCompanyByDateRangeResponse } from '../models/user/ActivityByUserByCompanyByDateRange.response';
import { CreateUserInput } from '../models/user/createUser.input';
import { GetUsersActivityAndTargetByCompanyInput } from '../models/user/GetUsersActivityAndTargetByCompany.input';
import { GetUsersActivityAndTargetByCompanyResponse } from '../models/user/GetUsersActivityAndTargetByCompany.response';
import { TargetsByUserByCompanyByDateRangeResponse } from '../models/user/TargetsByUserByCompanyByDateRange.response';
import { UpdateUserInput } from '../models/user/userInput.input';
import { UserRankingWithActivityAndTargetRatioByCompanyResponse } from '../models/user/UserRankingWithActivityTargetRatioByCompany.response';
import { UsersByCompanyResponse } from '../models/user/UsersByCompany.response';
import { User } from '../models/user/User.model';


@Injectable({ providedIn: 'root' })
export class UserProfileService {

    constructor(
        private http: HttpClient,
        private readonly apollo: Apollo) { }

    createUserMutation$(createUserInput: CreateUserInput) {
        return this.apollo
            .mutate({
                mutation: createUserMutation,
                variables: {
                    createUserInput
                }
            }).pipe(map(result => {
                let user = new User();
                user = result.data['createUser'];
                return user;
            }));
    }

    updateUserMutation$(updateUserInput: UpdateUserInput) {
        return this.apollo
            .mutate({
                mutation: updateUserMutation,
                variables: {
                    updateUserInput
                }
            }).pipe(map(result => {
                return Boolean;
            }));
    }
    activityByUserByDateRange$(
        companyIds: string[],
        dateRange: Date[],
        userIds: string[],
    ): Observable<ActivityByUserByCompanyByDateRangeResponse> {
        return this.apollo
            .query({
                query: ActivityByUserByCompanyByDateRange,
                variables: {
                    companyIds,
                    dateRange,
                    userIds,
                }
            }).pipe(map(result => {
                return new ActivityByUserByCompanyByDateRangeResponse(result.data['getActivityByUserByCompanyByDateRange']);
            }));
    }

    targetsByUserByDateRange$(
        companyIds: string[],
        dateRange: Date[],
        userIds: string[]
    ): Observable<TargetsByUserByCompanyByDateRangeResponse> {
        return this.apollo
            .query({
                query: TargetsByUserByCompanyByDateRange,
                variables: {
                    companyIds,
                    dateRange,
                    userIds,
                }
            }).pipe(map(result => {
                return new TargetsByUserByCompanyByDateRangeResponse(result.data['getTargetsByUserByCompanyByDateRange']);
            }));
    }

    usersByCompany$(companyId: string): Observable<UsersByCompanyResponse> {
        return this.apollo
            .query({
                query: UsersByCompany,
                variables: {
                    companyId,
                }
            }).pipe(map(result => {
                return result.data['getUsersByCompany'];
            }));
    }

    usersAllByCompany$(companyId: string): Observable<UsersByCompanyResponse> {
        return this.apollo
            .query({
                query: usersAllByCompany,
                variables: {
                    companyId,
                }
            }).pipe(map(result => {
                return result.data['getAllUsersByCompany'];
            }));
    }

    allVisibleUsersByCompany$(companyId: string): Observable<UsersByCompanyResponse> {
        return this.apollo
            .query({
                query: allVisibleUsersByCompany,
                variables: {
                    companyId,
                }
            }).pipe(map(result => {
                return result.data['getAllVisibleUsersByCompany'];
            }));
    }


    agentCard$(companyId: string): Observable<GetUsersActivityAndTargetByCompanyResponse> {

        const input = new GetUsersActivityAndTargetByCompanyInput(
            companyId,
            startOfDay(new Date()),
            endOfDay(new Date())
        );

        return this.apollo
            .query({
                query: getUsersActivityAndTargetByCompany,
                variables: { input }
            }).pipe(map(result => {
                return new GetUsersActivityAndTargetByCompanyResponse(
                    result.data['getUsersActivityAndTargetByCompany']
                );
            }));
    }

    userRanking$(
        companyId: string, startDate: Date, finishDate: Date,
    ): Observable<UserRankingWithActivityAndTargetRatioByCompanyResponse> {
        return this.apollo
            .query({
                query: UserRankingWithActivityAndTargetRatioByCompany,
                variables: {
                    companyId,
                    startDate,
                    finishDate,
                }
            }).pipe(map(result => {
                return new UserRankingWithActivityAndTargetRatioByCompanyResponse(
                    result.data['getWostUserWithActivityAndTargetRatioByCompany']
                );
            }));
    }

    rankingByUsers$(
        companyId: string, startDate: Date, finishDate: Date, userIds: string[]
    ): Observable<UserRankingWithActivityAndTargetRatioByCompanyResponse> {
        return this.apollo
            .query({
                query: UserRankingWithAcitivyAndTargetRatioByCompanyByUsers,
                variables: {
                    companyId,
                    startDate,
                    finishDate,
                    userIds
                }
            }).pipe(map(result => {
                return new UserRankingWithActivityAndTargetRatioByCompanyResponse(
                    result.data['getUserWithActivityAndTargetRatioByCompanyByUsers']
                );
            }));
    }

    getUserAvatarDownloadUrl$(userId: string): Observable<string> {
        return this.apollo
            .query({
                query: getUserAvatarDownloadUrl,
                variables: {
                    userId
                }
            }).pipe(map(result => {
                return result.data['getUserAvatarDownloadUrl'];
            }));
    }

    getUserAvatarUploadUrl$(avatar: string, contentType: string): Observable<string> {
        return this.apollo
            .query({
                query: getUserAvatarUploadUrl,
                variables: {
                    avatar,
                    contentType
                }
            }).pipe(map(result => {
                return result.data['getUserAvatarUploadUrl'];
            }));
    }
}
