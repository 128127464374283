import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageViewGuard } from 'src/app/core/guards/pageView.guard';
import { FeatureGuard } from '../../core/guards/feature.guard';
import { LayoutComponent } from '../../layouts/layout.component';
import { BundlechartsComponent } from '../charts/bundlecharts/bundlecharts.component';
import { EditCompanyConfigurationComponent } from '../configuration-panel/edit-company-configuration/edit-company-configuration.component';
import { DashboardAdminComponent } from '../dashboard-admin/dashboard-admin.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { StatisticsComponent } from '../statistics/statistics.component';
import { TargetComponent } from '../target/target.component';
import { SummaryComponent } from './summary/summary.component';

const routes: Routes = [
  {
    path: 'comercial-activity', component: LayoutComponent, canActivate: [FeatureGuard], children: [
      { path: 'dashboard-agent', component: DashboardComponent, canActivate: [PageViewGuard] },
      { path: 'dashboard-admin', component: DashboardAdminComponent, canActivate: [PageViewGuard] },
      { path: 'targets', component: TargetComponent, canActivate: [PageViewGuard] },
      { path: 'statistics', component: StatisticsComponent, canActivate: [PageViewGuard] },
      { path: 'summary', component: SummaryComponent, canActivate: [PageViewGuard] },
      { path: 'company/configuration/edit', component: EditCompanyConfigurationComponent, canActivate: [PageViewGuard] },
      { path: 'bundle-charts', component: BundlechartsComponent, canActivate: [PageViewGuard] },
      {
        path: '',
        redirectTo: '/account/login',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: '',
    redirectTo: 'comercial-activity',
    pathMatch: 'full'
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComercialActivityRoutingModule { }
