import { Component, OnInit, ViewChild } from '@angular/core';
import { UserLogged } from 'src/app/core/models/auth.models';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyConfigurationResponse } from 'src/app/core/models/companyConfiguration/companyConfiguration.response';
import { GetCompanyConfigurationInput } from 'src/app/core/models/companyConfiguration/getCompanyConfiguration.input';
import { UpdateCompanyConfigurationInput } from 'src/app/core/models/companyConfiguration/updateCompanyConfiguration.input';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { CompanyConfigurationService } from 'src/app/core/services/companyConfiguration.service';
import { EventService } from 'src/app/core/services/event.service';
import { CompanyDropdownComponent } from 'src/app/shared-component/dropdown/company-dropdown/company-dropdown.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { RolType } from 'src/app/shared/types/rol.types';
import { StorageService } from '../../../core/services/storage.service';
import { TranslatorService } from '../../../shared/services/translator.service';
import { LanguageType } from '../../../shared/types/language.type';

@Component({
    selector: 'app-edit-company-configuration',
    templateUrl: './edit-company-configuration.component.html',
    styleUrls: ['./edit-company-configuration.component.scss']
})
export class EditCompanyConfigurationComponent implements OnInit {
    [x: string]: any;

    @ViewChild(CompanyDropdownComponent, { static: false }) companyDropdownComponent: CompanyDropdownComponent;
    companySelected: Company;
    currentUser: UserLogged;
    configuration: CompanyConfigurationResponse;
    rankingStatus: boolean;
    selectCompanys: Company[];
    rolType: RolType = new RolType();
    onlyRankingCompany: boolean;
    languageSelected: LanguageType;
    languages: string[];

    constructor(
        private eventService: EventService,
        private companyConfigurationService: CompanyConfigurationService,
        private authService: AuthenticationService,
        private alertService: AlertService,
        private storage: StorageService,
        private translateService: TranslatorService,
    ) { }

    ngOnInit() {
        this.eventService.broadcast('changePageHeading', this.translateService.trans('configuration.tittle'));
        this.currentUser = this.authService.getUser();
        this.languages = Object.values(LanguageType);
    }

    getCompanyConfiguration(company: Company) {

        const getCompanyConfigurationInput = new GetCompanyConfigurationInput(
            company.id,
        );

        this.companyConfigurationService.getCompanyConfiguration$(getCompanyConfigurationInput).subscribe(
            (companyConfiguration: CompanyConfigurationResponse) => {
                this.configuration = companyConfiguration;
                this.languageSelected = companyConfiguration.language;
                this.onlyRankingCompany = companyConfiguration.onlyRankingCompany;
            },
            error => {
                this.alertService.show('error', this.translateService.trans('toast.configuration.error'), '');
            }
        );
    }

    onCompaniesLoaded(companies: Company[]) {

        this.onSelectedCompany([companies[0]]);
    }

    onSelectedCompany(companies: Company[]) {

        this.companySelected = companies.shift();
        this.getCompanyConfiguration(this.companySelected);
    }

    changeRankingStatus() {
        const updateCompanyConfigurationInput = new UpdateCompanyConfigurationInput(
            this.companySelected.id,
        );

        updateCompanyConfigurationInput.onlyRankingCompany = this.onlyRankingCompany;
        updateCompanyConfigurationInput.language = this.languageSelected;

        this.companyConfigurationService.updateCompanyConfiguration$(updateCompanyConfigurationInput).subscribe(result => {
            this.updateLocalStorage();
            this.translateService.setLanguage(this.languageSelected);
            window.location.reload();

            this.alertService.show('success', this.translateService.trans('toast.configuration.succ'), '');
        });
    }

    private updateLocalStorage() {
        const companies = this.storage.getCompanies();
        companies[0].companyConfiguration.language = this.languageSelected;
        this.storage.setCompanies(companies);
    }
}
