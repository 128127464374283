import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Company } from 'src/app/core/models/company/company.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PageTitleResolver } from 'src/app/shared/services/page-tittle.resolver';
import { RefillableDocumentTypes } from '../../../../shared/types/refillable-documents.types';
import { TranslatorService } from '../../../../shared/services/translator.service';

@Component({
    selector: 'app-refillable-document-modal',
    templateUrl: './refillable-document-modal.component.html',
    styleUrls: ['./refillable-document-modal.component.scss']
})
export class RefillableDocumentModalComponent implements OnInit {

    constructor(
        public readonly activeModal: NgbActiveModal,
        private storageService: StorageService,
        private alertService: AlertService,
        private router: Router,
        private pageTittleResolver: PageTitleResolver,
        private translateService: TranslatorService,
    ) { }

    refillableDocumentRoute = '/refillable-documents/new-document';
    documentName: string;
    documentType: string;
    documentTypes: string[];
    companies: Company[];
    httpSubcription: Subscription;
    documentTypeName: string;

    ngOnInit() {
        this.companies = this.storageService.getCompanies();
        this.filterDocumentByCompany();
    }

    private filterDocumentByCompany() {
        if (this.companies[0].name.includes('Deditec')) {
            this.documentTypes = [new RefillableDocumentTypes().SALES_MANDATE, new RefillableDocumentTypes().AESTHETIC_CONSENT];
        } else if (this.companies[0].name.includes('Solvia')) {
            this.documentTypes = [new RefillableDocumentTypes().SALES_MANDATE];
        } else if (this.companies[0].name.includes('Bellavita')) {
            this.documentTypes = [new RefillableDocumentTypes().AESTHETIC_CONSENT];
        } else {
            this.documentTypes = [];
        }
    }

    save() {
        if (this.validateFile()) {
            return;
        }
        this.checkRoute();
        this.router.navigate([this.refillableDocumentRoute, { documentName: this.documentName, documentType: this.documentType }]);
        this.activeModal.close();

    }

    private checkRoute() {
        if (this.documentType === 'sales_mandate') {
            this.refillableDocumentRoute = '/refillable-documents/new-document';
        }
        if (this.documentType === 'aesthetic_consent') {
            this.refillableDocumentRoute = '/refillable-documents/new-consent';
        }
    }

    validateFile() {
        let validate = false;
        if (!this.documentName) {
            this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), 'Debe incluir el nombre del archivo');
            validate = true;
        }
        if (!this.documentType) {
            this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_name_add'));
            validate = true;
        }
        return validate;
    }
    translate(documentType: string) {
        if (documentType === "sales_mandate") {
            return "Mandato de venta"
        }
        if (documentType === "aesthetic_consent") {
            return "Consentimiento estético"
        }
    }

}
