import { Component, Input, OnInit } from '@angular/core';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Echarts } from 'echarts';
import { forkJoin } from 'rxjs';
import { Company } from 'src/app/core/models/company/company.model';
import { User } from 'src/app/core/models/user/User.model';
import { EventService } from 'src/app/core/services/event.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { EfficiencyEvolutionService } from 'src/app/shared/services/charts/efficiency-evolution.service';
import { UserStatisticsCalculator } from 'src/app/shared/services/stadistics/user-statistics.calculator';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
  selector: 'app-efficiency-progresive',
  templateUrl: './efficiency-progresive.component.html',
  styleUrls: ['./efficiency-progresive.component.scss']
})
export class EfficiencyProgresiveComponent implements OnInit {

  @Input() userSelected: User;
  showChart: boolean = true;
  companySelected: Company;
  date: Date;
  subscribeTypes: SubscribeTypes = new SubscribeTypes();
  chart: Echarts;
  dateRange: Date[];
  workingDays: Date[];

  options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    legend: {
      data: [ this.translateService.trans('charts.eficiency'), this.translateService.trans('charts.complete')]
    },
    xAxis:
    {
      type: 'category',
      data: [],
      axisPointer: {
        type: 'shadow'
      },
      axisLabel: {
        formatter: (function (value) {
          let label;
          label = format(new Date(value), 'dd/MM/yyyy');
          label = label.charAt(0).toUpperCase() + label.substring(1);
          return label;
        })
      }
    }
    ,
    yAxis:
    {
      type: 'value',
      name: this.translateService.trans('charts.percent'),
      min: 0,
      max: 200,
      interval: 50,
      axisLabel: {
        formatter: '{value} %'
      }
    },
    series: []
  };

  constructor(
    private userService: UserProfileService,
    private storageService: StorageService,
    private efficiencyEvolutionService: EfficiencyEvolutionService,
    private userStatisticsCalculator: UserStatisticsCalculator,
    private eventService: EventService,
    private translateService: TranslatorService,


  ) {
    this.date = new Date();
    this.dateRange = [startOfMonth(new Date()), endOfMonth(new Date())];
  }

  ngOnInit() {
    this.eventService.subscribe(this.subscribeTypes.ACTIVITY_DATEPICKER_CHANGE, (data) => {
      this.date = data;
      this.dateRange = [startOfMonth(new Date(data)), endOfMonth(new Date(data))];
      this.reloadChart()
    });
    this.eventService.subscribe(this.subscribeTypes.USER_SELECTED, userSelected => {
      this.userSelected = userSelected;
      this.reloadChart();
    })
    this.eventService.subscribe(this.subscribeTypes.ACTIVITY_CREATED, (data) => this.reloadChart());
    this.eventService.subscribe(this.subscribeTypes.ACTIVITY_DELETED, (data) => this.reloadChart());
    this.reloadChart()
  }

  reloadChart() {
    this.options.series = [];
    this.dateRange[0] = startOfMonth(this.date);
    this.dateRange[1] = endOfMonth(this.date);
    this.workingDays = this.efficiencyEvolutionService.generateXAxis(this.dateRange[0], this.dateRange[1]);
    const selectedCompaniesIds = this.storageService.getCompanies().map(company => company.id);
    forkJoin({
      activities: this.userService.activityByUserByDateRange$(selectedCompaniesIds, this.dateRange, [this.userSelected.id]),
      targets: this.userService.targetsByUserByDateRange$(selectedCompaniesIds, this.dateRange, [this.userSelected.id])
    }).subscribe(response => {
      this.options.xAxis.data = this.workingDays;
      const activitiesTotal = this.efficiencyEvolutionService.calculateProgresiveActivities(response, this.workingDays);
      const seriesSucces = this.getSerieObject(this.translateService.trans('charts.complete'), '#878787', activitiesTotal);
      const userWithActivityTarget = response.targets.users[0];
      if (!response.activities.users[0]) {
        this.showChart = false;
        return;
      }
      userWithActivityTarget.activitys = response.activities.users[0].activitys;
      const userEfficiencyByDay = this.userStatisticsCalculator.calculateEfficiencyByDateProgresive(
        userWithActivityTarget, this.workingDays
      );
      const seriesEfficiency = this.getSerieObject(this.translateService.trans('charts.eficiency'), '#63b598', userEfficiencyByDay);
      this.options.series.push(seriesSucces);
      this.options.series.push(seriesEfficiency);
      const maxEfficiency: number = Math.max(...seriesEfficiency.data);
      const maxActivityTarget: number = Math.max(...seriesSucces.data);
      this.options.yAxis.max = parseInt(Math.max(maxEfficiency, maxActivityTarget).toFixed(0)) + 10;
      this.chart.setOption(this.options, { notMerge: true });
      this.showChart = true;
    });
  }

  private getSerieObject(name: string, color: string, data: number[]) {
    return {
      name,
      type: 'line',
      smooth: true,
      itemStyle: { color },
      lineStyle: { width: 3, color },
      data,
    };
  }

  onChartInit(ec) {
    this.chart = ec;
  }
}
