import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { from } from 'rxjs';
import { concatAll } from 'rxjs/operators';
import { Company } from 'src/app/core/models/company/company.model';
import { PageViewListRow } from 'src/app/core/models/pageView/page-view.list';
import { User } from 'src/app/core/models/user/User.model';
import { EventService } from 'src/app/core/services/event.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { CompanyDropdownComponent } from 'src/app/shared-component/dropdown/company-dropdown/company-dropdown.component';
import { UserFilterService } from 'src/app/shared/services/userFilter.service';
import { environment } from 'src/environments/environment';
import { PageViewService } from '../../../core/services/page-view.service';
import { PageTitleResolver } from '../../../shared/services/page-tittle.resolver';
import { TranslatorService } from '../../../shared/services/translator.service';
import { StorageService } from '../../../core/services/storage.service';
//TODO : NUEVAS IMPORTACIONES
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-page-view-list',
  templateUrl: './page-view-list.component.html',
  styleUrls: ['./page-view-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ display: 'none', minHeight: '0' })),
      state('expanded', style({ display: 'table' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PageViewListComponent implements OnInit {

  @ViewChild(CompanyDropdownComponent, { static: false }) companyDropdownComponent: CompanyDropdownComponent;
  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  displayedColumns: string[] = ['user', 'visits'];
  dataSource = new MatTableDataSource();

  selectCompany: Company[];
  companies: Company[];
  selectedUsers: User[];
  users: User[];
  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;
  dateRange: Date[];
  checked = true;
  urlAvatar = environment.storageBucket;
  defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
  loading = true;
  loadingStart = true;
  rowSkeleton = { 'border-radius': '0', height: '40px' };
  pageViewListRows: PageViewListRow[];
  pageViewDetails;
  isFirstLoad = true;
  disabled = false;
  public filteredUsersMulti: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  public userMultiFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  public userMultiCtrl: FormControl = new FormControl();


  constructor(
    private userService: UserProfileService,
    private localeService: BsLocaleService,
    private userFilterService: UserFilterService,
    private eventService: EventService,
    private pageViewService: PageViewService,
    private pageTitleResolver: PageTitleResolver,
    private translateService: TranslatorService,
    private storageService: StorageService,
  ) {
    this.dateRange = [startOfMonth(new Date()), endOfMonth(new Date())];
    if (new Date() < this.dateRange[1]) {
      this.dateRange[1] = new Date();
    }
    const language = this.storageService.getCompanies()[0].companyConfiguration.language;
    this.localeService.use(language);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD-MM-YYYY' });
  }

  ngOnInit() {
    this.eventService.broadcast('changePageHeading', this.translateService.trans('pageview.tittle'));
    this.dataSource = new MatTableDataSource(this.pageViewListRows);

    //TODO : AÑADIDO
    this.userMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
        this.filterUsersMulti();
    });

  }

  reloadPageViewList() {
    this.loading = true;
    const selectedUsersIds = this.selectedUsers.map(selectedUser => selectedUser.id);
    this.dateRange[0] = startOfDay(this.dateRange[0]);
    this.dateRange[1] = endOfDay(this.dateRange[1]);
    this.pageViewService.getCountPageView$(selectedUsersIds, this.dateRange).subscribe(pageViewResponse => {
      this.dataSource = new MatTableDataSource(pageViewResponse);
      this.loading = false;
      this.loadingStart = false;
      this.isFirstLoad = false;
    });
  }

  getUsers() {
    const userObservables = [];
    this.selectCompany.forEach(companyId => {
      userObservables.push(this.userService.usersByCompany$(companyId.id));
    });

    let users = [];
    from(userObservables)
      .pipe(concatAll())
      .subscribe((companyUsers: any) => {
        users = [...users, ...companyUsers];
      }, () => { }, () => {
        this.users = this.userFilterService.filterUniqueUsers(users);
        this.selectedUsers = this.users;
        //TODO : AÑADIDO
        this.userMultiCtrl.patchValue(this.selectedUsers);
        this.filteredUsersMulti.next(this.users.slice());


        if (this.isFirstLoad) {
          this.reloadPageViewList();
        }
      });
  }

  onCompaniesLoaded(companies) {
    this.companies = companies;
    this.onSelectedCompany(companies);
  }
  onSelectedCompany(companies) {
    this.selectCompany = companies;
    this.getUsers();
  }

  selectAll() {
    if (this.checked) {
      this.selectedUsers = this.users;
    } else {
      this.selectedUsers = [];
    }
  }

  errorHandler(event) {
    event.target.src = this.defaultAvatar;
  }

  loadDetails(userId: string) {
    this.pageViewDetails = [];
    this.pageViewService.getPageViewByDateRangeByUser$(userId, this.dateRange).subscribe(pageViews => {
      pageViews.forEach(pageView => {
        pageView.route = this.pageTitleResolver.getPageTitle(pageView.route);
      });
      pageViews.sort((a, b) => a.date > b.date ? 1 : -1);
      this.pageViewDetails = pageViews;
    });
  }


  //TODO : AQUI VAN TODOS LOS METODOS NUEVOS COPIADOS DE LA OTRA CLASE, TENDREMOS QUE VER QUE TODO FUNCIO BIEN O IGUAL...





  toggleSelectAll(selectAllValue: boolean) {
    this.filteredUsersMulti.pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(val => {
            if (selectAllValue) {
                this.userMultiCtrl.patchValue(val);
                this.selectedUsers = this.users;
                
            } else {
                this.userMultiCtrl.patchValue([]);
                this.selectedUsers = [];
            }
        });
}

    protected filterUsersMulti() {
      if (!this.users) {
          return;
      }
      let search = this.userMultiFilterCtrl.value;
      if (!search) {
          this.filteredUsersMulti.next(this.users.slice());
          return;
      } else {
          search = search.toLowerCase();
      }
    
      this.filteredUsersMulti.next(
          this.users.filter(user => user.name.toLowerCase().indexOf(search) > -1)
      );

    }

}
