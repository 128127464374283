import { Component, Input, OnInit } from '@angular/core';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Echarts } from 'echarts';
import { forkJoin } from 'rxjs';
import { EfficiencyEvolutionService } from 'src/app/shared/services/charts/efficiency-evolution.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { Company } from '../../../core/models/company/company.model';
import { User } from '../../../core/models/user/User.model';
import { AuthenticationService } from '../../../core/services/auth.service';
import { EventService } from '../../../core/services/event.service';
import { StorageService } from '../../../core/services/storage.service';
import { UserProfileService } from '../../../core/services/user.service';
import { UserStatisticsCalculator } from '../../../shared/services/stadistics/user-statistics.calculator';
import { RolType } from '../../../shared/types/rol.types';

@Component({
  selector: 'app-efficiency-evolution',
  templateUrl: './efficiency-evolution.component.html',
  styleUrls: ['./efficiency-evolution.component.scss']
})
export class EfficiencyEvolutionComponent implements OnInit {

  @Input() userSelected: User;
  userSelectedId: string;
  showChart: boolean = true;
  companySelected: Company;
  date: Date;
  subscribeTypes: SubscribeTypes = new SubscribeTypes();
  rolType: RolType = new RolType();
  chart: Echarts;
  dateRange: Date[];
  workingDays: Date[];
  maxActivityTarget: number = 0;

  options = {
    tooltip: {
      position: 'top',
      formatter: (p) => {
        let label = format(new Date(p.data[0]), 'dd/MM/yyy');
        return label + ': ' + p.data[1] + ' %';
      }
    },
    top: 'middle',
    left: 'center',
    orient: 'vertical',
    visualMap: {
      pieces: [
        { min: 0, max: 30, label: 'Baja ', color: '#ff0000' },
        { min: 30, max: 80, label: 'Media', color: '#ffae00' },
        { min: 80, max: 100, label: 'Óptima ', color: '#20ee00' },
      ],
      type: 'piecewise',
      orient: 'horizontal',
      left: 'center',
      top: '95%',
      inRange: {
        symbol: 'circle',
        color: ['#ff0000', '#ffae00', '#20ee00'],
        symbolSize: [8, 35],
      },
      show: true
    },
    title: {
      top: 30,
      left: 'center',
      text: ''
    },
    calendar: {
      dayLabel: {
        firstDay: 1,
        nameMap: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      },
      cellSize: this.calculateCellSize(),
      orient: 'vertical',
      left: 'center',
      range: [],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#929292',
          width: 0.5,
          type: 'solid'
        }
      },
      itemStyle: {
        color: '#ffffff',
        borderWidth: 1,
        borderColor: '#b4b4b4'
      },
      yearLabel: { show: false },
      monthLabel: { show: false }
    },

    series: [{
      name: 'Eficiencia',
      type: 'scatter',
      coordinateSystem: 'calendar',
      data: []
    }, {
      name: 'Superado',
      type: 'effectScatter',
      coordinateSystem: 'calendar',
      data: [],
      showEffectOn: 'render',
      rippleEffect: {
        brushType: 'stroke',
        scale: 1.5
      },
      hoverAnimation: true,
      zlevel: 1
    }
    ]
  };


  constructor(
    private userService: UserProfileService,
    private storageService: StorageService,
    private efficiencyEvolutionService: EfficiencyEvolutionService,
    private userStatisticsCalculator: UserStatisticsCalculator,
    private eventService: EventService,
    private authService: AuthenticationService,
  ) {
    this.date = new Date();
    this.dateRange = [startOfMonth(new Date()), endOfMonth(new Date())];
  }

  ngOnInit() {
    this.eventService.subscribe(this.subscribeTypes.ACTIVITY_DATEPICKER_CHANGE, (data) => {
      this.date = data;
      this.dateRange = [startOfMonth(new Date(data)), endOfMonth(new Date(data))];
      this.reloadChart()
    });

    if (this.authService.getUser().roles === this.rolType.ROLE_USER) {
      this.userSelectedId = this.authService.getUser().id;
    }
    this.eventService.subscribe(this.subscribeTypes.USER_SELECTED, userSelected => {
      this.userSelected = userSelected;
      this.reloadChart();
    })
    this.eventService.subscribe(this.subscribeTypes.ACTIVITY_CREATED, (data) => this.reloadChart());
    this.eventService.subscribe(this.subscribeTypes.ACTIVITY_DELETED, (data) => this.reloadChart());
    this.reloadChart()
  }

  reloadChart() {
    this.options.series[0].data = [];
    this.dateRange[0] = startOfMonth(this.date);
    this.dateRange[1] = endOfMonth(this.date);
    this.workingDays = this.efficiencyEvolutionService.generateXAxis(this.dateRange[0], this.dateRange[1]);
    const selectedCompaniesIds = this.storageService.getCompanies().map(company => company.id);
    this.userSelectedId = this.userSelected.id;
    forkJoin({
      activities: this.userService.activityByUserByDateRange$(selectedCompaniesIds, this.dateRange, [this.userSelectedId]),
      targets: this.userService.targetsByUserByDateRange$(selectedCompaniesIds, this.dateRange, [this.userSelectedId])
    }).subscribe(response => {
      this.options.calendar.range = this.dateRange;

      const userWithActivityTarget = response.targets.users[0];
      if (!response.activities.users[0]) {
        this.showChart = false;
        return;
      }
      userWithActivityTarget.activitys = response.activities.users[0].activitys;
      const userEfficiencyByDay = this.userStatisticsCalculator.calculateEfficiencyByDate(userWithActivityTarget, this.workingDays)
      this.options.series[0].data = userEfficiencyByDay.filter(serieData => serieData[1] <= 100);
      this.options.series[1].data = userEfficiencyByDay.filter(serieData => serieData[1] > 100);
      this.options.visualMap.pieces[2].max = Math.max(...userEfficiencyByDay.map(count => count[1])) + 1;
      this.chart.setOption(this.options, { notMerge: true });
      this.showChart = true;
    });
  }

  onChartInit(ec) {
    this.chart = ec;
  }

  calculateCellSize() {
    if (
      (window.innerWidth <= 800 && window.innerHeight <= 600) ||
      (window.innerHeight <= 800 && window.innerWidth <= 600)
    ) {
      return [40, 40];
    } else {
      return [55, 55];
    }
  }
}
