import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { endOfDay, startOfDay } from 'date-fns';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput/public_api';
import { jqxKnobComponent } from 'jqwidgets-ng/jqxknob/public_api';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { BsDatepickerViewMode, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { Subscription } from 'rxjs';
import { ActivityTargetsByUserInDateSelectedResponse } from 'src/app/core/models/activity/ActivityTargetsByUserInDateSelected.response';
import { CreateActivityInput } from 'src/app/core/models/activity/createActivity.input';
import { UserLogged } from 'src/app/core/models/auth.models';
import { CompaniesByUserLoggedResponse } from 'src/app/core/models/company/CompaniesByUserLogged.response';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyEmployeeRoleTargetTypes } from 'src/app/core/models/companyemployeeroletargettypes/companyemployeeroletargettypes.model';
import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { EventService } from 'src/app/core/services/event.service';
import { RolType } from 'src/app/shared/types/rol.types';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { environment } from '../../../environments/environment';
import { CalculatorUtil } from '../../shared/services/calculator-util.service';
import { DateUtil } from '../../shared/services/date-util.service';
import { TranslatorService } from '../../shared/services/translator.service';
import { StorageService } from '../../core/services/storage.service';
@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, OnDestroy {

    @Input() userSelected;
    companies: Company[] = [];
    userCompanies: Company[];
    companySelected: string;
    selectCompany: Company;
    startDate: Date;
    finishDate: Date;
    dateRange: Date[];
    rolType: RolType = new RolType();
    sumActivity: number;
    sumTarget: number;
    activityTargetPercent: number;
    activityCircles = [];
    showDates: boolean = true;
    userLogged: UserLogged;
    createActivity: CreateActivityInput;
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    bsConfigMonth: Partial<BsDatepickerConfig>;
    minMode: BsDatepickerViewMode = 'month';
    companySubcription: Subscription;
    myKnob: jqxKnobComponent;
    myInput: jqxInputComponent;
    date: Date;

    progressBar: any =
        {
            style: { fill: '#e30613' },
            background: { fill: '#eeeeee' },
            size: '30%',
            offset: '60%'
        };

    pointer: any =
        {
            type: 'line',
            style: { fill: '#00a644' },
            size: '0%',
            offset: '60%',
            thickness: 20
        };

    constructor(
        private eventService: EventService,
        private activityService: ActivityService,
        private authService: AuthenticationService,
        private companyService: CompanyService,
        private localeService: BsLocaleService,
        private calculatorUtil: CalculatorUtil,
        private dateUtil: DateUtil,
        private transService: TranslatorService,
        private storageService: StorageService,
    ) {
        
        const language = this.storageService.getCompanies()[0].companyConfiguration.language;
        this.localeService.use(language);
        this.dateRange = [new Date(), new Date()];
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'YYYY-MM-DD' });

        this.bsConfigMonth = Object.assign({}, {
            containerClass: this.colorTheme,
            dateInputFormat: 'MMMM-YYYY',
            minMode: this.minMode
        });
        this.date = new Date();
    }

    ngOnInit() {
        this.userLogged = this.authService.getUser();
        if (this.userLogged.roles !== this.rolType.ROLE_USER) {
            this.eventService.broadcast('changePageHeading', this.transService.trans('dashboard.admin.statistic'));
        }
        this.startDate = this.dateRange[0];
        this.finishDate = this.dateRange[1];

        if (this.userLogged.roles === this.rolType.ROLE_USER) {
            this.userSelected = this.userLogged;
            this.getCompanys();
        } else {
            this.companySubcription = this.eventService.subscribe(this.subscribeTypes.USER_SELECTED, userSelected => {
                if (!userSelected) {
                    this.activityCircles = [];
                    this.userSelected = null;
                } else {
                    this.userSelected = userSelected;
                    this.getCompanys();
                    this.getStadistic();
                }
            });
        }
        this.eventService.subscribe(this.subscribeTypes.ACTIVITY_CREATED, (data) => this.getStadistic());
        this.eventService.subscribe(this.subscribeTypes.ACTIVITY_DELETED, (data) => this.getStadistic());
    }
    ngOnDestroy() {
    }

    getCompanys() {
        this.companies = [];
        this.userCompanies = [];
        this.companyService.companiesByUserLogged$().subscribe(
            (companiesByUserLoggedResponse: CompaniesByUserLoggedResponse) => {
                companiesByUserLoggedResponse.companyUserEmployeeRole.forEach(companyUserRoles => {
                    const filteredCompanies = this.companies.filter(companies => {
                        return companies.id === companyUserRoles.idCompany.id;
                    });
                    if (filteredCompanies.length === 0) {
                        this.companies.push(companyUserRoles.idCompany);
                    }
                });

                this.companyService.companiesByUser$(this.userSelected.id).subscribe(
                    (companiesByUserResponse: CompaniesByUserLoggedResponse) => {
                        companiesByUserResponse.companyUserEmployeeRole.forEach(companyUserRoles => {
                            const filteredUserCompanies = this.companies.filter(companies => {
                                return companies.id === companyUserRoles.idCompany.id;
                            });
                            const filteredCompanies = this.userCompanies.filter(companies => {
                                return companies.id === companyUserRoles.idCompany.id;
                            });
                            if (filteredUserCompanies.length !== 0 && filteredCompanies.length === 0) {
                                this.userCompanies.push(companyUserRoles.idCompany);
                            }
                        });
                        this.selectCompany = this.userCompanies[0];
                        this.companySelected = this.selectCompany.id;
                        this.getStadistic();
                    });

            });
    }

    getStadistic() {
        if (this.userSelected && this.companySelected) {
            this.dateRange[0] = startOfDay(this.dateRange[0]);
            this.dateRange[1] = endOfDay(this.dateRange[1]);
            this.activityService.activityTargetsByUserInDateSelected$(this.companySelected, this.userSelected.id, this.dateRange).subscribe(
                (statistic: ActivityTargetsByUserInDateSelectedResponse) => {
                    if (statistic.user.length === 0) {
                        this.activityCircles = [];
                        return;
                    }
                    this.activityCircles = [];
                    statistic.user[0].companyUserEmployeeRoles.forEach(element => {
                        let sumActivity: number;
                        let sumTarget: number;
                        const targetCircle = [];
                        element.idEmployeeRole.companyEmployeeRoleTargetTypes.forEach(target => {
                            if (target.idTarget.targetEspecifications.length === 0) {
                                return;
                            }
                            sumActivity = this.calculateSumActivity(target);
                            sumTarget = this.calculateSumTarget(target);

                            const businessDays = this.dateUtil.getDiffInBusinessDays(this.dateRange[0], this.dateRange[1]);
                            sumTarget = sumTarget * businessDays;
                            this.activityTargetPercent = (sumActivity / sumTarget) * 100;
                            this.activityTargetPercent = this.calculatorUtil.fixNanAndFinite(this.activityTargetPercent);
                            targetCircle.push([target.idTarget, sumActivity, sumTarget, this.activityTargetPercent]);
                        });
                        if (targetCircle.length > 0) this.activityCircles.push([element.idEmployeeRole.name, targetCircle]);
                    });
                });
        }
    }

    private calculateSumActivity(target: CompanyEmployeeRoleTargetTypes) {
        return target.idTarget.activitys
            .reduce((a2, currentValue) => {
                if (currentValue.id) {
                    return a2 + currentValue.realAmount;
                } else {
                    return a2;
                }
            }, 0);
    }

    private calculateSumTarget(target: CompanyEmployeeRoleTargetTypes) {
        return target.idTarget.targetEspecifications
            .reduce((a2, currentTarget) => {
                if (currentTarget) {
                    return a2 + currentTarget.goal;
                } else {
                    return a2;
                }
            }, 0);
    }

    sendCompanySelected(company) {
        this.companySelected = company.id;
        this.getStadistic();
    }

    onDateFromChange() {
        this.startDate = this.dateRange[0];
        this.getStadistic();
    }

    onDateToChange() {
        this.finishDate = this.dateRange[1];
        this.getStadistic();
    }
    onFormFieldChange() {
        this.eventService.broadcast(this.subscribeTypes.ACTIVITY_DATEPICKER_CHANGE, this.date);
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }

}
