import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { createHttpLink } from "apollo-link-http";

import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, from } from 'apollo-link';
import { ErrorLink } from 'apollo-link-error';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../core/services/auth.service';
import { GraphqlErrorHandler } from '../shared/services/graphql-error-handler.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        ApolloModule,
        HttpLinkModule
    ],
})


export class GraphQlModule {

    httpLink = createHttpLink({ uri: environment.backendEndpoint + '/graphql' });

    authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                authorization: (this.authService.getToken()) ? `JWT ${this.authService.getToken()}` : null
            }
        });

        return forward(operation);
    });

    constructor(
        apollo: Apollo,
        public authService: AuthenticationService,
        private graphqlErrorHandler: GraphqlErrorHandler,
    ) {
        const errorLink = new ErrorLink(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                this.graphqlErrorHandler.handle(graphQLErrors);
            }
        });

        apollo.create({
            link: from([errorLink, this.authMiddleware, this.httpLink]),
            cache: new InMemoryCache({
                addTypename: false
            }),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore'
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'

                }


            }
        });
    }
}
