import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Door } from '../../core/models/door/door.model';
import { google } from 'google-maps';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/models/user/User.model';

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss']
})
export class GeolocationComponent implements OnInit {

  @Input() door: Door;
  @Input() user: User;
  urlAvatar = environment.storageBucket;

  @ViewChild('gmap', { static: true }) gmapElement: ElementRef;

  constructor(
    readonly activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    const myLatlng = new google.maps.LatLng(parseFloat(this.door.latitude), parseFloat(this.door.longitude));
    const mapProp = {
      center: myLatlng,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    const myMarker = new google.maps.Marker({
      position: myLatlng,
    });
    myMarker.setMap(map);
  }
}
