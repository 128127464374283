import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCompanyGroupByCompany } from '../../graphql/operations/companyGroup/queries';
import { CompanyGroup } from '../models/company-group/company-group.model';

@Injectable({ providedIn: 'root' })
export class CompanyGroupService {
    constructor(
        private readonly apollo: Apollo) { }

    getCompanyGroupByCompany$(companyId: string): Observable<CompanyGroup> {
        return this.apollo
            .query({
                query: getCompanyGroupByCompany,
                variables: {
                    companyId
                }
            }).pipe(map(result => {
                return result.data['getCompanyGroupByCompany'];
            }));
    }

}
