import { Door } from '../door/door.model';
import { User } from '../user/User.model';
export class CreateScheduleControl {

    id: string;
    user: User;
    date: string;
    signature: string;
    door: Door[];

    constructor(
        user?: User,
        date?: string,
        signature?: string,
        door?: Door[]) {

        this.user = user;
        this.date = date;
        this.signature = signature;
        this.door = door;
    }
}
