import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user/User.model';

@Component({
  selector: 'app-target-container',
  templateUrl: './target-container.component.html',
  styleUrls: ['./target-container.component.scss']
})
export class TargetContainerComponent implements OnInit {
  @Input() roles: any;
  @Input() userSelected: User;
  @Input() dateRange: Date[];

  targetEspecificationsOrdered: any[] = [];
  totalTargetEspecifications: any[] = [];

  ngOnInit() {
    this.orderTargetEspecifications(this.roles);

  }

  orderTargetEspecifications(roles: any[]){
    this.targetEspecificationsOrdered = [];
    if(roles[0] == "Asesor Personal OM"){
      let objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "CONTACTOS FARMING");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "VISITAS NEGOCIOS");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "VISITAS DE CAPTACIÓN");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "FIRMA EXCLUSIVA");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "FIRMA SIN EXCLUSIVA");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }
      
      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "PUBLICACIONES");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "SEGUIMIENTOS PROPIETARIOS");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }
      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "REPRICING");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "DESPUBLICADOS");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "LEADS PROPIETARIOS");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "LEADS COMPRADORES");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "VISITAS OM");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "SEGUIMIENTOS COMPRADORES");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "SOLICITUD OM");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "ARRAS OM");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }

      objetive = roles[1].find(object =>  object[0].name.toUpperCase() == "ESCRITURAS OM");

      if (objetive){
        this.targetEspecificationsOrdered.push(objetive)
      }
    }

    
    this.totalTargetEspecifications = [...this.targetEspecificationsOrdered, ...this.roles[1]]
    const setTarget = new Set();
    this.totalTargetEspecifications.forEach(setTarget.add, setTarget)
    this.totalTargetEspecifications = Array.from(setTarget);
  }
  
}