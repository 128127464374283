import { TargetEspecificationsInput } from '../targetEspecifications/targetEspecifications.input';

export class TargetInput {
    name: string;
    targetEspecifications: TargetEspecificationsInput[];

    constructor(name: string, targetEspecifications: TargetEspecificationsInput[] ) {
        this.name = name;
        this.targetEspecifications = targetEspecifications;

    }
}
