import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './core/services/auth.service';
import { EventService } from './core/services/event.service';
import { StorageService } from './core/services/storage.service';
import { TranslatorService } from './shared/services/translator.service';
import { SubscribeTypes } from './shared/types/subcribes.types';

declare var gtag: Function;


@Component({
  selector: 'app-agentsapp',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private eventService: EventService,
    private translatorService: TranslatorService,
    private storage: StorageService,
  ) {

    if (this.storage.getCompanies()) {
      const language = this.storage.getCompanies()[0].companyConfiguration.language;
      this.translatorService.setLanguage(language);
    }

    if (!this.authService.getUser()) {
      this.eventService.subscribe(new SubscribeTypes().USER_LOGGED, user => {
        const userId = user.id;
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            gtag('config', 'UA-158817588-1',
              {
                'page_path': event.urlAfterRedirects,
                'userId': userId
              },
            );
          }
        });
      });
    } else {
      const userId = this.authService.getUser().id;
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-158817588-1',
            {
              'page_path': event.urlAfterRedirects,
              'userId': userId
            },
          );
        }
      });
    }
  }
  ngOnInit() {
  }
}
