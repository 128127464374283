import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyGroupService } from 'src/app/core/services/companyGroup.service';
import { DocumentInput } from '../../../core/models/document/document.input';
import { DocumentService } from '../../../core/services/document.service';
import { EventService } from '../../../core/services/event.service';
import { StorageService } from '../../../core/services/storage.service';
import { AlertService } from '../../../shared/services/alert.service';
import { SubscribeTypes } from '../../../shared/types/subcribes.types';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {

  constructor(
    public readonly activeModal: NgbActiveModal,
    private documentService: DocumentService,
    private companyGroupService: CompanyGroupService,
    private storageService: StorageService,
    private alertService: AlertService,
    private eventService: EventService,
    private httpService: HttpClient,
    private translateService: TranslatorService,

  ) { }

  documentName: string;
  documentFile: File;
  companies: Company[];
  httpSubcription: Subscription;
  uploading = false;

  ngOnInit() {
    this.companies = this.storageService.getCompanies();
  }

  save() {
    if (this.validateFile()) {
      return;
    }
    this.uploading = true;
    const documentInput = new DocumentInput();
    documentInput.name = this.documentName;
    documentInput.status = true;

    this.companyGroupService.getCompanyGroupByCompany$(this.companies[0].id).subscribe(data => {
      documentInput.companyGroupId = data.id;
      documentInput.url = documentInput.companyGroupId + '/' + (this.documentFile.name);
      this.documentUpload(documentInput);
    });
  }

  private createDocument(documentInput: DocumentInput) {
    this.documentService.createDocument$(documentInput).subscribe(data => {
      if (data) {
        this.eventService.broadcast(new SubscribeTypes().DOCUMENT_CREATED);
        this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_info'));
        this.documentFile = null;
        this.documentName = '';
      } else {
        this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_info'));
      }
    });
  }

  private validateFile() {
    let validate = false;
    if (!this.documentName) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('upload.modal.name.err'));
      validate = true;
    }
    if (!this.documentFile) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('upload.modal.file.err'));
      validate = true;
    }
    return validate;
  }

  fileInputChange(event) {
    this.documentFile = event.target.files[0];
  }

  documentUpload(documentInput: DocumentInput) {
    const documentName = documentInput.companyGroupId + '/' + (this.documentFile.name);
    this.documentService.getDocumentUploadUrl$(documentName, this.documentFile.type).subscribe(uploadUrl => {
      const headers = new HttpHeaders({ 'Content-Type': this.documentFile.type });
      const req = new HttpRequest('PUT', uploadUrl, this.documentFile, {
        headers: headers,
      });
      this.httpService.request(req).subscribe(res => {
        if (res.type === HttpEventType.Response) {
          if (!res.type || (res.type === 4 && res.ok === false)) {
            this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('upload.modal.file.err_info'));
            return;
          }
          this.uploading = false;
          this.createDocument(documentInput);
        }
      });
    });
  }
}
