import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { UserLogged } from 'src/app/core/models/auth.models';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyUserEmployeeRole } from 'src/app/core/models/companyuseremployeerole/companyUserEmployeeRole.model';
import { EmployeeRoleByCompanyResponse } from 'src/app/core/models/employeeRole/companyemployeeroletargettypes.response';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { EmployeeRoleService } from 'src/app/core/services/employeerole.service';
import { EventService } from 'src/app/core/services/event.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { environment } from '../../../environments/environment';
import { CompaniesByUserLoggedResponse } from '../../core/models/company/CompaniesByUserLogged.response';
import { StorageService } from '../../core/services/storage.service';
import { RolType } from '../../shared/types/rol.types';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @Input() isCondensed = false;
  menu: any;
  currentUser: UserLogged;
  companies: any[];
  employeeRoles: CompanyUserEmployeeRole[];
  selectCompany: Company[];
  rolType: RolType = new RolType();
  subscribeTypes: SubscribeTypes = new SubscribeTypes();
  lastCompanySelected: Company;
  urlAvatar = environment.storageBucket;
  defaultAvatar = environment.storageBucket + '/user/avatar/default.png';

  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private eventService: EventService,
    private employeeRoleService: EmployeeRoleService,
    private companyService: CompanyService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.getUser();
    this.eventService.subscribe(this.subscribeTypes.EMPLOYEEROLE_CREATED, data => this.getCompanys());
    this.getCompanys();
    this.eventService.subscribe(this.subscribeTypes.COMPANIES_UPDATED, (data) => this.getCompanys());
    this.eventService.subscribe(this.subscribeTypes.USER_UPDATED, (data) => this.getUser());
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
  }
  getUser() {

    this.currentUser = this.authService.getUser();
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  getCompanys() {
    this.companyService.companiesByUserLogged$().subscribe(
      (companiesByUserLoggedResponse: CompaniesByUserLoggedResponse) => {
        this.companies = companiesByUserLoggedResponse.companyUserEmployeeRole;
        this.selectCompany = [this.companies[0].idCompany];
        this.companies = this.filterNoRepeatCompanies(this.companies);
        this.sendCompanySelected(this.selectCompany);
        this.eventService.broadcast(this.subscribeTypes.USER_ROLES, this.companies);
      });
  }
  filterNoRepeatCompanies(companies) {
    return companies.filter((company, pos, arr) => {
      if ((arr.map(subCompany => subCompany.idCompany.id).indexOf(company.idCompany.id) === pos)) {
        return true;
      }
    });
  }

  getEmployeeRoles(companySelected) {
    this.employeeRoleService.employeeRoleByCompany$(companySelected).subscribe(
      (employeeRoleByCompanyResponse: EmployeeRoleByCompanyResponse) => {
        this.employeeRoles = employeeRoleByCompanyResponse.employeeRole;
      });
  }

  sendCompanySelected(companies) {
    if (Object.keys(companies).length < 1) {
      this.selectCompany = [this.lastCompanySelected];
      return [this.companies[0].idCompany];
    } else {
      this.lastCompanySelected = companies[0];
      this.eventService.broadcast(this.subscribeTypes.COMPANIES, companies);
      this.storageService.setCompanies(companies);
      return companies;
    }
  }

  printUserRoles(userRol) {
    if (userRol === this.rolType.ROLE_SUPERADMIN) {
      return 'SuperAdmin';
    }
    if (userRol === this.rolType.ROLE_USER) {
      return 'Comercial';
    }
    if (userRol === this.rolType.ROLE_ADMIN) {
      return 'Administrador';
    }
    if (userRol === this.rolType.ROLE_COORDINATOR) {
      return 'Coordinador';
    }
  }

  errorHandler(event) {
    event.target.src = this.defaultAvatar;
  }

}

