import gql from 'graphql-tag';

export const loginUserMutation = gql`
    mutation loginUser($loginUserInput: LoginUserInput){
        loginUser(loginUserInput: $loginUserInput){
            accessToken,
            expiresIn
        }
    }`;

export const sendEmailForRecoverPasswordMutation = gql`
    mutation sendEmailForRecoverPassword($input: SendEmailForRecoverPasswordInput){
        sendEmailForRecoverPassword(sendEmailForRecoverPasswordInput: $input)
    }
`;

export const changePasswordWithSecurityToken = gql`
  mutation changePasswordWithSecurityToken($input: ChangePasswordWithSecurityTokenInput){
    changePasswordWithSecurityToken(changePasswordWithSecurityTokenInput: $input)
  }
`;
