import { Injectable } from '@angular/core';
import { addDays } from '@fullcalendar/core';
import { differenceInDays, format, isSameDay } from 'date-fns';
import { Activity } from '../../../core/models/activity/activity.model';
import { Target } from '../../../core/models/target/target.model';
import { User } from '../../../core/models/user/User.model';
import { CalculatorUtil } from '../calculator-util.service';
import { DateUtil } from '../date-util.service';

@Injectable({ providedIn: 'root' })
export class EfficiencyEvolutionService {

    constructor(
        private dateUtil: DateUtil,
        private calculatorUtil: CalculatorUtil,

    ) { }

    getTotalTargetGoalsInDateRangeByUser(user: User, startDate: Date, finishDate: Date) {
        startDate = new Date(startDate);
        finishDate = new Date(finishDate);
        let totalTargetGoal = 0;
        const totalBusinessDays = this.dateUtil.getDiffInBusinessDays(finishDate, startDate);
        totalTargetGoal = user.companyUserEmployeeRoles.reduce((accumulator, currentValue) =>
            accumulator + currentValue.idEmployeeRole.companyEmployeeRoleTargetTypes.reduce((a2, currentTarget) =>
                currentTarget.idTarget ? a2 + this.sumGoalOfTargetInDateRange(currentTarget.idTarget, startDate, finishDate) : a2,
                0),
            0);
        return parseFloat((totalTargetGoal * totalBusinessDays).toFixed(2));
    }

    sumGoalOfTargetInDateRange(target: Target, startDate: Date, finishDate: Date) {
        return target.targetEspecifications.reduce((a3, currentSpec) => {
            let numberToSum = 0;
            if ((startDate >= new Date(currentSpec.startDate) && startDate <= new Date(currentSpec.finishDate)) ||
                (new Date(currentSpec.startDate) >= startDate && new Date(currentSpec.startDate) <= finishDate)) {
                numberToSum = currentSpec.goal;
            }

            return parseFloat((a3 + numberToSum).toFixed(2));
        }, 0);
    }

    getTotalActivitiesInDate(activities: Array<Activity>, date: Date, ) {

        let totalAmount = 0;

        activities.forEach(activity => {

            if (isSameDay(new Date(activity.dateDay), new Date(date))) {
                totalAmount += activity.realAmount;
            }
        });

        return totalAmount;
    }


    generateXAxis(dateFrom: Date, dateTo: Date) {
        const days = [];
        for (let i = 0; i <= differenceInDays(dateTo, dateFrom); i++) {
            if (this.dateUtil.isBusinessDay(addDays(dateFrom, i))) {
                days.push(format(addDays(dateFrom, i), 'yyyy-MM-dd'));
            }
        }

        return days;
    }

    calculateTotalActivities(user, dates):number[] {
        let activityTargetOfDay = [];
        if (user.activities.users.length > 0) {
            let activities: Activity[] = user.activities.users[0].activitys;
            let target: User = user.targets.users[0];
            dates.forEach(dateDay => {
                const activitiesOfDay = this.getTotalActivitiesInDate(activities, dateDay);
                const targetsOfDay = this.getTotalTargetGoalsInDateRangeByUser(target, dateDay, dateDay);
                activityTargetOfDay.push(this.calculatorUtil.fixNanAndFinite((activitiesOfDay / targetsOfDay) * 100).toFixed(2));
            });
        }
        return activityTargetOfDay;
    }

    calculateProgresiveActivities(user, dates) {

        let activitiesOfDay;
        let targetsOfDay;
        let activityTargetOfDay = [];
        let totalActivities = 0;
        if (user.activities.users.length > 0) {
            let activities: Activity[] = user.activities.users[0].activitys;
            let target: User = user.targets.users[0];
            dates.forEach(dateDay => {
                activitiesOfDay = this.getTotalActivitiesInDate(activities, dateDay);
                targetsOfDay = this.getTotalTargetGoalsInDateRangeByUser(target, dateDay, dateDay);
                totalActivities += this.calculatorUtil.fixNanAndFinite((activitiesOfDay / targetsOfDay) * 100) / dates.length;
                activityTargetOfDay.push(totalActivities.toFixed(2));
            });
        }
        return activityTargetOfDay;
    }
}
