import { Component, OnInit, ElementRef } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';
import { TranslatorService } from '../../shared/services/translator.service';

@Component({
    selector: 'app-target',
    templateUrl: './target.component.html',
    styleUrls: ['./target.component.scss']
})
export class TargetComponent implements OnInit {

    constructor(private eref: ElementRef,
         private eventService: EventService,
         private translateService: TranslatorService,
         ) {

    }

    ngOnInit() {
        this.eventService.broadcast('changePageHeading', this.translateService.trans('charts.target')+' - Dashboard Admin');
    }

}
