export class TranslationFr {

  static readonly dict =
    {
      'dashboard.admin.statistic': 'Statistiques - Dashboard Admin',
      'menu.not_hired': 'Non embauch\é',
      'menu.not': 'Non',
      'menu.hired': 'embauch\é',
      'menu.management': 'Gestion',
      'menu.statistics': 'Statistiques',
      'menu.work_control': 'Contrôle du travail',
      'menu.signing_panel': 'Interface pointage',
      'menu.signing_panel.user': 'Historique pointage',
      'menu.flow_management': 'Contrôle des visites',
      'menu.task_management': 'Gestionnaire des tâches',
      'menu.autofill': 'Formulaires',
      'menu.document_store': 'Bibliothèque',
      'menu.configuration': 'Configuration',
      'menu.company_config': 'Options d\'entreprise',
      'menu.company_data': 'Informations entreprise',
      'activities.no_user_selected': 'Aucun utilisateur sélectionné',
      'activities.comercial_of': 'Activité commerciale du ',
      'activities.of_month': 'Statistiques mensuelles ',
      'activities.select_date_range': 'Sélectionner une période',
      'activities.select_date': 'Sélectionner une date',
      'activities.select_company': 'Sélectionner une entreprise',
      'activities.no_target_in_date': 'Vous n\'avez pas d\'objectif à la date sélectionnée',
      'topbar.hello': 'Bonjour',
      'topbar.sign': 'Pointer',
      'topbar.account': 'Mon compte',
      'topbar.logout': 'Sortir',
      'agentcard.target': 'Objectifs/Efficacité',
      'agentcard.activity_today': 'Activité du jour',
      'agentcard.target_not_found': 'N\'avez pas d\'objectif',
      'charts.eficiency': 'Efficacité',
      'charts.acitivity': 'Activité',
      'charts.target': 'Objectif',
      'charts.complete': 'Atteint',
      'charts.calendar_eficiency': 'Calendrier Efficacité/jour',
      'charts.eficiency_progresion': 'Progression de l\'efficacité',
      'charts.percent': 'Pourcentage',
      'charts.select_all': 'Tout sélectionner',
      'summary.select_users': 'Sélectionner les utilisateurs',
      'summary.select_user': 'Sélectionner le utilisateur',
      'summary.filter': 'Filtrer',
      'summary.filter_table': 'Filtrer le tableau',
      'summary.user': 'Utilisateur',
      'summary.total_activity': 'Total activité',
      'summary.total_target': 'Total objectif',
      'summary.tittle': 'Résumé de la Activité commerciale ',
      'configuration.name': 'Prénom',
      'configuration.cif': 'SIREN',
      'configuration.email': 'E-mail',
      'configuration.email_err': 'Entrer l\'email correctement',
      'configuration.contact_user': 'Personne à contacter',
      'configuration.contact_phone': 'Numéro de téléphone',
      'configuration.address': 'Adresse',
      'configuration.city': 'Ville',
      'configuration.state': 'Pays',
      'configuration.avatar': 'Avatar',
      'configuration.select': 'Sélectionne',
      'configuration.save': 'Enregister',
      'configuration.auth_failed': 'Tu ne devrais pas être ici',
      'configuration.return_back': 'Retourne à ta place',
      'configuration.tittle': 'Configuration',
      'configuration.ranking': 'Limiter le classement au bureau',
      'configuration.ranking_info': 'Permettre aux agents de ne voir que les agents de votre entreprise',
      'configuration.language': 'Choisir la langue',
      'toast.configuration.error': 'Un problème est survenu',
      'toast.configuration.succ': 'Configuration de l’entreprise sauvegardée avec succès',
      'toast.edit_role.succ': 'C\'est fait !!!',
      'toast.edit_role.succ_info': 'Configuration sauvegardée avec succès',
      'toast.edit_role.succ_delete': 'C\'est fait ! Supprimé avec succès',
      'toast.edit_role.err': 'Erreur!!',
      'toast.edit_role.err_delete': 'Un problème est survenu lors de la suppression',
      'toast.edit_role.err_color': 'Vous devez sélectionner la couleur du Rôle',
      'toast.edit_role.err_name': 'Vous devez saisir le nom du rôle',
      'toast.edit_role.err_user': 'Vous devez ajouter au moins une entreprise et un utilisateur',
      'toast.edit_role.err_user_add': 'Vous devez sélectionner au moins une entreprise et un utilisateur',
      'toast.edit_role.err_rol_add': 'Vous devez sélectionner au moins une entreprise et un rôle',
      'toast.edit_role.err_name_add': 'Vous devez saisir le nom',
      'toast.edit_role.err_surname_add': 'Vous devez saisir le nom de famille',
      'toast.edit_role.err_email_add': 'Vous devez saisir le email',
      'toast.edit_role.err_date': 'Vous devez sélectionner une date',
      'toast.edit_role.err_select_user': 'Vous devez sélectionner un utilisateur',
      'toast.upload_avatar': 'Un problème est survenu lors du téléchargement de l\'avatar',
      'toast.alert': 'Alerte',
      'role.edit.tittle': 'Modifier le rôle',
      'role.create.tittle': 'Créer un nouveau rôle',
      'role.color': 'Couleur',
      'role.company': 'Entreprise',
      'role.delete': 'Effacer',
      'role.add': 'Ajouter',
      'role.edit': 'Éditer',
      'role.reset': 'Annuler',
      'role.close': 'Fermer',
      'role.create': 'Créer un rôle',
      'role.rol': 'Rôle',
      'role.options': 'Options',
      'role.list.tittle': 'Liste des rôles',
      'user.edit': 'Modifier l\'utilisateur',
      'user.add': 'Créer un nouvel utilisateur',
      'user.surname': 'Nom de famille',
      'user.phone': 'Téléphone',
      'user.rol': 'Rôle l\'utilisateur',
      'user.status': 'Status',
      'user.status_on': 'On',
      'user.status_off': 'Off',
      'user.select_rol': 'Sélectionner le rôle',
      'user.button.create': 'Créer un utilisateur',
      'user.list.tittle': 'Liste des utilisateurs',
      'document.add': 'Ajouter document',
      'document.list': 'Liste des Documents',
      'swag.sure': 'Vous êtes sûr ?',
      'swag.cant_revert': 'Cette action ne peut pas être modifiée',
      'swag.out': 'est la sortie',
      'swag.new_day': 'c\'est un nouveau jour',
      'swag.yes': 'Oui',
      'swag.no': 'Ne pas',
      'pageview.visit': 'Visites',
      'pageview.date': 'Date',
      'pageview.times': 'Heures',
      'pageview.time': 'Heure',
      'pageview.route': 'Historique',
      'Dashboard Administrador': 'Dashboard Admin',
      'Dashboard': 'Dashboard',
      'Fichar': 'Pointer',
      'Gestion objetivos': 'Objectifs de gestion',
      'Gestion estadisticas agente': 'Utilisateur Statistiques',
      'Listado control laboral': 'Liste de contrôle des horaires',
      'panel': 'Panneau d\'enregistrement du travail',
      'Control de visitas': 'Contrôle des visites',
      'Parámetros empresa': 'Configuration de l\'entreprise',
      'Perfil empresa': 'Profil de l\'entreprise',
      'Perfil usuario': 'Profil de l\'utilisateur',
      'Gestion roles': 'Gérer les rôles',
      'Gestion usuarios': 'Gérer de l\'utilisateurs',
      'pageview.tittle': 'Liste de contrôle de visite',
      'perfomance.tittle': 'Classement des collaborateurs',
      'document.name': 'Nom du fichier',
      'document.type': 'Sélectionner le type',
      'document.open': 'Ouvrir le formulaire',
      'document.type_name': 'Type',
      'document.create_date': 'Date de Création',
      'document.refillable.tittle': 'Liste des formulaires',
      'schedule.control.tittle': 'Système de contrôle de la journée ',
      'schedule.control.comp.tittle': 'Contrôle des horaires',
      'schedule.control.total_time': 'Total des heures travaillées',
      'schedule.control.no_time': 'Vous n\'avez pas encore enregistré d\'entrée',
      'schedule.control.last_in': 'depuis la dernière connexion',
      'schedule.control.total_now': 'total temps actuel',
      'schedule.control.in': 'Entrée',
      'schedule.control.out': 'Sortir',
      'schedule.control.select.date': 'Sélectionner la date',
      'schedule.control.resume': 'Résumé de la journée',
      'schedule.control.no_comments': 'Pas de commentaires',
      'schedule.control.no_data': 'Vous n\'avez pas encore enregistré d\'entrée',
      'schedule.control.succ': 'Vous vous êtes bien inscrit',
      'schedule.control.err': 'Un problème est survenu lors de la connexion',
      'schedule.control.swag.last_day': 'Hier tu n\'as pas vérifié',
      'schedule.control.swag.last_day_info': 'Vous signez le début d\'hier?',
      'schedule.list.create': 'Ajouter une nouvelle signature',
      'schedule.button.create': 'Créer',
      'schedule.add.default.comment': 'Créé par l\'administration',
      'schedule.list.new': 'Nouveau',
      'schedule.list.by_user': 'Par personne',
      'schedule.list.all': 'Tout',
      'schedule.list.is_working': 'Travaille actuellement',
      'schedule.list.is_not_working': 'Ne fonctionne pas actuellement',
      'schedule.list.not_leave': 'Cet utilisateur ne s\'est pas déconnecté',
      'schedule.list.export': 'Exporter le détail des entrées/sorties',
      'schedule.list.datetime': 'Date et heure',
      'schedule.list.sign': 'Signature',
      'schedule.list.actions': 'Actions',
      'schedule.list.in': 'Entrée',
      'schedule.list.out': 'Sortie',
      'schedule.list.tittle': 'Liste de contrôle des horaires',
      'schedule.list.paginator.total': 'Enregistrements par page',
      'schedule.list.paginator.next': 'Suivant',
      'schedule.list.paginator.before': 'Précédent',
      'schedule.list.alert.date': 'La date ne peut pas être supérieure à aujourd\'hui, l\'heure par défaut sera définie',
      'schedule.list.update.succ': 'Vous avez mis à jour avec succès',
      'schedule.list.update.err': 'Un problème est survenu lors de la mise à jour',
      'schedule.panel.tittle': 'Tableau de bord pointage entreprise',
      'schedule.user.not_active': '( désactivée )',
      'target.edit.button': 'Modifier l\'objetif',
      'target.edit.tittle': 'Mettre à jour l\'objectif global',
      'target.edit.roles': 'Rôles applicables',
      'target.edit.list': 'Liste d\'objectifs',
      'target.edit.target': 'Objetifs',
      'target.edit.value': 'Valeur',
      'target.edit.start': 'Début',
      'target.edit.end': 'Final',
      'target.edit.weigth': 'Pesée',
      'target.edit.select.multiple': 'Sélectionnez une entreprise et un ou plusieurs rôles',
      'target.edit.weigth.err': 'Le poids doit être un nombre positif',
      'target.edit.goal.err': 'La valeur doit être un nombre positif',
      'target.edit.succ': 'Objetif mise à jour avec succès',
      'target.edit.err': 'Un problème est survenu lors de la mise à jour de cet objectif',
      'target.new.tittle': 'Nouvel objectif',
      'target.new.target.tittle': 'Nouvel objectif mondial',
      'target.new.target.name': 'Nom de l\objetif',
      'target.new.date.range': 'Période',
      'target.new.add.succ': 'Objectif ajouté',
      'target.new.add.succ_info': 'L\'objectif a été ajouté avec succès',
      'target.new.add.err.company': 'Il n\'y a pas d\'entreprise sélectionnée',
      'target.new.add.err.date': 'Vérifiez que vous avez correctement sélectionné la date',
      'target.new.add.err.blank': 'Il vérifie les blancs avant le nom',
      'target.new.add.err.full': 'Vous devez remplir toutes les données correctement',
      'calendar.tittle': 'Modifier les objectifs du jour',
      'calendar.amount': 'Montant',
      'calendar.no_target': 'Il n\'y a pas d\'objectifs pour ce rôle',
      'calendar.today': 'Aujourd\'hui',
      'calendar.month': 'Mois',
      'calendar.week': 'Semaine',
      'calendar.day': 'Jour',
      'calendar.list': 'Liste',
      'calendar.warning': 'Vérifier les données',
      'calendar.warning_info': 'Certains objectifs n\'ont pas pu être mis à jour, vérifiez que l\'objectif est une donnée positive',
      'task.new': 'Créer nouvelle tâche',
      'task.tittle': 'Titre',
      'task.tittle.err': 'Le titre est obligatoire',
      'task.description': 'Description',
      'task.description.err': 'La description est obligatoire',
      'task.date.limit': 'Date limite',
      'task.date.limit.err': 'Vous devez entrer la date d\'échéance de la tâche',
      'task.assig': 'Attribué à',
      'task.succ_info': 'La tâche a été correctement créée, l\'utilisateur désigné sera informé par email',
      'task.err_info': 'Une erreur s\'est produite lors de la création de la tâche',
      'task.comment': 'Commentaires',
      'task.comment.err': 'Pas de commentaires',
      'task.comment.add': 'Ajoutez un nouveau commentaire ici',
      'task.comment.add.err': 'Le commentaire ne peut pas être vide',
      'task.comment.add.succ_info': 'Le commentaire a été ajouté avec succès',
      'task.comment.add.err_info': 'Un problème est survenu lors de l\'ajout du commentaire',
      'task.comment.delete.succ_info': 'Le commentaire a été supprimé avec succès',
      'task.comment.delete.err_info': 'Un problème est survenu lors de la suppression du commentaire',
      'task.detail.tittle_err': 'Vous devez entrer un titre pour la tâche',
      'task.detail.date.limit': 'Date limite de livraison',
      'task.detail.date.start': 'Date d’initialisation de la tâche',
      'task.detail.date.start_err': 'Vous n\'avez pas commencé cette tâche',
      'task.detail.date.end': 'Date de finalisation de la tâche',
      'task.detail.date.end_err': 'Vous n\'avez pas terminé cette tâche',
      'task.assig_err': 'Aucun utilisateur affecté',
      'task.created': 'Créé par',
      'task.modal.tittle': 'Détails de la tâche',
      'task.updated.succ_info': 'La tâche a été mise à jour avec succès',
      'task.updated.err_info': 'Une erreur s\'est produite lors de la mise à jour de la tâche',
      'task.archived': 'Afficher les tâches archivées',
      'task.archived.show': 'Afficher',
      'task.archived.hide': 'Masquer',
      'task.archived.succ_info': 'La tâche a été archivée avec succès',
      'task.archived.err_info': 'Une erreur s\'est produite lors de l\'archivage de la tâche',
      'task.asignedto': 'Assigné à',
      'user.profile.tittle': 'Bienvenue',
      'user.profile.tittle2': 'Mon compte',
      'geo.disabled': 'L\'emplacement est désactivé',
      'schedule.export.tittle': 'Rapport d\'enregistrement du travail',
      'schedule.export.list': 'Liste de transfert',
      'schedule.export.users': 'Utilisateurs',
      'schedule.export.time': 'Heures totales',
      'schedule.export.timedecimal': 'Heures totales decimal',
      'schedule.export.date.start': 'Date de début du rapport',
      'schedule.export.date.end': 'Date de fin du rapport',
      'schedule.export.totalworked': 'Total d\'heures travaillées',
      'schedule.export.document': 'InformeRegistroFichajeLaboral',
      'schedule.export.list.user': 'Liste des transferts par utilisateur',
      'schedule.export.sign': 'Signature électronique',
      'schedule.export.diary': 'REGISTRE DU JOURNAL QUOTIDIEN',
      'schedule.export.worker': 'Employé',
      'Pendiente':'Attente',
      'En progreso':'En progrès',
      'Finalizada':'Fini',
      'task.rest.today':'Le mandat se termine aujourd\'hui',
      'task.rest.day':'Le délai se termine dans 1 jour',
      'task.rest.general':'Le délai se termine dans',
      'task.rest.end':'Le délai est passé',
      'task.rest.days':'Jours',
      'schedule.err.delete.all':'Impossible de supprimer tous les enregistrements',
      'schedule.err.time':'Doit remplir l\'heure',
      'schedule.err.order':'Les dates doivent être dans l\'ordre croissant',
      'schedule.err.twoentries':'Vous ne pouvez pas avoir deux entrées ou sorties ensemble',
      'schedule.err.sortir':'Impossible de démarrer avec la sortie',
      'sign.modal.tittle': 'Signature numérique',
      'sign.modal.add_comment': 'Ajouter un commentaire',
      'sign.modal.add_comment.info': 'Ecrire un commentaire',
      'sign.modal.comment': 'Commentaire',
      'sign.modal.clean': 'Effacer',
      'upload.modal.name.err': 'Doit inclure le nom du fichier',
      'upload.modal.file.err': 'Doit inclure du fichier',
      'upload.modal.file.err_info': 'Un problème est survenu lors du téléchargement du document',
      'upload.modal.tittle': 'Télécharger des fichiers',
      'upload.modal.select.file': 'Choisissez un fichier',
      'target.container.tittle': 'Objectifs pour le rôle',
      'error.user_has_been_disabled.body': 'L’utilisateur avec lequel vous tentez vous connecter est désactivé',
      'error.user_has_been_disabled.title': 'Utilisateur désactivé',
    };
}
