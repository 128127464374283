import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToasterModule } from 'angular2-toaster';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { esLocale, frLocale } from 'ngx-bootstrap/locale';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQlModule } from './graphql/graphql.module';
import { LayoutsModule } from './layouts/layouts.module';
import { ComercialActivityModule } from './pages/comercial-activity/comercial-activity.module';
import { DocumentsModule } from './pages/documents/documents.module';
import { RefillableDocumentsModule } from './pages/refillable-document/refillable-document.module';
import { ScheduleModule } from './pages/schedule/schedule.module';
import { TaskModule } from './pages/task/task.module';
import { AlertComponent } from './shared-component/alert/alert.component';
import { GeolocationComponent } from './shared-component/geolocation/geolocation.component';
import { TranslatorService } from './shared/services/translator.service';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeEs);
registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        AlertComponent,
        GeolocationComponent,
    ],
    imports: [
        ComercialActivityModule,
        ScheduleModule,
        DocumentsModule,
        TaskModule,
        RefillableDocumentsModule,
        NgbModalModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LayoutsModule,
        AppRoutingModule,
        GraphQlModule,
        ToasterModule.forRoot(),
        NgxMaterialTimepickerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        SweetAlert2Module.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'es'
        }),
        SharedModule,
        BsDatepickerModule.forRoot(),
        NgbModule
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: LOCALE_ID, deps: [TranslatorService], useFactory: translatorService => translatorService.getLanguage() },
    ]
})
export class AppModule {
    constructor() {
        defineLocale('fr', frLocale);
        defineLocale('es', esLocale);
    }
}
