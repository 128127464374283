import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Company } from 'src/app/core/models/company/company.model';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EventService } from 'src/app/core/services/event.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { environment } from 'src/environments/environment';
import { RefillableDocumentDto } from '../../../core/models/refillable-document/dto/refillable-document.dto';
import { RefillableDocumentService } from '../../../core/services/refillable-document.service';
import { RefillableDocumentModalComponent } from '../add-modal/refillable-document-modal/refillable-document-modal.component';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
    selector: 'app-refillable-document-list',
    templateUrl: './refillable-document-list.component.html',
    styleUrls: ['./refillable-document-list.component.scss']
})
export class RefillableDocumentListComponent implements OnInit {

    refillableDocuments: RefillableDocumentDto[];
    companies: Company[];
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    loading = [];

    constructor(
        private refillableDocumentsService: RefillableDocumentService,
        private storageService: StorageService,
        private eventService: EventService,
        private modalService: NgbModal,
        private alertService: AlertService,
        readonly authService: AuthenticationService,
        private translateService: TranslatorService,
    ) { }

    ngOnInit() {
        this.eventService.broadcast('changePageHeading', this.translateService.trans('document.refillable.tittle'));
        this.refillableDocuments = [];
        this.companies = this.storageService.getCompanies();
        this.getDocumentList(this.companies);
        this.eventService.subscribe(new SubscribeTypes().REFILLABLE_DOCUMENT_CREATED, data => {
            this.getDocumentList(this.companies);
        });
    }
    private getDocumentList(companies: Company[]) {
        this.refillableDocuments = [];
        companies.forEach(company => {
            this.refillableDocumentsService.refillableDocumentList$(company.id).subscribe(data => {
                this.refillableDocuments = this.refillableDocuments.concat(data.refillableDocument).sort();
            });
        });
    }

    showDocumentModal() {
        const documentModal = this.modalService.open(RefillableDocumentModalComponent, { backdrop: 'static' });
    }

    deleteDocument(documentId: string) {
        this.alertService.showConfirm(this.translateService.trans('swag.sure'), this.translateService.trans('swag.revert')).then(result => {
            if (!result.value) {
                return;
            }
            this.loading[documentId] = true;
            this.refillableDocumentsService.deleteRefillableDocument$(documentId).subscribe(data => {
                if (data) {
                    this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ'));
                    this.loading[documentId] = false;
                    this.getDocumentList(this.companies);
                } else {
                    this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_delete'));
                    this.loading[documentId] = false;
                }
            });
        });
    }

    downloadDocument(documentId: string) {
        this.refillableDocumentsService.getRefillableDocumentDownloadUrl$(documentId).subscribe(documentUrl => {
            window.open(documentUrl);
        });
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }

    translate(documentType: string) {
        if (documentType === "sales_mandate") {
            return "Mandato de venta"
        }
        if (documentType === "aesthetic_consent") {
            return "Consentimiento estético"
        }
    }

}
