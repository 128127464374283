import { Door } from '../door/door.model';
import { User } from '../user/User.model';
export class ScheduleControlListRow {
    id: string;
    user: User;
    dateDay: Date;
    totalTime: Date;
    signature: string;
    doors: Door[];
}
