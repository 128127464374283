import gql from 'graphql-tag';

export const getCountPageView = gql`
    query getCountPageViewByUserByDateRange($usersId: [String]!, $dateRange: [DateTime]!) {
        getCountPageViewByUserByDateRange(usersId: $usersId, dateRange: $dateRange){
            userId,
            name,
            lastName,
            avatar,
            visits
        }
    }`;

export const getPageViewByDateRangeByUser = gql`
    query getPageViewByDateRangeByUser($userId: String!, $dateRange: [DateTime]!) {
        getPageViewByDateRangeByUser(userId: $userId, dateRange: $dateRange){
            date,
            route,
        }
    }`;