import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/auth.service';
import { AlertService } from './alert.service';
import { TranslatorService } from './translator.service';

@Injectable({ providedIn: 'root' })
export class GraphqlErrorHandler {

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly toastService: AlertService,
    private readonly translator: TranslatorService
  ) { }

  handle(graphQLErrors: readonly any[]): void {

    graphQLErrors.map(({ message, locations, path }) => {

      if (typeof message === 'string') {
        if (message === 'error.user_has_been_disabled') {
          this.handlerRequest(message, 'error');
        }
      }
    });

  }

  private handlerRequest(message, type) {

    this.toastService.show(
      type,
      this.translator.trans(`${message}.title`),
      this.translator.trans(`${message}.body`),
    );

    this.authService.logout();

    this.router.navigate(['/']);
  }
}
