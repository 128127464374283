import { CompanyEmployeeRoleTargetTypes } from '../companyemployeeroletargettypes/companyemployeeroletargettypes.model';
import { CompanyUserEmployeeRole } from '../companyuseremployeerole/companyUserEmployeeRole.model';

export class EmployeeRole {

    id: string;
    name: string;
    color: string;
    companyEmployeeRoleTargetTypes: CompanyEmployeeRoleTargetTypes[];
    companyUserEmployeeRole: CompanyUserEmployeeRole[];

}
