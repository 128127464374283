import { Activity } from '../activity/activity.model';
import { CompanyUserEmployeeRole } from '../companyuseremployeerole/companyUserEmployeeRole.model';

export class User {
    id: string;
    email: string;
    password: string;
    requestToken: string;
    roles: string;
    name: string;
    lastName: string;
    avatar: string;
    color: string;
    contactPhone: string;
    status: boolean;
    activitys: [Activity];
    companyUserEmployeeRoles: [CompanyUserEmployeeRole];
}
