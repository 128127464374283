import { Door } from '../door/door.model';
import { User } from '../user/User.model';
export class ScheduleControl {

    id: string;
    user: User;
    date: Date;
    door: Door[];

    constructor(
        user?: User,
        date?: Date,
        door?: Door[]) {

        this.user = user;
        this.date = date;
        this.door = door;
    }
}
