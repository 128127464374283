import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { GetTasksInputDto } from '../models/task/get-tasks-input.dto';
import { getTasks, getTask, getTasksArchived } from '../../graphql/operations/task/queries';
import { GetTasksResponseDto } from '../models/task/get-tasks-response.dto';
import { UpdateTaskInputDto } from '../models/task/update-task-input.dto';
import { updateTask, createTask } from '../../graphql/operations/task/mutations';
import { UpdateTaskResponseDto } from '../models/task/update-task-response.dto';
import { CreateTaskInputDto } from '../models/task/create-task-input.dto';
import { CreateTaskResponseDto } from '../models/task/create-task-response.dto';
import { GetTaskInputDto } from '../models/task/get-task-input.dto';
import { GetTaskResponseDto } from '../models/task/get-task-response.dto';

@Injectable({ providedIn: 'root' })
export class TaskService {
    constructor(
        private readonly apollo: Apollo
    ) {
    }

    getTasks$(getTasksInput: GetTasksInputDto) {
        return this.apollo
            .query({
                query: getTasks,
                variables: {
                    getTasksInput
                }
            }).pipe(map(result => {
                return new GetTasksResponseDto(result.data['getTasks'].tasks);
            }));
    }

    getTasksArchived$(getTasksInput: GetTasksInputDto) {
        return this.apollo
            .query({
                query: getTasksArchived,
                variables: {
                    getTasksInput
                }
            }).pipe(map(result => {
                return new GetTasksResponseDto(result.data['getTasksArchived'].tasks);
            }));
    }

    getTask$(getTaskInput: GetTaskInputDto) {
        return this.apollo
            .query({
                query: getTask,
                variables: {
                    getTaskInput
                }
            }).pipe(map(result => {
                return new GetTaskResponseDto(result.data['getTask'].task);
            }));
    }

    updateTask$(updateTaskInput: UpdateTaskInputDto) {
        return this.apollo
            .mutate({
                mutation: updateTask,
                variables: {
                    updateTaskInput
                }
            }).pipe(map(result => {
                return new UpdateTaskResponseDto(result.data['updateTask'].result);
            }));
    }

    createTask$(createTaskInput: CreateTaskInputDto) {
        return this.apollo
            .mutate({
                mutation: createTask,
                variables: {
                    createTaskInput
                }
            }).pipe(map(result => {
                return new CreateTaskResponseDto(result.data['createTask'].result);
            }));
    }

}

