import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, OptionsInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { getDaysInMonth } from 'date-fns';
import * as moment from 'moment';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyUserEmployeeRole } from 'src/app/core/models/companyuseremployeerole/companyUserEmployeeRole.model';
import { EmployeeRoleByCompanyResponse } from 'src/app/core/models/employeeRole/companyemployeeroletargettypes.response';
import { DateRange } from 'src/app/core/models/shared/dateRange';
import { Target } from 'src/app/core/models/target/target.model';
import { TargetsByEmployeeRoleByDateByCompanyResponse } from 'src/app/core/models/target/TargetsByEmployeeRoleByDateByCompany.response';
import { TargetEspecificationsInput } from 'src/app/core/models/targetEspecifications/targetEspecifications.input';
import { TargetEspecifications } from 'src/app/core/models/targetEspecifications/targetEspecifications.model';
import { EmployeeRoleService } from 'src/app/core/services/employeerole.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TargetService } from 'src/app/core/services/target.service';
import { TargetEspecificationsService } from 'src/app/core/services/targetEspecifications.service';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {
    @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent;
    eventsModel: any;
    calendarPlugins = [dayGridPlugin]; // important!
    events = [];
    options: OptionsInput;
    targets: Target[] = [];
    employeeRoles: CompanyUserEmployeeRole[] = [];
    employeerole: CompanyUserEmployeeRole;
    companySelected: Company;
    currentDate;
    companies: Company[];
    closeResult: string;
    language = 'es';
    calendarApi: Calendar;

    constructor(
        private employeeRoleService: EmployeeRoleService,
        private targetService: TargetService,
        private modalService: NgbModal,
        private storageService: StorageService,
        private targetEspecificationsService: TargetEspecificationsService,
        private toasterService: ToasterService,
        private storage: StorageService,
        private translateService: TranslatorService,
    ) { }

    ngOnInit() {
        if (this.storage.getCompanies()) {
            this.language = this.storage.getCompanies()[0].companyConfiguration.language;
            this.translateService.setLanguage(this.language);
        }
        this.companies = this.storageService.getCompanies();
        this.companies.forEach(element => {
            this.getEmployeeRole(element.id);
        });
        this.currentDate = new Date();
        // Calendario
        this.setOptionsCalendar();
    }


    ngAfterViewInit() {
        this.getApiCalendar();
    }

    setOptionsCalendar() {
        this.options = {
            fixedWeekCount: false,
            editable: true,
            locale: this.language,
            firstDay: 1,
            eventTextColor: 'white',
            events: this.events,
            progressiveEventRendering: true,
            weekends: false,
            // eventLimitText: 'objetivos',
            // eventLimit: 4,
            businessHours: {
                daysOfWeek: [1, 2, 3, 4, 5],
            },
            views: {
                timeGrid: {
                    eventLimit: 6, // adjust to 6 only for timeGridWeek/timeGridDay
                }
            },
            // header: {
            //     left: 'myPrev, todayCustom myNext',
            //     center: 'title',
            //     right: 'dayGridMonth, dayGridWeek dayGridDay'
            // },
            buttonText: {
                today: this.translateService.trans('calendar.today'),
                month: this.translateService.trans('calendar.month'),
                week: this.translateService.trans('calendar.week'),
                day: this.translateService.trans('calendar.day'),
                list: this.translateService.trans('calendar.list'),
                next: 'siguiente',
                prev: 'anterior'
            },
            navLinks: true,
            customButtons: {
                myPrev: {
                    text: this.translateService.trans('schedule.list.paginator.before'), // button change to prev month
                    click: this.changeMonthPrev.bind(this)
                },
                myNext: {
                    text: this.translateService.trans('schedule.list.paginator.next'), // button change to next month
                    click: this.changeMonthNext.bind(this)
                },
                todayCustom: {
                    text: this.translateService.trans('calendar.today'), // button change to next month
                    click: this.changeToThisDay.bind(this)
                }
            },
            plugins: [dayGridPlugin]
        };
    }

    getApiCalendar() {
        this.calendarApi = this.calendarComponent.getApi();
    }
    // sacar a servicio
    addEvent(date) {
        const daysOfMonth = getDaysInMonth(date);
        const currentMonth = date.getMonth() + 1;
        const currentYear = date.getFullYear();

        this.events = [];
        for (let index = 1; index <= daysOfMonth; index++) {
            this.employeeRoles.forEach(rol => {
                let startdate;
                if (currentMonth >= 10) {
                    if (index < 10) {
                        startdate = currentYear + '-' + currentMonth + '-0' + index;
                    } else {
                        startdate = currentYear + '-' + currentMonth + '-' + index;
                    }
                } else {
                    if (index < 10) {
                        startdate = currentYear + '-0' + currentMonth + '-0' + index;
                    } else {
                        startdate = currentYear + '-0' + currentMonth + '-' + index;
                    }
                }
                const event = {
                    title: rol.idEmployeeRole.name,
                    date: startdate,
                    textColor: 'white',
                    backgroundColor: '#e30613',
                    borderColor: '#e30613',
                    id: rol.idEmployeeRole.id
                };
                this.events.push(event);
            });
        }
    }

    eventClicked(info) {
        let idRol: string;
        let converseDate: string;
        converseDate = info.event.start.getFullYear() + '-' + (info.event.start.getMonth() + 1) + '-' + info.event.start.getDate();
        idRol = info.event.id;
        this.getTargets(idRol, converseDate);
    }

    // Al hacer click en anterior o siguiente recarga los employeeRoles
    changeToThisDay() {
        const api = this.calendarComponent.getApi();
        api.today();
        const currentDate = this.calendarApi.getDate();
        this.addEvent(currentDate);
    }

    changeMonthPrev() {
        this.calendarApi.removeAllEvents();
        const api = this.calendarComponent.getApi();
        api.prev();
        const currentDate = this.calendarApi.getDate();
        this.addEvent(currentDate);
    }

    changeMonthNext() {
        this.calendarApi.removeAllEvents();
        const api = this.calendarComponent.getApi();
        api.next();
        const currentDate = this.calendarApi.getDate();
        this.addEvent(currentDate);
    }

    getEmployeeRole(companySelected) {
        this.employeeRoleService.employeeRoleByCompany$(companySelected).subscribe(
            (employeeRoleByCompanyResponse: EmployeeRoleByCompanyResponse) => {
                employeeRoleByCompanyResponse.employeeRole.forEach(rol => {
                    const filteredRoles = this.employeeRoles.filter(roles => {
                        return roles.idEmployeeRole.id === rol.idEmployeeRole.id;
                    });
                    if (filteredRoles.length === 0) {
                        this.employeeRoles.push(rol);
                    }
                });
                this.addEvent(this.currentDate);
            });
    }

    getTargets(RolId, date) {
        this.targets = [];
        this.companies.forEach(company => {
            this.targetService.targetByEmployeeRoleByDateByCompany$(company.id, date, RolId).subscribe(
                (targetsByCompany: TargetsByEmployeeRoleByDateByCompanyResponse) => {
                    targetsByCompany.target.forEach((target: Target) => {
                        target.targetEspecifications = target.targetEspecifications.map((targetEspecifications: TargetEspecifications) => {
                            targetEspecifications.range = new DateRange(
                                moment(new Date(targetEspecifications.startDate)),
                                moment(new Date(targetEspecifications.finishDate)));
                            return targetEspecifications;
                        });
                        const targetInsert: Target = this.selectTargetByRangeShortDate(target);
                        this.targets.push(targetInsert);
                    });
                });
        });
    }

    sendCompanySelected(company) {
        this.companySelected = company;
        this.getEmployeeRole(company.id);
    }

    // Modal

    open(content, info) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${this.updateTarget()}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${reason}`;
        });
        this.eventClicked(info);
    }

    // function to select the target with range date short
    // pasar a servicio
    selectTargetByRangeShortDate(targetArray: Target) {
        const diferenceOfDays = targetArray.targetEspecifications[0].range.finishDate.diff(
            targetArray.targetEspecifications[0].range.startDate, 'days');
        let indexSave = 0;
        targetArray.targetEspecifications.forEach((targetEspecification: TargetEspecifications, index) => {
            const startDateCompare = targetEspecification.range.startDate;
            const finishDateCompare = targetEspecification.range.finishDate;
            if (diferenceOfDays >= finishDateCompare.diff(startDateCompare, 'days')) {
                indexSave = index;
            }
        });
        const targetInsert = new Target(
            targetArray.id, targetArray.name, targetArray.targetEspecifications[indexSave],
            targetArray.companyEmployeeRoleTargetTypes
        );
        return targetInsert;
    }

    // goto finish the update
    private updateTarget() {
        let updateSuccess = 0;
        this.targets.forEach((target: Target) => {
            if (target.targetEspecification.newGoal !== undefined && target.targetEspecification.newGoal >= 0) {
                const goalToInsert: number = target.targetEspecification.newGoal;
                const targetEspecificationsInput = new TargetEspecificationsInput(
                    target.targetEspecification.startDate,
                    target.targetEspecification.finishDate,
                    goalToInsert
                );
                this.targetEspecificationsService.updateTargetEspecificationsMutation$(target.targetEspecification.id,
                    targetEspecificationsInput).subscribe();
                updateSuccess = 1;
            } else if (target.targetEspecification.newGoal < 0) {
                updateSuccess = 2;
            }
        });
        if (updateSuccess === 1) {
            this.toasterService.pop('success', this.translateService.trans('target.edit.succ'), this.translateService.trans('toast.edit_role.succ_info'));
        } else if (updateSuccess === 2) {
            this.toasterService.pop('warning', this.translateService.trans('calendar.warning'),
                this.translateService.trans('calendar.warning_info'));
        }
    }
}
