import { Injectable } from '@angular/core';
import { ScheduleControlListRow } from '../../core/models/schedulelist/schedulecontrollist.row';
import { AlertService } from '../services/alert.service';
import { TranslatorService } from '../services/translator.service';
@Injectable()
export class ScheduleControlValidator {

  constructor(
    private alertService: AlertService,
    private translateService: TranslatorService,

  ) { }

  // valide the fields input of form
  validationForm(row: ScheduleControlListRow) {
    let request = true;
    if (row.doors.length === 0) {
      request = false;

      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.err.delete.all'));
    }
    row.doors.forEach((door, index) => {
      if (!door.eventDate) {
        request = false;

        this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.err.time'));
      }
      if (index > 0 && door.eventDate < row.doors[index - 1].eventDate) {
        request = false;

        this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.err.order'));
      }
      if (index > 0 && door.type === row.doors[index - 1].type) {
        request = false;
        this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.err.twoentries'));
      }
      if (!row.doors[0].type) {
        request = false;
        this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.err.sortir'));
      }
    })
    return request;
  }
}
