import { TargetEspecifications } from '../targetEspecifications/targetEspecifications.model';

export class CreateTargetEspecificationsResponse {
    targetsEspecifications: TargetEspecifications;

    constructor(targetEspecifications: TargetEspecifications) {
        this.targetsEspecifications = targetEspecifications;

    }
}
