import gql from 'graphql-tag';

export const createTargetMutation = gql`
    mutation createTarget($createTargetInput: CreateTargetInput, $companiesId: [String]!, $employeeRolesId: [String]!, $weighing: [Float]!){
        createTarget(createTargetInput : $createTargetInput, companiesId: $companiesId, employeeRolesId: $employeeRolesId, weighing: $weighing)
    }`

export const deleteTargetMutation = gql`
mutation deleteTarget($companyId: String!,$targetId: String!,$employeeRoleId: String!){
    deleteTarget(companyId : $companyId,targetId: $targetId,employeeRoleId: $employeeRoleId)
}`
