import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompaniesByUserLoggedResponse } from '../../../core/models/company/CompaniesByUserLogged.response';
import { Company } from '../../../core/models/company/company.model';
import { CompanyService } from '../../../core/services/company.service';
import { SubscribeTypes } from '../../../shared/types/subcribes.types';
import { EventService } from '../../../core/services/event.service';

@Component({
    selector: 'app-company-dropdown',
    templateUrl: './company-dropdown.component.html',
    styleUrls: ['./company-dropdown.component.scss']
})
export class CompanyDropdownComponent implements OnInit {

    companys: any;
    selectedCompanies: Company[];
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    defaultValue;

    @Input('selectAll') selectAll: boolean = true;
    @Input('multiple') multiple: boolean = false;
    @Output('onSelectCompany') companySelectedEvent: EventEmitter<any> = new EventEmitter();
    @Output('onCompaniesLoaded') onCompaniesLoadedEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        private companyService: CompanyService,
        private eventService: EventService,
    ) { }

    ngOnInit() {
        this.companys = [];
        this.filterCompany();
        this.eventService.subscribe(this.subscribeTypes.COMPANIES_UPDATED, (data) => this.filterCompany());

    }

    filterCompany() {
        this.companys = [];
        this.companyService.companiesByUserLogged$().subscribe(
            (companiesByUserLoggedResponse: CompaniesByUserLoggedResponse) => {
                companiesByUserLoggedResponse.companyUserEmployeeRole.forEach(companyUserRoles => {
                    const filteredCompanies = this.companys.filter(companies => {
                        return companies.idCompany.id === companyUserRoles.idCompany.id;
                    });
                    if (filteredCompanies.length === 0) {
                        this.companys.push(companyUserRoles);
                    }
                });
                this.selectedCompanies = this.companys.map(company => company.idCompany);
                // the input selectAll declare if u prefer select all company(true)
                // or the first company(false)
                this.onCompaniesLoadedEvent.emit(this.companys.map(company => company.idCompany));
                if (this.multiple && this.selectAll) {
                    this.defaultValue = this.selectedCompanies;
                } else {
                    this.defaultValue = this.selectedCompanies[0];
                }
            });
    }

    selectCompanies(companies) {
        const arrayCompanies = [];
        if (Array.isArray(companies)) {
            if (companies.length > 0 && companies != null) {
                this.companySelectedEvent.emit(companies);
            }
        } else {
            arrayCompanies.push(companies);
            if (arrayCompanies.length > 0 && arrayCompanies != null) {
                this.companySelectedEvent.emit(arrayCompanies);
            }
        }
    }

}

