import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TargetEspecificationsInput } from '../models/targetEspecifications/targetEspecifications.input';
import { updateTargetEspecificationsMutation, createTargetEspecificationsMutation, deleteTargetEspecificationsMutation } from '../../graphql/operations/targetEspecifications/mutations';
import { UpdateTargetEspecificationsResponse } from '../models/targetEspecifications/updateTargetEspecifications.response';
import { CreateTargetEspecificationsResponse } from '../models/targetEspecifications/createTargetEspecifications.response';
import { TargetEspecifications } from '../models/targetEspecifications/targetEspecifications.model';
import { CreateTargetEspecificationsDTO } from '../models/targetEspecifications/createTargetEspecifications.dto';
import { DeleteTargetEspecificationsInput } from '../models/targetEspecifications/deleteTargetEspecifications.dto';

@Injectable({ providedIn: 'root' })
export class TargetEspecificationsService {
    constructor(
        private readonly apollo: Apollo) { }

    // tslint:disable-next-line: max-line-length
    updateTargetEspecificationsMutation$(targetEspecificationsId: string, targetEspecificationsInput: TargetEspecificationsInput): Observable<UpdateTargetEspecificationsResponse> {
        return this.apollo
            .mutate({
                mutation: updateTargetEspecificationsMutation,
                variables: {
                    targetEspecificationsId,
                    targetEspecificationsInput
                }
            }).pipe(map(result => {
                return new UpdateTargetEspecificationsResponse(result.data['updateTargetEspecifications']);
            }));
    }

    createTargetEspecifications$(createTargetEspecificationsInputDto: CreateTargetEspecificationsDTO) {
        return this.apollo
            .mutate({
                mutation: createTargetEspecificationsMutation,
                variables: {
                    createTargetEspecificationsInputDto
                }
            }).pipe(map(result => {
                return new CreateTargetEspecificationsResponse(result.data['createTargetEspecifications']);
            }));
    }

    deleteTargetEspecification$(deleteTargetEspecificationsInputDto: DeleteTargetEspecificationsInput) {
        return this.apollo
            .mutate({
                mutation: deleteTargetEspecificationsMutation,
                variables: {
                    deleteTargetEspecificationsInputDto
                }
            }).pipe(map(result => {
                return Boolean;
            }));
    }

}
