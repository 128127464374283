import gql from 'graphql-tag';

export const updateActivityMutation = gql`
mutation updateActivity($activityId: String!, $realAmount: Float!){
    updateActivity(activityId : $activityId,realAmount : $realAmount){
        id,
        dateDay,
        realAmount
    }
}`;

export const createActivityMutation = gql`
mutation createActivity($activity: ActivityInput!){
    createActivity(activity : $activity){
        id,
        dateDay,
        realAmount
    }
}`;

export const deleteActivityMutation = gql`
mutation deleteActivity($deleteActivityInput: DeleteActivityInputDto!){
    deleteActivity(deleteActivityInput : $deleteActivityInput)
}`;
