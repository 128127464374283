import gql from 'graphql-tag';

export const getCompanyConfiguration = gql`
    query  getCompanyConfiguration($getCompanyConfigurationInput: GetCompanyConfigurationInput!) {
        getCompanyConfiguration(getCompanyConfigurationInput: $getCompanyConfigurationInput) {
            id,
            onlyRankingCompany,
            language,
            features{
                id,
                name
            }
        }
    }`;

