import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { companyLogo, companiesByUserLogged, companiesByCif, companiesByUser, getCompanylogoUploadUrl, getCompanylogoDownloadUrl } from '../../graphql/operations/company/queries';
import { LogoResponse } from '../models/company/logo.response';
import { map } from 'rxjs/operators';
import { CompaniesByUserLoggedResponse } from '../models/company/CompaniesByUserLogged.response';
import { CompanyByCifResponse } from '../models/company/companyByCif.response';
import { CompanyInput } from '../models/company/updateCompany.input';
import { updateCompanyMutation } from 'src/app/graphql/operations/company/mutations';


@Injectable({ providedIn: 'root' })
export class CompanyService {
    constructor(
        private http: HttpClient,
        private readonly apollo: Apollo) { }


    companyLogo$(companyId: string): Observable<LogoResponse> {
        return this.apollo
            .query({
                query: companyLogo,
                variables: {
                    companyId
                }
            }).pipe(map(result => {
                return new LogoResponse(result.data['getLogoByCompany']['logo']);
            }));
    }

    companiesByUserLogged$(): Observable<CompaniesByUserLoggedResponse> {
        return this.apollo
            .query({
                query: companiesByUserLogged,
            }).pipe(map(result => {
                return new CompaniesByUserLoggedResponse(result.data['getCompaniesByUserLogged']);
            }));
    }

    companiesByCif$(): Observable<CompanyByCifResponse> {
        return this.apollo
            .query({
                query: companiesByCif,
            }).pipe(map(result => {
                return new CompanyByCifResponse(result.data['getCompaniesByCifByUserLogged']);
            }));
    }

    companiesByUser$(userId: String): Observable<CompaniesByUserLoggedResponse> {
        return this.apollo
            .query({
                query: companiesByUser,
                variables: {
                    userId,
                }
            }).pipe(map(result => {
                return new CompaniesByUserLoggedResponse(result.data['getCompaniesByUser']);
            }));
    }

    updateCompanyMutation$(companyInput: CompanyInput) {
        return this.apollo
            .mutate({
                mutation: updateCompanyMutation,
                variables: {
                    companyInput
                }
            }).pipe(map(result => {
                return Boolean;
            }));
    }

    getCompanylogoDownloadUrl$(companyId: string): Observable<string> {
        return this.apollo
            .query({
                query: getCompanylogoDownloadUrl,
                variables: {
                    companyId
                }
            }).pipe(map(result => {
                return result.data['getCompanylogoDownloadUrl'];
            }));
    }

    getCompanylogoUploadUrl$(logo: string, contentType: string): Observable<string> {
        return this.apollo
            .query({
                query: getCompanylogoUploadUrl,
                variables: {
                    logo,
                    contentType
                }
            }).pipe(map(result => {
                return result.data['getCompanylogoUploadUrl'];
            }));
    }
}
