export class CreateTargetEspecificationsFormDTO {

    daterange: Date[];
    goal: number;

    constructor(goal: number) {
        this.goal = goal;
    }


}
