import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { endOfDay, endOfMonth, format, startOfDay, startOfMonth } from 'date-fns';
import { defineLocale, esLocale, isSameDay, listLocales } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { from, Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { concatAll, take, takeUntil } from 'rxjs/operators';
import { GetSignWorkByCompanyInputDto } from 'src/app/core/models/schedulelist/schedulelist.input.dto';
import { ScheduleControl } from 'src/app/core/models/shedulecontrol/schedulecontrol.model';
import { CompanyDropdownComponent } from 'src/app/shared-component/dropdown/company-dropdown/company-dropdown.component';
import { GeolocationComponent } from 'src/app/shared-component/geolocation/geolocation.component';
import { RolType } from 'src/app/shared/types/rol.types';
import { ScheduleControlValidator } from 'src/app/shared/validator/schedule.validator';
import { environment } from 'src/environments/environment';
import { Company } from '../../../core/models/company/company.model';
import { Door } from '../../../core/models/door/door.model';
import { ScheduleControlListExport } from '../../../core/models/schedulelist/schedulecontrollist.export';
import { ScheduleControlListRow } from '../../../core/models/schedulelist/schedulecontrollist.row';
import { User } from '../../../core/models/user/User.model';
import { AuthenticationService } from '../../../core/services/auth.service';
import { EventService } from '../../../core/services/event.service';
import { ScheduleControlService } from '../../../core/services/shedulecontrol.service';
import { UserProfileService } from '../../../core/services/user.service';
import { ScheduleTimeCalculator } from '../../../shared/schedule-time.calculator';
import { AlertService } from '../../../shared/services/alert.service';
import { ScheduleListExportService } from '../../../shared/services/scheduleListExport.service';
import { UserFilterService } from '../../../shared/services/userFilter.service';
import { CreateScheduleComponent } from './modals/create-schedule/create-schedule.component';
import { TranslatorService } from '../../../shared/services/translator.service';
import { StorageService } from '../../../core/services/storage.service';

@Component({
    selector: 'app-schedule-list',
    templateUrl: './schedule-list.component.html',
    styleUrls: ['./schedule-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ display: 'none', minHeight: '0' })),
            state('expanded', style({ display: 'table' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ScheduleListComponent implements OnInit {

    @ViewChild(CompanyDropdownComponent, { static: false }) companyDropdownComponent: CompanyDropdownComponent;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    displayedColumns: string[] = ['user', 'date', 'worked', 'signature'];
    dataSource = new MatTableDataSource();

    getSignWorkByCompanyInputDto: GetSignWorkByCompanyInputDto;
    selectCompany: Company[];
    companies: Company[];
    selectedUsers: any[];
    users: any[];
    filterUsers: any[];
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    dateRange: Date[];
    scheduleList: ScheduleControlListRow[] = [];
    scheduleListExport: ScheduleControlListExport[] = [];
    checked = true;
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    totalData = 0;
    loading = true;
    loadingStart = true;
    rowSkeleton = { 'border-radius': '0', height: '40px' };
    disabled = false;
    areWorking = [];

    darkTheme: NgxMaterialTimepickerTheme = {
        container: {
            bodyBackgroundColor: 'white',
            buttonColor: 'lightgrey'
        },
        dial: {
            dialBackgroundColor: '#d9534f',
        },
        clockFace: {
            clockFaceBackgroundColor: 'lightgrey',
            clockHandColor: '#d9534f',
            clockFaceTimeInactiveColor: 'grey'
        }
    };
    isFirstLoad = true;

    @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

    public userMultiFilterCtrl: FormControl = new FormControl();
    public userMultiCtrl: FormControl = new FormControl();
    protected _onDestroy = new Subject<void>();
    public filteredUsersMulti: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

    constructor(
        private scheduleControlService: ScheduleControlService,
        private userService: UserProfileService,
        private localeService: BsLocaleService,
        private scheduleTimeCalculator: ScheduleTimeCalculator,
        private userFilterService: UserFilterService,
        private scheduleListExportService: ScheduleListExportService,
        private eventService: EventService,
        private alertService: AlertService,
        private scheduleValidator: ScheduleControlValidator,
        private modalService: NgbModal,
        private authService: AuthenticationService,
        private translateService: TranslatorService,
        private storage: StorageService,
    ) {
        this.getSignWorkByCompanyInputDto = new GetSignWorkByCompanyInputDto();
        this.dateRange = [startOfMonth(new Date()), endOfMonth(new Date())];
        if (new Date() < this.dateRange[1]) {
            this.dateRange[1] = new Date();
        }
        const language = this.storage.getCompanies()[0].companyConfiguration.language;

        this.localeService.use(language);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD-MM-YYYY' });
    }

    ngOnInit() {
        this.eventService.broadcast('changePageHeading', this.translateService.trans('schedule.list.tittle'));
        this.dataSource = new MatTableDataSource(this.scheduleList);
        this.dataSource.paginator = this.paginator;
        this.paginator._intl.itemsPerPageLabel = this.translateService.trans('schedule.list.paginator.total');
        this.paginator._intl.nextPageLabel = this.translateService.trans('schedule.list.paginator.next');
        this.paginator._intl.previousPageLabel = this.translateService.trans('schedule.list.paginator.before');
        this.dataSource.sort = this.sort;

        this.userMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterUsersMulti();
            });
    }
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    reloadScheduleList() {
        this.loading = true;
        this.isFirstLoad = false;
        this.areWorking = [];
        this.createSignWorkByCompanyInputDto(this.paginator.pageSize, this.paginator.pageIndex);
        this.scheduleControlService.getListScheduleControl$(this.getSignWorkByCompanyInputDto).subscribe(data => {
            this.scheduleList = [];
            data.scheduleControl.forEach(schedules => {
                schedules.door.sort((a, b) => a.eventDate > b.eventDate ? 1 : -1);
                const scheduleRow = new ScheduleControlListRow();
                scheduleRow.id = schedules.id;
                scheduleRow.user = schedules.user;
                scheduleRow.dateDay = schedules.date;
                scheduleRow.totalTime = new Date(0, 0, 0, 0, 0, 0, this.scheduleTimeCalculator.getTotalHoursFromSchedule(schedules));
                scheduleRow.doors = schedules.door;
                this.isWorking(schedules);
                this.scheduleList.push(scheduleRow);
            });

            this.dataSource = new MatTableDataSource(this.scheduleList);
            this.totalData = data.totalData;
            this.loading = false;
            this.loadingStart = false;
        });
    }

    createSignWorkByCompanyInputDto(itemPerPage, page) {
        this.getSignWorkByCompanyInputDto.companyIds = this.selectCompany.map(company => company.id);
        if(!this.userMultiCtrl.value){
            this.userMultiCtrl.setValue(this.selectedUsers);
        }
        this.getSignWorkByCompanyInputDto.userIds = this.userMultiCtrl.value.map(users => users.id);
        this.getSignWorkByCompanyInputDto.startDate = startOfDay(this.dateRange[0]);
        this.getSignWorkByCompanyInputDto.finishDate = endOfDay(this.dateRange[1]);
        this.getSignWorkByCompanyInputDto.itemPerPage = itemPerPage;
        this.getSignWorkByCompanyInputDto.page = page;
    }

    getUsers() {
        const userObservables = [];
        this.selectCompany.forEach(companyId => {
            userObservables.push(this.userService.allVisibleUsersByCompany$(companyId.id));
        });
        let users = [];
        from(userObservables)
            .pipe(concatAll())
            .subscribe((companyUsers: any) => {
                users = [...users, ...companyUsers];
            }, () => { }, () => {
                this.users = this.userFilterService.filterUniqueUsers(users);

                this.filterUsers = this.users;
                this.selectedUsers = this.users;
                this.users.sort((a,b) => (a.status < b.status) ? 1 : ((b.status < a.status) ? -1 : 0))

                this.userMultiCtrl.setValue(this.selectedUsers);
                this.filteredUsersMulti.next(this.users.slice());
                if (this.isFirstLoad) {
                    this.reloadScheduleList();
                }
            });
    }

    toggleSelectAll(selectAllValue: boolean) {
        this.filteredUsersMulti.pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(val => {
                if (selectAllValue) {
                    this.userMultiCtrl.patchValue(val);
                } else {
                    this.userMultiCtrl.patchValue([]);
                }
            });
    }
    protected setInitialValue() {
        this.filteredUsersMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.multiSelect.compareWith = (a: User, b: User) => a && b && a.id === b.id;
            });
    }
    protected filterUsersMulti() {
        if (!this.users) {
            return;
        }
        let search = this.userMultiFilterCtrl.value;
        if (!search) {
            this.filteredUsersMulti.next(this.users.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
       
        this.filteredUsersMulti.next(
            this.users.filter(user => user.name.toLowerCase().indexOf(search) > -1)
        );

    }


    onDateChange(date: Date) {
        this.getSignWorkByCompanyInputDto.startDate = startOfDay(date[0]);
        this.getSignWorkByCompanyInputDto.finishDate = endOfDay(date[1]);
    }

    changePage(page) {
        this.loading = true;
        this.getSignWorkByCompanyInputDto.itemPerPage = page.pageSize;
        this.getSignWorkByCompanyInputDto.page = page.pageIndex;
        this.reloadScheduleList();
    }

    exportToCsv(type) {
        this.createSignWorkByCompanyInputDto(this.totalData, 0);
        this.scheduleListExportService.exportToCsv(this.getSignWorkByCompanyInputDto, type, this.selectedUsers);
    }

    exportToExcelByScheduleRow(row: ScheduleControlListRow) {
        const getSignWorkByCompanyInputDto = new GetSignWorkByCompanyInputDto();
        getSignWorkByCompanyInputDto.companyIds = this.selectCompany.map(company => company.id);
        getSignWorkByCompanyInputDto.userIds = [row.user.id];
        getSignWorkByCompanyInputDto.startDate = startOfDay(this.dateRange[0]);
        getSignWorkByCompanyInputDto.finishDate = endOfDay(this.dateRange[1]);
        getSignWorkByCompanyInputDto.itemPerPage = 999;
        getSignWorkByCompanyInputDto.page = 0;
        this.scheduleListExportService.exportToCsvByScheduleRow(getSignWorkByCompanyInputDto);
    }

    updateDoor(row: ScheduleControlListRow) {
        if (!this.scheduleValidator.validationForm(row)) {
            return;
        }
        const scheduleControl: ScheduleControl = new ScheduleControl();
        scheduleControl.id = row.id;
        scheduleControl.date = row.dateDay;
        row.doors.forEach(door => {
            delete door.signature;
        });
        scheduleControl.door = row.doors;
        this.scheduleControlService.updateScheduleControl$(scheduleControl).subscribe(data => {
            if (data) {
                this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('schedule.list.update.succ'));
                this.reloadScheduleList();
            } else {
                this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.list.update.err'));
            }
        });
    }

    addDoor(row: ScheduleControlListRow) {
        const door = new Door();
        const list = this.scheduleList.filter(sheduleRow => sheduleRow.id === row.id);
        door.eventDate = new Date(row.dateDay + 'T00:00');
        if (row.doors.length > 0) {
            door.signature = '';
            door.type = !row.doors[row.doors.length - 1].type;
        }
        list[0].doors.push(door);
    }

    deleteDoor(row: ScheduleControlListRow, index: number) {
        this.scheduleList.filter(sheduleRow => sheduleRow.id === row.id)[0].doors.splice(index, 1);
    }
    deleteSignWork(signWorkId: string) {
        this.alertService.showConfirm(this.translateService.trans('swag.sure'), this.translateService.trans('swag.cant_revert')).then(result => {
            if (!result.value) {
                return;
            }
            this.loading = true;
            this.scheduleControlService.deleteSignWork$(signWorkId).subscribe(data => {
                if (data) {
                    this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ'));
                    this.reloadScheduleList();
                    this.loading = false;
                } else {
                    this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_delete'));
                    this.loading = false;
                }
            });
        });
    }

    changeDate(event: string, index: number, row: ScheduleControlListRow) {
        let newDate = this.scheduleList.filter(sheduleRow => sheduleRow.id === row.id)[0].doors[index].eventDate;
        event = event.replace('24:', '00:');
        const eventHour = event.split(':');
        if (parseInt(eventHour[0]) === 0 && parseInt(eventHour[1]) > 0) {
            event = '12:00';
            this.alertService.show('warning', this.translateService.trans('toast.alert'), this.translateService.trans('schedule.list.alert.date'));
        }
        newDate = new Date(format(new Date(newDate), 'yyyy-MM-dd') + 'T' + event + ':00');
        this.scheduleList.filter(sheduleRow => sheduleRow.id === row.id)[0].doors[index].eventDate = newDate;
    }

    updateType(type: boolean, index: number, row: ScheduleControlListRow) {
        this.scheduleList.filter(sheduleRow => sheduleRow.id === row.id)[0].doors[index].type = type;
    }
    undo() {
        this.reloadScheduleList();
    }

    onCompaniesLoaded(companies) {
        this.companies = companies;
        this.onSelectedCompany(companies);
        this.checkUser();
    }

    onSelectedCompany(companies) {
        this.selectCompany = companies;
        this.checkUser();
    }

    private checkUser() {
        if (this.authService.getUserRol() === new RolType().ROLE_USER) {
            this.users = [this.authService.getUser()];
            this.selectedUsers = this.users;
            this.disabled = true;
            if (this.isFirstLoad) {
                this.reloadScheduleList();
            }
        } else {
            this.getUsers();
        }
    }

    selectAll() {
        if (this.checked) {
            this.selectedUsers = this.users;
        } else {
            this.selectedUsers = [];
        }
    }

    showPosition(door: Door, user: User) {
        const positionModal = this.modalService.open(GeolocationComponent, { size: 'lg', backdrop: 'static' });
        positionModal.componentInstance.door = door;
        positionModal.componentInstance.user = user;
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }

    checkToday(dateDay: Date) {
        return isSameDay(new Date(dateDay), new Date());
    }

    checkLastDoor(row: ScheduleControlListRow) {
        return row.doors[row.doors.length - 1].type;
    }

    isWorking(work) {
        const dateDay = new Date();
        if (!isSameDay(dateDay, new Date(work.date))) {
            return;
        }
        const lastDoor = work.door[work.door.length - 1].type;
        if (lastDoor) {
            this.areWorking[work.user.id] = true;
        } else if (!lastDoor && work.door[work.door.length - 1].eventDate > dateDay) {
            this.areWorking[work.user.id] = true;
        } else {
            this.areWorking[work.user.id] = false;
        }
    }
    addSchedule() {
        const addScheduleModal = this.modalService.open(CreateScheduleComponent, { backdrop: 'static', keyboard: false });
        addScheduleModal.componentInstance.scheduleCreated.subscribe((scheduleCreated) => {
            if (scheduleCreated) {
                this.reloadScheduleList();
            }
        });
        addScheduleModal.componentInstance.users = this.users;
    }
}
