import gql from 'graphql-tag';

export const getRefillableDocumentsByCompany = gql`
    query getRefillableDocumentsByCompany($companyId: String!) {
        getRefillableDocumentsByCompany(companyId: $companyId) {
            id,
            name,
            type,
            company{
                id,
                name
            },
            user{
                id,
                name,
                lastName,
                avatar
            },
            createdAt
        }
    }`;

export const getRefillableDocumentDownloadUrl = gql`
query getRefillableDocumentDownloadUrl($documentId: String!) {
    getRefillableDocumentDownloadUrl(documentId: $documentId)
}`;