import { TargetSummaryDto } from "./target-summary.dto";
import { User } from '../user/User.model';
import { EmployeeRole } from '../employeeRole/employeeRole.model';

export class ComercialActivitySummaryDto {
    user: User;
    sumTotalActivity: number;
    sumTotalTarget: number;
    targets: TargetSummaryDto[];
    employeeRole: EmployeeRole;
}