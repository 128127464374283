
export class DoorDto {
    id: string;
    eventDate: Date;
    type: boolean;
    signature: string;
    latitude: string;
    longitude: string;
    comment: string;
}
