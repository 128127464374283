import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createDocument, deleteDocument } from '../../graphql/operations/document/mutations';
import { documentList, getDocumentDownloadUrl, getDocumentUploadUrl } from '../../graphql/operations/document/queries';
import { DocumentListResponse } from '../models/document/document-list.reponse';
import { DocumentInput } from '../models/document/document.input';

@Injectable({ providedIn: 'root' })
export class DocumentService {

    constructor(
        private readonly apollo: Apollo
    ) { }

    documentList$(companyId: string): Observable<DocumentListResponse> {
        return this.apollo
            .query({
                query: documentList,
                variables: {
                    companyId
                }
            }).pipe(map(result => {
                return new DocumentListResponse(result.data['getDocumentsByGroup']);
            }));
    }

    getDocumentDownloadUrl$(documentId: string): Observable<string> {
        return this.apollo
            .query({
                query: getDocumentDownloadUrl,
                variables: {
                    documentId
                }
            }).pipe(map(result => {
                return result.data['getDocumentDownloadUrl'];
            }));
    }

    getDocumentUploadUrl$(documentName: string, contentType: string): Observable<string> {
        return this.apollo
            .query({
                query: getDocumentUploadUrl,
                variables: {
                    documentName,
                    contentType
                }
            }).pipe(map(result => {
                return result.data['getDocumentUploadUrl'];
            }));
    }

    createDocument$(documentInput: DocumentInput) {
        return this.apollo
            .query({
                query: createDocument,
                variables: {
                    documentInput
                }
            }).pipe(map(result => {
                return result.data['createDocument'];
            }));
    }

    deleteDocument$(documentId: string) {
        return this.apollo
            .query({
                query: deleteDocument,
                variables: {
                    documentId
                }
            }).pipe(map(result => {
                return result.data['deleteDocument'];
            }));
    }

}
