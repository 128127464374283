import gql from 'graphql-tag';

export const saveRefillableDocumentMutation = gql`
mutation saveRefillableDocument($saveRefillableDocumentInput: RefillableDocumentInput!){
    saveRefillableDocument(saveRefillableDocumentInput: $saveRefillableDocumentInput)
}`;

export const deleteRefillableDocument = gql`
mutation deleteRefillableDocument($refillableDocumentId: String!){
    deleteRefillableDocument(refillableDocumentId: $refillableDocumentId)
}`;
