import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { createSignWorkMutation, deleteSignWork, updateSignWorkMutation } from 'src/app/graphql/operations/schedulecontrol/mutations';
import { getSheduleByCompany, getSheduleByDay, isWorking, getSheduleByCompanyToExport, getYesterdayShedule, getSheduleByCompanyToExportNoSignature } from '../../graphql/operations/schedulecontrol/queries';
import { GetSignWorkByCompanyInputDto } from '../models/schedulelist/schedulelist.input.dto';
import { ScheduleControlListResponse } from '../models/schedulelist/schedulelist.response';
import { GetScheduleControlResponse } from '../models/shedulecontrol/getScheduleControl.response';
import { ScheduleControl } from '../models/shedulecontrol/schedulecontrol.model';
import { ScheduleControlResponse } from '../models/shedulecontrol/schedulecontrol.response';
import { ScheduleControlListExport } from '../models/schedulelist/schedulecontrollist.export';
import { CreateScheduleControl } from '../models/shedulecontrol/create-schedulecontrol.model';
import { createSignWorkAnotherDayMutation } from '../../graphql/operations/schedulecontrol/mutations';
import { TranslatorService } from 'src/app/shared/services/translator.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Injectable({ providedIn: 'root' })
export class ScheduleControlService {
    constructor(
        private readonly apollo: Apollo,
        private alertService: AlertService,
        private translateService: TranslatorService,
        ) { }

    createScheduleControl$(scheduleControl: ScheduleControl, userId: string): Observable<ScheduleControlResponse> {
        const signWork = scheduleControl;
        return this.apollo
            .mutate({
                mutation: createSignWorkMutation,
                variables: {
                    signWork,
                    userId
                }
            }).pipe(map(result => {
                return new ScheduleControlResponse(result.data['createSignWork']);
            }));
    }
    createScheduleControlAnotherDay$(scheduleControl: CreateScheduleControl, userId: string): Observable<ScheduleControlResponse> {
        const signWork = scheduleControl;
        return this.apollo
            .mutate({
                mutation: createSignWorkAnotherDayMutation,
                variables: {
                    signWork,
                    userId
                }
            }).pipe(map(result => {
                return new ScheduleControlResponse(result.data['createSignWorkAnotherDay']);
            }));
    }
    updateScheduleControl$(scheduleControl: ScheduleControl): Observable<ScheduleControlResponse> {
        const signWork = scheduleControl;
        return this.apollo
            .mutate({
                mutation: updateSignWorkMutation,
                variables: {
                    signWork,
                }
            }).pipe(map(result => {
                return new ScheduleControlResponse(result.data['updateSignWork']);
            }));
    }

    getScheduleControl$(dateDay: Date, userId: string): Observable<GetScheduleControlResponse> {
        return this.apollo
            .query({
                query: getSheduleByDay,
                variables: {
                    dateDay,
                    userId
                }
            }).pipe(map(result => {
                return new GetScheduleControlResponse(
                    result.data['getSignWorkByUserLoggedByDate'].signWork,
                    result.data['getSignWorkByUserLoggedByDate'].date,
                );
            }),catchError(error => {
                 this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.control.err'));
                 return Observable.throw(error);
            }))
    }

    getYesterdayScheduleControl$(userId: string): Observable<GetScheduleControlResponse> {
        return this.apollo
            .query({
                query: getYesterdayShedule,
                variables: {
                    userId
                }
            }).pipe(map(result => {
                return new GetScheduleControlResponse(
                    result.data['getYesterdayScheduleControl'].signWork,
                    result.data['getYesterdayScheduleControl'].date,
                );
            }));
    }

    getListScheduleControl$(getSignWorkByCompanyInput: GetSignWorkByCompanyInputDto): Observable<ScheduleControlListResponse> {
        return this.apollo
            .query({
                query: getSheduleByCompany,
                variables: {
                    getSignWorkByCompanyInput,
                }
            }).pipe(map(result => {
                return new ScheduleControlListResponse(
                    result.data['getSignWorkByCompany'].signWorks,
                    result.data['getSignWorkByCompany'].totalData
                );
            }));
    }

    getListScheduleControlToExport$(getSignWorkByCompanyInput: GetSignWorkByCompanyInputDto): Observable<ScheduleControlListExport[]> {
        return this.apollo
            .query({
                query: getSheduleByCompanyToExport,
                variables: {
                    getSignWorkByCompanyInput,
                }
            }).pipe(map(result => {
                return result.data['getSignWorkByCompanyToExport'];
            }));
    }

    getListScheduleControlToExportNoSignature$(getSignWorkByCompanyInput: GetSignWorkByCompanyInputDto): Observable<ScheduleControlListExport[]> {
        return this.apollo
            .query({
                query: getSheduleByCompanyToExportNoSignature,
                variables: {
                    getSignWorkByCompanyInput,
                }
            }).pipe(map(result => {
                return result.data['getSignWorkByCompanyToExport'];
            }));
    }

    isWorking$(userId: string, dateDay: Date): Observable<Boolean> {
        return this.apollo
            .query({
                query: isWorking,
                variables: {
                    userId,
                    dateDay,
                }
            }).pipe(map(result => {
                return result.data['isWorking'];
            }));
    }

    deleteSignWork$(signWorkId: string): Observable<Boolean> {
        return this.apollo
            .query({
                query: deleteSignWork,
                variables: {
                    signWorkId,

                }
            }).pipe(map(result => {
                return result.data['deleteSignWork'];
            }));
    }

}
