import { Injectable } from '@angular/core';
import { RolType } from '../types/rol.types';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { UserLogged } from 'src/app/core/models/auth.models';
import { User } from 'src/app/core/models/user/User.model';

@Injectable({ providedIn: 'root' })
export class UserFilterService {

    userLogged: UserLogged;
    rolType: RolType = new RolType();

    constructor(
        private authService: AuthenticationService,

    ) { }

    filterAgentsByRole(agents: Array<User>) {
        const userLogged = this.authService.getUser();

        return agents.filter((agent, pos, arr) => {
            if (!(arr.map(subAgent => subAgent.id).indexOf(agent.id) === pos)) {
                return false;
            }
            if (userLogged.roles === this.rolType.ROLE_ADMIN || userLogged.roles === this.rolType.ROLE_SUPERADMIN) {
                return true;
            } else if (userLogged.roles === this.rolType.ROLE_COORDINATOR) {
                if (agent.roles === this.rolType.ROLE_USER || agent.roles === this.rolType.ROLE_COORDINATOR) {
                    return true;
                }
            } else if (agent.roles === this.rolType.ROLE_USER) {
                return true;
            }
            return false;
        });
    }

    filterUniqueUsers(users: Array<User>) {
        const userLogged = this.authService.getUser();

        return users.filter((agent, pos, arr) => {
            if (!(arr.map(subAgent => subAgent.id).indexOf(agent.id) === pos)) {
                return false;
            }
            if (userLogged.roles === this.rolType.ROLE_ADMIN || this.rolType.ROLE_SUPERADMIN) {
                return true;
            } else if (userLogged.roles === this.rolType.ROLE_COORDINATOR) {
                if (agent.roles === this.rolType.ROLE_USER || agent.roles === this.rolType.ROLE_COORDINATOR) {
                    return true;
                }
            } else if (agent.roles === this.rolType.ROLE_USER) {
                return true;
            }
            return false;
        });
    }
    filterNoRepeatUsers(users: Array<User>) {
        return users.filter((agent, pos, arr) => {
            if ((arr.map(subAgent => subAgent.id).indexOf(agent.id) === pos)) {
                return true;
            }
        });
    }
}
