import { ScheduleControl } from './schedulecontrol.model';

export class GetScheduleControlResponse {
    scheduleControl: ScheduleControl;
    date: Date;

    constructor(scheduleControl: ScheduleControl, date: Date) {
        this.scheduleControl = scheduleControl;
        this.date = date;
    }
}
