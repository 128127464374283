import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { Company } from '../../../core/models/company/company.model';
import { GetTasksInputDto } from '../../../core/models/task/get-tasks-input.dto';
import { TaskDto } from '../../../core/models/task/task.dto';
import { UpdateTaskInputDto } from '../../../core/models/task/update-task-input.dto';
import { EventService } from '../../../core/services/event.service';
import { TaskService } from '../../../core/services/task.service';
import { AlertService } from '../../../shared/services/alert.service';
import { TaskStatusTittleResolver } from '../../../shared/services/task-status-tittle.resolver';
import { TaskStatusEnum } from '../../../shared/types/task-status.enum';
import { CreateTaskComponent } from '../create-task/create-task.component';
import { TaskThermometerResolver } from '../../../shared/services/task-thermometer.resolver';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
    selector: 'app-task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

    taskStatuses = [];
    selectCompany: Company[];
    companies: Company[];
    showDropdown = false;
    tasks = [];
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    statuses = [];
    showArchived = false;

    constructor(
        private taskStatusTittleResolver: TaskStatusTittleResolver,
        private eventService: EventService,
        private taskService: TaskService,
        private alertService: AlertService,
        private modalService: NgbModal,
        private router: Router,
        private taskThermometerResolver: TaskThermometerResolver,
        private translateService: TranslatorService,

    ) { }

    ngOnInit() {
        this.eventService.broadcast('changePageHeading', this.translateService.trans('menu.task_management'));

        this.taskStatuses = this.translateStatusAndColor();

        Object.values(TaskStatusEnum).forEach(status => {
            this.statuses.push(this.taskStatusTittleResolver.getTaskStatusTittle(status));
        });
    }

    onCompaniesLoaded(companies) {
        this.companies = companies;
        this.onSelectedCompany(companies);

        if (this.companies.length > 1) {
            this.showDropdown = true;
        }
    }

    onSelectedCompany(companies) {
        this.selectCompany = companies;
        this.getTasks();
    }

    getTasks() {
        const getTasksInput = new GetTasksInputDto();
        getTasksInput.companyId = this.selectCompany[0].id;
        if (this.showArchived) {
            this.taskService.getTasksArchived$(getTasksInput).subscribe(data => {
                this.filterTasks(data.tasks);
            });
        } else {
            this.taskService.getTasks$(getTasksInput).subscribe(data => {
                this.filterTasks(data.tasks);
            });
        }
    }

    private filterTasks(tasks: TaskDto[]) {
        Object.values(TaskStatusEnum).forEach(taskStatus => {
            this.tasks[this.taskStatusTittleResolver.getTaskStatusTittle(taskStatus)] = tasks.filter(task => {
                return task.status === taskStatus;
            });
        });
    }

    private translateStatusAndColor() {
        const taskStatusTranslate = [];
        Object.values(TaskStatusEnum).forEach(status => {
            taskStatusTranslate.push({ status: this.taskStatusTittleResolver.getTaskStatusTittle(status), color: this.taskStatusTittleResolver.getTaskStatusColor(status) });
        });
        return taskStatusTranslate;
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
            this.taskService.updateTask$(this.createUpdateTaskStatusInput(event.item.data, event.container.id)).subscribe(data => {
                if (data.result) {
                    this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('task.updated.succ_info'));
                } else {
                    transferArrayItem(event.container.data,
                        event.previousContainer.data,
                        event.previousIndex,
                        event.currentIndex);
                    this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('task.updated.err_info'));
                }
            });
        }
    }

    private createUpdateTaskStatusInput(task: TaskDto, taskStatus: string) {
        const updateTaskInput = new UpdateTaskInputDto();
        updateTaskInput.id = task.id;
        updateTaskInput.status = this.taskStatusTittleResolver.getDbTaskStatusTittle(taskStatus);
        if (updateTaskInput.status === TaskStatusEnum.IN_PROGRESS) {
            updateTaskInput.dateStart = new Date();
        } else if (updateTaskInput.status === TaskStatusEnum.FINISHED) {
            updateTaskInput.dateEnd = new Date();
        }
        return updateTaskInput;
    }

    createTaskModal() {
        const createTaskModal = this.modalService.open(CreateTaskComponent, { size: 'lg', backdrop: 'static' });
        createTaskModal.componentInstance.companyId = this.selectCompany[0].id;
        createTaskModal.componentInstance.created.subscribe((created) => {
            this.getTasks();
        });
    }

    showTaskDetail(task: TaskDto) {
        this.router.navigate(['task/detail'], { queryParams: { taskId: task.id, companyId: this.selectCompany[0].id } });
    }
    archiveTask(taskId: string) {
        const updateTaskInput = new UpdateTaskInputDto();
        updateTaskInput.id = taskId;
        updateTaskInput.archived = true;
        this.alertService.showArchiveConfirm(this.translateService.trans('swag.sure'), this.translateService.trans('swag.cant_revert')).then(result => {
            if (!result.value) {
                return;
            }
            this.taskService.updateTask$(updateTaskInput).subscribe(data => {
                if (data.result) {
                    this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('task.archived.succ_info'));
                    this.getTasks();
                } else {
                    this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('task.archived.err_info'));
                }
            });
        });
    }
}
