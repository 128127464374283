import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleControl } from '../../../../../core/models/shedulecontrol/schedulecontrol.model';
import { User } from '../../../../../core/models/user/User.model';
import { ScheduleControlService } from '../../../../../core/services/shedulecontrol.service';
import { AlertService } from '../../../../../shared/services/alert.service';
import { DoorDto } from '../../../../../core/models/door/dto/door.dto';
import { CreateScheduleControl } from '../../../../../core/models/shedulecontrol/create-schedulecontrol.model';
import { format, startOfDay } from 'date-fns';
import { defineLocale } from 'moment';
import { listLocales, esLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslatorService } from '../../../../../shared/services/translator.service';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Component({
    selector: 'app-create-schedule',
    templateUrl: './create-schedule.component.html',
    styleUrls: ['./create-schedule.component.scss']
})
export class CreateScheduleComponent implements OnInit {

    @Output() scheduleCreated: EventEmitter<boolean> = new EventEmitter();
    @Input() users: User[];

   
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    locale = 'es';
    locales = listLocales();
    dateSelected: Date;
    selectedUser: User;
    scheduleControl: ScheduleControl;

    public userMultiFilterCtrl: FormControl = new FormControl();
    protected _onDestroy = new Subject<void>();
    public filteredUsersMulti: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);


    constructor(
        readonly activeModal: NgbActiveModal,
        private localeService: BsLocaleService,
        private scheduleControlService: ScheduleControlService,
        private alertService: AlertService,
        private translateService: TranslatorService,
    ) {
        this.localeService.use(this.locale);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD-MM-YYYY' });
        this.dateSelected = new Date();
    }

    ngOnInit() {
        this.filteredUsersMulti.next(this.users.slice());
        this.userMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
            this.filterUsersMulti();
        });
    }

    save() {
        if (!this.validateSchedule()) {
            return;
        }
        const door = new DoorDto();
        door.type = true;
        door.signature = '';
        door.eventDate = this.dateSelected;
        door.comment = this.translateService.trans('schedule.add.default.comment');
        const scheduleControl = new CreateScheduleControl();
        scheduleControl.door = [door];
        scheduleControl.date = format(this.dateSelected, 'yyyy-MM-dd');
        this.scheduleControlService.createScheduleControlAnotherDay$(scheduleControl, this.selectedUser.id).subscribe(data => {
            if (data) {
                this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('schedule.control.succ'));
                this.scheduleControl = data.scheduleControl;
                this.scheduleCreated.emit(true);
            } else {
                this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.control.err'));
            }
        });
        this.activeModal.close();
    }

    closeModal() {
        this.activeModal.close();
    }

    onFormFieldChange(user: User) {
        this.selectedUser = user;
    }
    onDateChange(date: Date) {
        this.dateSelected = startOfDay(date);
    }

    private validateSchedule() {
        let isValid = true;
        if (!this.dateSelected) {
            this.alertService.show('warning', this.translateService.trans('toast.alert'), this.translateService.trans('toast.edit_role.err_date'));
            isValid = false;
        }
        if (!this.selectedUser) {
            this.alertService.show('warning', this.translateService.trans('toast.alert'), this.translateService.trans('toast.edit_role.err_select_user'));
            isValid = false;
        }
        return isValid;
    }

    protected filterUsersMulti() {
        
        if (!this.users) {
            return;
        }

        let search = this.userMultiFilterCtrl.value;

        if (!search) {
            this.filteredUsersMulti.next(this.users.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredUsersMulti.next(
            this.users.filter(user => user.name.toLowerCase().indexOf(search) > -1)
        );

    }
}
