import gql from 'graphql-tag';

export const createCompanyUserEmployeeRole = gql`
mutation createCompanyUserEmployeeRole($companyUserEmployeeRolesInput: CompanyUserEmployeeRolesInput){
    createCompanyUserEmployeeRole(companyUserEmployeeRolesInput : $companyUserEmployeeRolesInput)
}`;

export const deleteCompanyUserEmployeeRole = gql`
mutation deleteCompanyUserEmployeeRole($companyUserEmployeeRoleInput: CompanyUserEmployeeRoleInput){
    deleteCompanyUserEmployeeRole(companyUserEmployeeRoleInput : $companyUserEmployeeRoleInput)
}`;

export const updateEmployeeRole = gql`
mutation updateEmployeeRole($employeeRoleInput: EmployeeRoleInput!){
    updateEmployeeRole(employeeRoleInput : $employeeRoleInput){
        id,
        name,
        color
    }
}`;

export const createEmployeeRole = gql`
mutation createEmployeeRole($employeeRoleInput: EmployeeRoleInput!){
    createEmployeeRole(employeeRoleInput : $employeeRoleInput){
        id,
        name,
        color
    }
}`;