import { CompanyUserEmployeeRole } from '../companyuseremployeerole/companyUserEmployeeRole.model';


export class CompaniesByUserLoggedResponse {
    companyUserEmployeeRole: Array<CompanyUserEmployeeRole>;

    constructor(companiesAndEmployeeRolesUserLogged: Array<CompanyUserEmployeeRole>) {
        this.companyUserEmployeeRole = companiesAndEmployeeRolesUserLogged;
    }
}
