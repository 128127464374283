import { Injectable } from '@angular/core';
import { format, isSameDay } from 'date-fns';
import { Activity } from 'src/app/core/models/activity/activity.model';
import { User } from 'src/app/core/models/user/User.model';
import { CalculatorUtil } from '../calculator-util.service';
import { UserTargetsChartService } from '../charts/user-targets-chart.service';
import { DateUtil } from '../date-util.service';

@Injectable({ providedIn: 'root' })
export class UserStatisticsCalculator {

    constructor(
        private calculatorUtil: CalculatorUtil,
        private userTargetsChartService: UserTargetsChartService,
        private dateUtil: DateUtil,
    ) {
    }

    calculateEfficiency(agent: User) {
        const targetWeighings = this.calculateTargetsWeighing(agent);
        const activitiesByTarget: {} = {};
        agent.activitys.forEach(activity => {
            if(activity.target === null){
                return
            }
            if (!activitiesByTarget[activity.target.id]) {
                activitiesByTarget[activity.target.id] = [];
            }
            activitiesByTarget[activity.target.id].push(activity);
        });
        let totalEfficiency = 0;
        Object.entries(activitiesByTarget).forEach(([targetId, targetActivities]: [string, Activity[]]) => {
            const weighingCheck = targetWeighings.filter(item => item[0] === targetId).shift();
            let targetWeighing = 0;
            if (weighingCheck) {
                targetWeighing = weighingCheck[1];
            }

            const totalTargetActivities = this.sumActivities(targetActivities);
            let efficiency: number;
            const totalGoalTarget = this.sumGoalByTargetByAgent(targetId, agent);
            efficiency = ((totalTargetActivities * targetWeighing) / totalGoalTarget);
            efficiency = this.calculatorUtil.fixNanAndFinite(efficiency);
            totalEfficiency += efficiency;
        });
        return totalEfficiency;
    }
    sumActivities(activities): number {
        return activities.reduce((accumulator, currentValue) => accumulator + currentValue.realAmount, 0);
    }

    sumTargetByAgent(agent: User): number {
        return agent.companyUserEmployeeRoles
            .reduce((accumulator, currentValue) => accumulator + currentValue.idEmployeeRole.companyEmployeeRoleTargetTypes
                .reduce((a2, currentTarget) => {
                    if (currentTarget.idTarget) {
                        return a2 + currentTarget.idTarget.targetEspecifications
                            .reduce((a3, currentSpec) => a3 + currentSpec.goal, 0);
                    } else {
                        return a2;
                    }
                }, 0), 0);
    }

    sumTargetByAgentByDate(agent: User, startDate: Date, finishDate: Date): number {
        const datesArray = this.dateUtil.getDatesArray(startDate, finishDate)
        return agent.companyUserEmployeeRoles
            .reduce((accumulator, currentValue) => accumulator + currentValue.idEmployeeRole.companyEmployeeRoleTargetTypes
                .reduce((a2, currentTarget) => {
                    if (currentTarget.idTarget) {
                        return a2 + currentTarget.idTarget.targetEspecifications
                            .reduce((a3, currentSpec) => {
                                datesArray.forEach(date => {
                                    if (this.dateUtil.isBusinessDay(date) && this.userTargetsChartService.isBetweenDates(date, new Date(currentSpec.startDate), new Date(currentSpec.finishDate))) {
                                        a3 = a3 + currentSpec.goal;
                                    }
                                });
                                return a3;
                            }, 0);
                    } else {
                        return a2;
                    }
                }, 0), 0);
    }

    sumGoalByTargetByAgent(targetid: string, agent: User) {
        return agent.companyUserEmployeeRoles
            .reduce((accumulator, currentValue) => accumulator + currentValue.idEmployeeRole.companyEmployeeRoleTargetTypes
                .reduce((a2, currentTarget) => {
                    if (currentTarget.idTarget && currentTarget.idTarget.id === targetid) {
                        return a2 + currentTarget.idTarget.targetEspecifications
                            .reduce((a3, currentSpec) => {
                                return a3 + currentSpec.goal;
                            }, 0);
                    } else {
                        return a2;
                    }
                }, 0), 0);
    }

    calculateTargetsWeighing(agent: User): any {
        const targetIdWighing = agent.companyUserEmployeeRoles
            .map(companyUserEmployeeRole => companyUserEmployeeRole.idEmployeeRole.companyEmployeeRoleTargetTypes)
            .map(companyEmployeeRoleTargetTypes => {
                return companyEmployeeRoleTargetTypes
                    .filter(companyEmployeeRoleTarget => companyEmployeeRoleTarget.idTarget !== null)
                    .map(companyEmployeeRoleTarget => {
                        const target = companyEmployeeRoleTarget.idTarget;
                        return [target.id, companyEmployeeRoleTarget.weighing];
                    });
            });
        return [].concat(...targetIdWighing);
    }

    calculateEfficiencyByDate(agent: User, workingDays: Date[]): number[] {
        const targetWeighings = this.calculateTargetsWeighing(agent);
        const activitiesByTarget: {} = {};
        agent.activitys.forEach(activity => {
            if (!activitiesByTarget[activity.target.id]) {
                activitiesByTarget[activity.target.id] = [];
            }
            activitiesByTarget[activity.target.id].push(activity);
        });

        let totalEfficiency = 0;
        let efficiencyByDay = [];
        workingDays.forEach(dateDay => {
            totalEfficiency = 0;
            Object.entries(activitiesByTarget).forEach(([targetId, targetActivities]: [string, Activity[]]) => {
                targetActivities
                    .filter(targetActivity => isSameDay(new Date(targetActivity.dateDay), new Date(dateDay)))
                    .forEach(element => {
                        let targetWeighing = this.checkWeighing(targetWeighings, targetId);

                        const totalTargetActivities = this.sumActivities([element]);
                        const totalGoalTarget = this.sumGoalByTargetByAgent(targetId, agent);
                        let efficiency = ((totalTargetActivities * targetWeighing) / totalGoalTarget);
                        totalEfficiency += this.calculatorUtil.fixNanAndFinite(efficiency);
                    });
            });
            const efficiencyAndDay = [format(new Date(dateDay), 'yyyy-MM-dd'), parseFloat(totalEfficiency.toFixed(2))]
            efficiencyByDay.push(efficiencyAndDay)
        });
        return efficiencyByDay;
    }
    calculateEfficiencyByDateProgresive(agent: User, workingDays: Date[]) {
        const targetWeighings = this.calculateTargetsWeighing(agent);
        const activitiesByTarget: {} = {};
        agent.activitys.forEach(activity => {
            if (!activitiesByTarget[activity.target.id]) {
                activitiesByTarget[activity.target.id] = [];
            }
            activitiesByTarget[activity.target.id].push(activity);
        });

        let totalEfficiency: number = 0;
        let efficiencyByDay: number[] = [];
        workingDays.forEach(dateDay => {
            Object.entries(activitiesByTarget).forEach(([targetId, targetActivities]: [string, Activity[]]) => {
                targetActivities
                    .filter(targetActivity => isSameDay(new Date(targetActivity.dateDay), new Date(dateDay)))
                    .forEach(element => {
                        let targetWeighing = this.checkWeighing(targetWeighings, targetId);

                        const totalTargetActivities = this.sumActivities([element]);
                        const totalGoalTarget = this.sumGoalByTargetByAgent(targetId, agent);
                        let efficiency = ((totalTargetActivities * targetWeighing) / totalGoalTarget);
                        totalEfficiency += (this.calculatorUtil.fixNanAndFinite(efficiency) / workingDays.length);
                    });
            });
            efficiencyByDay.push(+totalEfficiency.toFixed(2))
        });
        return efficiencyByDay;
    }

    checkWeighing(targetWeighings: any, targetId: string) {
        const weighingCheck = targetWeighings.filter(item => item[0] === targetId).shift();
        let targetWeighing = 0;
        if (weighingCheck) {
            targetWeighing = weighingCheck[1];
        }
        return targetWeighing;
    }
}
