import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })


export class PageTitleResolver {
    routePages = [];

    constructor(
    ) {
        this.routePages['dashboard-agent'] = 'Dashboard Agente';
        this.routePages['dashboard-admin'] = 'Dashboard Administrador';
        this.routePages['targets'] = 'Gestion objetivos';
        this.routePages['statistics'] = 'Gestion estadisticas agente';
        this.routePages['summary'] = 'Resumen actividad comercial';
        this.routePages['company/configuration/edit'] = 'Parámetros empresa';
        this.routePages['company/edit'] = 'Perfil empresa';
        this.routePages['employee-role/list'] = 'Gestion roles';
        this.routePages[''] = 'Perfil usuario';
        this.routePages['users/list'] = 'Gestion usuarios';
        this.routePages['page-view'] = 'Control de visitas';
        this.routePages['list'] = 'Listado control laboral';
        this.routePages['control'] = 'Fichar';
        this.routePages['documents-store'] = 'Almacén documentos';
        this.routePages['new-document'] = 'Documento rellenable';
        this.routePages['sales_mandate'] = 'Mandato de venta';
        this.routePages['aesthetic_consent'] = 'Consentimiento Estético';
    }

    getPageTitle(route: string) {
        if (this.routePages[route]) {
            return this.routePages[route];
        } else {
            return route;
        }
    }
}
