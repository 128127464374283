import { TaskStatusEnum } from '../../../shared/types/task-status.enum';

export class UpdateTaskInputDto {
    id: string;
    status: TaskStatusEnum;
    name: string;
    description: string;
    dateStart: Date;
    dateEnd: Date;
    userId: string;
    archived: boolean;
}
