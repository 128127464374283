import { Activity } from './activity.model';

export class UpdateActivityResponse {
    id: string;
    dateDay: string;
    realAmount: number;

    constructor(activitycreated : Activity){
        this.id=activitycreated.id;
        this.dateDay=activitycreated.dateDay;
        this.realAmount=activitycreated.realAmount;
    }
}
