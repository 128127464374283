import { Injectable } from '@angular/core';
import { differenceInBusinessDays, differenceInMonths, format, addMonths } from 'date-fns';
import { Activity } from '../../../core/models/activity/activity.model';
import { Target } from '../../../core/models/target/target.model';
import { User } from '../../../core/models/user/User.model';

@Injectable({ providedIn: 'root' })
export class UserTargetsChartService {

    getTotalTargetGoalsInDateRangeByUser(user: User, startDate: Date, finishDate: Date) {

        let totalTargetGoal = 0;
        const totalBusinessDays = differenceInBusinessDays(finishDate, startDate);

        totalTargetGoal = user.companyUserEmployeeRoles.reduce((accumulator, currentValue) =>
            accumulator + currentValue.idEmployeeRole.companyEmployeeRoleTargetTypes.reduce((a2, currentTarget) =>
                currentTarget.idTarget ? a2 + this.sumGoalOfTargetInDateRange(currentTarget.idTarget, startDate, finishDate) : a2,
                0),
            0);

        return parseFloat((totalTargetGoal * totalBusinessDays).toFixed(2));
    }

    sumGoalOfTargetInDateRange(target: Target, startDate: Date, finishDate: Date) {
        return target.targetEspecifications.reduce((a3, currentSpec) => {
            let numberToSum = 0;
            if ((startDate >= new Date(currentSpec.startDate) && startDate <= new Date(currentSpec.finishDate)) ||
                (new Date(currentSpec.startDate) >= startDate && new Date(currentSpec.startDate) <= finishDate)) {
                numberToSum = currentSpec.goal;
            }

            return parseFloat((a3 + numberToSum).toFixed(2));
        }, 0);
    }

    getTotalActivitiesInDateRange(activities: Array<Activity>, startDate: Date, finishDate: Date, ) {

        let totalAmount = 0;

        activities.forEach(activity => {
            if (this.isBetweenDates(new Date(activity.dateDay), startDate, finishDate)) {
                totalAmount += activity.realAmount;
            }
        });

        return totalAmount;
    }


    generateXAxis(dateFrom: Date, dateTo: Date) {
        const months = [];

        for (let i = 0; i <= differenceInMonths(dateTo, dateFrom); i++) {
            months.push(format(addMonths(dateFrom, i), 'yyyy-MM'));
        }

        return months;
    }

    isBetweenDates(date: Date, startDate: Date, finishDate: Date) {
        return startDate <= date && finishDate >= date;
    }
}
