import { Component, OnInit, ElementRef, AfterViewInit} from '@angular/core';
import { EventService } from '../../core/services/event.service';
import { UserLogged } from 'src/app/core/models/auth.models';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {

  constructor(
    private eventService: EventService,
    ) {
  }
  currentUser: UserLogged;
  isUserAdmin: boolean;

  ngOnInit() {
    this.eventService.broadcast('changePageHeading', 'Dashboard');
  }

  ngAfterViewInit() { }

}
