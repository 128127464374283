import gql from 'graphql-tag';

export const EmployeeRoleByCompany = gql`
    query getEmployeeRoleByCompany($companyId: String!) {
        getEmployeeRoleByCompany(companyId: $companyId) {
            idEmployeeRole{
                id,
                name,
                color
            },
            idCompany{
                id,
                name
            },
            idUser{
                id,
                name,
                lastName
            }
        }
    }`;

export const getEmployeeRolesFiltered = gql`
query getEmployeeRolesFiltered($getEmployeeRolesFilteredInput: GetEmployeeRolesFilteredInput!) {
    getEmployeeRolesFiltered(getEmployeeRolesFilteredInput: $getEmployeeRolesFilteredInput) {
        id,
        name,
        color
    }
}`;

export const getCompanyUserByEmployeeRole = gql`
query getCompanyUserByEmployeeRole($getCompanyUserByEmployeeRoleInput: GetCompanyUserByEmployeeRoleInput!) {
    getCompanyUserByEmployeeRole(getCompanyUserByEmployeeRoleInput: $getCompanyUserByEmployeeRoleInput) {
        idUser{
            id,
            name,
            lastName
        },
        idEmployeeRole{
            id,
            name,
            color
        },
        idCompany{
            id,
            name
        }
    }
}`;

export const getCompanyEmployeeRoleByUser = gql`
query getCompanyEmployeeRoleByUser($getCompanyUserByEmployeeRoleInput: GetCompanyUserByEmployeeRoleInput!) {
    getCompanyEmployeeRoleByUser(getCompanyUserByEmployeeRoleInput: $getCompanyUserByEmployeeRoleInput) {
        idUser{
            id,
            name,
            lastName
        },
        idEmployeeRole{
            id,
            name,
            color
        },
        idCompany{
            id,
            name
        }
    }
}`;

