import { Component } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';
import { AlertService } from '../../shared/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {

  configuration: ToasterConfig;

  constructor(
    private readonly alertService: AlertService
  ) {

    this.configuration = this.alertService.getConfiguration();
  }

}
