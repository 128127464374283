import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { jqxKnobModule } from 'jqwidgets-ng/jqxknob';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddActivityComponent } from '../../shared-component/add-activity/add-activity.component';
import { KnobComponentComponent } from '../../shared-component/knob-component/knob-component.component';
import { TargetContainerComponent } from '../../shared-component/target-container/target-container.component';
import { TargetNamePipe } from '../../shared/pipe/target-name.pipe';
import { TransPipe } from '../../shared/pipe/trans.pipe';
import { ActivitiesComponent } from '../activities/activities.component';
import { AgentCardComponent } from '../agent-card/agent-card.component';
import { AgentsComponent } from '../agents/agents.component';
import { BundlechartsComponent } from '../charts/bundlecharts/bundlecharts.component';
import { EfficiencyEvolutionComponent } from '../charts/efficiency-evolution/efficiency-evolution.component';
import { EfficiencyProgresiveComponent } from '../charts/efficiency-progresive/efficiency-progresive.component';
import { UserTargetsChartComponent } from '../charts/user-targets-chart/user-targets-chart.component';
import { UsersEfficiencyChartComponent } from '../charts/users-efficiency-chart/users-efficiency-chart.component';
import { EditCompanyConfigurationComponent } from '../configuration-panel/edit-company-configuration/edit-company-configuration.component';
import { EditEmployeeRolesComponent } from '../configuration-panel/employee-roles/edit-employee-roles/edit-employee-roles.component';
import { UsersEditComponent } from '../configuration-panel/users/users-edit/users-edit.component';
import { DashboardAdminComponent } from '../dashboard-admin/dashboard-admin.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { PerfomancesComponent } from '../perfomances/perfomances.component';
import { StatisticsComponent } from '../statistics/statistics.component';
import { ButtonsComponent } from '../target/buttons/buttons.component';
import { EditTargetComponent } from '../target/buttons/edit-target/edit-target.component';
import { NewTargetComponent } from '../target/buttons/new-target/new-target.component';
import { CalendarComponent } from '../target/calendar/calendar.component';
import { TargetComponent } from '../target/target.component';
import { TargetsComponent } from '../targets/targets.component';
import { ComercialActivityRoutingModule } from './comercial-activity-routing.module';
import { SummaryComponent } from './summary/summary.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin
]);
@NgModule({
    declarations: [
        DashboardComponent,
        TargetComponent,
        StatisticsComponent,
        TargetsComponent,
        UserTargetsChartComponent,
        PerfomancesComponent,
        AgentsComponent,
        ButtonsComponent,
        CalendarComponent,
        ActivitiesComponent,
        DashboardAdminComponent,
        AgentCardComponent,
        EditTargetComponent,
        NewTargetComponent,
        TargetContainerComponent,
        KnobComponentComponent,
        AddActivityComponent,
        EditCompanyConfigurationComponent,
        BundlechartsComponent,
        EfficiencyEvolutionComponent,
        EfficiencyProgresiveComponent,
        TargetNamePipe,
        SummaryComponent,
        UsersEfficiencyChartComponent,
        UsersEditComponent,
        EditEmployeeRolesComponent,
    ],
    imports: [
        ComercialActivityRoutingModule,
        CommonModule,
        MatOptionModule,
        MatInputModule,
        MatCheckboxModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        FormsModule,
        NgxSkeletonLoaderModule,
        NgxEchartsModule,
        FullCalendarModule,
        ToasterModule,
        jqxKnobModule,
        UiSwitchModule,
        ColorPickerModule,
        SharedModule,
    ],
    providers: [
        ToasterService,
        TransPipe,
    ]
})
export class ComercialActivityModule { }
