import { EventEmitter, Injectable, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { changePasswordWithSecurityToken, loginUserMutation, sendEmailForRecoverPasswordMutation } from '../../graphql/operations/mutations';
import { loggedUserQuery } from '../../graphql/operations/queries';
import { UserLogged } from '../models/auth.models';
import { SELECTED_COMPANIES, StorageService } from './storage.service';

export const TOKEN_NAME = 'clicktecToken';
export const TOKEN_USER = '_user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    @Output() readonly resfreshNewUser: EventEmitter<any> = new EventEmitter();

    constructor(
        private apollo: Apollo,
        private storageService: StorageService,
    ) {
    }


    // TODO Creo que no se deberían mezclar servicios "locales" con servicios de apollo

    login$(email: string, password: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: loginUserMutation,
                variables: {
                    loginUserInput: {
                        email,
                        password
                    }
                }
            });
    }

    userLogged$(): Observable<any> {
        return this.apollo
            .query({
                query: loggedUserQuery
            });
    }

    sendEmailForRecoverPassword$(email: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: sendEmailForRecoverPasswordMutation,
                variables: {
                    input: {
                        email
                    }
                }
            });
    }

    changePasswordWithSecurityToken$(password: string, token: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: changePasswordWithSecurityToken,
                variables: {
                    input: {
                        password,
                        token
                    }
                }
            });
    }

    // TODO Creo que no se deberían mezclar servicios "locales" con servicios de apollo

    getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
    }

    setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
    }

    getUser(): UserLogged {
        return this.storageService.secureStorage.getItem(TOKEN_USER);
    }

    setUser(user: UserLogged): void {

        this.storageService.secureStorage.setItem(TOKEN_USER, user);
    }

    getUserRol(): string {
        const decoded = this.storageService.secureStorage.getItem(TOKEN_USER);
        if (decoded) {
            return decoded.roles;
        }
    }

    canShowElement(...rolesPermitted: Array<string>): boolean {
        return rolesPermitted.includes(this.getUserRol());
    }

    canShowFeature(...features: Array<string>): boolean {
        let canShow = false;
        if (!this.storageService.getCompanies()) {
            return;
        }
        this.storageService.getCompanies().forEach(company => {
            // HOTFIX WHEN FEATURES UPLOADED
            if (!company.companyConfiguration.features) {
                this.logout();
                return false;
            }

            company.companyConfiguration.features.forEach(feature => {
                if (features.includes(feature.name)) {
                    canShow = true;
                }
            });
        });
        return canShow;
    }

    getTokenExpirationDate(token: string): Date {

        const decoded = token;

        if (decoded === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(+decoded);

        return date;
    }


    isTokenExpired(token?: string): boolean {
        // TODO: implement
        let tokenTemp: string;

        if (!token) {
            tokenTemp = this.getToken();
        }
        if (!tokenTemp) {
            return true;
        }

        return false;

    }

    logout(): void {
        this.storageService.secureStorage.clear();
        //AQUI VA LA MUTACION QUE BORRA EL TOKEN DEL BACKEND
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(SELECTED_COMPANIES);

    }

    refreshViewWithNewUser(): void {
        this.resfreshNewUser.emit();
    }



}

