import gql from 'graphql-tag';

export const getCompanyGroupByCompany = gql`
    query getCompanyGroupByCompany($companyId: String!) {
        getCompanyGroupByCompany(companyId: $companyId) {
            id,
            name,
            cif,
            status,
            address,
            city,
            owner,
            ownerNif,
            ownerAddress,
            ownerCity,
            ownerPostalCode,
            isFranchise,
            franchiseGroupName,
            franchiseGroupNif,
            franchiseGroupAddress,
            franchiseGroupCity,
            franchiseGroupPostalCode
        }
    }`;
