import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { RefillableDocumentInput } from 'src/app/core/models/refillable-document/dto/refillableDocument.input';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { CompanyGroup } from '../../../core/models/company-group/company-group.model';
import { SaveRefillableDocumentInputDto } from '../../../core/models/refillable-document/dto/saveRefillableDocumentInput.dto';
import { AuthenticationService } from '../../../core/services/auth.service';
import { CompanyGroupService } from '../../../core/services/companyGroup.service';
import { EventService } from '../../../core/services/event.service';
import { RefillableDocumentService } from '../../../core/services/refillable-document.service';
import { StorageService } from '../../../core/services/storage.service';
import { AlertService } from '../../../shared/services/alert.service';
import { PageTitleResolver } from '../../../shared/services/page-tittle.resolver';
import { AestheticConsentValidator } from '../validators/aesthetic-consent.validator';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
    selector: 'app-refillable-document-consent',
    templateUrl: './refillable-document-consent.component.html',
    styleUrls: ['./refillable-document-consent.component.scss']
})

export class RefillableDocumentConsentComponent implements OnInit {

    @ViewChild('marketerSignPad', { static: false }) marketerSignPad: SignaturePad;

    signMode = false;
    printing = false;
    refillableDocumentListRoute = '/refillable-documents/list';
    signaturePadOptions: object = {
        minWidth: 1,
    };
    companyGroup: CompanyGroup;

    document = new SaveRefillableDocumentInputDto();

    signCompleted = false;
    loading = false;

    constructor(
        private readonly refillableDocumentService: RefillableDocumentService,
        private authService: AuthenticationService,
        private storageService: StorageService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private eventService: EventService,
        private aestheticConsentValidator: AestheticConsentValidator,
        private companyGroupService: CompanyGroupService,
        private pageTittleResolver: PageTitleResolver,
        private translateService: TranslatorService,
    ) { }

    ngOnInit() {
        window.addEventListener('message', (e) => {
            if (e.data.event === 'completed') {
                this.signCompleted = true;
            }
        });
        this.eventService.subscribe('SelectedCompanies', arrayOfcompanyRecived => {
            this.companyGroupService.getCompanyGroupByCompany$(arrayOfcompanyRecived[0].id).subscribe(data => {
                this.companyGroup = data;
            });
        });
        this.eventService.broadcast('changePageHeading', 'Nuevo ' + this.pageTittleResolver.getPageTitle(this.route.snapshot.params['documentType']));
    }

    public saveDocument() {
        // if (!this.validateForm(this.document)) {
        //     return;
        // }
        // if (!this.validateSignature()) {
        //     return;
        // }
        this.loading = true;
        this.document.marketerSign = this.marketerSignPad.toDataURL('image/png');
        const refillableDocument = new RefillableDocumentInput();
        refillableDocument.name = this.route.snapshot.params['documentName'];
        refillableDocument.type = this.route.snapshot.params['documentType'];
        refillableDocument.refillableDocument = JSON.stringify(this.document);
        refillableDocument.companyId = this.storageService.getCompanies()[0].id;
        refillableDocument.userId = this.authService.getUser().id;

        this.refillableDocumentService.saveDocument$(refillableDocument).subscribe(url => {
            if (url) {
                this.eventService.broadcast(new SubscribeTypes().REFILLABLE_DOCUMENT_CREATED);
                window.open(url);
                this.router.navigate([this.refillableDocumentListRoute]);
            } else {
                this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), 'Ha ocurrido un problema al guardar el archivo');
            }
            this.loading = false;
        });
    }

    private validateSignature() {
        let isValid = true;
        if (this.marketerSignPad.isEmpty()) {
            this.alertService.show('warning', this.translateService.trans('toast.alert'), 'El comprador debe firmar para poder continuar');
            isValid = false;
        }
        return isValid;
    }

    toggleSignMode() {
        this.signMode = !this.signMode;
    }

    returnRefillableList() {
        this.router.navigate([this.refillableDocumentListRoute]);
    }

    validateForm(document) {
        if (this.aestheticConsentValidator.validationForm(document) === false) {
            this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), 'Debe rellenar todos los campos del formulario');
            return false;
        } else {
            this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), 'Has rellenado el formulario correctamente');
            return true;
        }
    }

}
