import { EmployeeRole } from '../employeeRole/employeeRole.model';
import { Company } from '../../models/company/company.model';
import { User } from '../../models/user/User.model';

export class CompanyUserEmployeeRole {

    idCompany: Company;
    idUser: User;
    idEmployeeRole: EmployeeRole;

}
