import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CompanyDropdownComponent } from '../shared-component/dropdown/company-dropdown/company-dropdown.component';
import '../../app/config/ngx-bootstrap.config';
import { TransPipe } from './pipe/trans.pipe';
import { TranslatorService } from './services/translator.service';
import { UIModule } from './ui/ui.module';


@NgModule({
  declarations: [
    CompanyDropdownComponent,
    TransPipe
  ],
  imports: [
    CommonModule,
    UIModule,
    MatOptionModule,
    MatInputModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    CompanyDropdownComponent,
    TranslateModule,
    TransPipe,
    BsDatepickerModule,
  ],
  providers: [
    TranslatorService,
  ]
})
export class SharedModule {
}
