import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { differenceInMilliseconds, format, subMilliseconds } from 'date-fns';
import { isSameDay } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { countUpTimerConfigModel, CountupTimerService, timerTexts } from 'ngx-timer';
import { Subscription } from 'rxjs';
import { UserLogged } from 'src/app/core/models/auth.models';
import { DoorDto } from 'src/app/core/models/door/dto/door.dto';
import { ScheduleControl } from 'src/app/core/models/shedulecontrol/schedulecontrol.model';
import { User } from 'src/app/core/models/user/User.model';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EventService } from 'src/app/core/services/event.service';
import { ScheduleControlService } from 'src/app/core/services/shedulecontrol.service';
import { SignatureModalComponent } from 'src/app/shared-component/signature-modal/signature-modal.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { GeolocationService } from 'src/app/shared/services/geolocation.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { environment } from 'src/environments/environment';
import { Door } from '../../../core/models/door/door.model';
import { StorageService } from '../../../core/services/storage.service';
import { ScheduleTimeCalculator } from '../../../shared/schedule-time.calculator';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
  selector: 'app-schedule-control',
  templateUrl: './schedule-control.component.html',
  styleUrls: ['./schedule-control.component.scss']
})
export class ScheduleControlComponent implements OnInit, OnDestroy {

  @Input() user: User;

  button = false;
  urlAvatar = environment.storageBucket;
  defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
  userLogged: UserLogged;
  dateDay: Date;
  scheduleControl: ScheduleControl;
  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;
  companiesSubcription: Subscription;
  alternate = true;
  toggle = true;
  size = 45;
  expandEnabled = true;
  side = 'left';
  signature;
  isDisabled: boolean;
  totalHours = 0;
  totalHoursInDate: Date;
  totalTimePass: any = 0;
  clockConfig: countUpTimerConfigModel;
  loading: boolean;
  timePassed: Date;
  mySubscription: any;
  signatureSubcription: Subscription;
  checkLastDoorSubcription: Subscription;
  isWorking = false;
  loadStart: boolean;
  comment: string;
  position;

  constructor(
    private authService: AuthenticationService,
    private eventService: EventService,
    private scheduleControlService: ScheduleControlService,
    private localeService: BsLocaleService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private countupTimerService: CountupTimerService,
    private scheduleTimeCalculator: ScheduleTimeCalculator,
    private router: Router,
    readonly activeModal: NgbActiveModal,
    private geolocationService: GeolocationService,
    private translateService: TranslatorService,
    private storageService: StorageService,
  ) {
    const language = this.storageService.getCompanies()[0].companyConfiguration.language;
    this.localeService.use(language);

    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD/MM/YYYY' });
    this.dateDay = new Date();
    this.scheduleControl = new ScheduleControl();
    this.totalHoursInDate = new Date(0, 0, 0, 0, 0, 0, 0);

    this.clockConfig = new countUpTimerConfigModel();
    this.clockConfig.timerClass = 'test_Timer_class';
    this.clockConfig.timerTexts = new timerTexts();
    this.clockConfig.timerTexts.hourText = 'h';
    this.clockConfig.timerTexts.minuteText = 'm';
    this.clockConfig.timerTexts.secondsText = 's';
  }

  ngOnInit() {
    this.modalService.dismissAll();
    this.loadStart = true;
    this.eventService.subscribe('Signature', img => {
      this.signature = img;
      this.saveSchedule();
    });
    if (!this.user) {
      this.userLogged = this.authService.getUser();
      this.eventService.broadcast('changePageHeading', this.translateService.trans('schedule.control.comp.tittle'));
    } else {
      this.userLogged = this.user;
      this.getSchedule(this.dateDay);
      this.openModalWithComponent();
      this.signatureSubcription = this.eventService.subscribe(new SubscribeTypes().SIGNATURE_MODAL_CLOSED, (data) => {
        this.activeModal.close();
      });
    }
    this.getPosition();
    this.checkLastDoorSubcription = this.eventService.subscribe(new SubscribeTypes().CHECK_LAST_DOOR, async result => {
      let door: Door;
      if (result !== true) {
        if (!this.signature) {
          return;
        }
        this.scheduleControl = new ScheduleControl();
        this.scheduleControl.door = [];
        this.scheduleControl.date = new Date();
        door = this.createDoor(true, this.signature);
        this.createSchedule(door);
        delete (this.signature);
      }
    });
  }

  ngOnDestroy() {
    if (this.user) {
      this.signatureSubcription.unsubscribe();
    }
    if (this.checkLastDoorSubcription) {
      this.checkLastDoorSubcription.unsubscribe();
    }
  }

  getSchedule(dateDay) {
    this.totalHoursInDate = new Date(0, 0, 0, 0, 0, 0, 0);
    this.button = false;
    this.isWorking = false;
    this.isDisabled = !isSameDay(dateDay, this.dateDay);
      this.scheduleControlService.getScheduleControl$(new Date(format(dateDay, 'yyyy-MM-dd')), this.userLogged.id).subscribe(
        data => {
          const timer = new Date(data.date);
          this.scheduleControl = data.scheduleControl;
          if (data.scheduleControl) {
            this.totalHours = this.scheduleTimeCalculator.getTotalHoursFromSchedule(data.scheduleControl);
            if (data.scheduleControl.door[data.scheduleControl.door.length - 1].type === true) {
              this.timePassed = new Date(data.scheduleControl.door[this.scheduleControl.door.length - 1].eventDate);
              this.totalTimePass = differenceInMilliseconds(
                timer,
                new Date(data.scheduleControl.door[this.scheduleControl.door.length - 1].eventDate));
              this.button = true;
              this.isWorking = true;
            }
            this.totalHoursInDate = new Date(0, 0, 0, 0, 0, 0, this.totalHours);
  
            this.countupTimerService.startTimer(subMilliseconds(timer, this.totalTimePass));
          }
          this.loadStart = false;
        });
  }

  getTotalTime() {
    return new Date(0, 0, 0, parseInt(this.countupTimerService.timerValue.hours), parseInt(this.countupTimerService.timerValue.mins), parseInt(this.countupTimerService.timerValue.seconds), this.totalHours);
  }

  onExpandEntry(expanded, index) {
  }

  onHeaderClick(event) {
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }

  onDotClick(event) {
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }

  toggleSide() {
    this.side = this.side === 'left' ? 'right' : 'left';
  }

  async saveSchedule() {
    this.dateDay = new Date();
    if (!this.signature) {
      this.openModalWithComponent();
    } else {
      if (!this.scheduleControl) {
        await this.checkLastDoor();
      } else {
        const door = await this.createDoor(!this.getLastDoorType(), this.signature);
        this.createSchedule(door);
        delete (this.signature);
      }
    }
  }
  private createSchedule(door: Door) {
    this.loading = true;
     if(door){
       try {
      this.scheduleControl.door = [door];
       } catch (error) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.control.err'));
       }
     }else{
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.control.err'));
      this.scheduleControl.door = [];
      return;
     }
    this.scheduleControlService.createScheduleControl$(this.scheduleControl, this.userLogged.id).subscribe(data => {
      if (data) {
        this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('schedule.control.succ'));
        this.scheduleControl = data.scheduleControl;
        this.button = this.getLastDoorType();
        this.getSchedule(this.dateDay);
        this.loading = false;
        this.eventService.broadcast(new SubscribeTypes().SCHEDULE_ADD, true);
        if (this.user) {
          this.activeModal.close();
        }
      } else {
       this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.control.err'));
      }
    });
  }

  private getLastDoorType() {
    return this.scheduleControl.door[this.scheduleControl.door.length - 1].type;
  }

  private createDoor(type: boolean, signature: string) {
    const door = new DoorDto();
    door.type = type;
    door.signature = signature;
    door.comment = this.comment;
    if (this.position) {
      door.latitude = this.position.lat + '';
      door.longitude = this.position.lng + '';
    }
    return door;
  }

  private async getPosition(){
    this.position = await this.geolocationService.findMe().then(position => position);
  }

  openModalWithComponent() {
    const signatureModal = this.modalService.open(SignatureModalComponent, { backdrop: 'static', keyboard: false });
    signatureModal.componentInstance.schedule = true;

    signatureModal.componentInstance.signConfirmed.subscribe(signature => {
      this.signature = signature[0];
      this.comment = signature[1];
      this.isDisabled = true;
      this.saveSchedule();
    });
  }

  errorHandler(event) {
    event.target.src = this.defaultAvatar;
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.checkHiddenDocument();
  }
  checkHiddenDocument() {
    if (!document.hidden) {
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/schedule/control']);
      });
    }
  }

  async checkLastDoor() {
    this.scheduleControlService.getYesterdayScheduleControl$(this.userLogged.id).subscribe(
      async data => {
        if (!data.scheduleControl) {
          this.eventService.broadcast(new SubscribeTypes().CHECK_LAST_DOOR, false);
          return;
        }
        if (data.scheduleControl.door[data.scheduleControl.door.length - 1].type) {
          await this.alertService.showConfirmDoor(this.translateService.trans('schedule.control.swag.last_day'), this.translateService.trans('schedule.control.swag.last_day_info')).then(async result => {
            if (result.value) {
              const door = await this.createDoor(false, this.signature);
              door.eventDate = new Date();
              data.scheduleControl.door.push(door);
              this.scheduleControlService.createScheduleControl$(data.scheduleControl, this.userLogged.id).subscribe(data => {
                if (data) {
                  this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('schedule.control.succ'));
                  this.getSchedule(this.dateDay);
                  this.loading = false;
                  this.eventService.broadcast(new SubscribeTypes().SCHEDULE_ADD, true);
                  if (this.user) {
                    this.activeModal.close();
                  }
                } else {
                  this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('schedule.control.err'));
                }
              });
              delete (this.signature);
            } else {
              this.eventService.broadcast(new SubscribeTypes().CHECK_LAST_DOOR, false);
            }
          });
        } else {
          this.eventService.broadcast(new SubscribeTypes().CHECK_LAST_DOOR, false);
        }
      });
  }
}
