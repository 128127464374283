import { Injectable } from '@angular/core';
import { I18n } from '@aws-amplify/core';
import { StorageService } from '../../core/services/storage.service';
import { TranslationEs } from '../translation/translation.es';
import { TranslationFr } from '../translation/translation.fr';

@Injectable()
export class TranslatorService {

  dict = {
    es: TranslationEs.dict,
    fr: TranslationFr.dict,
  };

  constructor(
    private readonly storage: StorageService,
  ) {
    I18n.putVocabularies(this.dict);
  }

  trans(stringToken: string): string {
    return I18n.get(stringToken);
  }

  getLanguage(): string {
    const companies = this.storage.getCompanies();
    if (!companies || companies.length === 0) {
      return 'es';
    }
    const language = companies[0].companyConfiguration.language;
    return language ? language : 'es';
  }

  setLanguage(language: string): void {
    if (language in this.dict) {
      I18n.setLanguage(language);
    } else {
      I18n.setLanguage('es');
    }
  }
}
