import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyUserEmployeeRoleInput } from 'src/app/core/models/companyuseremployeerole/companyUserEmployeeRole.input';
import { GetCompanyUserByEmployeeRoleInput } from 'src/app/core/models/employeeRole/getCompanyUserByEmployeeRole.input';
import { User } from 'src/app/core/models/user/User.model';
import { CompanyUserEmployeeRole } from '../../../../core/models/companyuseremployeerole/companyUserEmployeeRole.model';
import { EmployeeRole } from '../../../../core/models/employeeRole/employeeRole.model';
import { CreateUserInput } from '../../../../core/models/user/createUser.input';
import { EmployeeRoleService } from '../../../../core/services/employeerole.service';
import { UserProfileService } from '../../../../core/services/user.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { CompanyUserEmployeeRolesInput } from 'src/app/core/models/companyuseremployeerole/companyUserEmployeeRoles.input';
import { EventService } from 'src/app/core/services/event.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { UpdateUserInput } from 'src/app/core/models/user/userInput.input';
import { HttpHeaders, HttpRequest, HttpEventType, HttpClient } from '@angular/common/http';
import { CompanyGroupService } from '../../../../core/services/companyGroup.service';
import { environment } from 'src/environments/environment';
import { TranslatorService } from '../../../../shared/services/translator.service';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {

  @Input() user: User;
  @Input() companies: Company[];
  companyUserEmployeeRole: CompanyUserEmployeeRole[];
  employeeRoles: EmployeeRole[];
  uniqueEmployeeRoles: EmployeeRole[];
  selectedCompany: Company;
  selectedEmployeeRoles: EmployeeRole[];
  companyEmployeeRoleUserTemp: CompanyUserEmployeeRole[];
  userForm: CreateUserInput;
  subscribeTypes: SubscribeTypes = new SubscribeTypes();
  correctEmail = true;
  documentFile: File;
  urlAvatar = environment.storageBucket;
  imageView: string;

  constructor(
    private employeeRoleService: EmployeeRoleService,
    public readonly activeModal: NgbActiveModal,
    private alertService: AlertService,
    private userService: UserProfileService,
    private eventService: EventService,
    private companyGroupService: CompanyGroupService,
    private httpService: HttpClient,
    private translateService: TranslatorService,

  ) { }

  ngOnInit() {
    this.companyEmployeeRoleUserTemp = [];
    this.companyUserEmployeeRole = [];
    this.uniqueEmployeeRoles = [];
    if (this.user) {
      this.userForm = this.user;
    } else {
      this.userForm = new CreateUserInput();
    }
    const getCompanyUserByEmployeeRoleInput = new GetCompanyUserByEmployeeRoleInput();
    getCompanyUserByEmployeeRoleInput.companyIds = this.companies.map(company => company.id);
    this.employeeRoleService.getCompanyEmployeeRoleByUser$(getCompanyUserByEmployeeRoleInput).subscribe(data => {
      if (this.user) {
        this.companyUserEmployeeRole = data.map(item => item).filter(role => role.idUser.id === this.user.id);
      }
      this.employeeRoles = data.map(companyUserEmployeeRole => companyUserEmployeeRole.idEmployeeRole);
      this.employeeRoles.forEach(rol => {
        const filteredRoles = this.uniqueEmployeeRoles.filter(roles => {
          return roles.id === rol.id;
        });
        if (filteredRoles.length === 0) {
          this.uniqueEmployeeRoles.push(rol);
        }
      });
    });
  }

  addCompanyUserEmployeeRole() {
    if (this.validateUserAndCompany()) {
      return;
    }
    this.selectedEmployeeRoles.forEach(selectedEmployeeRole => {
      const companyUserEmployeeRoleInput = new CompanyUserEmployeeRole();
      companyUserEmployeeRoleInput.idEmployeeRole = selectedEmployeeRole;
      companyUserEmployeeRoleInput.idCompany = this.selectedCompany;
      companyUserEmployeeRoleInput.idUser = this.user;
      this.companyUserEmployeeRole.push(companyUserEmployeeRoleInput);
      this.companyEmployeeRoleUserTemp.push(companyUserEmployeeRoleInput);
    });
  }

  private validateUserAndCompany() {
    if (!this.selectedCompany || !this.selectedEmployeeRoles) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_rol_add'));
      return true;
    }
    return false;
  }

  deleteCompanyUserEmployeerole(companyUserEmployeeRole: CompanyUserEmployeeRole) {
    const companyUserEmployeeRoleInput = new CompanyUserEmployeeRoleInput(
      companyUserEmployeeRole.idCompany.id,
      companyUserEmployeeRole.idUser.id,
      companyUserEmployeeRole.idEmployeeRole.id
    );

    this.employeeRoleService.deleteCompanyUserEmployeeRole$(companyUserEmployeeRoleInput).subscribe(data => {
      if (data) {
        this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_info'));
        this.eventService.broadcast(this.subscribeTypes.USER_LOGGED);
        this.companyUserEmployeeRole = this.companyUserEmployeeRole.filter(companyEmployeeRole => {
          if (
            companyEmployeeRole.idCompany.id === companyUserEmployeeRoleInput.idCompany &&
            companyEmployeeRole.idEmployeeRole.id === companyUserEmployeeRoleInput.idEmployeeRole &&
            companyEmployeeRole.idUser.id === companyUserEmployeeRoleInput.idUser
          ) {
            return false;
          } else {
            return true;
          }
        });
      } else {
        this.alertService.show('error',this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_info'));
      }
    });
  }

  save() {
    if (this.validateCreateCompanyUserEmployeeRole()) {
      return;
    }
    if (!this.user) {
      const user = new CreateUserInput();
      user.name = this.userForm.name;
      user.lastName = this.userForm.lastName;
      user.email = this.userForm.email;
      user.contactPhone = this.userForm.contactPhone;
      if (this.userForm.avatar) {
        user.avatar = this.userForm.avatar;
      } else {
        user.avatar = '';
      }
      user.status = this.userForm.status;
      user.roles = this.userForm.roles;
      user.color = '0,0,0';
      this.userService.createUserMutation$(user).subscribe(data => {
        this.user = new User();
        this.user = data;
        this.companyEmployeeRoleUserTemp.forEach(companyUserRole => {
          companyUserRole.idUser = this.user;
        });
        this.createCompanyEmployeeRoles();
      });
    } else {
      const user = new UpdateUserInput();
      user.id = this.user.id;
      user.name = this.userForm.name;
      user.lastName = this.userForm.lastName;
      user.contactEmail = this.userForm.email;
      user.contactPhone = this.userForm.contactPhone;
      if (this.userForm.avatar) {
        user.avatar = this.userForm.avatar;
      }
      user.status = this.userForm.status;
      user.roles = this.userForm.roles;

      this.userService.updateUserMutation$(user).subscribe(data => {
        this.companyEmployeeRoleUserTemp.forEach(companyUserRole => {
          companyUserRole.idUser = this.user;
        });
        this.createCompanyEmployeeRoles();
      });
    }
  }

  private createCompanyEmployeeRoles() {
    this.employeeRoleService.createCompanyUserEmployeeRole$(this.createCreateCompanyUserEmployeeRoleInput()).subscribe(data => {
      if (data) {
        this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_info'));
        this.eventService.broadcast(this.subscribeTypes.USER_CREATED);
        this.companyEmployeeRoleUserTemp = [];
      } else {
        this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_info'));
      }
    });
  }

  private createCreateCompanyUserEmployeeRoleInput(): CompanyUserEmployeeRolesInput {
    const companyUserEmployeeRoleInput = new CompanyUserEmployeeRolesInput();
    companyUserEmployeeRoleInput.companyUserEmployeeRoles = this.companyEmployeeRoleUserTemp
      .map(companyEmployeeRoleUser => {
        return new CompanyUserEmployeeRoleInput(
          companyEmployeeRoleUser.idCompany.id,
          companyEmployeeRoleUser.idUser.id,
          companyEmployeeRoleUser.idEmployeeRole.id
        );
      });

    return companyUserEmployeeRoleInput;
  }

  private validateCreateCompanyUserEmployeeRole() {
    let validate = false;
    if (this.companyEmployeeRoleUserTemp.length === 0 && this.companyUserEmployeeRole.length === 0) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_user_add'));
      validate = true;
    }
    if (!this.userForm.name) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_name_add'));
      validate = true;
    }
    if (!this.userForm.lastName) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_surname_add'));
      validate = true;
    }
    if (!this.userForm.email) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_email_add'));
      validate = true;
    }
    if (!this.userForm.email) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_name'));
      validate = true;
    }
    if (!this.correctEmail) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('configuration.email_err'));
      validate = true;
    }
    return validate;
  }

  onChange(event) {
    this.userForm.status = event;
  }

  checkEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.correctEmail = re.test(email);
  }

  undo() {
    const getCompanyUserByEmployeeRoleInput = new GetCompanyUserByEmployeeRoleInput();
    getCompanyUserByEmployeeRoleInput.companyIds = this.companies.map(company => company.id);
    this.employeeRoleService.getCompanyEmployeeRoleByUser$(getCompanyUserByEmployeeRoleInput).subscribe(data => {
      if (this.user) {
        this.companyUserEmployeeRole = data.map(item => item).filter(role => role.idUser.id === this.user.id);
      }
    });
  }

  fileInputChange(event) {
    this.documentFile = event.target.files[0];
    this.userForm.avatar = this.documentFile.name;
  }

  avatarUpload() {
    if (!this.documentFile) {
      this.save();
      return;
    }
    this.companyGroupService.getCompanyGroupByCompany$(this.companies[0].id).subscribe(data => {
      const documentName = 'user/avatar/' + data.id + '/' + (this.documentFile.name);
      this.userService.getUserAvatarUploadUrl$(documentName, this.documentFile.type).subscribe(uploadUrl => {
        const headers = new HttpHeaders({ 'Content-Type': this.documentFile.type });
        const req = new HttpRequest('PUT', uploadUrl, this.documentFile, {
          headers: headers,
        });
        this.httpService.request(req).subscribe(res => {
          if (res.type === HttpEventType.Response) {
            if (!res.type || (res.type === 4 && res.ok === false)) {
              this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.upload_avatar'));
              return;
            }
            this.userForm.avatar = documentName;
            this.save();
          }
        });
      });
    });
  }


}