import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { GetTaskInputDto } from 'src/app/core/models/task/get-task-input.dto';
import { environment } from '../../../../environments/environment';
import { TaskDto } from '../../../core/models/task/task.dto';
import { UpdateTaskInputDto } from '../../../core/models/task/update-task-input.dto';
import { User } from '../../../core/models/user/User.model';
import { EventService } from '../../../core/services/event.service';
import { TaskService } from '../../../core/services/task.service';
import { UserProfileService } from '../../../core/services/user.service';
import { AlertService } from '../../../shared/services/alert.service';
import { TaskStatusTittleResolver } from '../../../shared/services/task-status-tittle.resolver';
import { TaskThermometerResolver } from '../../../shared/services/task-thermometer.resolver';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
    styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {

    taskId: string;
    task: TaskDto;
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    locale = 'es';
    locales = listLocales();
    dateStart: Date;
    dateEnd: Date;
    dateStartEdit: Date;
    dateEndEdit: Date;
    status: string;
    statusColor: string;
    editTittle = false;
    editDescription = false;
    editUserAsigned = false;
    editDateStart = false;
    editDateEnd = false;
    updateTaskInput = new UpdateTaskInputDto();
    users;
    companyId: string;
    selectedUser: User;
    editIcon = [];
    tittleError = false;
    thermometerStatus: string;
    thermometerType: string;
    thermometerTooltip: string;

    constructor(
        private eventService: EventService,
        private activatedRoute: ActivatedRoute,
        private localeService: BsLocaleService,
        private taskService: TaskService,
        private alertService: AlertService,
        private taskStatusTittleResolver: TaskStatusTittleResolver,
        private userService: UserProfileService,
        private taskThermometerResolver: TaskThermometerResolver,
        private translateService: TranslatorService,

    ) {
        this.localeService.use(this.locale);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD/MM/YYYY HH:mm' });
    }

    ngOnInit() {
        this.eventService.broadcast('changePageHeading', this.translateService.trans('task.modal.tittle'));
        this.activatedRoute.queryParams.subscribe(params => {
            this.taskId = params.taskId;
            this.companyId = params.companyId;
            this.reloadTask();
        });
    }

    private reloadTask() {
        const getTaskInput = new GetTaskInputDto();
        getTaskInput.taskId = this.taskId;
        this.taskService.getTask$(getTaskInput).subscribe(data => {
            this.task = data.task;
            this.dateStart = new Date(this.task.dateStart);
            this.setDefaultDatePicker();
            this.status = this.taskStatusTittleResolver.getTaskStatusTittle(this.task.status);
            this.statusColor = this.taskStatusTittleResolver.getTaskStatusColor(this.task.status);
            this.thermometerStatus = this.taskThermometerResolver.getThermometerStatus(this.task.dueDate);
            this.thermometerType = this.taskThermometerResolver.getThermometerType(this.task.dueDate);
            this.thermometerTooltip = this.taskThermometerResolver.getRestDays(this.task.dueDate);
            this.setUpdateTaskInput();
        });
    }

    setUpdateTaskInput() {
        this.updateTaskInput.name = this.task.name;
        this.updateTaskInput.description = this.task.description;
    }

    private setDefaultDatePicker() {
        this.dateEnd = new Date(this.task.dateEnd);
        if (!this.task.dateStart) {
            this.dateStartEdit = new Date();
        } else {
            this.dateStartEdit = this.dateStart;
        }
        if (!this.task.dateEnd) {
            this.dateEndEdit = new Date();
        } else {
            this.dateEndEdit = this.dateEnd;
        }
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }

    setDateStart(dateStart) {
        this.updateTaskInput.dateStart = new Date(dateStart);
    }

    setDateEnd(dateEnd) {
        this.updateTaskInput.dateEnd = new Date(dateEnd);
    }

    alertUpdated(taskUpdated: boolean) {
        if (taskUpdated) {
            this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('task.updated.succ_info'));
            this.reloadTask();
        } else {
            this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('task.updated.err_info'));
        }
    }
    private getUsers() {
        this.userService.usersByCompany$(this.companyId).subscribe(users => {
            this.users = users;
            this.editUserAsigned = true;
        });
    }
    getEditUsers() {
        this.getUsers();
    }

    save() {
        if (this.validateForm()) {
            return;
        }
        this.updateTaskInput.id = this.task.id;
        if (this.selectedUser) {
            this.updateTaskInput.userId = this.selectedUser.id;
        }
        this.editTittle = false;
        this.editDescription = false;
        this.editUserAsigned = false;
        this.editDateStart = false;
        this.editDateEnd = false;
        this.taskService.updateTask$(this.updateTaskInput).subscribe(result => {
            this.alertUpdated(result.result);
        });
    }

    private validateForm() {
        if (!this.task.name) {
            if (this.updateTaskInput.name === '' || !this.updateTaskInput.name) {
                this.tittleError = true;
            }
        }
        return this.tittleError;
    }

    mouseEnter(item: string) {
        this.editIcon[item] = true;
    }

    mouseLeave(item: string) {
        this.editIcon[item] = false;
    }
}
