import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { GetTaskCommentsInputDto } from '../models/task-comment/get-task-comments-input.dto';
import { GetTaskCommentsResponseDto } from '../models/task-comment/get-task-comments-response.dto';
import { getTaskComments } from '../../graphql/operations/task-comment/queries';
import { CreateTaskCommentInputDto } from '../models/task-comment/create-task-comment-input.dto';
import { createTaskComment, deleteTaskComment } from '../../graphql/operations/task-comment/mutations';
import { CreateTaskCommentResponseDto } from '../models/task-comment/create-task-comment-response.dto';
import { DeleteTaskCommentInputDto } from '../models/task-comment/delete-task-comment-input.dto';
import { DeleteTaskCommentResponseDto } from '../models/task-comment/delete-task-comment-response.dto';

@Injectable({ providedIn: 'root' })
export class TaskCommentService {
    constructor(
        private readonly apollo: Apollo
    ) {
    }

    getTaskComments$(getTaskCommentsInput: GetTaskCommentsInputDto) {
        return this.apollo
            .query({
                query: getTaskComments,
                variables: {
                    getTaskCommentsInput
                }
            }).pipe(map(result => {
                return new GetTaskCommentsResponseDto(result.data['getTaskComments'].taskComments);
            }));
    }

    createTaskComment$(createTaskCommentInput: CreateTaskCommentInputDto) {
        return this.apollo
            .mutate({
                mutation: createTaskComment,
                variables: {
                    createTaskCommentInput
                }
            }).pipe(map(result => {
                return new CreateTaskCommentResponseDto(result.data['createTaskComment'].result);
            }));
    }

    deleteTaskComment$(deleteTaskCommentInput: DeleteTaskCommentInputDto) {
        return this.apollo
            .mutate({
                mutation: deleteTaskComment,
                variables: {
                    deleteTaskCommentInput
                }
            }).pipe(map(result => {
                return new DeleteTaskCommentResponseDto(result.data['deleteTaskComment'].result);
            }));
    }

}

