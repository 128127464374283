import { Pipe, PipeTransform } from '@angular/core';
import { TranslatorService } from '../services/translator.service';

@Pipe({ name: 'Trans' })
export class TransPipe implements PipeTransform {

  constructor(
    private readonly translate: TranslatorService
  ) {
  }

  transform(stringForTranslate: string, parameters?: object): string {
    let translatedstring = this.translate.trans(stringForTranslate);
    if (parameters && Object.keys(parameters).length > 0) {

      for (const paramToken in parameters) {

        if (parameters[paramToken]) {

          translatedstring = translatedstring.replace(paramToken, parameters[paramToken]);
        }
      }
    }

    return translatedstring;

  }
}
