import { TargetEspecifications } from '../targetEspecifications/targetEspecifications.model';
import { TargetEspecificationsInput } from '../targetEspecifications/targetEspecifications.input';
import { CreateTargetEspecificationsFormDTO } from './createTargetEspecificationsForm.dto';

export class CreateTargetFormDTO {

    name: string;
    targetEspecifications: CreateTargetEspecificationsFormDTO[];
    weighing: number;

    constructor(name: string, targetEspecifications: CreateTargetEspecificationsFormDTO[], weighing: number) {
        this.name = name;
        this.targetEspecifications = targetEspecifications;
        this.weighing = weighing;
    }
}
