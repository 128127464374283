import { Injectable } from '@angular/core';
import { differenceInMilliseconds } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class ScheduleTimeCalculator {

  getTotalHoursFromSchedule(data) {
    let totalHours = 0;
    let varCount = 0;
    data.door.forEach((element, index) => {
      if (element.type === false) {
        try {
          totalHours += differenceInMilliseconds(new Date(element.eventDate), new Date(data.door[varCount].eventDate));
          varCount = varCount + 2;
        } catch (error) {
          console.log("Deberias hablar con el administrador, algun fichaje tiene varias salidas sin controlar.")
        }
      }
    });
    return totalHours;
  }

  getTotalHours(scheduleTotalDate) {
    const ms = scheduleTotalDate % 1000;
    scheduleTotalDate = (scheduleTotalDate - ms) / 1000;
    const secs = scheduleTotalDate % 60;
    scheduleTotalDate = (scheduleTotalDate - secs) / 60;
    const mins = scheduleTotalDate % 60;
    const hrs = (scheduleTotalDate - mins) / 60;

    return hrs.toString().padStart(2, '0') + ':' + mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0');
  }

  getMilisecondsOfTimeString(time: string) {
    const a = time.split(':');

    const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

    return seconds * 1000;
  }
}
