import gql from 'graphql-tag';

export const getSheduleByDay = gql`
    query getSignWorkByUserLoggedByDate($dateDay: DateTime!,$userId: String!) {
        getSignWorkByUserLoggedByDate(dateDay: $dateDay, userId: $userId) {
            signWork {
                id,
                date,
                signature,
                door{
                    id,
                    eventDate,
                    type,
                    signature,
                    comment
                }
            },
            date
        }
    }`;

export const getYesterdayShedule = gql`
query getYesterdayScheduleControl($userId: String!) {
    getYesterdayScheduleControl(userId: $userId) {
        signWork {
            id,
            date,
            signature,
            door{
                id,
                eventDate,
                type,
                signature,
                comment
            }
        },
        date
    }
}`;

export const getSheduleByCompany = gql`
    query getSignWorkByCompany($getSignWorkByCompanyInput: GetSignWorkByCompanyInput!) {
        getSignWorkByCompany(getSignWorkByCompanyInput: $getSignWorkByCompanyInput) {
            signWorks {
                id,
                date,
                signature,
                user{
                    id,
                    name,
                    lastName,
                    avatar,
                    companyUserEmployeeRoles{
                        idCompany{
                            name
                        }
                    }
                },
                door{
                    id,
                    eventDate,
                    type,
                    signature,
                    latitude,
                    longitude,
                    comment
                }
            },
            totalData
        }
    }`;

export const getSheduleByCompanyToExport = gql`
    query getSignWorkByCompanyToExport($getSignWorkByCompanyInput: GetSignWorkByCompanyInput!) {
        getSignWorkByCompanyToExport(getSignWorkByCompanyInput: $getSignWorkByCompanyInput) {
            userName,
            dateDay,
            totalTime,
            door {
                eventDate,
                signature,
                comment
            },
            userId,
            comment
        }
    }`;

export const getSheduleByCompanyToExportNoSignature = gql`
    query getSignWorkByCompanyToExport($getSignWorkByCompanyInput: GetSignWorkByCompanyInput!) {
        getSignWorkByCompanyToExport(getSignWorkByCompanyInput: $getSignWorkByCompanyInput) {
            userName,
            dateDay,
            totalTime,
            userId,
            comment
        }
    }`;

export const isWorking = gql`
query isWorking($dateDay: DateTime!,$userId: String!) {
    isWorking(dateDay: $dateDay, userId: $userId)
}`;


