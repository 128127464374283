import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/core/models/user/User.model';
import { EventService } from 'src/app/core/services/event.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { UserFilterService } from 'src/app/shared/services/userFilter.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { Company } from '../../core/models/company/company.model';
import { StorageService } from '../../core/services/storage.service';
import { TranslatorService } from '../../shared/services/translator.service';

@Component({
    selector: 'app-agents',
    templateUrl: './agents.component.html',
    styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit, OnDestroy {

    agentsSubcription: Subscription;
    activityCreatedSubscription: Subscription;
    userSelectedSubscription: Subscription;

    Arr = Array;
    num = 3;
    loading = true;

    agents: Array<User>;
    userSelected: User;
    currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    statisticsBoard = false;


    constructor(
        private userService: UserProfileService,
        private eventService: EventService,
        private readonly storageService: StorageService,
        private userFilterService: UserFilterService,
        private transService: TranslatorService,
    ) {
    }

    ngOnInit() {
        this.eventService.subscribe('changePageHeading', data => {
            this.loading = true;
            if (data === this.transService.trans('dashboard.admin.statistic')) {
                this.statisticsBoard = true;
            }
        });
        this.agentsSubcription = this.eventService.subscribe(this.subscribeTypes.COMPANIES, selectedCompanies => {
            this.loading = true;
            this.getAgents(selectedCompanies);
        });

        this.activityCreatedSubscription = this.eventService.subscribe(this.subscribeTypes.ACTIVITY_CREATED, data => {
            this.loading = true;
            this.getAgents(this.storageService.getCompanies());
        }
        );
        this.activityCreatedSubscription = this.eventService.subscribe(this.subscribeTypes.ACTIVITY_DELETED, data => {
            this.loading = true;
            this.getAgents(this.storageService.getCompanies());
        }
        );

        this.userSelectedSubscription = this.eventService.subscribe(this.subscribeTypes.USER_SELECTED, userSelected => {
            this.userSelected = userSelected;
        });

        if (this.storageService.getCompanies()) {

            this.getAgents([this.storageService.getCompanies()[0]]);
        }
    }
    ngOnDestroy() {
        this.agentsSubcription.unsubscribe();
        this.activityCreatedSubscription.unsubscribe();
    }

    private getAgents(selectedCompanies: Company[]) {
        this.agents = [];

        const subscriptions: Array<Observable<User[]>> = [];
        selectedCompanies
            .map(company => company.id)
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach(companyId => {
                subscriptions.push(
                    this.userService.agentCard$(companyId).pipe(map(response => response.users))
                );
            });

        forkJoin(subscriptions)
            .subscribe(agentsCard => {
                const agents = [].concat(...agentsCard);
                this.agents = this.userFilterService.filterAgentsByRole(agents);
                this.loading = false;
            });
    }
}
