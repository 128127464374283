import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { FeatureGuard } from './core/guards/feature.guard';
import { PageViewGuard } from './core/guards/pageView.guard';
import { LayoutComponent } from './layouts/layout.component';



const routes: Routes = [
    { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
    { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard, PageViewGuard] },
    {
        path: 'comercial-activity',
        loadChildren: () => import('./pages/comercial-activity/comercial-activity.module').then(m => m.ComercialActivityModule),
        canActivate: [FeatureGuard]
    },
    {
        path: 'schedule',
        loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.ScheduleModule),
        canActivate: [FeatureGuard]
    },
    {
        path: 'documents',
        loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [FeatureGuard]
    },
    {
        path: 'task',
        loadChildren: () => import('./pages/task/task.module').then(m => m.TaskModule),
        canActivate: [FeatureGuard]
    },
    {
        path: 'refillable-documents',
        loadChildren: () => import('./pages/refillable-document/refillable-document.module').then(m => m.RefillableDocumentsModule),
        canActivate: [FeatureGuard]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
