import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Company } from 'src/app/core/models/company/company.model';
import { UploadDocumentComponent } from 'src/app/shared-component/document/upload-document/upload-document.component';
import { Document } from '../../core/models/document/document.model';
import { DocumentService } from '../../core/services/document.service';
import { EventService } from '../../core/services/event.service';
import { StorageService } from '../../core/services/storage.service';
import { AlertService } from '../../shared/services/alert.service';
import { SubscribeTypes } from '../../shared/types/subcribes.types';
import { AuthenticationService } from '../../core/services/auth.service';
import { TranslatorService } from '../../shared/services/translator.service';

@Component({
  selector: 'app-documents-store',
  templateUrl: './documents-store.component.html',
  styleUrls: ['./documents-store.component.scss']
})
export class DocumentsStoreComponent implements OnInit {

  documents: Document[];
  companies: Company[];

  constructor(
    private documentService: DocumentService,
    private storageService: StorageService,
    private eventService: EventService,
    private modalService: NgbModal,
    private alertService: AlertService,
    readonly authService: AuthenticationService,
    private translateService: TranslatorService,
  ) { }

  ngOnInit() {
    this.eventService.broadcast('changePageHeading', this.translateService.trans('document.list'));
    this.documents = [];
    this.companies = this.storageService.getCompanies();
    this.getDocumentList(this.companies);
    this.eventService.subscribe(new SubscribeTypes().DOCUMENT_CREATED, data => {
      this.getDocumentList(this.companies);
    });
  }

  private getDocumentList(companies: Company[]) {
    this.documents = [];
    companies.forEach(company => {
      this.documentService.documentList$(company.id).subscribe(data => {
        this.documents = this.documents.concat(data.document).sort();
        this.documents.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      });
    });
  }

  showDocumentModal() {
    const documentModal = this.modalService.open(UploadDocumentComponent, { size: 'lg', backdrop: 'static' });
  }

  deleteDocument(documentId: string) {
    this.alertService.showConfirm(this.translateService.trans('swag.sure'), this.translateService.trans('swag.cant_revert')).then(result => {
      if (!result.value) {
        return;
      }
      this.documentService.deleteDocument$(documentId).subscribe(data => {
        if (data) {
          this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_delete'));
          this.getDocumentList(this.companies);
        } else {
          this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_delete'));
        }
      });
    });
  }

  downloadDocument(documentId: string) {
    this.documentService.getDocumentDownloadUrl$(documentId).subscribe(documentUrl => {
      window.open(documentUrl);
    });
  }

}
