import gql from 'graphql-tag';

export const updateTask = gql`
  mutation  updateTask($updateTaskInput: UpdateTaskInput!) {
    updateTask(updateTaskInput: $updateTaskInput){
        result
    }
}`;

export const createTask = gql`
  mutation  createTask($createTaskInput: CreateTaskInput!) {
    createTask(createTaskInput: $createTaskInput){
        result
    }
}`;
