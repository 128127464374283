import { Component, OnInit } from '@angular/core';
import { endOfMonth, getTime, startOfMonth } from 'date-fns';
import { Echarts } from 'echarts';

@Component({
  selector: 'app-bundlecharts',
  templateUrl: './bundlecharts.component.html',
  styleUrls: ['./bundlecharts.component.scss']
})
export class BundlechartsComponent implements OnInit {

  chart: Echarts;
  dataMap: any = {};
  option4: any;
  option6: any;
  option8: any;

  option1 = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    // toolbox: {
    //   feature: {
    //     dataView: { show: true, readOnly: false },
    //     magicType: { show: true, type: ['line', 'bar'] },
    //     restore: { show: true },
    //     saveAsImage: { show: true }
    //   }
    // },
    legend: {
      data: ['Eficiencia', 'Completado', 'Actividades']
    },
    xAxis: [
      {
        type: 'category',
        data: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Porcentaje',
        min: 0,
        max: 250,
        interval: 50,
        axisLabel: {
          formatter: '{value} %'
        }
      },
      {
        type: 'value',
        name: 'Total Actividades',
        min: 0,
        max: 200,
        interval: 20,
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: [
      {
        name: 'Eficiencia',
        type: 'bar',
        data: [80, 50, 70, 60, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
      },
      {
        name: 'Completado',
        type: 'bar',
        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7]
      },
      {
        name: 'Actividades',
        type: 'line',
        yAxisIndex: 1,
        data: [50, 30, 15, 150, 80, 30, 100, 60, 23.0, 50, 35, 99]
      }
    ]
  };

  option2 = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    // toolbox: {
    //   feature: {
    //     dataView: { show: true, readOnly: false },
    //     magicType: { show: true, type: ['line', 'bar'] },
    //     restore: { show: true },
    //     saveAsImage: { show: true }
    //   }
    // },
    legend: {
      data: ['Eficiencia', 'Completado']
    },
    xAxis: [
      {
        type: 'category',
        data: ['01-01-2020', '02-01-2020', '03-01-2020', '04-01-2020', '05-01-2020', '06-01-2020', '07-01-2020', '08-01-2020', '09-01-2020', '10-01-2020', '11-01-2020', '12-01-2020', '13-01-2020', '14-01-2020', '15-01-2020', '16-01-2020', '17-01-2020', '18-01-2020', '19-01-2020', '20-01-2020', '21-01-2020', '22-01-2020', '23-01-2020', '24-01-2020', '25-01-2020', '26-01-2020', '27-01-2020', '28-01-2020', '29-01-2020', '30-01-2020'],
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Porcentaje',
        min: 0,
        max: 250,
        interval: 50,
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Eficiencia',
        type: 'line',
        data: [80, 50, 70, 60, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3, 80, 50, 70, 60, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3, 80, 50, 70, 60, 76.7, 135.6]
      },
      {
        name: 'Completado',
        type: 'bar',
        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7,]
      }
    ]
  };

  option3 = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    // toolbox: {
    //   feature: {
    //     dataView: { show: true, readOnly: false },
    //     magicType: { show: true, type: ['line', 'bar'] },
    //     restore: { show: true },
    //     saveAsImage: { show: true }
    //   }
    // },
    legend: {
      data: ['Eficiencia', 'Completado']
    },
    xAxis: [
      {
        type: 'category',
        data: ['Contactos Farming', 'Visita a Negocios', 'Leads propietarios', 'Visitas de Captacion', 'Firma exclusiva', 'Firma sin Exclusiva', 'Publicaciones', 'Seguimiento Propietarios', 'Despublicados', 'Repricing', 'Seguimiento Compradores', 'Leads Compradores'],
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Porcentaje',
        min: 0,
        max: 200,
        interval: 30,
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Eficiencia',
        type: 'line',
        data: [80, 50, 70, 60, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3, 80]
      },
      {
        name: 'Completado',
        type: 'bar',
        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 2.6, 5.9, 80]
      }
    ]
  };

  //grafica tocha

  dataFormatter(obj) {
    var pList = ['Antonio Exposito', 'Cristobal Robles', 'Fran Exposito', 'Arturo Alameda', 'Antonio Rus', 'Carmen Ruiz', 'Macarena Poyatos', 'Lorenzo Perea'];
    var temp;
    for (var year = 2002; year <= 2011; year++) {
      var max = 0;
      var sum = 0;
      temp = obj[year];
      for (var i = 0, l = temp.length; i < l; i++) {
        max = Math.max(max, temp[i]);
        sum += temp[i];
        obj[year][i] = {
          name: pList[i],
          value: temp[i]
        };
      }
      obj[year + 'max'] = Math.floor(max / 100) * 100;
      obj[year + 'sum'] = sum;
    }
    return obj;
  }



  constructor() {

    this.dataMap.dataGDP = this.dataFormatter({ //max : 60000,
      2011: [151.93, 137.28, 215.76, 137.55, 149.88, 226.7, 108.83, 122, 191.69, 410.27, 328.85, 153.65, 1756, 117.82, 451.85, 261.03, 132.26, 196.56, 532.28, 117.87, 25.66, 11.37, 210.68, 51.84, 893.12, 505.83, 112.3, 520.37, 1670.44, 2102.21, 610.05],
      2010: [141.58, 92.46, 294.26, 92.86, 112, 187.27, 87.58, 108.6, 175.98, 415.48, 272.31, 159.33, 137.12, 94, 169.92, 92.36, 159.61, 137.96, 463.06, 95.85, 24.5, 75.58, 185.48, 46.16, 24.18, 507.46, 123.48, 20.75, 13.43, 89.65, 537.47],
      2009: [12153.03, 7521.85, 17235.48, 7358.31, 9740.25, 15212.49, 7278.75, 8587, 15046.45, 34457.3, 22990.35, 10062.82, 12236.53, 7655.18, 33896.65, 19480.46, 12961.1, 13059.69, 39482.56, 7759.16, 1654.21, 6530.01, 14151.28, 3912.68, 6169.75, 441.36, 8169.8, 3387.56, 1081.27, 1353.31, 4277.05],
      2008: [11115, 6719.01, 16011.97, 7315.4, 8496.2, 13668.58, 6426.1, 8314.37, 14069.87, 30981.98, 21462.69, 8851.66, 10823.01, 6971.05, 30933.28, 18018.53, 11328.92, 11555, 36796.71, 7021, 1503.06, 5793.66, 12601.23, 3561.56, 5692.12, 394.85, 7314.58, 3166.82, 1018.62, 1203.92, 4183.21],
      2007: [9846.81, 5252.76, 13607.32, 6024.45, 6423.18, 11164.3, 5284.69, 7104, 12494.01, 26018.48, 18753.73, 7360.92, 9248.53, 5800.25, 25776.91, 15012.46, 9333.4, 9439.6, 31777.01, 5823.41, 1254.17, 4676.13, 10562.39, 2884.11, 4772.52, 341.43, 5757.29, 2703.98, 797.35, 919.11, 3523.16],
      2006: [8117.78, 4462.74, 11467.6, 4878.61, 4944.25, 9304.52, 4275.12, 6211.8, 10572.24, 21742.05, 15718.47, 6112.5, 7583.85, 4820.53, 21900.19, 12362.79, 7617.47, 7688.67, 26587.76, 4746.16, 1065.67, 3907.23, 8690.24, 2338.98, 3988.14, 290.76, 4743.61, 2277.35, 648.5, 725.9, 3045.26],
      2005: [6969.52, 3905.64, 10012.11, 4230.53, 3905.03, 8047.26, 3620.27, 5513.7, 9247.66, 18598.69, 13417.68, 5350.17, 6554.69, 4056.76, 18366.87, 10587.42, 6590.19, 6596.1, 22557.37, 3984.1, 918.75, 3467.72, 7385.1, 2005.42, 3462.73, 248.8, 3933.72, 1933.98, 543.32, 612.61, 2604.19],
      2004: [6033.21, 3110.97, 8477.63, 3571.37, 3041.07, 6672, 3122.01, 4750.6, 8072.83, 15003.6, 11648.7, 4759.3, 5763.35, 3456.7, 15021.84, 8553.79, 5633.24, 5641.94, 18864.62, 3433.5, 819.66, 3034.58, 6379.63, 1677.8, 3081.91, 220.34, 3175.58, 1688.49, 466.1, 537.11, 2209.09],
      2003: [5007.21, 2578.03, 6921.29, 2855.23, 2388.38, 6002.54, 2662.08, 4057.4, 6694.23, 12442.87, 9705.02, 3923.11, 4983.67, 2807.41, 12078.15, 6867.7, 4757.45, 4659.99, 15844.64, 2821.11, 713.96, 2555.72, 5333.09, 1426.34, 2556.02, 185.09, 2587.72, 1399.83, 390.2, 445.36, 1886.35],
      2002: [4315, 2150.76, 6018.28, 2324.8, 1940.94, 5458.22, 2348.54, 3637.2, 5741.03, 10606.85, 8003.67, 3519.72, 4467.55, 2450.48, 10275.5, 6035.48, 4212.82, 4151.54, 13502.42, 2523.73, 642.73, 2232.86, 4725.01, 1243.43, 2312.82, 162.04, 2253.39, 1232.03, 340.65, 377.16, 1612.6]
    });
    this.dataMap.dataEstate = this.dataFormatter({ //max : 3600,
      2011: [1074.93, 411.46, 918.02, 224.91, 384.76, 876.12, 238.61, 492.1, 1019.68, 2747.89, 1677.13, 634.92, 911.16, 402.51, 1838.14, 987, 634.67, 518.04, 3321.31, 465.68, 208.71, 396.28, 620.62, 160.3, 222.31, 17.44, 398.03, 134.25, 29.05, 79.01, 176.22],
      2010: [1006.52, 377.59, 697.79, 192, 309.25, 733.37, 212.32, 391.89, 1002.5, 2600.95, 1618.17, 532.17, 679.03, 340.56, 1622.15, 773.23, 564.41, 464.21, 2813.95, 405.79, 188.33, 266.38, 558.56, 139.64, 223.45, 14.54, 315.95, 110.02, 25.41, 60.53, 143.44],
      2009: [1062.47, 308.73, 612.4, 173.31, 286.65, 605.27, 200.14, 301.18, 1237.56, 2025.39, 1316.84, 497.94, 656.61, 305.9, 1329.59, 622.98, 546.11, 400.11, 2470.63, 348.98, 121.76, 229.09, 548.14, 136.15, 205.14, 13.28, 239.92, 101.37, 23.05, 47.56, 115.23],
      2008: [844.59, 227.88, 513.81, 166.04, 273.3, 500.81, 182.7, 244.47, 939.34, 1626.13, 1052.03, 431.27, 506.98, 281.96, 1104.95, 512.42, 526.88, 340.07, 2057.45, 282.96, 95.6, 191.21, 453.63, 104.81, 195.48, 15.08, 193.27, 93.8, 19.96, 38.85, 89.79],
      2007: [821.5, 183.44, 467.97, 134.12, 191.01, 410.43, 153.03, 225.81, 958.06, 1365.71, 981.42, 366.57, 511.5, 225.96, 953.69, 447.44, 409.65, 301.8, 2029.77, 239.45, 67.19, 196.06, 376.84, 93.19, 193.59, 13.24, 153.98, 83.52, 16.98, 29.49, 91.28],
      2006: [658.3, 156.64, 397.14, 117.01, 136.5, 318.54, 131.01, 194.7, 773.61, 1017.91, 794.41, 281.98, 435.22, 184.67, 786.51, 348.7, 294.73, 254.81, 1722.07, 192.2, 44.45, 158.2, 336.2, 80.24, 165.92, 11.92, 125.2, 73.21, 15.17, 25.53, 68.9],
      2005: [493.73, 122.67, 330.87, 106, 98.75, 256.77, 112.29, 163.34, 715.97, 799.73, 688.86, 231.66, 331.8, 171.88, 664.9, 298.19, 217.17, 215.63, 1430.37, 165.05, 38.2, 143.88, 286.23, 76.38, 148.69, 10.02, 108.62, 63.78, 14.1, 22.97, 55.79],
      2004: [436.11, 106.14, 231.08, 95.1, 73.81, 203.1, 97.93, 137.74, 666.3, 534.17, 587.83, 188.28, 248.44, 167.2, 473.27, 236.44, 204.8, 191.5, 1103.75, 122.52, 30.64, 129.12, 264.3, 68.3, 116.54, 5.8, 95.9, 56.84, 13, 20.78, 53.55],
      2003: [341.88, 92.31, 185.19, 78.73, 61.05, 188.49, 91.99, 127.2, 487.82, 447.47, 473.16, 162.63, 215.84, 138.02, 418.21, 217.58, 176.8, 186.49, 955.66, 100.93, 25.14, 113.69, 231.72, 59.86, 103.79, 4.35, 83.9, 48.09, 11.41, 16.85, 47.84],
      2002: [298.02, 73.04, 140.89, 65.83, 51.48, 130.94, 76.11, 118.7, 384.86, 371.09, 360.63, 139.18, 188.09, 125.27, 371.13, 199.31, 145.17, 165.29, 808.16, 82.83, 21.45, 90.48, 210.82, 53.49, 95.68, 3.42, 77.68, 41.52, 9.74, 13.46, 43.04]
    });

    this.dataMap.dataFinancial = this.dataFormatter({ //max : 3200,
      2011: [2215.41, 756.5, 746.01, 519.32, 447.46, 755.57, 207.65, 370.78, 2277.4, 2600.11, 2730.29, 503.85, 862.41, 357.44, 1640.41, 868.2, 674.57, 501.09, 2916.13, 445.37, 105.24, 704.66, 868.15, 297.27, 456.23, 31.7, 432.11, 145.05, 62.56, 134.18, 288.77],
      2010: [1863.61, 572.99, 615.42, 448.3, 346.44, 639.27, 190.12, 304.59, 1950.96, 2105.92, 2326.58, 396.17, 767.58, 241.49, 1361.45, 697.68, 561.27, 463.16, 2658.76, 384.53, 78.12, 496.56, 654.7, 231.51, 375.08, 27.08, 384.75, 100.54, 54.53, 97.87, 225.2],
      2009: [1603.63, 461.2, 525.67, 361.64, 291.1, 560.2, 180.83, 227.54, 1804.28, 1596.98, 1899.33, 359.6, 612.2, 165.1, 1044.9, 499.92, 479.11, 402.57, 2283.29, 336.82, 65.73, 389.97, 524.63, 194.44, 351.74, 23.17, 336.21, 88.27, 45.63, 75.54, 198.87],
      2008: [1519.19, 368.1, 420.74, 290.91, 219.09, 455.07, 147.24, 177.43, 1414.21, 1298.48, 1653.45, 313.81, 497.65, 130.57, 880.28, 413.83, 393.05, 334.32, 1972.4, 249.01, 47.33, 303.01, 411.14, 151.55, 277.66, 22.42, 287.16, 72.49, 36.54, 64.8, 171.97],
      2007: [1302.77, 288.17, 347.65, 218.73, 148.3, 386.34, 126.03, 155.48, 1209.08, 1054.25, 1251.43, 223.85, 385.84, 101.34, 734.9, 302.31, 337.27, 260.14, 1705.08, 190.73, 34.43, 247.46, 359.11, 122.25, 168.55, 11.51, 231.03, 61.6, 27.67, 51.05, 149.22],
      2006: [982.37, 186.87, 284.04, 169.63, 108.21, 303.41, 100.75, 74.17, 825.2, 653.25, 906.37, 166.01, 243.9, 79.75, 524.94, 219.72, 174.99, 204.72, 899.91, 129.14, 16.37, 213.7, 299.5, 89.43, 143.62, 6.44, 152.25, 50.51, 23.69, 36.99, 99.25],
      2005: [840.2, 147.4, 213.47, 135.07, 72.52, 232.85, 83.63, 35.03, 675.12, 492.4, 686.32, 127.05, 186.12, 69.55, 448.36, 181.74, 127.32, 162.37, 661.81, 91.93, 13.16, 185.18, 262.26, 73.67, 130.5, 7.57, 127.58, 44.73, 20.36, 32.25, 80.34],
      2004: [713.79, 136.97, 209.1, 110.29, 55.89, 188.04, 77.17, 32.2, 612.45, 440.5, 523.49, 94.1, 171, 65.1, 343.37, 170.82, 118.85, 118.64, 602.68, 74, 11.56, 162.38, 236.5, 60.3, 118.4, 5.4, 90.1, 42.99, 19, 27.92, 70.3],
      2003: [635.56, 112.79, 199.87, 118.48, 55.89, 145.38, 73.15, 32.2, 517.97, 392.11, 451.54, 87.45, 150.09, 64.31, 329.71, 165.11, 107.31, 99.35, 534.28, 61.59, 10.68, 147.04, 206.24, 48.01, 105.48, 4.74, 77.87, 42.31, 17.98, 24.8, 64.92],
      2002: [561.91, 76.86, 179.6, 124.1, 48.39, 137.18, 75.45, 31.6, 485.25, 368.86, 347.53, 81.85, 138.28, 76.51, 310.07, 158.77, 96.95, 92.43, 454.65, 35.86, 10.08, 134.52, 183.13, 41.45, 102.39, 2.81, 67.3, 42.08, 16.75, 21.45, 52.18]
    });
  }

  ngOnInit() {
    this.option4 = {
      baseOption: {
        timeline: {
          axisType: 'category',
          // realtime: false,
          // loop: false,
          autoPlay: true,
          // currentIndex: 2,
          playInterval: 1000,
          // controlStyle: {
          //     position: 'left'
          // },
          data: [
            '2002-01-01', '2003-01-01', '2004-01-01',
            {
              value: '2005-01-01',
              tooltip: {
                formatter: '{b} Grafica'
              },
              symbol: 'diamond',
              symbolSize: 16
            },
            '2006-01-01', '2007-01-01', '2008-01-01', '2009-01-01', '2010-01-01',
            {
              value: '2011-01-01',
              tooltip: {
                formatter: function (params) {
                  return params.name + 'Grafica';
                }
              },
              symbol: 'diamond',
              symbolSize: 18
            },
          ],
          label: {
            formatter: function (s) {
              return (new Date(s)).getFullYear();
            }
          }
        },
        title: {
          subtext: 'General'
        },
        tooltip: {
        },
        legend: {
          left: 'right',
          data: ['Eficiencia', 'Completado', 'Total'],
        },
        calculable: true,
        grid: {
          top: 80,
          bottom: 100,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true,
                formatter: function (params) {
                  return params.value.replace('\n', '');
                }
              }
            }
          }
        },
        xAxis: [
          {
            'type': 'category',
            'axisLabel': { 'interval': 0 },
            'data': [
              'Antonio Exposito', '\nCristobal Robles', 'Fran Exposito', '\nArturo Alameda', 'Antonio Rus', '\nCarmen Ruiz', 'Macarena Poyatos', '\nLorenzo Perea'
            ],
            splitLine: { show: false }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '% Completado'
          }
        ],
        series: [
          { name: 'Eficiencia', type: 'bar' },
          { name: 'Completado', type: 'bar' },
          { name: 'Total', type: 'bar' },
          {
            name: 'Grafico',
            type: 'pie',
            center: ['75%', '35%'],
            radius: '28%',
            z: 100
          }
        ]
      },
      options: [
        {
          title: { text: 'Resumen Enero' },
          series: [
            { data: this.dataMap.dataGDP['2002'] },
            { data: this.dataMap.dataFinancial['2002'] },
            { data: this.dataMap.dataEstate['2002'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2002sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2002sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2002sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Febrero' },
          series: [
            { data: this.dataMap.dataGDP['2003'] },
            { data: this.dataMap.dataFinancial['2003'] },
            { data: this.dataMap.dataEstate['2003'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2003sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2003sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2003sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Marzo' },
          series: [
            { data: this.dataMap.dataGDP['2004'] },
            { data: this.dataMap.dataFinancial['2004'] },
            { data: this.dataMap.dataEstate['2004'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2004sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2004sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2004sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Abril' },
          series: [
            { data: this.dataMap.dataGDP['2005'] },
            { data: this.dataMap.dataFinancial['2005'] },
            { data: this.dataMap.dataEstate['2005'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2005sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2005sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2005sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Mayo' },
          series: [
            { data: this.dataMap.dataGDP['2006'] },
            { data: this.dataMap.dataFinancial['2006'] },
            { data: this.dataMap.dataEstate['2006'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2006sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2006sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2006sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Junio' },
          series: [
            { data: this.dataMap.dataGDP['2007'] },
            { data: this.dataMap.dataFinancial['2007'] },
            { data: this.dataMap.dataEstate['2007'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2007sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2007sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2007sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Julio' },
          series: [
            { data: this.dataMap.dataGDP['2008'] },
            { data: this.dataMap.dataFinancial['2008'] },
            { data: this.dataMap.dataEstate['2008'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2008sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2008sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2008sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Agosto' },
          series: [
            { data: this.dataMap.dataGDP['2009'] },
            { data: this.dataMap.dataFinancial['2009'] },
            { data: this.dataMap.dataEstate['2009'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2009sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2009sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2009sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Septiembre' },
          series: [
            { data: this.dataMap.dataGDP['2010'] },
            { data: this.dataMap.dataFinancial['2010'] },
            { data: this.dataMap.dataEstate['2010'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2010sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2010sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2010sum'] }
              ]
            }
          ]
        },
        {
          title: { text: 'Resumen Octubre' },
          series: [
            { data: this.dataMap.dataGDP['2011'] },
            { data: this.dataMap.dataFinancial['2011'] },
            { data: this.dataMap.dataEstate['2011'] },
            {
              data: [
                { name: 'Eficiencia', value: this.dataMap.dataGDP['2011sum'] },
                { name: 'Completado', value: this.dataMap.dataFinancial['2011sum'] },
                { name: 'Total', value: this.dataMap.dataEstate['2011sum'] }
              ]
            }
          ]
        }
      ]
    };

    //Grafica para ver la eficiencia de un trabajador a lo en un mes por dias

    this.option6 = {
      title: {
        top: 30,
        left: 'center',
        text: 'Resultados 2020'
      },
      tooltip: {},
      visualMap: {
        min: 0,
        max: 10000,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        top: 65,
        textStyle: {
          color: '#000'
        }
      },
      calendar: {
        top: 120,
        left: 30,
        right: 30,
        cellSize: ['auto', 13],
        range: '2020',
        itemStyle: {
          borderWidth: 0.5
        },
        yearLabel: { show: false }
      },
      series: {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: this.getVirtulData(2020)
      }
    };
  }

  //Grafica staqueable por objetivos por trabajador y eficiencia
  option5 = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    legend: {
      data: ['Contactos farming', 'Visita a negocios', 'Leads Propietarios', 'Visitas de captacion', 'Firma Exclusiva']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: ['Antonio Exposito', 'Cristobal Robles', 'Fran Exposito', 'Arturo Alameda', 'Antonio Rus', 'Carmen Ruiz', 'Macarena Poyatos']
    },
    series: [
      {
        name: 'Contactos farming',
        type: 'bar',
        stack: '总量',
        label: {
          show: true,
          position: 'insideRight'
        },
        data: [20, 2, 30, 4, 3, 33, 10]
      },
      {
        name: 'Visita a negocios',
        type: 'bar',
        stack: '总量',
        label: {
          show: true,
          position: 'insideRight'
        },
        data: [12, 13, 10, 14, 10, 23, 21]
      },
      {
        name: 'Leads Propietarios',
        type: 'bar',
        stack: '总量',
        label: {
          show: true,
          position: 'insideRight'
        },
        data: [20, 12, 11, 24, 20, 30, 10]
      },
      {
        name: 'Visitas de captacion',
        type: 'bar',
        stack: '总量',
        label: {
          show: true,
          position: 'insideRight'
        },
        data: [10, 22, 21, 14, 10, 30, 10]
      },
      {
        name: 'Firma Exclusiva',
        type: 'bar',
        stack: '总量',
        label: {
          show: true,
          position: 'insideRight'
        },
        data: [20, 32, 11, 34, 30, 30, 20]
      }
    ]
  };

  //Grafica para ver la eficiencia de un trabajador a lo en un mes por dias
  getVirtulData(year) {
    year = year || '2020';
    let date = getTime(startOfMonth(new Date()));
    let end = getTime(endOfMonth(new Date()));
    let dayTime = 3600 * 24 * 1000;
    let data = [];
    for (let time = date; time < end; time += dayTime) {
      data.push([
        this.chart.format.formatTime('yyyy-MM-dd', time),
        Math.floor(Math.random() * 100)
      ]);
    }
    return data;
  }

  option7 = {
    title: {
      text: 'Eficiencia Objetivos'
    },
    tooltip: {},
    legend: {
      data: ['Eficiencia', 'Completado']
    },
    radar: {
      // shape: 'circle',
      name: {
        textStyle: {
          color: '#fff',
          backgroundColor: '#999',
          borderRadius: 3,
          padding: [3, 5]
        }
      },
      indicator: [
        { name: 'Contactos Farming', max: 100 },
        { name: 'Visita a Negocios', max: 100 },
        { name: 'Leads propietarios', max: 100 },
        { name: 'Visitas de captacion', max: 100 },
        { name: 'Firma Exclusiva', max: 100 },
        { name: 'Firma Sin Exclusiva', max: 100 },
        { name: 'Repricing', max: 100 },
        { name: 'Despublicados', max: 100 }
      ]
    },
    series: [{
      name: 'Eficiencia vs Complado',
      type: 'radar',
      // areaStyle: {normal: {}},
      data: [
        {
          value: [90, 30, 80, 30, 45, 15, 100, 30],
          name: 'Eficiencia'
        },
        {
          value: [100, 25, 110, 80, 90, 60, 90, 30],
          name: 'Completado'
        }
      ]
    }]
  };

  //Grafica para ver la evolucion de consecucion de los objetivos
  base = +new Date(1968, 9, 3);
  oneDay = 24 * 3600 * 1000;
  date = [];

  data = [Math.random() * 300];

  onChartInit(ec) {
    this.chart = ec;



    for (let i = 1; i < 1000; i++) {
      var now = new Date(this.base += this.oneDay);
      this.date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'));
      this.data.push(Math.round((Math.random() - 0.5) * 20 + this.data[i - 1]));
    }

    this.option8 = {
      tooltip: {
        trigger: 'axis',
        position: function (pt) {
          return [pt[0], '10%'];
        }
      },
      title: {
        left: 'center',
        text: 'Evolucion de los objetivos',
      },
      // toolbox: {
      //   feature: {
      //     dataZoom: {
      //       yAxisIndex: 'none'
      //     },
      //     restore: {},
      //     saveAsImage: {}
      //   }
      // },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.date
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%']
      },
      dataZoom: [{
        type: 'inside',
        start: 0,
        end: 10
      }, {
        start: 0,
        end: 10,
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '80%',
        handleStyle: {
          color: '#fff',
          shadowBlur: 3,
          shadowColor: 'rgba(0, 0, 0, 0.6)',
          shadowOffsetX: 2,
          shadowOffsetY: 2
        }
      }],
      series: [
        {
          name: 'Eficiencia',
          type: 'line',
          smooth: true,
          symbol: 'none',
          sampling: 'average',
          itemStyle: {
            color: 'rgb(255, 70, 131)'
          },
          data: this.data
        },
        {
          name: 'Completado',
          type: 'line',
          smooth: true,
          symbol: 'none',
          sampling: 'average',
          itemStyle: {
            color: 'rgb(255, 70, 321)'
          },
          data: this.data
        }
      ]
    };
  }
}
