
export class SaveRefillableDocumentInputDto {
    in = '';
    day = '';
    month = '';
    year = '';
    buyerName = '';
    buyerDni = '';
    buyerCity = '';
    buyerStreet = '';
    buyerNumber = '';
    buyerPC = '';
    sellerName = '';
    sellerDni = '';
    sellerCity = '';
    sellerStreet = '';
    sellerNumber = '';
    sellerPC = '';
    propertyCity = '';
    propertyStreet = '';
    propertyNumber = '';
    propertyPC = '';
    registryOne = '';
    registryNumber = '';
    registryTome = '';
    registryBook = '';
    registrySheet = '';
    propertyRegistry = '';
    contractDuration = '';
    extendMonths = '';
    priceWord = '';
    priceAmount = '';
    honoraryWords = '';
    honoraryAmount = '';

    marketerSign: string;
    sellerSign: string;

}
