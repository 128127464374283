export class CompanyUserEmployeeRoleInput {

    idCompany: string;
    idUser: string;
    idEmployeeRole: string;

    constructor(idCompany: string, idUser: string, idEmployeeRole: string) {
        this.idCompany = idCompany;
        this.idUser = idUser;
        this.idEmployeeRole = idEmployeeRole;

    }

}
