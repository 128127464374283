
export class GetUsersActivityAndTargetByCompanyInput {
    companyId: string;
    startDate: Date;
    finishDate: Date;

    constructor(companyId: string, startDate: Date, finishDate: Date) {
        this.companyId = companyId;
        this.startDate = startDate;
        this.finishDate = finishDate;
    }
}
