import gql from 'graphql-tag';

export const createDocument = gql`
    mutation  createDocument($documentInput: DocumentInput!) {
        createDocument(documentInput: $documentInput)
    }`;

export const deleteDocument = gql`
    mutation  deleteDocument($documentId: String!) {
        deleteDocument(documentId: $documentId)
    }`;
