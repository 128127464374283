import { ScheduleControl } from '../shedulecontrol/schedulecontrol.model';


export class ScheduleControlListResponse {
    scheduleControl: ScheduleControl[];
    totalData: number;

    constructor(scheduleControl: ScheduleControl[], totalData: number) {
        this.scheduleControl = scheduleControl;
        this.totalData = totalData;
    }
}
