import { TargetEspecifications } from '../targetEspecifications/targetEspecifications.model';

export class UpdateTargetEspecificationsResponse {
    targetsEspecifications: Array<TargetEspecifications>;

    constructor(updatedTargetEspecifications: Array<TargetEspecifications>) {
        this.targetsEspecifications = updatedTargetEspecifications;

    }
}
