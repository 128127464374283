import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../../shared/shared.module';
import { CreateTaskComponent } from './create-task/create-task.component';
import { TaskCommentComponent } from './task-comment/task-comment.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskRoutingModule } from './task.routing.module';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
    declarations: [
        TaskListComponent,
        CreateTaskComponent,
        TaskDetailComponent,
        TaskCommentComponent,
    ],
    imports: [
        TaskRoutingModule,
        CommonModule,
        SharedModule,
        DragDropModule,
        MatOptionModule,
        MatInputModule,
        MatCheckboxModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        FormsModule,
        UiSwitchModule,
    ]
})
export class TaskModule { }
