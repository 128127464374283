import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { RefillableDocumentInput } from '../../../core/models/refillable-document/dto/refillableDocument.input';
@Injectable()
export class SalesMandateValidator {

  constructor(
    private alertService: AlertService
  ) { }

  validationForm(document: RefillableDocumentInput) {

    let isValid = true;
    Object.values(document).forEach(props => {
      if (props === '') {
        isValid = false;
      }
    });
    return isValid;
  }
}
