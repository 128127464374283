import { Feature } from '../feature/feature.model';
import { LanguageType } from '../../../shared/types/language.type';
export class CompanyConfigurationResponse {
    id: string;
    onlyRankingCompany: boolean;
    features: Feature[];
    language: LanguageType;
    showCharts: boolean;

    constructor(id: string, onlyRankingCompany: boolean, features: Feature[], language: LanguageType) {
        this.id = id;
        this.onlyRankingCompany = onlyRankingCompany;
        this.features = features;
        this.showCharts = true;
        this.language = language;
    }
}
