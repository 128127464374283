import { Injectable } from '@angular/core';
import { TaskStatusEnum } from '../types/task-status.enum';

@Injectable({ providedIn: 'root' })


export class TaskStatusTittleResolver {
    status = [];
    color = [];
    constructor(
    ) {
        this.status[TaskStatusEnum.PENDING] = 'Pendiente';
        this.status[TaskStatusEnum.IN_PROGRESS] = 'En progreso';
        this.status[TaskStatusEnum.FINISHED] = 'Finalizada';

        this.status['Pendiente'] = TaskStatusEnum.PENDING;
        this.status['En progreso'] = TaskStatusEnum.IN_PROGRESS;
        this.status['Finalizada'] = TaskStatusEnum.FINISHED;

        this.color[TaskStatusEnum.PENDING] = 'info';
        this.color[TaskStatusEnum.IN_PROGRESS] = 'warning';
        this.color[TaskStatusEnum.FINISHED] = 'success';
    }

    getTaskStatusTittle(status: string) {
        if (this.status[status]) {
            return this.status[status];
        } else {
            return status;
        }
    }

    getDbTaskStatusTittle(status: string) {
        if (this.status[status]) {
            return this.status[status];
        } else {
            return status;
        }
    }

    getTaskStatusColor(status: string) {
        if (this.color[status]) {
            return this.color[status];
        } else {
            return 'info';
        }
    }
}
