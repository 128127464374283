import gql from 'graphql-tag';

export const getTaskComments = gql`
    query getTaskComments($getTaskCommentsInput: GetTaskCommentsInput!) {
        getTaskComments(getTaskCommentsInput: $getTaskCommentsInput) {
            taskComments {
            id,
            text,
            user{
                id,
                name,
                lastName,
                avatar
            },
            createdAt
            }
        }
    }`;
