import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { from } from 'rxjs';
import { concatAll } from 'rxjs/operators';
import { ActivityTargetsByUserInDateSelectedResponse } from 'src/app/core/models/activity/ActivityTargetsByUserInDateSelected.response';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyEmployeeRoleTargetTypes } from 'src/app/core/models/companyemployeeroletargettypes/companyemployeeroletargettypes.model';
import { User } from 'src/app/core/models/user/User.model';
import { CalculatorUtil } from 'src/app/shared/services/calculator-util.service';
import { SummaryExportService } from 'src/app/shared/services/comercial-activity-summary-export.service';
import { DateUtil } from 'src/app/shared/services/date-util.service';
import { environment } from 'src/environments/environment';
import { ActivityService } from '../../../core/services/activity.service';
import { EventService } from '../../../core/services/event.service';
import { UserProfileService } from '../../../core/services/user.service';
import { UserFilterService } from '../../../shared/services/userFilter.service';
import { TargetSummaryDto } from '../../../core/models/summary/target-summary.dto';
import { ComercialActivitySummaryDto } from '../../../core/models/summary/comercial-activities.dto';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ display: 'none', minHeight: '0' })),
      state('expanded', style({ display: 'block' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SummaryComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['user', 'activity', 'target', 'percent'];
  dataSource = new MatTableDataSource();

  selectCompany: Company[];
  companies: Company[];
  users: User[];
  checked = true;
  selectedUsers: User[];
  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;
  locale = 'es';
  locales = listLocales();
  dateRange: Date[];
  comercialActivities: ComercialActivitySummaryDto[] = [];
  activityTargetPercent: number;
  rowSkeleton = { 'border-radius': '0', height: '40px' };
  loadingStart: boolean;
  loading: boolean;
  urlAvatar = environment.storageBucket;
  defaultAvatar = environment.storageBucket + '/user/avatar/default.png';



  constructor(
    private userService: UserProfileService,
    private userFilterService: UserFilterService,
    private eventService: EventService,
    private localeService: BsLocaleService,
    private calculatorUtil: CalculatorUtil,
    private dateUtil: DateUtil,
    private activityService: ActivityService,
    private summaryExportService: SummaryExportService,
    private translateService: TranslatorService

  ) {
    this.dateRange = [startOfMonth(new Date()), endOfMonth(new Date())];
    if (new Date() < this.dateRange[1]) {
      this.dateRange[1] = new Date();
    }
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD-MM-YYYY' });
  }

  ngOnInit() {
    this.eventService.broadcast('changePageHeading', this.translateService.trans('summary.tittle'));
    this.dataSource = new MatTableDataSource(this.comercialActivities);
    this.dataSource.sort = this.sort;
    this.loadingStart = true;
  }


  getUsers() {
    const userObservables = [];
    this.selectCompany.forEach(companyId => {
      userObservables.push(this.userService.usersByCompany$(companyId.id));
    });

    let users = [];
    from(userObservables)
      .pipe(concatAll())
      .subscribe((companyUsers: any) => {
        users = [...users, ...companyUsers];
      }, () => { }, () => {
        this.users = this.userFilterService.filterUniqueUsers(users);
        this.selectedUsers = this.users;
        this.getStadistic();
      });
  }
  reloadComercialActivity() {
    this.loading = true;
    this.getStadistic();
  }

  onCompaniesLoaded(companies) {
    this.companies = companies;
    this.onSelectedCompany(companies);
    this.getUsers();
  }
  onSelectedCompany(companies) {
    this.selectCompany = companies;
  }

  onFormFieldChange(users: User[]) {
    this.selectedUsers = users;
  }

  onDateChange(date: Date) {
    this.dateRange[0] = startOfDay(date[0]);
    this.dateRange[1] = endOfDay(date[1]);
  }

  selectAll() {
    if (this.checked) {
      this.selectedUsers = this.users;
    } else {
      this.selectedUsers = [];
    }
  }

  getStadistic() {
    if (this.selectedUsers && this.selectCompany) {
      this.dateRange[0] = startOfDay(this.dateRange[0]);
      this.dateRange[1] = endOfDay(this.dateRange[1]);
      this.comercialActivities = [];
      this.selectCompany.forEach(company => {
        this.selectedUsers.forEach(selectedUser => {
          this.activityService.activityTargetsByUserInDateSelected$(company.id, selectedUser.id, this.dateRange).subscribe(
            (statistic: ActivityTargetsByUserInDateSelectedResponse) => {
              if (statistic.user.length === 0) {
                return;
              }
              statistic.user[0].companyUserEmployeeRoles.forEach(element => {
                let sumActivity: number;
                let sumTarget: number;
                let sumTotalActivity = 0;
                let sumTotalTarget = 0;
                const targetsSummary: TargetSummaryDto[] = [];
                element.idEmployeeRole.companyEmployeeRoleTargetTypes.forEach(target => {
                  if (target.idTarget.targetEspecifications.length === 0) {
                    return;
                  }
                  sumActivity = this.calculateSumActivity(target);
                  sumTarget = this.calculateSumTarget(target);

                  const businessDays = this.dateUtil.getDiffInBusinessDays(this.dateRange[0], this.dateRange[1]);
                  sumTarget = sumTarget * businessDays;
                  sumTotalActivity += sumActivity;
                  sumTotalTarget += sumTarget;
                  this.activityTargetPercent = (sumActivity / sumTarget) * 100;
                  this.activityTargetPercent = this.calculatorUtil.fixNanAndFinite(this.activityTargetPercent);
                  const targetSummary = new TargetSummaryDto();
                  targetSummary.target = target.idTarget;
                  targetSummary.sumActivity = parseFloat(sumActivity.toFixed(2));
                  targetSummary.sumTarget = parseFloat(sumTarget.toFixed(2));
                  targetSummary.percent = parseFloat(this.activityTargetPercent.toFixed(2));
                  targetsSummary.push(targetSummary);
                });
                if (targetsSummary.length > 0) {
                  const comercialActivity = new ComercialActivitySummaryDto();
                  comercialActivity.user = selectedUser;
                  comercialActivity.sumTotalActivity = sumTotalActivity;
                  comercialActivity.sumTotalTarget = sumTotalTarget;
                  comercialActivity.targets = targetsSummary;
                  comercialActivity.employeeRole = element.idEmployeeRole;
                  this.comercialActivities.push(comercialActivity);
                }
              });
              this.dataSource = new MatTableDataSource(this.comercialActivities);
            });
        });
      });
      this.loadingStart = false;
      this.loading = false;
    }
  }

  private calculateSumActivity(target: CompanyEmployeeRoleTargetTypes) {
    return target.idTarget.activitys
      .reduce((a2, currentValue) => {
        if (currentValue.id) {
          return a2 + currentValue.realAmount;
        } else {
          return a2;
        }
      }, 0);
  }

  private calculateSumTarget(target: CompanyEmployeeRoleTargetTypes) {
    return target.idTarget.targetEspecifications
      .reduce((a2, currentTarget) => {
        if (currentTarget) {
          return a2 + currentTarget.goal;
        } else {
          return a2;
        }
      }, 0);
  }

  exportToCsv() {
    this.summaryExportService.exportToCsv(this.comercialActivities, this.dateRange, this.selectCompany);
  }

  errorHandler(event) {
    event.target.src = this.defaultAvatar;
  }

}
