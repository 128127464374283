import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isSameDay, isToday } from 'date-fns';
import { jqxKnobComponent } from 'jqwidgets-ng/jqxknob';
import { UserLogged } from 'src/app/core/models/auth.models';
import { User } from 'src/app/core/models/user/User.model';
import { AuthenticationService } from '../../core/services/auth.service';
import { RolType } from '../../shared/types/rol.types';
import { AddActivityComponent } from '../add-activity/add-activity.component';

@Component({
  selector: 'app-knob-component',
  templateUrl: './knob-component.component.html',
  styleUrls: ['./knob-component.component.scss']
})
export class KnobComponentComponent implements OnInit {


  @Input() sumActivity: number;
  @Input() sumTarget: number;
  @Input() activityTarget: any;
  @Input() userSelected: User;
  @Input() dateRange: Date[];

  @ViewChild('myKnob', { static: false }) myKnob: jqxKnobComponent;

  activityAmmount: number;

  activity: any;
  targetId: any;
  dial: any;
  actualActivity: number;
  userLogged: UserLogged;
  arrayDates: string[];
  currentDate: Date = new Date();

  progressBar: any =
    {
      style: { fill: '#e30613' },
      background: { fill: '#eeeeee' },
      size: '30%',
      offset: '60%'
    };
  pointer: any =
    {
      style: { fill: '#000000' },
      type: 'line',
      size: '0%',
      offset: '60%',
      thickness: 20
    };
  isAdmin: boolean;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.userLogged = this.authService.getUser();
    this.activityAmmount = this.sumActivity;

    if (!isSameDay(this.dateRange[0], this.dateRange[1]) || this.dateRange[0] > this.currentDate) {
      this.isAdmin = this.authService.canShowElement(new RolType().ROLE_ADMIN, new RolType().ROLE_SUPERADMIN, new RolType().ROLE_COORDINATOR);
    }

    if (this.authService.canShowElement(new RolType().ROLE_USER)) {
      this.isAdmin = true;
      if (isToday(this.dateRange[0]) && isToday(this.dateRange[1])) {
        this.isAdmin = false;
      }
    }
    this.activity = this.activityTarget.activitys[0];
    this.targetId = this.activityTarget.id;
    this.setColor();
    this.actualActivity = this.sumActivity;
  }

  private setColor() {
    let inputValue;
    if (this.activityAmmount) {
      inputValue = this.activityAmmount;
    } else {
      inputValue = this.sumActivity;
    }
    const actualPercent = ((inputValue / this.sumTarget) * 100);
    if (actualPercent < 50) {
      this.progressBar.style.fill = '#ff0000';
    }
    if (actualPercent >= 50 && actualPercent < 85) {
      this.progressBar.style.fill = '#ffae00';
    }
    if (actualPercent >= 85 && actualPercent <= 100) {
      this.progressBar.style.fill = '#20ee00';
    }
    if (actualPercent > 100) {
      this.progressBar.style.fill = '#00a2ff';
    }
  }

  showActivityModal() {

    if (this.isAdmin) {
      return;
    }

    const activitiesModal = this.modalService.open(AddActivityComponent);
    activitiesModal.componentInstance.target = this.activityTarget;
    activitiesModal.componentInstance.activityDate = this.dateRange[0];
    activitiesModal.componentInstance.user = this.userSelected;
  }
}
