import { User } from '../user/User.model';
export class AgentRank {
    agent: User;
    sumActivity: number;
    sumTarget: number;
    activityTargetPercent: number;
    efficiency: number;

    constructor(agent?: User, sumActivity?: number, sumTarget?: number, activityTargetPercent?: number,efficiency?: number ) {
        this.agent = agent;
        this.sumActivity = sumActivity;
        this.sumTarget = sumTarget;
        this.activityTargetPercent = activityTargetPercent;
        this.efficiency = efficiency;
    }
}
