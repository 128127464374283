import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageViewGuard } from 'src/app/core/guards/pageView.guard';
import { FeatureGuard } from '../../core/guards/feature.guard';
import { LayoutComponent } from '../../layouts/layout.component';
import { PageViewListComponent } from '../page-view/page-view-list/page-view-list.component';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { ScheduleControlComponent } from './schedule-control/schedule-control.component';
import { SchedulePanelComponent } from './schedule-panel/schedule-panel.component';

const routes: Routes = [
  {
    path: 'schedule', component: LayoutComponent, canActivate: [FeatureGuard], children: [
      { path: 'control', component: ScheduleControlComponent, canActivate: [PageViewGuard] },
      { path: 'list', component: ScheduleListComponent, canActivate: [PageViewGuard] },
      { path: 'panel', component: SchedulePanelComponent, canActivate: [PageViewGuard] },
      { path: 'page-view', component: PageViewListComponent, canActivate: [PageViewGuard] },
      {
        path: '',
        redirectTo: 'control',
        pathMatch: 'full'
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScheduleRoutingModule { }
