import gql from 'graphql-tag';

export const createUserMutation = gql`
  mutation  createUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput){
      id,
      name,
      lastName,
      color,
      roles,
      avatar,
      email,
      contactPhone,
      status,
    }
}`;

export const updateUserMutation = gql`
  mutation  updateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput)
}`;
