import { TargetInput } from './Target.input';

export class CreateTargetInput {
    target: TargetInput;
    // companyIds: string[];
    // employeeRoleIds: string[];

    constructor(target: TargetInput) {
        this.target = target;
    }
}
