import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeRoleService } from 'src/app/core/services/employeerole.service';
import { EmployeeRoleByCompanyResponse } from 'src/app/core/models/employeeRole/companyemployeeroletargettypes.response';
import { EventService } from 'src/app/core/services/event.service';
import { SubscribeTypes } from '../../../shared/types/subcribes.types';
import { Company } from 'src/app/core/models/company/company.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-buttons',
    templateUrl: './buttons.component.html',
    styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit, OnDestroy {

    employeeRoles = [];
    companies: Company[];
    closeResult: string;
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    companiesSubcription: Subscription;

    constructor(private modalService: NgbModal,
                private employeeRoleService: EmployeeRoleService,
                private eventService: EventService,
                private storageService: StorageService) {
    }

    ngOnInit() {
        this.companies = this.storageService.getCompanies();
        this.companiesSubcription = this.eventService.subscribe(this.subscribeTypes.COMPANIES, companies => {
            companies.forEach(company => {
                this.getEmployeeRole(company.id);
            });
        });
        this.companies.forEach(company => {
            this.getEmployeeRole(company.id);
        });
    }
    ngOnDestroy() {
        this.companiesSubcription.unsubscribe();
    }

    open(buttons) {
        this.modalService.open(buttons, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    getEmployeeRole(companySelected) {
        this.employeeRoleService.employeeRoleByCompany$(companySelected).subscribe(
            (employeeRoleByCompanyResponse: EmployeeRoleByCompanyResponse) => {
                employeeRoleByCompanyResponse.employeeRole.forEach(rol => {
                    const filteredRoles = this.employeeRoles.filter(roles => {
                        return roles.idEmployeeRole.id === rol.idEmployeeRole.id;
                    });
                    if (filteredRoles.length === 0) {
                        this.employeeRoles.push(rol);
                    }
                });
            });
    }
}
