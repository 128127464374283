import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CreateTaskCommentInputDto } from '../../../core/models/task-comment/create-task-comment-input.dto';
import { GetTaskCommentsInputDto } from '../../../core/models/task-comment/get-task-comments-input.dto';
import { TaskCommentDto } from '../../../core/models/task-comment/task-comment.dto';
import { TaskDto } from '../../../core/models/task/task.dto';
import { TaskCommentService } from '../../../core/services/task-comment.service';
import { AlertService } from '../../../shared/services/alert.service';
import { DeleteTaskCommentInputDto } from '../../../core/models/task-comment/delete-task-comment-input.dto';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
    selector: 'app-task-comment',
    templateUrl: './task-comment.component.html',
    styleUrls: ['./task-comment.component.scss']
})
export class TaskCommentComponent implements OnInit {

    @Input() task: TaskDto;
    taskComments: TaskCommentDto[];
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    createTaskComment: CreateTaskCommentInputDto;
    commentError = false;
    showErase = [];

    constructor(
        private taskCommentService: TaskCommentService,
        private alertService: AlertService,
        private translateService: TranslatorService,
    ) { }

    ngOnInit() {
        this.createTaskComment = new CreateTaskCommentInputDto();
        this.reloadComments();
    }

    private reloadComments() {
        const getTaskCommentsInputDto = new GetTaskCommentsInputDto();
        getTaskCommentsInputDto.taskId = this.task.id;
        this.taskCommentService.getTaskComments$(getTaskCommentsInputDto).subscribe(data => {
            this.taskComments = data.taskComments;
        });
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }

    save() {
        if (this.validateComment()) {
            return;
        }
        this.createTaskComment.taskId = this.task.id;
        this.taskCommentService.createTaskComment$(this.createTaskComment).subscribe(data => {
            if (data.result) {
                this.reloadComments();
                this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('task.comment.add.succ_info'));
            } else {
                this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('task.comment.add.err_info'));
            }
        });
    }


    private validateComment() {
        this.commentError = false;
        if (!this.createTaskComment.text || this.createTaskComment.text === '') {
            this.commentError = true;
        }
        return this.commentError;
    }

    mouseEnter(taskCommentId: string) {
        this.showErase[taskCommentId] = true;
    }

    mouseLeave(taskCommentId: string) {
        this.showErase[taskCommentId] = false;
    }

    deleteComment(taskCommentId: string) {
        const deleteTaskComment = new DeleteTaskCommentInputDto();
        deleteTaskComment.taskCommentId = taskCommentId;
        this.alertService.showConfirm(this.translateService.trans('swag.sure'), this.translateService.trans('swag.cant_revert')).then(result => {
            if (!result.value) {
                return;
            }
            this.taskCommentService.deleteTaskComment$(deleteTaskComment).subscribe(data => {
                if (data.result) {
                    this.reloadComments();
                    this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('task.comment.delete.succ_info'));
                } else {
                    this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('task.comment.delete.err_info'));
                }
            });
        });
    }
}
