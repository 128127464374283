import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';

@Component({
    selector: 'app-dashboard-admin',
    templateUrl: './dashboard-admin.component.html',
    styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent implements OnInit {

    constructor(
        private eventService: EventService,
    ) { }

    ngOnInit() {
        this.eventService.broadcast('changePageHeading', 'Dashboard Admin');
    }
}
