import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureGuard } from '../../core/guards/feature.guard';
import { PageViewGuard } from '../../core/guards/pageView.guard';
import { TaskListComponent } from './task-list/task-list.component';
import { LayoutComponent } from '../../layouts/layout.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';

const routes: Routes = [
    {
        path: 'task', component: LayoutComponent, canActivate: [FeatureGuard], children: [
            { path: 'list', component: TaskListComponent, canActivate: [PageViewGuard] },
            { path: 'detail', component: TaskDetailComponent, canActivate: [PageViewGuard] },
            {
                path: '',
                redirectTo: 'task',
                pathMatch: 'full'
            }
        ]
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TaskRoutingModule { }
