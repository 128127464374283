import { Injectable } from '@angular/core';
import { differenceInBusinessDays, isSameDay, isWeekend } from 'date-fns';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateUtil {

    getDiffInBusinessDays(startDate: Date, finishDate: Date) {

        let diffInBusinessDays = Math.abs(differenceInBusinessDays(startDate, finishDate));
        if (isSameDay(startDate, finishDate) && !isWeekend(startDate)) {
            diffInBusinessDays = 1;
        } else if (!isWeekend(startDate)) {
            diffInBusinessDays += 1;
        }

        return diffInBusinessDays;
    }

    isBusinessDay(date) {
        return !isWeekend(date);
    }

    getDatesArray(startDate, stopDate) {
        const dateArray = [];
        let currentDate = moment(startDate);
        stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
            dateArray.push(new Date((currentDate).format('YYYY-MM-DD')));
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }

    timeStringToFloat(time) {
        const hoursMinutes = time.split(/[.:]/);
        const hours = parseInt(hoursMinutes[0], 10);
        const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        const sg = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
        return (hours + minutes / 60 + (sg / 60 / 60)).toFixed(3);
    }
}
