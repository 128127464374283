import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isSameDay } from 'date-fns';
import { Activity } from '../../core/models/activity/activity.model';
import { CreateActivityInput } from '../../core/models/activity/createActivity.input';
import { User } from '../../core/models/user/User.model';
import { ActivityService } from '../../core/services/activity.service';
import { EventService } from '../../core/services/event.service';
import { AlertService } from '../../shared/services/alert.service';
import { SubscribeTypes } from '../../shared/types/subcribes.types';
import { DeleteActivityInput } from '../../core/models/activity/deleteActivityInput.input';

@Component({
    selector: 'app-add-activity',
    templateUrl: './add-activity.component.html',
    styleUrls: []
})
export class AddActivityComponent implements OnInit, OnDestroy {

    @Input() target: any;
    @Input() activityDate: Date;
    @Input() user: User;

    activityAmount: number;
    activities: Activity[];

    constructor(
        private readonly activityService: ActivityService,
        private readonly eventService: EventService,
        private readonly alertService: AlertService,
        public readonly activeModal: NgbActiveModal
    ) {

    }

    ngOnInit(): void {

        this.activities = this.target.activitys.sort((a, b) => b.dateDay > a.dateDay ? 1 : -1);
    }

    ngOnDestroy(): void {
        this.activityAmount = null;
    }

    createActivity() {
        const createActivityInput = new CreateActivityInput(
            this.target.id,
            this.user.id,
            +this.activityAmount,
            isSameDay(new Date(), this.activityDate) ? new Date() : this.activityDate,
        );

        this.activityService.createActivity$(createActivityInput).subscribe(data => {
            this.alertService.show('success', 'Actividad creada correctamente', '');
            this.eventService.broadcast((new SubscribeTypes().ACTIVITY_CREATED), data);
            this.activeModal.close();
        });

    }

    deleteActivity(activity: Activity) {
        const deleteActivityInput = new DeleteActivityInput(
            activity.id,
            this.user.id,
            this.target.id
        );
        this.activityService.deleteActivity$(deleteActivityInput).subscribe(data => {
            this.eventService.broadcast((new SubscribeTypes().ACTIVITY_DELETED), activity.id);
            const index = this.activities.findIndex(d => d.id === activity.id); // find index in activities array
            this.activities.splice(index, 1); // remove element from array
            this.alertService.show('success', 'Actividad borrada correctamente', '');
        });


    }
}
