import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ToasterService } from 'angular2-toaster';
import { SharedModule } from '../../shared/shared.module';
import { UIModule } from '../../shared/ui/ui.module';
import { RefillableDocumentModalComponent } from './add-modal/refillable-document-modal/refillable-document-modal.component';
import { RefillableDocumentConsentComponent } from './consent/refillable-document-consent.component';
import { RefillableDocumentListComponent } from './list/refillable-document-list.component';
import { RefillableDocumentComponent } from './new/refillable-document.component';
import { RefillableDocumentsRoutingModule } from './refillable-document-routing.module';
import { AestheticConsentValidator } from './validators/aesthetic-consent.validator';
import { SalesMandateValidator } from './validators/sales-mandate.validator';

@NgModule({
    declarations: [
        RefillableDocumentComponent,
        RefillableDocumentListComponent,
        RefillableDocumentModalComponent,
        RefillableDocumentConsentComponent,
    ],
    imports: [
        RefillableDocumentsRoutingModule,
        CommonModule,
        SharedModule,
        FormsModule,
        SignaturePadModule,
        MatOptionModule,
        MatInputModule,
        MatCheckboxModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        UIModule
    ],
    providers: [
        ToasterService,
        SalesMandateValidator,
        AestheticConsentValidator,
    ]
})
export class RefillableDocumentsModule { }
