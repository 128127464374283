import gql from 'graphql-tag';

export const createSignWorkMutation = gql`
    mutation createSignWork($signWork: SignWorkInput, $userId: String){
        createSignWork(signWork : $signWork, userId: $userId){
            id,
            date,
            signature,
            door{
                id,
                eventDate,
                type
            }
        }
    }`

export const createSignWorkAnotherDayMutation = gql`
    mutation createSignWorkAnotherDay($signWork: CreateSignWorkInput, $userId: String){
        createSignWorkAnotherDay(signWork : $signWork, userId: $userId){
            id,
            date,
            signature,
            door{
                id,
                eventDate,
                type
            }
        }
    }`

export const updateSignWorkMutation = gql`
mutation updateSignWork($signWork: SignWorkInput){
    updateSignWork(signWork : $signWork){
        id,
        date,
        signature,
        door{
            id,
            eventDate,
            type
        }
    }
}`


export const deleteSignWork = gql`
mutation deleteSignWork($signWorkId: String!) {
    deleteSignWork(signWorkId: $signWorkId)
}`;
