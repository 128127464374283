import { User } from './User.model';
import { Activity } from '../activity/activity.model';

export class UserRankingWithActivityAndTargetRatioByCompanyResponse {
    user: User[];

    constructor(ranking: User[]) {
        this.user = ranking;
    }
}
