import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { getCompanyConfiguration } from '../../graphql/operations/companyConfiguration/queries';
import { CompanyConfigurationResponse } from '../models/companyConfiguration/companyConfiguration.response';
import { GetCompanyConfigurationInput } from '../models/companyConfiguration/getCompanyConfiguration.input';
import { UpdateCompanyConfigurationInput } from '../models/companyConfiguration/updateCompanyConfiguration.input';
import { updateCompanyConfigurationMutation } from 'src/app/graphql/operations/companyConfiguration/mutations';


@Injectable({ providedIn: 'root' })
export class CompanyConfigurationService {
    constructor(
        private http: HttpClient,
        private readonly apollo: Apollo) { }

    getCompanyConfiguration$(getCompanyConfigurationInput: GetCompanyConfigurationInput) {
        return this.apollo
            .query({
                query: getCompanyConfiguration,
                variables: {
                    getCompanyConfigurationInput
                }
            }).pipe(map(result => {
                return new CompanyConfigurationResponse(
                    result.data['getCompanyConfiguration'][0].id,
                    result.data['getCompanyConfiguration'][0].onlyRankingCompany,
                    result.data['getCompanyConfiguration'][0].features,
                    result.data['getCompanyConfiguration'][0].language,
                );
            }));
    }

    updateCompanyConfiguration$(updateCompanyConfigurationInput: UpdateCompanyConfigurationInput) {
        return this.apollo
            .mutate({
                mutation: updateCompanyConfigurationMutation,
                variables: {
                    updateCompanyConfigurationInput
                }
            }).pipe(map(result => {
                return result.data['updateCompanyConfiguration'];
            }));
    }
}
