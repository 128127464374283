import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { jqxKnobModule } from 'jqwidgets-ng/jqxknob';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxTimerModule } from 'ngx-timer';
import { UiSwitchModule } from 'ngx-ui-switch';
import { GeolocationComponent } from 'src/app/shared-component/geolocation/geolocation.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIModule } from 'src/app/shared/ui/ui.module';
import { ScheduleControlValidator } from 'src/app/shared/validator/schedule.validator';
import { SignatureModalComponent } from '../../shared-component/signature-modal/signature-modal.component';
import { PageViewListComponent } from '../page-view/page-view-list/page-view-list.component';
import { ScheduleControlComponent } from './schedule-control/schedule-control.component';
import { CreateScheduleComponent } from './schedule-list/modals/create-schedule/create-schedule.component';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { SchedulePanelComponent } from './schedule-panel/schedule-panel.component';
import { ScheduleRoutingModule } from './schedule-routing.module';

import dayGridPlugin from '@fullcalendar/daygrid';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin
]);
@NgModule({
    declarations: [
        SignatureModalComponent,
        ScheduleControlComponent,
        ScheduleListComponent,
        SchedulePanelComponent,
        PageViewListComponent,
        CreateScheduleComponent,
    ],
    imports: [
        ScheduleRoutingModule,
        CommonModule,
        MatOptionModule,
        MatInputModule,
        MatCheckboxModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        FormsModule,
        NgxSkeletonLoaderModule,
        NgxEchartsModule,
        FullCalendarModule,
        ToasterModule,
        jqxKnobModule,
        UiSwitchModule,
        ColorPickerModule,
        SignaturePadModule,
        NgxTimerModule,
        MglTimelineModule,
        UIModule,
        NgxDaterangepickerMd.forRoot({
            applyLabel: 'Vale',
            format: 'YYYY-MM-DD'
        }),
        NgxMaterialTimepickerModule.setLocale('es-ES'),
        SharedModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule
    ],
    providers: [
        ToasterService,
        ScheduleControlValidator,
        NgbActiveModal
    ]
})
export class ScheduleModule { }
