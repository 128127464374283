import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Subscription } from 'rxjs';
import { concatAll } from 'rxjs/operators';
import { Company } from 'src/app/core/models/company/company.model';
import { CompanyUserEmployeeRoleInput } from 'src/app/core/models/companyuseremployeerole/companyUserEmployeeRole.input';
import { CompanyUserEmployeeRolesInput } from 'src/app/core/models/companyuseremployeerole/companyUserEmployeeRoles.input';
import { EmployeeRole } from 'src/app/core/models/employeeRole/employeeRole.model';
import { GetCompanyUserByEmployeeRoleInput } from 'src/app/core/models/employeeRole/getCompanyUserByEmployeeRole.input';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { CompanyUserEmployeeRole } from '../../../../core/models/companyuseremployeerole/companyUserEmployeeRole.model';
import { EmployeeRoleInput } from '../../../../core/models/employeeRole/employeeRole.input';
import { User } from '../../../../core/models/user/User.model';
import { EmployeeRoleService } from '../../../../core/services/employeerole.service';
import { EventService } from '../../../../core/services/event.service';
import { UserProfileService } from '../../../../core/services/user.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { UserFilterService } from '../../../../shared/services/userFilter.service';
import { TranslatorService } from '../../../../shared/services/translator.service';

@Component({
  selector: 'app-edit-employee-roles',
  templateUrl: './edit-employee-roles.component.html',
  styleUrls: ['./edit-employee-roles.component.scss']
})
export class EditEmployeeRolesComponent implements OnInit {

  @Input() employeeRole: EmployeeRole;
  @Input() companies: Company[];
  public color1: string;
  users: User[];
  checked = false;
  selectedUser: User;
  selectedCompany: Company;
  color: string;
  employeeRoleName: string;
  companyEmployeeRoleUser: CompanyUserEmployeeRole[];
  companyEmployeeRoleUserTemp: CompanyUserEmployeeRole[];
  companyEmployeeRoleUserFinal: CompanyUserEmployeeRole[];
  subscribeTypes: SubscribeTypes = new SubscribeTypes();

  constructor(
    public readonly activeModal: NgbActiveModal,
    private employeeRoleService: EmployeeRoleService,
    private userService: UserProfileService,
    private userFilterService: UserFilterService,
    private alertService: AlertService,
    private eventService: EventService,
    private translateService: TranslatorService,
  ) { }

  ngOnInit() {
    this.companyEmployeeRoleUserTemp = [];
    this.companyEmployeeRoleUserFinal = [];
    this.companyEmployeeRoleUser = [];
    if (this.employeeRole) {
      this.employeeRoleName = this.employeeRole.name;
      this.color1 = 'rgba(' + this.employeeRole.color + ')';
      this.color = this.employeeRole.color;
      const getCompanyUserByEmployeeRoleInput = new GetCompanyUserByEmployeeRoleInput();
      getCompanyUserByEmployeeRoleInput.companyIds = this.companies.map(company => company.id);
      getCompanyUserByEmployeeRoleInput.employeeRoleId = this.employeeRole.id;
      this.employeeRoleService.getCompanyUserByEmployeeRole$(getCompanyUserByEmployeeRoleInput).subscribe(data => {
        this.companyEmployeeRoleUserFinal = data.map(item => item);
        this.companyEmployeeRoleUser = data.map(item => item);
      });
    }
    this.getUsers();
  }

  getUsers() {
    const userObservables = [];
    this.companies.forEach(companyId => {
      userObservables.push(this.userService.usersByCompany$(companyId.id));
    });

    let users = [];
    from(userObservables)
      .pipe(concatAll())
      .subscribe((companyUsers: any) => {
        users = [...users, ...companyUsers];
      }, () => { }, () => {
        this.users = this.userFilterService.filterNoRepeatUsers(users);
      });
  }

  save() {
    if (this.validateCreateCompanyUserEmployeeRole()) {
      return;
    }
    if (!this.employeeRole) {
      const employeeRole = new EmployeeRoleInput();
      employeeRole.name = this.employeeRoleName;
      employeeRole.color = this.color;

      this.employeeRoleService.createEmployeeRole$(employeeRole).subscribe(data => {
        this.employeeRole = new EmployeeRole();
        this.employeeRole = data;
        this.companyEmployeeRoleUserTemp.forEach(companyUserRole => {
          companyUserRole.idEmployeeRole = this.employeeRole;
        });
        this.createCompanyEmployeeRoles();
      });
    } else {
      const employeeRole = new EmployeeRoleInput();
      employeeRole.name = this.employeeRoleName;
      employeeRole.id = this.employeeRole.id;
      employeeRole.color = this.color;

      this.employeeRoleService.updateEmployeeRole$(employeeRole).subscribe(data => {
        this.employeeRole.color = employeeRole.color;
        this.employeeRole.name = employeeRole.name;
        this.createCompanyEmployeeRoles();
      });
    }

  }

  private createCompanyEmployeeRoles() {
    this.employeeRoleService.createCompanyUserEmployeeRole$(this.createCreateCompanyUserEmployeeRoleInput()).subscribe(data => {
      if (data) {
        this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_info'));
        this.companyEmployeeRoleUserTemp.forEach(companyUserRole => {
          this.companyEmployeeRoleUser.push(companyUserRole);
        });
        this.eventService.broadcast(this.subscribeTypes.EMPLOYEEROLE_CREATED);
        this.companyEmployeeRoleUserTemp = [];
      } else {
        this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.configuration.error'));
      }
    });
  }

  private createCreateCompanyUserEmployeeRoleInput(): CompanyUserEmployeeRolesInput {
    const companyUserEmployeeRoleInput = new CompanyUserEmployeeRolesInput();
    companyUserEmployeeRoleInput.companyUserEmployeeRoles = this.companyEmployeeRoleUserTemp
      .map(companyEmployeeRoleUser => {
        return new CompanyUserEmployeeRoleInput(
          companyEmployeeRoleUser.idCompany.id,
          companyEmployeeRoleUser.idUser.id,
          companyEmployeeRoleUser.idEmployeeRole.id
        );
      });

    return companyUserEmployeeRoleInput;
  }

  colorPickerChange(color) {
    color = color.split('(', 2)[1].split(')', 1)[0].split(',');
    color = color[0] + ',' + color[1] + ',' + color[2];
    this.color = color;
  }

  addCompanyUserEmployeeRole() {
    if (this.validateUserAndCompany()) {
      return;
    }
    const companyUserEmployeeRoleInput = new CompanyUserEmployeeRole();
    companyUserEmployeeRoleInput.idEmployeeRole = this.employeeRole;
    companyUserEmployeeRoleInput.idCompany = this.selectedCompany;
    companyUserEmployeeRoleInput.idUser = this.selectedUser;
    this.companyEmployeeRoleUserFinal.push(companyUserEmployeeRoleInput);
    this.companyEmployeeRoleUserTemp.push(companyUserEmployeeRoleInput);
  }

  private validateUserAndCompany() {
    if (!this.selectedCompany || !this.selectedUser) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_user_add'));
      return true;
    }
    return false;
  }
  private validateCreateCompanyUserEmployeeRole() {
    let validate = false;
    if (!this.color) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_color'));
      validate = true;
    }
    if (!this.employeeRoleName) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_name'));
      validate = true;
    }
    if (this.companyEmployeeRoleUser.length === 0 && this.companyEmployeeRoleUserTemp.length === 0) {
      this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_user'));
      validate = true;
    }
    return validate;
  }

  deleteCompanyUserEmployeerole(companyUserEmployeeRole: CompanyUserEmployeeRole) {
    const companyUserEmployeeRoleInput = new CompanyUserEmployeeRoleInput(
      companyUserEmployeeRole.idCompany.id,
      companyUserEmployeeRole.idUser.id,
      companyUserEmployeeRole.idEmployeeRole.id
    );

    this.employeeRoleService.deleteCompanyUserEmployeeRole$(companyUserEmployeeRoleInput).subscribe(data => {
      if (data) {
        this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('toast.edit_role.succ_info'));
        this.companyEmployeeRoleUserFinal = this.companyEmployeeRoleUserFinal.filter(companyEmployeeRole => {
          this.companyEmployeeRoleUserTemp = [];
          if (
            companyEmployeeRole.idCompany.id === companyUserEmployeeRoleInput.idCompany &&
            companyEmployeeRole.idEmployeeRole.id === companyUserEmployeeRoleInput.idEmployeeRole &&
            companyEmployeeRole.idUser.id === companyUserEmployeeRoleInput.idUser
          ) {
            return false;
          } else {
            return true;
          }
        });
      } else {
        this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('toast.edit_role.err_info'));
      }
    });
  }

  undo() {
    this.companyEmployeeRoleUserTemp = [];
    this.companyEmployeeRoleUserFinal = this.companyEmployeeRoleUser;
  }
}
