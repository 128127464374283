
import { User } from '../../models/user/User.model';
import { Target } from '../../models/target/target.model';
import { Activity } from './activity.model';

export class CreateActivityResponse {
    id: string;
    user: User;
    target: Target;
    dateDay : string;
    realAmount: number;

    constructor(activity :Activity){
        this.id=activity.id;
        this.user=activity.user;
        this.target=activity.target;
        this.dateDay=activity.dateDay;
        this.realAmount=activity.realAmount;
    }

}
