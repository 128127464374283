import gql from 'graphql-tag';

export const updateTargetEspecificationsMutation = gql`
    mutation updateTargetEspecifications($targetEspecificationsInput: TargetSpecificationsInput!,$targetEspecificationsId: String!){
        updateTargetEspecifications(targetEspecificationsId : $targetEspecificationsId,targetEspecificationsInput: $targetEspecificationsInput){
            id,
            startDate,
            finishDate,
            goal
        }
    }` ;

export const createTargetEspecificationsMutation = gql`
    mutation createTargetEspecifications($createTargetEspecificationsInputDto: CreateTargetEspecificationsInputDto!){
        createTargetEspecifications(createTargetEspecificationsInputDto : $createTargetEspecificationsInputDto){
            id,
            startDate,
            finishDate,
            goal
        }
    }` ;

export const deleteTargetEspecificationsMutation = gql`
    mutation deleteTargetEspecificationsMutation($deleteTargetEspecificationsInputDto: DeleteTargetEspecificationsInputDto!){
        deleteTargetEspecifications(deleteTargetEspecificationsInputDto : $deleteTargetEspecificationsInputDto)
    }` ;
