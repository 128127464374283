import { Injectable, NgZone } from '@angular/core';
import { BodyOutputType, Toast, ToasterConfig, ToasterService } from 'angular2-toaster';
import Swal from 'sweetalert2';
import { TranslatorService } from './translator.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AlertService {

    resp: boolean;


    swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false
    });

    constructor(
        private readonly zone: NgZone,
        private readonly toasterService: ToasterService,
        private translateService: TranslatorService,
    ) {
    }

    getConfiguration(): ToasterConfig {
        return new ToasterConfig({
            positionClass: 'toast-top-right',
            timeout: 30000,
            newestOnTop: true,
            tapToDismiss: true,
            preventDuplicates: true,
            animation: 'fade',
            limit: 5
        });
    }

    async show(type: string, title: string, body: string): Promise<void> {
        const toast: Toast = {
            type,
            title,
            body,
            timeout: 5000,
            showCloseButton: true,
            bodyOutputType: BodyOutputType.TrustedHtml,
        };

        this.zone.run(() => {
            this.toasterService.popAsync(toast);
        });
    }

    showConfirm(title: string, text: string) {
        return this.swalWithBootstrapButtons.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translateService.trans('swag.yes'),
            cancelButtonText: this.translateService.trans('swag.no'),
            reverseButtons: true,
        });
    }

    showArchiveConfirm(title: string, text: string) {
        return this.swalWithBootstrapButtons.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translateService.trans('swag.yes'),
            cancelButtonText: this.translateService.trans('swag.no'),
            reverseButtons: true,
        });
    }

    showConfirmDoor(title: string, text: string) {
        return this.swalWithBootstrapButtons.fire({
            title: title,
            text: text,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: this.translateService.trans('swag.yes') + ', ' + this.translateService.trans('swag.out'),
            cancelButtonText: this.translateService.trans('swag.no') + ', ' + this.translateService.trans('swag.cant_revert'),
            reverseButtons: true,
        });
    }
}
