
export class TargetEspecificationsInput {
    startDate: string;
    finishDate: string;
    goal: number;

    constructor(startDate: string, finishDate: string, goal: number) {
        this.startDate = startDate;
        this.finishDate = finishDate;
        this.goal = goal;
    }
}
