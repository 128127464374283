import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PageViewInputDto } from '../models/pageView/page-view.input';
import { AuthenticationService } from '../services/auth.service';
import { PageViewService } from '../services/page-view.service';


@Injectable({ providedIn: 'root' })
export class PageViewGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private pageViewService: PageViewService,
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticationService.getUser()) {
            return false;
        }
        const pageViewInput = new PageViewInputDto();
        pageViewInput.date = new Date();
        pageViewInput.route = route.routeConfig.path;
        pageViewInput.userId = this.authenticationService.getUser().id;
        this.pageViewService.createPageView$(pageViewInput).subscribe();
        return true;
    }
}
