import { Component, OnDestroy, OnInit } from '@angular/core';
import { endOfMonth, endOfYear, format, startOfMonth, startOfYear } from 'date-fns';
import { es } from 'date-fns/locale';
import { Echarts } from 'echarts';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { forkJoin, from, Subscription } from 'rxjs';
import { concatAll } from 'rxjs/operators';
import { ColorTypes } from 'src/app/shared/types/color.types';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { Company } from '../../../core/models/company/company.model';
import { ActivityByUserByCompanyByDateRangeResponse } from '../../../core/models/user/ActivityByUserByCompanyByDateRange.response';
import { User } from '../../../core/models/user/User.model';
import { EventService } from '../../../core/services/event.service';
import { StorageService } from '../../../core/services/storage.service';
import { UserProfileService } from '../../../core/services/user.service';
import { UserTargetsChartService } from '../../../shared/services/charts/user-targets-chart.service';
import { UserFilterService } from '../../../shared/services/userFilter.service';

@Component({
    selector: 'app-user-targets-chart',
    templateUrl: './user-targets-chart.component.html',
    styleUrls: ['./user-targets-chart.component.scss']
})
export class UserTargetsChartComponent implements OnInit, OnDestroy {

    options: any;
    chart: Echarts;
    dateRange: Date[];
    selectedDateFrom: Date;
    selectedDateTo: Date;
    selectedUsers: User[];
    userIds: string[];
    selectedCompaniesIds: string[];
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    colorTypes: ColorTypes = new ColorTypes();
    checked = true;
    usersByCompany: User[];
    users: User[];
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    locale = 'es';
    locales = listLocales();
    companiesSubcription: Subscription;
    loading = true;

    constructor(
        private readonly userService: UserProfileService,
        private readonly userTargetsChartService: UserTargetsChartService,
        private readonly eventService: EventService,
        private readonly storageService: StorageService,
        private localeService: BsLocaleService,
        private userFilterService: UserFilterService,

    ) {
        this.dateRange = [startOfYear(new Date()), endOfYear(new Date())];
        defineLocale(this.locale, esLocale);
        this.localeService.use(this.locale);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'MMMM-YY' });
    }

    ngOnInit() {
        this.selectedDateFrom = startOfYear(new Date());
        this.selectedDateTo = endOfYear(new Date());

        this.initializeChart();

        this.companiesSubcription = this.eventService.subscribe(this.subscribeTypes.COMPANIES, companies => {

            this.initializeCompaniesAndUsers(companies);
        });

        if (this.storageService.getCompanies()) {

            this.initializeCompaniesAndUsers(this.storageService.getCompanies());
        }

    }
    ngOnDestroy() {
        this.companiesSubcription.unsubscribe();
    }

    private initializeCompaniesAndUsers(companies: Company[]) {
        this.selectedCompaniesIds = companies.map(company => company.id).filter((value, index, self) => self.indexOf(value) === index);
        this.getUsers();
    }

    onChartInit(ec) {
        this.chart = ec;
    }

    onFormFieldChange() {
        this.selectedDateFrom = this.dateRange[0];
        this.selectedDateTo = this.dateRange[1];
        this.reloadChart();
    }

    private initializeChart() {
        this.options = {
            tooltip: {},
            xAxis: {
                type: 'category',
                axisLabel: {
                    formatter: (function (value) {
                        let label;
                        label = format(new Date(value), 'MMM-yy', { locale: es });
                        label = label.charAt(0).toUpperCase() + label.substring(1);
                        return label;
                    })
                }
            },
            yAxis: {
                type: 'value'
            },
            series: []
        };
    }

    private reloadChart() {
        const dateFrom = startOfMonth(new Date(this.selectedDateFrom + '-01'));
        const dateTo = endOfMonth(new Date(this.selectedDateTo + '-01'));
        if (this.selectedUsers.length !== 0) {
            this.userIds = this.selectedUsers.map(user => user.id);
        }
        forkJoin({
            activities: this.userService.activityByUserByDateRange$(this.selectedCompaniesIds, this.dateRange, this.userIds),
            targets: this.userService.targetsByUserByDateRange$(this.selectedCompaniesIds, this.dateRange, this.userIds)
        }).subscribe(response => {

            this.options.xAxis.data = this.userTargetsChartService.generateXAxis(dateFrom, dateTo);
            this.options.legend = [{ data: this.selectedUsers.map(user => this.getCategoryName(user)) }];
            this.options.legend = {
                type: 'scroll',
                animation: true,
                animationDurationUpdate: 2500,
                bottom: 0,
                align: 'left',
                padding: [80, 0, 0, 0]
            };
            this.options.color = this.colorTypes.colors;

            this.options.series = this.calculateActivitiesSeries(this.selectedUsers, response.activities);

            this.chart.setOption(this.options, { notMerge: true });
        });
        this.loading = false;
    }

    private calculateActivitiesSeries(users: User[], response: ActivityByUserByCompanyByDateRangeResponse) {
        const seriesByMonth = [];
        this.options.xAxis.data.forEach(month => {
            const firstDayOfMonth = startOfMonth(new Date(month + '-01'));
            const lastDayOfMonth = endOfMonth(new Date(month + '-01'));
            users.forEach(cuser => {
                const key = `${cuser.id}+${cuser.companyUserEmployeeRoles[0].idCompany.id}`;
                if (!seriesByMonth[key]) {
                    seriesByMonth[key] = [];
                }

                const user = response.users.filter(u => u.id === cuser.id).shift();
                seriesByMonth[key].push(
                    this.userTargetsChartService.getTotalActivitiesInDateRange(user ? user.activitys : [], firstDayOfMonth, lastDayOfMonth)
                );
            });
        });

        const series = [];
        Object.entries(seriesByMonth).forEach(([userIdCompanyId, userSerie]) => {
            const [userId, companyId] = userIdCompanyId.split('+');
            const user = this.getUserByIdAndCompany(userId, companyId);
            series.push({
                name: this.getCategoryName(user),
                stack: 'one',
                data: userSerie,
                type: 'bar',
                lineStyle: {
                    width: 4,
                }
            });
        });

        return series;
    }

    private getCategoryName(user: User): string {
        return `${user.name} ${user.lastName} - ${user.companyUserEmployeeRoles[0].idCompany.name}`;
    }

    getUserById(userId: string) {
        return this.users.filter(user => user.id === userId).shift();
    }

    getUserByIdAndCompany(userId: string, companyId: string) {
        return this.users.filter(user => user.id === userId && user.companyUserEmployeeRoles[0].idCompany.id === companyId).shift();
    }

    private getUsers() {

        const userObservables = [];
        this.selectedCompaniesIds.forEach(companyId => {
            userObservables.push(this.userService.usersByCompany$(companyId));
        });

        let users = [];
        from(userObservables)
            .pipe(concatAll())
            .subscribe((companyUsers: any) => {
                users = [...users, ...companyUsers];
            }, () => { }, () => {

                users = this.userFilterService.filterAgentsByRole(users);

                this.users = users;
                this.selectedUsers = users;
                this.reloadChart();
            });
    }
    selectAll() {
        if (this.checked) {
            this.selectedUsers = this.users;
        } else {
            this.selectedUsers = [];
        }
        this.reloadChart();
    }
}
