import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { createPageViewMutation } from 'src/app/graphql/operations/pageView/mutations';
import { getCountPageView, getPageViewByDateRangeByUser } from 'src/app/graphql/operations/pageView/queries';
import { PageViewInputDto } from '../models/pageView/page-view.input';

@Injectable({ providedIn: 'root' })
export class PageViewService {
    constructor(
        private readonly apollo: Apollo
    ) {
    }

    getCountPageView$(usersId: string[], dateRange: Date[]) {
        return this.apollo
            .mutate({
                mutation: getCountPageView,
                variables: {
                    usersId,
                    dateRange
                }
            }).pipe(map(result => {
                return result.data['getCountPageViewByUserByDateRange'];
            }));
    }

    getPageViewByDateRangeByUser$(userId: string, dateRange: Date[]) {
        return this.apollo
            .mutate({
                mutation: getPageViewByDateRangeByUser,
                variables: {
                    userId,
                    dateRange
                }
            }).pipe(map(result => {
                return result.data['getPageViewByDateRangeByUser'];
            }));
    }

    createPageView$(pageViewInput: PageViewInputDto) {
        return this.apollo
            .mutate({
                mutation: createPageViewMutation,
                variables: {
                    pageViewInput
                }
            }).pipe(map(result => {
                return result.data['createPageView'];
            }));
    }
}

