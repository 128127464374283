import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserLogged } from 'src/app/core/models/auth.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../../core/services/company.service';
import { EventService } from '../../core/services/event.service';
import { SubscribeTypes } from '../../shared/types/subcribes.types';


@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements OnInit {
    pageHeading: string;
    notificationItems: Array<{}>;
    openMobileMenu: boolean;
    currentUser: UserLogged;
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    subscribeTypes: SubscribeTypes = new SubscribeTypes();

    @Output() settingsButtonClicked = new EventEmitter();
    @Output() mobileMenuButtonClicked = new EventEmitter();

    constructor(
        private eventService: EventService,
        readonly authService: AuthenticationService,
        private companyService: CompanyService,
        private router: Router) {

    }

    ngOnInit() {
        this.getUser();
        this.openMobileMenu = false;
        this.eventService.subscribe('changePageHeading', (heading) => {
            this.pageHeading = heading;
        });
        this.eventService.subscribe(this.subscribeTypes.USER_UPDATED, (data) => this.getUser());

    }

    /**
     * Logout the user
     */
    logout() {
        this.authService.logout();
        this.router.navigate(['/account/login']);
    }

    getUser() {

        this.currentUser = this.authService.getUser();
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }

}

