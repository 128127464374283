import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureGuard } from '../../core/guards/feature.guard';
import { PageViewGuard } from '../../core/guards/pageView.guard';
import { LayoutComponent } from '../../layouts/layout.component';
import { RefillableDocumentComponent } from './new/refillable-document.component';
import { RefillableDocumentListComponent } from './list/refillable-document-list.component';
import { RefillableDocumentConsentComponent } from './consent/refillable-document-consent.component';

const routes: Routes = [
  {
    path: 'refillable-documents', component: LayoutComponent, canActivate: [FeatureGuard], children: [
      { path: 'new-document', component: RefillableDocumentComponent, canActivate: [PageViewGuard] },
      { path: 'new-consent', component: RefillableDocumentConsentComponent, canActivate: [PageViewGuard] },
      { path: 'list', component: RefillableDocumentListComponent, canActivate: [PageViewGuard] },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RefillableDocumentsRoutingModule { }
