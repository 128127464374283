export class TranslationEs {

  static readonly dict =
    {
      'dashboard.admin.statistic': 'Estadísticas - Dashboard Admin',
      'menu.not_hired': 'No contratado',
      'menu.not': 'No',
      'menu.hired': 'contratado',
      'menu.management': 'Gestión',
      'menu.statistics': 'Estadísticas',
      'menu.work_control': 'Control Laboral',
      'menu.signing_panel': 'Panel Fichaje',
      'menu.signing_panel.user': 'Histórico Fichajes',
      'menu.flow_management': 'Control Visitas',
      'menu.task_management': 'Gestor Tareas',
      'menu.autofill': 'Autorellenables',
      'menu.document_store': 'Biblioteca',
      'menu.configuration': 'Configuración',
      'menu.company_config': 'Opciones empresa',
      'menu.company_data': 'Datos empresa',
      'activities.no_user_selected': 'No hay ningun usuario seleccionado',
      'activities.comercial_of': 'Actividad comercial de ',
      'activities.of_month': 'Estadísticas Mensuales ',
      'activities.select_date_range': 'Seleccione rango de fechas',
      'activities.select_date': 'Seleccione una fecha',
      'activities.select_company': 'Seleccione empresa',
      'activities.no_target_in_date': 'No tiene ningun objetivo en la fecha seleccionada',
      'topbar.hello': 'Hola',
      'topbar.sign': 'Fichar',
      'topbar.account': 'Mi cuenta',
      'topbar.logout': 'Salir',
      'agentcard.target': 'Objetivos/Eficiencia',
      'agentcard.activity_today': 'Actividad de hoy',
      'agentcard.target_not_found': 'No se han encontrado objetivos',
      'charts.eficiency': 'Eficiencia',
      'charts.acitivity': 'Actividad',
      'charts.target': 'Objetivos',
      'charts.complete': 'Completado',
      'charts.calendar_eficiency': 'Calendario Eficiencia/Día',
      'charts.eficiency_progresion': 'Progresion de la Eficiencia',
      'charts.percent': 'Porcentaje',
      'charts.select_all': 'Seleccionar todos',
      'summary.select_users': 'Seleccione Usuarios',
      'summary.select_user': 'Seleccione Usuario',
      'summary.filter': 'Filtrar',
      'summary.filter_table': 'Filtrar la tabla',
      'summary.user': 'Usuario',
      'summary.total_activity': 'Total actividad',
      'summary.total_target': 'Total objetivo',
      'summary.tittle': 'Resumen Actividad Comercial',
      'configuration.name': 'Nombre',
      'configuration.cif': 'CIF',
      'configuration.email': 'E-mail',
      'configuration.email_err': 'Introduce un email correcto',
      'configuration.contact_user': 'Persona de contacto',
      'configuration.contact_phone': 'Teléfono de contacto',
      'configuration.address': 'Dirección',
      'configuration.city': 'Ciudad',
      'configuration.state': 'País',
      'configuration.avatar': 'Avatar',
      'configuration.select': 'Selecciona',
      'configuration.save': 'Guardar',
      'configuration.auth_failed': 'No deberías estar aquí',
      'configuration.return_back': 'Volver a tú lugar',
      'configuration.tittle': 'Configuración',
      'configuration.ranking': 'Restringir ranking a oficina',
      'configuration.ranking_info': 'Permitir que los agentes solo vean a los agentes de su empresa',
      'configuration.language': 'Seleccionar idioma',
      'toast.configuration.error': 'Ha ocurrido un problema',
      'toast.configuration.succ': 'Configuración de empresa guardada correctamente',
      'toast.edit_role.succ': 'Hecho!!',
      'toast.edit_role.succ_info': 'Se ha guardado Correctamente',
      'toast.edit_role.succ_delete': 'Se ha borrado Correctamente',
      'toast.edit_role.err': 'Error!!',
      'toast.edit_role.err_info': 'Ha ocurrido un problema al guardar',
      'toast.edit_role.err_delete': 'Ha ocurrido un problema al borrar',
      'toast.edit_role.err_color': 'Debe seleccionar el color del Rol',
      'toast.edit_role.err_name': 'Debe introducir el nombre del Rol',
      'toast.edit_role.err_user': 'Debe añadir al menos una empresa y usuario',
      'toast.edit_role.err_date': 'Debes seleccionar una fecha',
      'toast.edit_role.err_select_user': 'Debes seleccionar un usuario',
      'toast.edit_role.err_user_add': 'Debe seleccionar al menos una empresa y usuario',
      'toast.edit_role.err_rol_add': 'Debe seleccionar al menos una empresa y rol',
      'toast.edit_role.err_name_add': 'Debe introducir el nombre',
      'toast.edit_role.err_surname_add': 'Debe introducir el apellido',
      'toast.edit_role.err_email_add': 'Debe introducir el email',
      'toast.upload_avatar': 'Ha ocurrido un problema al subir el avatar',
      'toast.alert': 'Alerta',
      'role.edit.tittle': 'Modificar rol',
      'role.create.tittle': 'Crear nuevo rol',
      'role.color': 'Color',
      'role.company': 'Empresa',
      'role.delete': 'Borrar',
      'role.add': 'Añadir',
      'role.edit': 'Editar',
      'role.reset': 'Deshacer',
      'role.close': 'Cerrar',
      'role.create': 'Crear rol',
      'role.rol': 'Rol',
      'role.options': 'Opciones',
      'role.list.tittle': 'Listado Roles',
      'user.edit': 'Editar usuario',
      'user.add': 'Crear nuevo usuario',
      'user.surname': 'Apellidos',
      'user.phone': 'Teléfono',
      'user.rol': 'Rol usuario',
      'user.status': 'Status',
      'user.status_on': 'Activo',
      'user.status_off': 'Inactivo',
      'user.select_rol': 'Seleccione Rol',
      'user.button.create': 'Crear usuario',
      'user.list.tittle': 'Listado de usuarios',
      'document.add': 'Añadir documento',
      'document.list': 'Listado Documentos',
      'swag.sure': '¿Estas seguro?',
      'swag.out': 'es salida',
      'swag.new_day': 'es un nuevo dia',
      'swag.cant_revert': 'Esta acción no se puede revertir',
      'swag.yes': 'Si',
      'swag.no': 'No',
      'pageview.visit': 'Visitas',
      'pageview.date': 'Fecha',
      'pageview.time': 'Hora',
      'pageview.times': 'Horas',
      'pageview.route': 'Ruta',
      'panel': 'Panel de fichaje',
      'pageview.tittle': 'Listado control de visitas',
      'perfomance.tittle': 'Ranking empleados',
      'document.name': 'Nombre archivo',
      'document.type': 'Seleccione tipo',
      'document.open': 'Abrir formulario',
      'document.type_name': 'Tipo',
      'document.create_date': 'Fecha creación',
      'document.refillable.tittle': 'Listado documentos rellenables',
      'schedule.control.tittle': 'Sistema de control de la jornada',
      'schedule.control.comp.tittle': 'Control de horarios',
      'schedule.control.total_time': 'Total de horas trabajadas',
      'schedule.control.no_time': 'No has registrado ninguna entrada todavia',
      'schedule.control.last_in': 'desde la ultima entrada',
      'schedule.control.total_now': 'total tiempo actual',
      'schedule.control.in': 'Entrar',
      'schedule.control.out': 'Salir',
      'schedule.control.select.date': 'Seleccione la fecha',
      'schedule.control.resume': 'Resumen del día',
      'schedule.control.no_comments': 'No hay comentarios',
      'schedule.control.no_data': 'No has registrado ninguna entrada todavia',
      'schedule.control.succ': 'Has fichado correctamente',
      'schedule.control.err': 'Ha ocurrido un problema al fichar',
      'schedule.control.swag.last_day': 'Ayer no fichaste salida',
      'schedule.control.swag.last_day_info': '¿Estas fichando la salida de ayer?',
      'schedule.list.create': 'Añadir nuevo fichaje',
      'schedule.button.create': 'Crear',
      'schedule.add.default.comment': 'Creado por administración',
      'schedule.list.new': 'Nuevo',
      'schedule.list.by_user': 'Por persona',
      'schedule.list.all': 'Todo',
      'schedule.list.is_working': 'Trabajando actualmente',
      'schedule.list.is_not_working': 'No se encuentra trabajando actualmente',
      'schedule.list.not_leave': 'Este usuario no ha fichado salida',
      'schedule.list.export': 'Exportar detalle entradas/salidas',
      'schedule.list.datetime': 'Fecha y hora',
      'schedule.list.sign': 'Firma',
      'schedule.list.actions': 'Acciones',
      'schedule.list.in': 'Entrada',
      'schedule.list.out': 'Salida',
      'schedule.list.tittle': 'Listado control de horarios',
      'schedule.list.paginator.total': 'Registros por pagina',
      'schedule.list.paginator.next': 'Siguiente',
      'schedule.list.paginator.before': 'Anterior',
      'schedule.list.alert.date': 'La fecha no puede ser superior al dia de hoy, se pondra la hora por defecto',
      'schedule.list.update.succ': 'Has actualizado Correctamente',
      'schedule.list.update.err': 'Ha ocurrido un problema al Actualizar',
      'schedule.panel.tittle': 'Panel fichaje empresa',
      'schedule.user.not_active': '( Desactivado )',
      'target.edit.button': 'Editar objetivo',
      'target.edit.tittle': 'Actualizar Objetivo Global',
      'target.edit.roles': 'Roles aplicables',
      'target.edit.list': 'Lista objetivos',
      'target.edit.target': 'Objetivo',
      'target.edit.value': 'Valor',
      'target.edit.start': 'Inicio',
      'target.edit.end': 'Final',
      'target.edit.weigth': 'Peso',
      'target.edit.select.multiple': 'Seleccione una empresa y uno o varios roles',
      'target.edit.weigth.err': 'El peso tiene que ser un numero positivo',
      'target.edit.goal.err': 'El valor tiene que ser un numero positivo',
      'target.edit.succ': 'Objetivo actualizado con Exito',
      'target.edit.err': 'Ha ocurrido un problema al actualizar este objetivo',
      'target.new.tittle': 'Nuevo objetivo',
      'target.new.target.tittle': 'Nuevo Objetivo Global',
      'target.new.target.name': 'Nombre objetivo',
      'target.new.date.range': 'Rango de fechas',
      'target.new.add.succ': 'Objetivo Añadido',
      'target.new.add.succ_info': 'El objetivo se ha añadido correctamente',
      'target.new.add.err.company': 'No hay una Empresa seleccionada',
      'target.new.add.err.date': 'Comprueba que has seleccionado la fecha correctamente',
      'target.new.add.err.blank': 'El comprueba los espacios en blanco antes del nombre',
      'target.new.add.err.full': 'Debe rellenar todos los datos correctamente',
      'calendar.tittle': 'Modificar objetivos de hoy',
      'calendar.amount': 'Cantidad',
      'calendar.no_target': 'No hay objetivos para este rol',
      'calendar.today': 'Hoy',
      'calendar.month': 'Mes',
      'calendar.week': 'Semana',
      'calendar.day': 'Día',
      'calendar.list': 'Lista',
      'calendar.warning': 'Revise los datos',
      'calendar.warning_info': 'Alguno de los objetivos no han podido ser actualizados, revise que el objetivo es un dato positivo',
      'task.new': 'Crear nueva tarea',
      'task.tittle': 'Título',
      'task.tittle.err': 'El título es obligatorio',
      'task.description': 'Descripción',
      'task.description.err': 'La descripción es obligatoria',
      'task.date.limit': 'Fecha límite',
      'task.date.limit.err': 'Debe introducir fecha límite para la tarea',
      'task.assig': 'Asignado a',
      'task.succ_info': 'La tarea se ha creado correctamente, se informara por email al usuario designado',
      'task.err_info': 'Ha ocurrido un error al crear la tarea',
      'task.comment': 'Comentarios',
      'task.comment.err': 'No hay comentarios',
      'task.comment.add': 'Introduzca nuevo comentario aquí',
      'task.comment.add.err': 'El comentario no puede estar vacío',
      'task.comment.add.succ_info': 'Se ha añadido el comentario correctamente',
      'task.comment.add.err_info': 'Ha ocurrido un problema al añadir el comentario',
      'task.comment.delete.succ_info': 'Se ha borrado el comentario correctamente',
      'task.comment.delete.err_info': 'Ha ocurrido un problema al borrar el comentario',
      'task.detail.tittle_err': 'Debe introducir un titulo para la tarea',
      'task.detail.date.limit': 'Fecha límite de entrega',
      'task.detail.date.start': 'Fecha inicio tarea',
      'task.detail.date.start_err': 'No ha iniciado esta tarea',
      'task.detail.date.end': 'Fecha fin tarea',
      'task.detail.date.end_err': 'No ha finalizado esta tarea',
      'task.assig_err': 'No hay usuarios asignados',
      'task.created': 'Creado por',
      'task.modal.tittle': 'Detalles tarea',
      'task.updated.succ_info': 'La tarea se ha actualizado correctamente',
      'task.updated.err_info': 'Ha ocurrido un error al actualizar la tarea',
      'task.archived': 'Mostrar archivadas',
      'task.archived.show': 'Mostrar',
      'task.archived.hide': 'No mostrar',
      'task.archived.succ_info': 'La tarea se ha archivado correctamente',
      'task.archived.err_info': 'Ha ocurrido un error al archivar la tarea',
      'task.asignedto': 'Asignada a',
      'user.profile.tittle': 'Bienvenido',
      'user.profile.tittle2': 'Mi cuenta',
      'geo.disabled': 'La localizacion esta desactivada',
      'schedule.export.tittle': 'Informe Registro Fichaje Laboral',
      'schedule.export.list': 'Listado de fichajes',
      'schedule.export.users': 'Usuarios',
      'schedule.export.time': 'Total horas',
      'schedule.export.timedecimal': 'Total horas decimal',
      'schedule.export.date.start': 'Fecha inicio informe',
      'schedule.export.date.end': 'Fecha fin informe',
      'schedule.export.totalworked': 'Total de horas trabajadas',
      'schedule.export.document': 'InformeRegistroFichajeLaboral',
      'schedule.export.list.user': 'Listado de Fichajes por usuario',
      'schedule.export.sign': 'Firma digital',
      'schedule.export.diary': 'REGISTRO DIARIO DE JORNADA',
      'schedule.export.worker': 'Trabajador',
      'Pendiente':'Pendiente',
      'En progreso':'En progreso',
      'Finalizada':'Finalizada',
      'task.rest.today':'El plazo acaba hoy',
      'task.rest.day':'El plazo acaba en 1 día',
      'task.rest.general':'El plazo acaba en',
      'task.rest.end':'Se acabo el plazo',
      'task.rest.days':'Dias',
      'schedule.err.delete.all':'No puede borrar todos los registros',
      'schedule.err.time':'Debe rellenar la hora',
      'schedule.err.order':'Las fechas tienen que ir en orden ascendente',
      'schedule.err.twoentries':'No puede tener dos Entradas o Salidas juntas',
      'schedule.err.sortir':'No puede se puede empezar con salida',
      'sign.modal.tittle': 'Firma Digital',
      'sign.modal.add_comment': 'Añadir comentario',
      'sign.modal.add_comment.info': 'Escriba un comentario',
      'sign.modal.comment': 'Comentario',
      'sign.modal.clean': 'Limpiar',
      'upload.modal.name.err': 'Debe incluir el nombre del archivo',
      'upload.modal.file.err': 'Debe incluir un archivo',
      'upload.modal.file.err_info': 'Ha ocurrido un problema al subir el documento',
      'upload.modal.tittle': 'Subir archivos',
      'upload.modal.select.file': 'Seleccione archivo',
      'target.container.tittle': 'Objetivos para el rol',
      'error.user_has_been_disabled.body': 'El usuario con el que está intentando acceder está desactivado.',
      'error.user_has_been_disabled.title': 'Usuario desactivado',
    };

}
