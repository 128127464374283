import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';


@Injectable({ providedIn: 'root' })
export class FeatureGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticationService.getUser()) {
            return;
        }
        if (route.url[0].path === 'comercial-activity') {
            return this.authenticationService.canShowFeature('Comerciales');
        }
        else if (route.url[0].path === 'schedule') {
            return this.authenticationService.canShowFeature('Control Laboral');
        }
        else if (route.url[0].path === 'documents') {
            return this.authenticationService.canShowFeature('Documentos');
        }
        else if (route.url[0].path === 'refillable-documents') {
            return this.authenticationService.canShowFeature('Rellenable');
        }
        else if (route.url[0].path === 'task') {
            return this.authenticationService.canShowFeature('Gestor Tareas');
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
