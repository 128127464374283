import { Component, OnDestroy, OnInit } from '@angular/core';
import { eachMonthOfInterval, endOfDay, endOfMonth, format, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { Echarts } from 'echarts';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { forkJoin, from, Observable, Subscription } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { CalculatorUtil } from 'src/app/shared/services/calculator-util.service';
import { DateUtil } from 'src/app/shared/services/date-util.service';
import { UserStatisticsCalculator } from 'src/app/shared/services/stadistics/user-statistics.calculator';
import { ColorTypes } from 'src/app/shared/types/color.types';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { Company } from '../../../core/models/company/company.model';
import { User } from '../../../core/models/user/User.model';
import { EventService } from '../../../core/services/event.service';
import { StorageService } from '../../../core/services/storage.service';
import { UserProfileService } from '../../../core/services/user.service';
import { TranslatorService } from '../../../shared/services/translator.service';
import { UserFilterService } from '../../../shared/services/userFilter.service';

@Component({
  selector: 'app-users-efficiency-chart',
  templateUrl: './users-efficiency-chart.component.html',
  styleUrls: ['./users-efficiency-chart.component.scss']
})
export class UsersEfficiencyChartComponent implements OnInit, OnDestroy {

  chart: Echarts;
  options: any;
  dateRange: Date[];
  selectedDateFrom: Date;
  selectedDateTo: Date;
  selectedUsers: User[];
  userIds: string[];
  selectedCompaniesIds: string[];
  subscribeTypes: SubscribeTypes = new SubscribeTypes();
  colorTypes: ColorTypes = new ColorTypes();
  checked = true;
  usersByCompany: User[];
  users: User[];
  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;
  companiesSubcription: Subscription;
  loading = false;
  dataMap: any = {};
  companySelected: Company[] = [];
  monthIndex = [];
  userName: string;
  test: string;
  language;

  constructor(
    private readonly userService: UserProfileService,
    private readonly eventService: EventService,
    private localeService: BsLocaleService,
    private userFilterService: UserFilterService,
    private userStatisticsCalculator: UserStatisticsCalculator,
    private dateUtil: DateUtil,
    private calculatorUtil: CalculatorUtil,
    private storageService: StorageService,
    private translateService: TranslatorService,
  ) {
    this.language = this.storageService.getCompanies()[0].companyConfiguration.language;
    this.localeService.use(this.language);

    this.dateRange = [startOfYear(startOfDay(new Date())), endOfMonth(endOfDay(new Date()))];
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'MMMM-YY' });
  }

  ngOnInit() {
    this.options = {
      baseOption: {
        timeline: {
          axisType: 'category',
          autoPlay: true,
          playInterval: 2000,
          controlStyle: {
            position: 'left'
          },
          data: [],
        },
        title: {
          subtext: 'General'
        },
        tooltip: {
        },
        legend: {
          left: 'right',
          data: [this.translateService.trans('charts.complete'), this.translateService.trans('charts.eficiency')],
        },
        calculable: true,
        grid: {
          top: 80,
          bottom: 100,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true,
                formatter: function (params) {
                  return params.value.replace('\n', '');
                }
              }
            }
          }
        },
        xAxis: [
          {
            'type': 'category',
            'axisLabel': { 'interval': 0 },
            'data': [],
            splitLine: { show: false }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: this.translateService.trans('charts.percent')
          }
        ],
        series: [
          { name: this.translateService.trans('charts.complete'), type: 'bar' },
          { name: this.translateService.trans('charts.eficiency'), type: 'bar' },
          {
            name: 'Grafico',
            type: 'pie',
            center: ['85%', '25%'],
            radius: '28%',
            z: 100
          }
        ]
      },
      options: []
    };
    this.initializeCompaniesAndUsers(this.companySelected = [this.storageService.getCompanies()[0]]);
    this.companiesSubcription = this.eventService.subscribe(this.subscribeTypes.COMPANIES, companies => {
      this.companySelected = companies;
      this.initializeCompaniesAndUsers(companies);
    });
  }
  ngOnDestroy() {
    this.companiesSubcription.unsubscribe();
  }

  private initializeCompaniesAndUsers(companies: Company[]) {
    this.selectedCompaniesIds = companies.map(company => company.id).filter((value, index, self) => self.indexOf(value) === index);
    this.getUsers();
  }

  onChartInit(ec) {
    this.chart = ec;
  }

  onFormFieldChange() {
    this.dateRange[0] = startOfDay(this.dateRange[0]);
    this.dateRange[1] = startOfDay(this.dateRange[1]);
    this.reloadChart();
  }

  private getUsers() {
    const userObservables = [];
    this.selectedCompaniesIds.forEach(companyId => {
      userObservables.push(this.userService.usersByCompany$(companyId));
    });

    let users = [];
    from(userObservables)
      .pipe(concatAll())
      .subscribe((companyUsers: any) => {
        users = [...users, ...companyUsers];
      }, () => { }, () => {

        users = this.userFilterService.filterAgentsByRole(users);

        this.users = users;
        this.selectedUsers = users;
        this.reloadChart();
      });
  }

  selectAll() {
    if (this.checked) {
      this.selectedUsers = this.users;
    } else {
      this.selectedUsers = [];
    }
  }

  reloadChart() {
    this.options.options = [];
    this.reloadChartData();
  }

  reloadChartData() {
    this.loading = true;
    const startDate = startOfDay(this.dateRange[0]);
    const finishDate = endOfDay(this.dateRange[1]);
    const months = eachMonthOfInterval({
      start: startDate,
      end: finishDate
    });
    this.monthIndex = [];
    let subscriptions: Array<Observable<any[]>> = [];
    months.forEach(month => {
      this.monthIndex.push((month.toLocaleString(this.language, { month: 'short' }).replace(/\b\w/g, l => l.toUpperCase()) + format(month, 'yy')));
    });
    this.options.baseOption.timeline.data = this.monthIndex;
    this.options.baseOption.xAxis[0].data = this.selectedUsers.map((selectedUser, index) => {
      if (index % 2 === 0) {
        return '\n' + selectedUser.name + ' ' + selectedUser.lastName;
      } else {
        return selectedUser.name + ' ' + selectedUser.lastName;
      }
    });
    const activityTargetPercentByMonth = [];
    const efficiencyPercentByMonth = [];
    this.dataMap = [];
    months.forEach((month, index) => {
      subscriptions = [];
      let totalEfficiencyByMonth = 0;
      let totalActivityTargetyByMonth = 0;
      let userWithTarget = 0;
      let monthStartDate = startOfMonth(startOfDay(month));
      if (index === 0) {
        monthStartDate = startDate;
      }
      let monthFinishDate = endOfMonth(endOfDay(month));
      if (index + 1 === months.length) {
        monthFinishDate = finishDate;
      }
      const diffInBusinessDays = this.dateUtil.getDiffInBusinessDays(monthStartDate, monthFinishDate);
      const monthIndex = month.toLocaleString(this.language, { month: 'long' }).replace(/\b\w/g, l => l.toUpperCase()) + ' ' + format(month, 'yy');
      this.dataMap.activityTargetPercent = [];
      this.companySelected.forEach(company => {
        subscriptions.push(
          this.userService.rankingByUsers$(company.id, monthStartDate, monthFinishDate, this.selectedUsers.map(selectedUser => selectedUser.id)).pipe(map(response => response.user))
        );
      });
      forkJoin(subscriptions)
        .subscribe(userRank => {
          const uniqueAgents = this.mergeAgentsResult(userRank);
          activityTargetPercentByMonth[monthIndex] = [];
          efficiencyPercentByMonth[monthIndex] = [];
          uniqueAgents.forEach((agentByMonth) => {
            let sumActivity = 0;
            let sumTarget = 0;
            let activityTargetPercent = 0;
            let totalEfficiency: number;
            sumActivity = this.userStatisticsCalculator.sumActivities(agentByMonth.activitys);
            sumTarget = this.userStatisticsCalculator.sumTargetByAgentByDate(agentByMonth, monthStartDate, monthFinishDate);
            totalEfficiency = this.userStatisticsCalculator.calculateEfficiency(agentByMonth);
            const efficiencyByMonth = totalEfficiency / (diffInBusinessDays + 1);
            activityTargetPercent = ((sumActivity / sumTarget) * 100);
            activityTargetPercent = this.calculatorUtil.fixNanAndFinite(activityTargetPercent);
            if (sumTarget > 0) {
              userWithTarget++;
            }
            totalEfficiencyByMonth += efficiencyByMonth;
            totalActivityTargetyByMonth += activityTargetPercent;
            activityTargetPercentByMonth[monthIndex].push({ name: agentByMonth.name + ' ' + agentByMonth.lastName, value: parseFloat(activityTargetPercent.toFixed(2)) });
            efficiencyPercentByMonth[monthIndex].push({ name: agentByMonth.name + ' ' + agentByMonth.lastName, value: parseFloat(efficiencyByMonth.toFixed(2)) });
          });
          this.options.options[index] = {
            title: { text: monthIndex },
            series: [
              { data: activityTargetPercentByMonth[monthIndex] },
              { data: efficiencyPercentByMonth[monthIndex] },
              {
                data: [
                  { name: this.translateService.trans('charts.complete'), value: (parseFloat(totalActivityTargetyByMonth.toFixed(2)) / userWithTarget).toFixed(2) },
                  { name: this.translateService.trans('charts.eficiency'), value: (parseFloat(totalEfficiencyByMonth.toFixed(2)) / userWithTarget).toFixed(2) },
                ]
              }
            ]
          };
          if (this.options.options.length === months.length) {
            this.loading = false;
            this.chart.setOption(this.options, { notMerge: true });
          }
        });
    });
  }

  private mergeAgentsResult(usersArrays: User[][]): User[] {
    const totalAgents: any = {};
    usersArrays.forEach(users => {
      users.forEach(user => {
        if (!totalAgents[user.id]) {
          totalAgents[user.id] = user;
        } else {
          const totalTarget: any = {};
          totalAgents[user.id].activitys = totalAgents[user.id].activitys.concat(...user.activitys);
          user.companyUserEmployeeRoles.forEach(companyUserEmployeeRole => {
            companyUserEmployeeRole.idEmployeeRole.companyEmployeeRoleTargetTypes.forEach(companyRoleTarget => {
              if (companyRoleTarget.idTarget) {
                if (!totalTarget[companyRoleTarget.idTarget.id]) {
                  totalTarget[companyRoleTarget.idTarget.id] = companyRoleTarget;
                }
              }
            });
            totalAgents[user.id].companyUserEmployeeRoles[0].idEmployeeRole.companyEmployeeRoleTargetTypes = Object.values(totalTarget);
          });
        }
      });
    });
    return Object.values(totalAgents);
  }
}
