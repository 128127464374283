import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { eachMonthOfInterval, endOfDay, endOfMonth, isSameDay, startOfDay, startOfMonth } from 'date-fns';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserLogged } from 'src/app/core/models/auth.models';
import { Company } from 'src/app/core/models/company/company.model';
import { User } from 'src/app/core/models/user/User.model';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { EventService } from 'src/app/core/services/event.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { CalculatorUtil } from 'src/app/shared/services/calculator-util.service';
import { MobileService } from 'src/app/shared/services/mobile.service';
import { RolType } from 'src/app/shared/types/rol.types';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { environment } from '../../../environments/environment';
import { CompanyByCifResponse } from '../../core/models/company/companyByCif.response';
import { AgentRank } from '../../core/models/user/AgentRank.model';
import { DateUtil } from '../../shared/services/date-util.service';
import { UserStatisticsCalculator } from '../../shared/services/stadistics/user-statistics.calculator';
import { UserFilterService } from '../../shared/services/userFilter.service';

@Component({
    selector: 'app-perfomances',
    templateUrl: './perfomances.component.html',
    styleUrls: ['./perfomances.component.scss']
})
export class PerfomancesComponent implements OnInit, OnDestroy {
    companySelected: Company[] = [];
    agentsRank: AgentRank[];
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    showDates: boolean;
    rolType: RolType = new RolType();
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    isMobile: boolean;
    dateRange: Date[];
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    userLogged: UserLogged;
    sortedData: AgentRank[];
    companiesSubcription: Subscription;
    loading = true;
    loadingStart = true;
    rowSkeleton = { 'border-radius': '0', height: '40px' };

    constructor(
        private eventService: EventService,
        private userService: UserProfileService,
        private authService: AuthenticationService,
        private companyService: CompanyService,
        private mobileService: MobileService,
        private localeService: BsLocaleService,
        private storageService: StorageService,
        private userStatisticsCalculator: UserStatisticsCalculator,
        private calculatorUtil: CalculatorUtil,
        private dateUtil: DateUtil,
        private userFilterService: UserFilterService,
    ) {
        this.userLogged = this.authService.getUser();
        this.dateRange = [startOfMonth(new Date()), endOfMonth(new Date())];
        if (new Date() < this.dateRange[1]) {
            this.dateRange[1] = new Date();
        }
        const language = this.storageService.getCompanies()[0].companyConfiguration.language;

        this.localeService.use(language);

        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD-MM-YYYY' });
    }
    ngOnInit() {
        this.isMobile = this.mobileService.isMobile();
        this.companiesSubcription = this.eventService.subscribe(this.subscribeTypes.COMPANIES, companies => {
            this.loading = true;
            this.storageService.setCompanies(companies);
            this.rankingToAdminOrUser();
        });
        this.rankingToAdminOrUser();

    }

    ngOnDestroy() {
        this.companiesSubcription.unsubscribe();
    }

    rankingToAdminOrUser() {
        const companyStorage = this.storageService.getCompanies();
        if (!companyStorage) {
            return;
        }
        const rankingCompanyStatus = companyStorage[0].companyConfiguration.onlyRankingCompany;
        if (this.authService.getUser().roles === this.rolType.ROLE_USER
            && rankingCompanyStatus === false) {

            this.companyService.companiesByCif$().subscribe(
                (companies: CompanyByCifResponse) => {
                    this.companySelected = companies.companies;
                    this.reloadRanking();
                    return this.companySelected;
                });
        } else {
            this.companySelected = this.storageService.getCompanies();
            this.reloadRanking();
        }
    }

    reloadRanking() {
        this.loading = true;
        const startDate = startOfDay(this.dateRange[0]);
        const finishDate = endOfDay(this.dateRange[1]);
        const diffInBusinessDays = this.dateUtil.getDiffInBusinessDays(startDate, finishDate);
        const months = eachMonthOfInterval({
            start: startDate,
            end: finishDate
        });
        const subscriptions: Array<Observable<User[]>> = [];
        months.forEach((month, index) => {
            let monthStartDate = startOfMonth(startOfDay(month));
            if (index === 0) {
                monthStartDate = startDate;
            }
            let monthFinishDate = endOfMonth(endOfDay(month));
            if (index + 1 === months.length) {
                monthFinishDate = finishDate;
            }
            this.companySelected.forEach(company => {
                subscriptions.push(
                    this.userService.userRanking$(company.id, monthStartDate, monthFinishDate).pipe(map(response => response.user))
                );
            });
        });
        forkJoin(subscriptions)
            .subscribe(users => {
                let uniqueAgents = this.mergeAgentsResult(users);
                uniqueAgents = this.userFilterService.filterAgentsByRole(uniqueAgents);
                this.agentsRank = [];
                uniqueAgents.forEach(agent => {
                    let sumActivity = 0;
                    let sumTarget = 0;
                    let activityTargetPercent = 0;
                    let agentRank: AgentRank;
                    let totalEfficiency: number;
                    sumActivity = this.userStatisticsCalculator.sumActivities(agent.activitys);
                    sumTarget = this.userStatisticsCalculator.sumTargetByAgentByDate(agent, startDate, finishDate);
                    totalEfficiency = this.userStatisticsCalculator.calculateEfficiency(agent);
                    if (!isSameDay(startDate, finishDate)) {
                        totalEfficiency = totalEfficiency / (diffInBusinessDays + 1);
                    }
                    activityTargetPercent = ((sumActivity / sumTarget) * 100);
                    activityTargetPercent = this.calculatorUtil.fixNanAndFinite(activityTargetPercent);
                    agentRank = new AgentRank(agent, sumActivity, sumTarget, activityTargetPercent, totalEfficiency);
                    this.agentsRank.push(agentRank);
                });
                this.sortRanking();
                this.agentsRank.reverse();
                this.sortedData = this.agentsRank;
                this.loading = false;
                this.loadingStart = false;
            });
    }

    private mergeAgentsResult(usersArrays: User[][]): User[] {
        const totalAgents: any = {};
        usersArrays.forEach(users => {
            users.forEach(user => {
                if (!totalAgents[user.id]) {
                    totalAgents[user.id] = user;
                } else {
                    const totalTarget: any = {};
                    totalAgents[user.id].activitys = totalAgents[user.id].activitys.concat(...user.activitys);
                    user.companyUserEmployeeRoles.forEach(companyUserEmployeeRole => {
                        companyUserEmployeeRole.idEmployeeRole.companyEmployeeRoleTargetTypes.forEach(companyRoleTarget => {
                            if (companyRoleTarget.idTarget) {
                                if (!totalTarget[companyRoleTarget.idTarget.id]) {
                                    totalTarget[companyRoleTarget.idTarget.id] = companyRoleTarget;
                                }
                            }
                        });
                        totalAgents[user.id].companyUserEmployeeRoles[0].idEmployeeRole.companyEmployeeRoleTargetTypes = Object.values(totalTarget);
                    });
                }
            });
        });
        return Object.values(totalAgents);
    }

    private sortRanking() {
        this.agentsRank.sort((a, b) => {
            if (a.efficiency > b.efficiency) {
                return 1;
            }
            if (a.efficiency < b.efficiency) {
                return -1;
            }
            return 0;
        });
    }

    sortData(sort: Sort) {
        const data = this.agentsRank.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'percent': return this.compare(a.activityTargetPercent, b.activityTargetPercent, isAsc);
                case 'efficiency': return this.compare(a.efficiency, b.efficiency, isAsc);
                default: return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }
}


