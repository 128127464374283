import { TargetEspecifications } from '../targetEspecifications/targetEspecifications.model';
import { CompanyEmployeeRoleTargetTypes } from '../companyemployeeroletargettypes/companyemployeeroletargettypes.model';
import { Activity } from '../activity/activity.model';
export class Target {

    id: string;
    name: string;
    targetEspecifications?: TargetEspecifications[];
    targetEspecification?: TargetEspecifications;
    companyEmployeeRoleTargetTypes: CompanyEmployeeRoleTargetTypes[];
    activitys: Activity[];

    constructor(id: string,
                name: string,
                targetEspecification: TargetEspecifications,
                companyEmployeeRoleTargetTypes: CompanyEmployeeRoleTargetTypes[]) {

        this.id = id;
        this.name = name;
        this.targetEspecification = targetEspecification;
        this.companyEmployeeRoleTargetTypes = companyEmployeeRoleTargetTypes;
    }
}
