import { Component, Input, OnInit } from '@angular/core';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxKnobComponent } from 'jqwidgets-ng/jqxknob';
import { User } from 'src/app/core/models/user/User.model';
import { CalculatorUtil } from 'src/app/shared/services/calculator-util.service';
import { MobileService } from 'src/app/shared/services/mobile.service';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { environment } from '../../../environments/environment';
import { EventService } from '../../core/services/event.service';
import { UserStatisticsCalculator } from '../../shared/services/stadistics/user-statistics.calculator';

@Component({
    selector: 'app-agent-card',
    templateUrl: './agent-card.component.html',
    styleUrls: ['./agent-card.component.scss']
})
export class AgentCardComponent implements OnInit {

    @Input() agent: User;
    @Input() statisticsBoard: boolean = false;
    @Input() isSelected: boolean = false;

    sumActivity: number;
    sumTarget: number;
    activityTargetPercent: number;
    totalEfficiency: number;
    currentDate = new Date();
    urlAvatar = environment.storageBucket;
    defaultAvatar = environment.storageBucket + '/user/avatar/default.png';
    subscribeTypes: SubscribeTypes = new SubscribeTypes();
    isMobile: boolean;
    myKnob: jqxKnobComponent;
    myInput: jqxInputComponent;
    dial: any;

    progressBar: any =
        {
            style: { fill: '#ffff00' },
            background: { fill: 'ff0000' },
            size: '15%',
            offset: '70%'
        };

    pointer: any =
        {
            type: 'line',
            style: { fill: '#00a644' },
            size: '30%',
            offset: '70%',
            thickness: 0
        };
    cardSelected: boolean;

    constructor(
        private eventService: EventService,
        private userStatisticsCalculator: UserStatisticsCalculator,
        private mobileService: MobileService,
        private calculatorUtil: CalculatorUtil

    ) { }

    ngOnInit() {
        this.isMobile = this.mobileService.isMobile();
        this.cardSelected = true;
        if (this.statisticsBoard) {
            this.cardSelected = false;
            this.eventService.subscribe(this.subscribeTypes.USER_SELECTED, (userSelected: User) => {
                if (!userSelected || userSelected.id !== this.agent.id) {
                    this.cardSelected = false;
                } else {
                    this.cardSelected = true;
                }
            });

            if (this.isSelected === true) {
                this.cardSelected = true;
            }
        }

        this.sumActivity = this.userStatisticsCalculator.sumActivities(this.agent.activitys);
        this.sumTarget = this.userStatisticsCalculator.sumTargetByAgent(this.agent);
        this.activityTargetPercent = (this.sumActivity / this.sumTarget) * 100;
        this.totalEfficiency = this.userStatisticsCalculator.calculateEfficiency(this.agent);
        this.activityTargetPercent = this.calculatorUtil.fixNanAndFinite(this.activityTargetPercent);

        if (this.activityTargetPercent === 0) {
            this.progressBar = {
                style: { fill: '#ff0000' },
                background: { fill: '#ff0000' },
                size: '15%',
                offset: '70%'
            };
        }

        if (this.activityTargetPercent !== 0) {
            this.progressBar = {
                style: { fill: this.changeProgressBarColor(this.activityTargetPercent) },
                background: { fill: '#eeeeee' },
                size: '15%',
                offset: '70%'
            };
        }
    }

    changeProgressBarColor(percent: number) {
        if (percent < 50) {
            return '#ff0000';
        }
        if (percent >= 50 && percent < 85) {
            return '#ffae00';
        }
        if (percent >= 85 && percent <= 100) {
            return '#20ee00';
        }
        if (percent > 100) {
            return ' #00a2ff';
        }
    }

    sendEventUserSelected() {
        if (!this.cardSelected) {
            this.eventService.broadcast(this.subscribeTypes.USER_SELECTED, this.agent);
        } else {
            this.eventService.broadcast(this.subscribeTypes.USER_SELECTED, false);
        }
    }

    errorHandler(event) {
        event.target.src = this.defaultAvatar;
    }
}
