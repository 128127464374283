import gql from 'graphql-tag';

export const activityTargetsByUserInDateSelected = gql`
query getActivityTargetsByUserInDateSelected($companyId: String!,$userId: String!,$dateRange: [DateTime]!) {
    getActivityTargetsByUserInDateSelected(companyId: $companyId,userId: $userId,dateRange: $dateRange) {
        id,
        name,
        lastName
        color,
        color,
        avatar,
        companyUserEmployeeRoles{
            idCompany{
                id,
                name
            },
            idEmployeeRole{
                id,
                name
                companyEmployeeRoleTargetTypes{
                    idTarget{
                        id,
                        name,
                        targetEspecifications{
                            id,
                            startDate,
                            finishDate,
                            goal
                        },
                        activitys{
                            id,
                            realAmount,
                            dateDay
                        }
                    },
                },
            },
        },
    }
}`;
