export class CreateUserInput {
    name: string;
    lastName: string;
    email: string;
    password: string;
    contactPhone: string;
    avatar: string;
    status: boolean;
    roles: string;
    color: string;
}
