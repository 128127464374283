import { Moment } from 'moment';

export class DateRange {

    startDate: Moment;
    finishDate: Moment;

    constructor(start: Moment, end: Moment) {
        this.startDate = start;
        this.finishDate = end;

    }
}
