import gql from 'graphql-tag';

export const createTaskComment = gql`
  mutation  createTaskComment($createTaskCommentInput: CreateTaskCommentInput!) {
    createTaskComment(createTaskCommentInput: $createTaskCommentInput){
        result
    }
}`;

export const deleteTaskComment = gql`
  mutation  deleteTaskComment($deleteTaskCommentInput: DeleteTaskCommentInput!) {
    deleteTaskComment(deleteTaskCommentInput: $deleteTaskCommentInput){
        result
    }
}`;
