import { LanguageType } from '../../../shared/types/language.type';
export class UpdateCompanyConfigurationInput {
    companyId: string;

    onlyRankingCompany: boolean;

    language: LanguageType;

    constructor(companyId: string) {
        this.companyId = companyId;
    }
}
