import { Door } from '../door/door.model';
export class ScheduleControlListExport {
    userName: string;
    dateDay: Date;
    totalTime: string;
    door: Door[];
    userId: string;
    comment: string;
}
export class ScheduleControlListRowExport {
    userName: string;
    dateDay: Date;
    totalTime: string;
    door: Door[];
    userId: string;
    comment: string;
}
