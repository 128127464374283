export class UpdateWeighingOfCompanyEmployeeRoleTargetType {
    idCompany: string;
    idTarget: string;
    idEmployeeRole: string;
    status: boolean;
    weighing: number;

    constructor(idCompany: string, idTarget: string, idEmployeeRole: string, status: boolean, weighing: number) {
        this.idCompany = idCompany;
        this.idTarget = idTarget;
        this.idEmployeeRole = idEmployeeRole;
        this.status = status;
        this.weighing = weighing;
    }
}
