export class DeleteActivityInput {
    activityId: string;
    userId: string;
    targetId: string;

    constructor(activityId, userId, targetId) {
        this.activityId = activityId;
        this.userId = userId;
        this.targetId = targetId;
    }
}
