import { Injectable,  } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CalculatorUtil {
    constructor(
    ) {
    }
    fixNanAndFinite(variable):number {
        if (isNaN(variable) || !isFinite(variable)) {
            return 0;
        }
        else {
            return variable;
        }
    }
}
