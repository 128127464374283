import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { Workbook } from 'exceljs';
import { Company } from '../../core/models/company/company.model';
import { EmployeeRole } from '../../core/models/employeeRole/employeeRole.model';
import { ComercialActivitySummaryDto } from '../../core/models/summary/comercial-activities.dto';
import { CalculatorUtil } from './calculator-util.service';
import { TranslatorService } from './translator.service';

@Injectable({ providedIn: 'root' })
export class SummaryExportService {


  company: Company;
  dateRange: Date[];
  companies: Company[];

  constructor(
    private calculatorUtil: CalculatorUtil,
    private translateService: TranslatorService
  ) { }

  //EXPORTAR A CSV
  exportToCsv(comercialActivities: ComercialActivitySummaryDto[], dateRange: Date[], companies: Company[]) {
    this.dateRange = dateRange;
    this.companies = companies;

    this.exportAsExcelFile(comercialActivities, 'Resumen Actividad Comercial');
  }


  //EXPORTAR A EXCEL
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    var fs = require('file-saver');

    const workbook = new Workbook();
    const header = ['Usuario', 'Total Actividad', 'Total Objetivo', 'Completado'];

    let data = json;

    let worksheet = workbook.addWorksheet('Actividad Comercial');

    // Add new row
    let titleRow = worksheet.addRow(['Resumen Actividad Comercial']);

    //Add Leyend

    // Add Companies and Date Range selected

    worksheet.addRow([]);

    const dates = worksheet.addRow(["Rango de Fechas :", format(this.dateRange[0], 'dd/MM/yyyy'), format(this.dateRange[1], 'dd/MM/yyyy')]);

    worksheet.addRow([]);
    const companies = worksheet.addRow(['Empresas Seleccionadas:']);
    const companiesName = this.companies.map(companies => companies.name);
    const rowCompaniesName = worksheet.addRow(companiesName);

    // Set font, size and style in title row.
    titleRow.font = { family: 4, size: 11, bold: true, name: 'FreeMono' };
    dates.font = { family: 4, size: 11, bold: true, name: 'FreeMono' };
    companies.font = { family: 4, size: 11, bold: true, name: 'FreeMono' };
    rowCompaniesName.font = { family: 4, size: 10, bold: true, name: 'FreeMono' };

    // merge cells
    worksheet.mergeCells('A1:D2');

    // Blank Row
    worksheet.addRow([]);



    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;

    // Add Data

    let employeeRoles = data.map(data => data.employeeRole);
    employeeRoles = this.filterUniqueEmployeeRoles(employeeRoles);

    employeeRoles.forEach(employeeRole => {
      worksheet.addRow([]);
      const employeeRoleRow = worksheet.addRow(['', employeeRole.name, '', '']);
      employeeRoleRow.height = 50;
      employeeRoleRow.eachCell((cell, number) => {
        cell.font = { family: 4, size: 12, bold: true, name: 'FreeMono' };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c1ea99' },
          bgColor: { argb: 'c1ea99' },
        };
      });
      //Add Header Row
      let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd3d3d3' },
          bgColor: { argb: 'd3d3d3' },
        },
          cell.font = { size: 10, bold: true, name: 'FreeMono' }
      });

      data.forEach(d => {
        if (d.employeeRole.id !== employeeRole.id) {
          return;
        }
        const dataRow = [];
        dataRow[0] = d.user.name + " " + d.user.lastName;
        dataRow[1] = d.sumTotalActivity;
        dataRow[2] = d.sumTotalTarget;
        dataRow[3] = this.calculatorUtil.fixNanAndFinite((d.sumTotalActivity / d.sumTotalTarget) * 100).toFixed(2);
        const row = worksheet.addRow(dataRow);
        row.height = 50;
        row.eachCell((cell, number) => {
          cell.font = { bold: true, name: 'FreeMono' };
          cell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
        });
        const targetsName = d.targets.map(target => target.target.name);
        const targetsSumActivity = d.targets.map(target => target.sumActivity);
        const targetsSumTargets = d.targets.map(target => target.sumTarget);
        const targetsPercent = d.targets.map(target => target.percent);
        const rowTargetsName = worksheet.addRow(targetsName);
        const rowSumActivity = worksheet.addRow(targetsSumActivity);
        const rowSumTarget = worksheet.addRow(targetsSumTargets);
        const rowPercent = worksheet.addRow(targetsPercent);
        rowTargetsName.eachCell((cell, number) => {
          cell.font = { size: 9, bold: true, name: 'FreeMono' };
          cell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' }, };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ececec' },
            bgColor: { argb: 'ececec' },
          },
            worksheet.getColumn(number).width = 30;
        });
        rowSumActivity.eachCell((cell, number) => {
          cell.font = { size: 9, name: 'FreeMono' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'e97474' },
            bgColor: { argb: 'e97474' },
          };
        });
        rowSumTarget.eachCell((cell, number) => {
          cell.font = { size: 9, name: 'FreeMono' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '80bbe5' },
            bgColor: { argb: '80bbe5' },
          };
        });
        rowPercent.eachCell((cell, number) => {
          cell.font = { size: 9, name: 'FreeMono' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'add6b8' },
            bgColor: { argb: 'add6b8' },
          };
        });
        worksheet.eachRow(row => {
          row.eachCell(cell => {
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          });
        });

      });
    });

    const leyenda = worksheet.getCell('E3');
    leyenda.value = "Leyenda:";
    leyenda.font = { size: 12, bold: true, name: 'FreeMono' };
    leyenda.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    const activity = worksheet.getCell('F3');
    activity.value = "Actividad";
    activity.font = { size: 10, name: 'FreeMono' };
    activity.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    const target = worksheet.getCell('F4');
    target.value = "Objetivos";
    target.font = { size: 10, name: 'FreeMono' };
    target.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    const percent = worksheet.getCell('F5');
    percent.value = "% Completado";
    percent.font = { size: 10, name: 'FreeMono' };
    percent.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    activity.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e97474' },
      bgColor: { argb: 'e97474' },
    };

    target.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '80bbe5' },
      bgColor: { argb: '80bbe5' },
    };

    percent.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'add6b8' },
      bgColor: { argb: 'add6b8' },
    };

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ResumenActividadComercial.xlsx');
    });
  }

  private filterUniqueEmployeeRoles(employeeRoles: EmployeeRole[]) {
    employeeRoles = employeeRoles.filter((role, pos, arr) => {
      if ((arr.map(subRole => subRole.id).indexOf(role.id) === pos)) {
        return true;
      }
    });
    return employeeRoles;
  }
}
