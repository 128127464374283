import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CreateTaskInputDto } from '../../../core/models/task/create-task-input.dto';
import { User } from '../../../core/models/user/User.model';
import { StorageService } from '../../../core/services/storage.service';
import { TaskService } from '../../../core/services/task.service';
import { UserProfileService } from '../../../core/services/user.service';
import { AlertService } from '../../../shared/services/alert.service';
import { TranslatorService } from '../../../shared/services/translator.service';

@Component({
    selector: 'app-create-task',
    templateUrl: './create-task.component.html',
    styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit {

    @Output() created = new EventEmitter<boolean>();

    @Input() companyId: string;

    createTaskInput: CreateTaskInputDto;
    dateDay: Date;
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    users;
    selectedUser: User;
    error = [];

    constructor(
        public readonly activeModal: NgbActiveModal,
        private localeService: BsLocaleService,
        private userService: UserProfileService,
        private taskService: TaskService,
        private alertService: AlertService,
        private translateService: TranslatorService,
        private storageService: StorageService,
    ) {
        const language = this.storageService.getCompanies()[0].companyConfiguration.language;
        this.localeService.use(language);

        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD/MM/YYYY' });
        this.createTaskInput = new CreateTaskInputDto();
    }

    ngOnInit() {
        this.dateDay = new Date();
        this.getUsers();
    }

    dateSelected(dateDay) {
        this.createTaskInput.dueDate = new Date(dateDay);
    }

    private getUsers() {
        this.userService.usersByCompany$(this.companyId).subscribe(users => {
            this.users = users;
        });
    }

    save() {
        if (!this.validateForm()) {
            return;
        }
        if (this.selectedUser) {
            this.createTaskInput.userId = this.selectedUser.id;
        }
        this.createTaskInput.companyId = this.companyId;
        this.taskService.createTask$(this.createTaskInput).subscribe(result => {
            if (result.result) {
                this.alertService.show('success', this.translateService.trans('toast.edit_role.succ'), this.translateService.trans('task.succ_info'));
                this.created.emit(true);
                this.activeModal.close();

            } else {
                this.alertService.show('error', this.translateService.trans('toast.edit_role.err'), this.translateService.trans('task.err_info'));
            }
        });
    }

    private validateForm() {
        let isValid = true;
        if (this.createTaskInput.name === '' || !this.createTaskInput.name) {
            this.error['name'] = true;
            isValid = false;
        }
        if (this.createTaskInput.description === '' || !this.createTaskInput.description) {
            this.error['description'] = true;
            isValid = false;
        }
        if (!this.createTaskInput.dueDate) {
            this.error['dueDate'] = true;
            isValid = false;
        }
        return isValid;
    }
}
