import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { TranslatorService } from './translator.service';


@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor(
    private alertService: AlertService,
    private translateService: TranslatorService,

  ) { }

  async findMe(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          this.alertService.show('warning', this.translateService.trans('toast.alert'), 'La localizacion esta desactivada');
          resolve(false);
        });
    });
  }


}
