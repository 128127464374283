export class SubscribeTypes {
    COMPANIES = 'SelectedCompanies';
    COMPANIES_UPDATED = 'CompaniesUpdatedData';
    USER_ROLES = 'SelectedUserRoles';
    USER_SELECTED = 'DashboardUserSelected';
    USER_UPDATED = 'userUpdated';
    USER_LOGGED = 'userLogged';
    ACTIVITY_CREATED = 'ActivityCreated';
    ACTIVITY_DELETED = 'ActivityDeleted';
    ACTIVITY_DATEPICKER_CHANGE = 'ActivityDatePickerChange';
    EMPLOYEEROLE_CREATED = 'EmployeeRoleCreated';
    USER_CREATED = 'userCreated';
    DOCUMENT_CREATED = 'documentCreated';
    REFILLABLE_DOCUMENT_CREATED = 'refillableDocumentCreated';
    SIGNATURE_MODAL_CLOSED = 'signatureModalClosed';
    SCHEDULE_ADD = 'scheduleAdd';
    CHECK_LAST_DOOR = 'checkLastDoor';
  }
