import gql from 'graphql-tag';

export const loggedUserQuery = gql`
  query loggedUser{
    loggedUser{
      id
      email
      roles
      name
      lastName
      avatar
      color
      contactPhone
      status
    }
  }`;


