import { EmployeeRole } from './employeeRole.model';
import { CompanyUserEmployeeRole } from '../companyuseremployeerole/companyUserEmployeeRole.model';

export class EmployeeRoleByCompanyResponse {
    employeeRole: Array<CompanyUserEmployeeRole>;

    constructor(employeeRole: CompanyUserEmployeeRole[]) {
        this.employeeRole = employeeRole;
    }
}
