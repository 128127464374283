import { Component, OnInit, ElementRef, Input, OnDestroy } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';
import { Company } from 'src/app/core/models/company/company.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { User } from 'src/app/core/models/user/User.model';
import { SubscribeTypes } from 'src/app/shared/types/subcribes.types';
import { Subscription } from 'rxjs';
import { TranslatorService } from '../../shared/services/translator.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})




export class StatisticsComponent implements OnInit, OnDestroy {

  @Input() agent: User;
  companies: Company[];
  subscribeTypes: SubscribeTypes = new SubscribeTypes();
  companiesSubcription: Subscription;
  constructor(
    private eventService: EventService,
    private storageService: StorageService,
    private transService: TranslatorService,
  ) {

  }

  ngOnInit() {
    this.eventService.broadcast('changePageHeading', this.transService.trans('dashboard.admin.statistic'));
    this.companiesSubcription = this.eventService.subscribe(this.subscribeTypes.COMPANIES, data => {
      this.companies = data;
    });
    this.companies = this.storageService.getCompanies();
  }
  ngOnDestroy(): void {
    this.companiesSubcription.unsubscribe();
  }

}
