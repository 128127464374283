import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UiSwitchModule } from 'ngx-ui-switch';
import { UploadDocumentComponent } from '../../shared-component/document/upload-document/upload-document.component';
import { SharedModule } from '../../shared/shared.module';
import { DocumentsStoreComponent } from '../documents-store/documents-store.component';
import { DocumentsRoutingModule } from './documents-routing.module';
import dayGridPlugin from '@fullcalendar/daygrid';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin
]);
@NgModule({
    declarations: [
        DocumentsStoreComponent,
        UploadDocumentComponent,
    ],
    imports: [
        DocumentsRoutingModule,
        CommonModule,
        MatOptionModule,
        MatInputModule,
        MatCheckboxModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        FormsModule,
        DatepickerModule.forRoot(),
        NgxSkeletonLoaderModule,
        NgxEchartsModule,
        FullCalendarModule,
        ToasterModule,
        UiSwitchModule,
        ColorPickerModule,
        SharedModule
    ],
    providers: [
        ToasterService,
    ]
})
export class DocumentsModule { }
