import gql from 'graphql-tag';

export const documentList = gql`
    query getDocumentsByGroup($companyId: String!) {
        getDocumentsByGroup(companyId: $companyId) {
            id,
            name,
            url,
            status
        }
    }`;

export const getDocumentDownloadUrl = gql`
    query getDocumentDownloadUrl($documentId: String!) {
        getDocumentDownloadUrl(documentId: $documentId)
    }`;

export const getDocumentUploadUrl = gql`
    query getDocumentUploadUrl($documentName: String!,$contentType: String!) {
        getDocumentUploadUrl(documentName: $documentName,contentType: $contentType)
    }`;
