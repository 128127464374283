import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsStoreComponent } from '../documents-store/documents-store.component';
import { LayoutComponent } from '../../layouts/layout.component';
import { FeatureGuard } from '../../core/guards/feature.guard';
import { PageViewGuard } from '../../core/guards/pageView.guard';

const routes: Routes = [
  {
    path: 'documents', component: LayoutComponent, canActivate: [FeatureGuard], children: [
      { path: 'documents-store', component: DocumentsStoreComponent, canActivate: [PageViewGuard] },
      {
        path: '',
        redirectTo: 'documents-store',
        pathMatch: 'full'
      }
    ]
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
